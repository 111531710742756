import { AddButton, BoxedOutlineButton, ButtonColorFamilyEnum, DialogHandler, Filter, FilterClickResponse, FilterFunctionResponseType, FiltersBar, IconProps, SearchInput, SnackTypeEnum, TableFiltersPanel, TextVariantEnum, Typography } from "@cerebruminc/cerebellum";
import { useEffect, useState } from "react";
import { deepCloneObject, noop } from "src/helpers";
import { Header, SearchAndFilterContainer, SearchBox, TitleBox } from "./PageContentLayoutStyles";
import type { PageContentLayoutProps } from "./types";
import { IconShim } from "src/views/OrdersView/OrdersViewStyles";
import { FiltersBarRow } from "src/views/styles/layoutStyles";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { activeFiltersAtom, activeTableFiltersAtom, availableFiltersAtom, filterModalFiltersAtom, searchTagsAtom, showClearAllToastAtom, stashedFiltersAtom, stashedSearchTagsAtom } from "src/components/FiltersModal/atom";
import { useClearOneFilter } from "src/components/FiltersModal/hooks/useClearOneFilter";
import { useClearAllFilters } from "src/components/FiltersModal/hooks/useClearAllFilters";
import { formatFilterModalFilters, formatFilters, formatToActiveFilterType } from "src/views/filterHelpers";
import { UserTableFilterIds } from "src/components/UserTable/types";
import { useApplySelectedFilters } from "src/components/FiltersModal/hooks/useApplySelectedFilters";
import { FilterTabIdsEnum } from "src/views/OrdersView/types";
export function PageContentLayout(props: PageContentLayoutProps): JSX.Element {
  const {
    actionButtonClick = noop,
    actionButtonText,
    children,
    disableActionButton,
    filterItemClicked,
    hideActionButton,
    onSearch,
    openFiltersModal,
    pageHeadline,
    pageType,
    searchPlaceholder,
    searchValue,
    filtersButtonMeasure,
    filtersPanelOpen,
    setFiltersPanelOpen,
    selectedFiltersPanelColumn
  } = props;
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const [localSearchValue, setLocalSearchValue] = useState(searchValue);
  const [activeFilters, setActiveFilters] = useAtom(activeFiltersAtom);
  const clearOneFilter = useClearOneFilter();
  const clearAllFilters = useClearAllFilters();
  const setTableFilters = useSetAtom(activeTableFiltersAtom);
  const [showClearAllToast, setShowClearAllToast] = useAtom(showClearAllToastAtom);
  const stashedFilters = useAtomValue(stashedFiltersAtom);
  const [allFiltersClearedSnackId, setAllFiltersClearedSnackId] = useState<string>();
  const availableFilters = useAtomValue(availableFiltersAtom);
  const setFilterModalFilters = useSetAtom(filterModalFiltersAtom);
  const [searchTags, setSearchTags] = useAtom(searchTagsAtom);
  const stashedSearchTags = useAtomValue(stashedSearchTagsAtom);
  const applySelectedFilters = useApplySelectedFilters();
  const searchActive = !!searchValue || searchHasFocus;

  // If the search value changes, update the local search value
  useEffect(() => {
    setLocalSearchValue(searchValue);
  }, [searchValue]);
  const handleClearAll = () => {
    clearAllFilters();
    setLocalSearchValue("");
    onSearch?.("");
  };
  const clearSingleFilter = ({
    tag
  }: FilterClickResponse) => {
    const isSearchTag = tag?.searchType === "email";
    if (isSearchTag) {
      const clonedSearchTags = [...searchTags];
      const searchTagIndex = clonedSearchTags.findIndex(searchTag => searchTag.id === tag.id);
      clonedSearchTags.splice(searchTagIndex, 1);
      setSearchTags(clonedSearchTags);
      setLocalSearchValue("");
      onSearch?.("");
    } else {
      clearOneFilter(tag);
    }
  };
  const reinstallFilters = () => {
    const clonedFilters = deepCloneObject(stashedFilters);
    const clonedSearchTags = deepCloneObject(stashedSearchTags);
    const formattedFilters = formatFilters({
      availableFilters,
      filtersObject: clonedFilters
    });
    setTableFilters(clonedFilters);
    setActiveFilters(formattedFilters);
    setSearchTags(clonedSearchTags);
    setLocalSearchValue(clonedSearchTags[0]?.title || "");
    onSearch?.(clonedSearchTags[0]?.label || "");
    const tabIds = Object.values(UserTableFilterIds);
    const newFilters = formatFilterModalFilters({
      availableFilters,
      filters: clonedFilters,
      tabIds
    });
    setFilterModalFilters(newFilters);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (showClearAllToast) {
      allFiltersClearedSnackId && DialogHandler.clear(allFiltersClearedSnackId);
      const allFiltersSnackId = Date.now().toString();
      DialogHandler.show({
        id: allFiltersSnackId,
        Icon: Filter,
        snackType: SnackTypeEnum.Bar,
        text: "All Filters Cleared",
        textLinkText: "Undo",
        textLinkClick: reinstallFilters
      });
      setShowClearAllToast(false);
      setAllFiltersClearedSnackId(allFiltersSnackId);
    }
  }, [showClearAllToast]);
  const visibleFilters = [...searchTags, ...activeFilters];
  const filterFunction = ({
    activeFilterIds = [],
    columnCellId
  }: FilterFunctionResponseType) => {
    applySelectedFilters({
      activeFilterIds,
      tabId: (columnCellId as FilterTabIdsEnum)
    });
    setFiltersPanelOpen?.(false);
  };
  if (pageType === "SinglePage") {
    return <>
        <Header>
          <TitleBox>
            <Typography variant={TextVariantEnum.HeadingH1} data-sentry-unmask>
              {pageHeadline}
            </Typography>
            {!hideActionButton && <AddButton disabled={disableActionButton} text={actionButtonText} onClick={(actionButtonClick as any)} />}
          </TitleBox>
          <SearchAndFilterContainer>
            {onSearch && <SearchBox $extended={searchActive}>
                <SearchInput onEnter={value => {
              const trimmedValue = `${value}`?.trim() || "";
              setSearchTags([{
                searchType: "email",
                id: `email_${value}`,
                title: "Email",
                label: trimmedValue
              }]);
              onSearch(String(trimmedValue));
            }} onInputBlur={() => setSearchHasFocus(false)} onInputFocus={() => setSearchHasFocus(true)} onValueChange={value => setLocalSearchValue(value)} placeholder={searchPlaceholder} value={localSearchValue || ""} clearButton />
              </SearchBox>}
            {Boolean(openFiltersModal) && <BoxedOutlineButton Icon={({
            fill
          }: IconProps) => <IconShim>
                    <Filter fill={fill} />
                  </IconShim>} iconGap={10} paddingString={"10px 16px 10px 11px"} onClick={openFiltersModal} text="Filters" />}
          </SearchAndFilterContainer>
        </Header>
        {visibleFilters?.length > 0 && <FiltersBarRow $marginTop={5}>
            <FiltersBar alignRight={true} clear={clearSingleFilter} clickable={!filtersPanelOpen} clearAll={handleClearAll} colorFamily={ButtonColorFamilyEnum.CoolGrey} filters={visibleFilters} {...filterItemClicked ? {
          filterClick: filterItemClicked
        } : {}} />
          </FiltersBarRow>}
        {filtersPanelOpen && setFiltersPanelOpen && selectedFiltersPanelColumn && <TableFiltersPanel activeFilters={formatToActiveFilterType(activeFilters)} columnData={selectedFiltersPanelColumn} filtersButtonMeasure={filtersButtonMeasure} filterFunction={filterFunction} filtersPanelOpen={filtersPanelOpen} setFiltersPanelOpen={setFiltersPanelOpen} />}

        <div>{children}</div>
      </>;
  }
  return <div>{children}</div>;
}