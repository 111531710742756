import { BoxedButton, ConnotationColorEnum, DialogHandler, PrimaryButton, TextButton, DigitalInput, SnackTypeEnum, Truncate, BodyMPrimary, BodySPrimary } from "@cerebruminc/cerebellum";
import type { SettingsFlow, UiNodeImageAttributes, UiNodeTextAttributes, UpdateSettingsFlowBody } from "@ory/kratos-client";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { ORY_UI_ELEMENTS, paths } from "src/const";
import { analytics, createRedirectionWithParams, getGroupNodes, getInitialValues, redirectAndPreserveParams } from "src/helpers";
import { useDownloadRecoveryCodes, useGetReturnUrl, useLogoutFlow, useSession } from "src/hooks";
import { Spinner, SkipModal, OryMessages } from "src/components";
import { ButtonBox, CodeBox, CodeText, FormBase, Header, QRBox, FinishSleeve, StepText, AuthBox, BackButtonBox, SkipButtonBox, DownloadButtonBox, ErrorMessageBox, SuccessMessage, CodeGrid } from "./MfaFormStyles";
import { useAtom } from "jotai";
import { mfaStepAtom } from "./state";
export interface MfaFormProps {
  closeFunction?: () => void;
  flow?: SettingsFlow;
  inModal?: boolean;
  loading: boolean;
  onSubmit: (values: UpdateSettingsFlowBody) => Promise<SettingsFlow>;
  skipButton?: boolean;
  submitting: boolean;
}
export const MfaForm = ({
  closeFunction,
  flow,
  inModal,
  loading,
  onSubmit,
  skipButton,
  submitting
}: MfaFormProps) => {
  const router = useRouter();
  const [, doLogout] = useLogoutFlow();
  const returnTo = useGetReturnUrl();
  const downloadCodes = useDownloadRecoveryCodes();
  const [step, setStep] = useAtom(mfaStepAtom);
  const [code, setCode] = useState("");
  const [inputError, setInputError] = useState("");
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [skipModalVisible, setSkipModalVisible] = useState(false);
  const {
    session
  } = useSession();
  const totpNodes = getGroupNodes(flow?.ui?.nodes, "totp");
  const initialValues = getInitialValues(flow?.ui?.nodes, "totp");
  // qr code node
  const qrCodeNode = totpNodes.find(node => node.type === "img");
  const qrCodeUrl = (qrCodeNode?.attributes as UiNodeImageAttributes | undefined)?.src;
  // secret key node
  const secretKeyNode = totpNodes.find(node => node.attributes && "id" in node.attributes && node.attributes.id === "totp_secret_key");
  const secretKey = (secretKeyNode?.attributes as UiNodeTextAttributes)?.text?.text;
  const containsUnlinkButton = Boolean(flow?.ui?.nodes?.some(item => item.meta.label?.id === ORY_UI_ELEMENTS.UNLINK_TOTP_APP));
  const lookupSecretNodes = getGroupNodes(flow?.ui?.nodes, "lookup_secret");
  // if code is already generated/revealed then codes are available as "lookup_secret_codes"
  const codesNode = lookupSecretNodes.find(node => node.attributes && "id" in node.attributes && node.attributes.id === "lookup_secret_codes")?.attributes;

  // if look up secret is already confirmed then this node will be available
  // incase user wants to reveal their backup codes
  const revealNode = lookupSecretNodes.find(node => node.attributes && "name" in node.attributes && node.attributes.name === "lookup_secret_reveal");
  const confirmNode = lookupSecretNodes.find(node => node.attributes && "name" in node.attributes && node.attributes.name === "lookup_secret_confirm");

  // If backup is there we will just display it
  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO fix this
  useEffect(() => {
    updateSecretCodes(flow);
  }, [codesNode, flow]);

  // Clear code on route change
  // biome-ignore lint/correctness/useExhaustiveDependencies: Monitoring step to clear the code when it changes
  useEffect(() => {
    setCode("");
    setInputError("");
  }, [step]);

  // Logic to reveal the already generated backup codes
  useEffect(() => {
    // if code is not generated then we need to reveal it
    if (flow) {
      if (!!revealNode) {
        revealBackupCodes();
      }
    }
  }, [revealNode, flow]);
  const updateSecretCodes = (updatedFlow: typeof flow) => {
    const nodes = getGroupNodes(updatedFlow?.ui.nodes, "lookup_secret");
    const codesNodeAttributes = nodes.find(node => node.attributes && "id" in node.attributes && node.attributes.id === "lookup_secret_codes")?.attributes;
    if (codesNodeAttributes && "text" in codesNodeAttributes) {
      const codes = codesNodeAttributes.text.text.split(",").map(item => item.trim())
      // for already used backup codes it will return "used" word
      // which we don't need to show to user
      .filter(item => item !== "used");
      setBackupCodes(codes);
    }
  };
  const revealBackupCodes = async () => {
    try {
      const res = await onSubmit({
        method: "lookup_secret",
        lookup_secret_reveal: true,
        csrf_token: initialValues.csrf_token
      });
      updateSecretCodes(res);
    } catch (error) {
      handleError(error);
    }
  };
  const generateBackupCodes = async () => {
    try {
      const res = await onSubmit({
        method: "lookup_secret",
        lookup_secret_regenerate: true,
        csrf_token: initialValues.csrf_token
      });
      updateSecretCodes(res);
    } catch (error) {
      handleError(error);
    }
  };
  const confirmBackupCodes = async () => {
    try {
      await onSubmit({
        method: "lookup_secret",
        lookup_secret_confirm: true,
        csrf_token: initialValues.csrf_token
      });
    } catch (error) {
      handleError(error);
    }
  };

  // NOTE: we will use it when the "Regenerate UI" is ready
  const regenerateBackupCodes = async () => {
    try {
      const res = await onSubmit({
        method: "lookup_secret",
        lookup_secret_regenerate: true,
        csrf_token: initialValues.csrf_token
      });
      updateSecretCodes(res);
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleVerifyCode = async () => {
    setInputError("");
    if (!code) {
      setInputError("Code is required");
      return;
    }
    try {
      await onSubmit({
        method: "totp",
        totp_code: code,
        csrf_token: initialValues.csrf_token
      });
      await generateBackupCodes();
      setCode("");
      setStep(3);
    } catch (error) {
      setInputError("Invalid Code");
      handleError(error);
    }
  };
  const redirectUserToNextPage = () => {
    if (returnTo) {
      const finalUrl = createRedirectionWithParams(returnTo);
      window.location.href = finalUrl;
      return;
    }
    redirectAndPreserveParams(router, paths.home);
  };
  const onDone = async () => {
    if (confirmNode) {
      await confirmBackupCodes();
    }
    inModal ? closeFunction?.() : redirectUserToNextPage();
  };
  const onSkip = () => {
    redirectUserToNextPage();
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(secretKey);
    DialogHandler.show({
      snackType: SnackTypeEnum.Toast,
      text: "Secret Code copied to clipboard",
      colorFamily: ConnotationColorEnum.Positive
    });
  };
  const handleError = (error: any) => {
    if (error?.response?.data?.error?.id === "session_refresh_required") {
      DialogHandler.show({
        id: "refresh-notice",
        snackType: SnackTypeEnum.Toast,
        text: "You must log back in",
        colorFamily: ConnotationColorEnum.Negative
      });
      setTimeout(() => {
        doLogout();
      }, 800);
    } else {
      DialogHandler.show({
        id: "2fa-failed",
        snackType: SnackTypeEnum.Toast,
        text: "Something went wrong",
        colorFamily: ConnotationColorEnum.Negative
      });
    }
  };

  // 2FA UI's
  const render2FaCode = () => {
    return <>
        <Header data-sentry-unmask>Setting up 2-Factor Authentication</Header>
        <StepText data-sentry-unmask>
          <b>Step 1: </b>Download an authenticator app like Google Authenticator to your mobile device.
        </StepText>
        <StepText data-sentry-unmask>
          <b>Step 2: </b>Scan the following QR code or copy the key and paste it into your authenticator app.
        </StepText>
        <QRBox>
          <img src={qrCodeUrl} alt="Authenticator app QR code" />
        </QRBox>
        <CodeBox>
          <CodeText>{secretKey}</CodeText>
          <TextButton text="Copy" onClick={handleCopy} />
        </CodeBox>
        <StepText data-sentry-unmask>
          <b>Step 3: </b>After scanning or entering the key in the authenticator app, copy the 6-digit code to enter on
          the next screen.
        </StepText>
        <ButtonBox>
          <PrimaryButton buttonHeight={46} buttonWidth={184} directionalButtonGap={84} nextButton onClick={() => {
          setStep(2);
          analytics.track("2 Factor Next Step");
        }} shadow text="Next" />
        </ButtonBox>
      </>;
  };
  const renderCodeInput = () => {
    return <FinishSleeve>
        <Header data-sentry-unmask>Finish Setup</Header>
        <AuthBox>
          <DigitalInput label="Enter Authenticator Code" name="totp_code" focus={step === 2} onChange={e => setCode(e.value)} onComplete={handleVerifyCode} showValidationMessage={!!inputError} validationMessage={inputError} value={code} />
        </AuthBox>
        <ButtonBox>
          <PrimaryButton buttonHeight={46} buttonType="submit" loading={loading || submitting} onClick={handleVerifyCode} shadow text="Enable" />
        </ButtonBox>
      </FinishSleeve>;
  };
  const renderBackupCodes = () => {
    return <>
        <Header data-sentry-unmask>Backup Codes</Header>
        <SuccessMessage data-sentry-unmask>2-Factor Authentication is now enabled!</SuccessMessage>
        <BodyMPrimary data-sentry-unmask>
          Download or copy these backup codes to access your account if your authenticator app is ever unavailable.
        </BodyMPrimary>
        {backupCodes.length === 0 ? <div className="flex items-center justify-center my-5">
            <Spinner />
          </div> : <CodeGrid>
            {backupCodes.map(code => <BodySPrimary key={code}>{code}</BodySPrimary>)}
          </CodeGrid>}
        <DownloadButtonBox>
          <TextButton text="Download Codes" onClick={() => downloadCodes(flow)} />
        </DownloadButtonBox>
        <ButtonBox>
          <PrimaryButton text="Done" buttonHeight={46} onClick={onDone} loading={loading || submitting} shadow />
        </ButtonBox>
      </>;
  };
  return <FormBase>
      {/* Messsages are redundant in the modal */}
      {!inModal && !submitting && flow?.ui?.messages?.length && <ErrorMessageBox $stepTwo={step === 2}>
          {/* this renders all types of messages whether its error message after form submission or information messages */}
          <OryMessages messages={flow?.ui?.messages} />
        </ErrorMessageBox>}
      {step === 2 && <BackButtonBox>
          <BoxedButton text="Back" backButton onClick={() => setStep(step - 1)} />
        </BackButtonBox>}
      {step !== 3 && !containsUnlinkButton && skipButton && <SkipButtonBox>
          <TextButton text="Skip for now" onClick={() => {
        setSkipModalVisible(true);
        analytics.track("2 Factor Skip", {
          step
        });
      }} />
        </SkipButtonBox>}

      {/* Render 2FA UI */}
      {step === 1 && qrCodeUrl && render2FaCode()}
      {step === 2 && renderCodeInput()}
      {/* Don't show on step two, to prevent a page jump */}
      {(step === 3 || step !== 2 && containsUnlinkButton) && renderBackupCodes()}

      <SkipModal onSkip={onSkip} hideModal={() => setSkipModalVisible(false)} modalVisible={skipModalVisible} />
    </FormBase>;
};