import { TabModal, TabModalPaddingEnum } from "@cerebruminc/cerebellum";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import { activeTableFiltersAtom, availableFiltersAtom, draftFromDateAtom, draftToDateAtom, filterModalCustomFromAtom, filterModalCustomToAtom, filterModalFiltersAtom, filtersModalOpenAtom, windowSizeAtom } from "./atom";
import { getModalHeightMode, revertFilterModalFilters } from "./helpers";
import type { TabsInterface } from "./types";
export const contentWidth = 328;
export const modalHeight = 550;
export const smallModalHeight = 445;
const horizontalTabMenuHeight = 144;
const sleevePadding = 75 + 50; // top + bottom
const titleMargin = 16;
export const mobileMenuNavAndPadding = horizontalTabMenuHeight + sleevePadding + titleMargin; // set in TabModal

interface FiltersModalProps {
  activeTab?: string;
  filters: Array<TabsInterface>;
  onClose?: () => void;
  onTabChange?: (id: string) => void;
  sidebarWidth?: number;
}

// The atoms used for filters modal needs to be wrapped in a provider with a
// unique store for each instance of the filters modal. This is because atoms
// are global and we don't want the filters modal to share state between
// instances. Failing to wrap with a provider will cause the filters modal to
// share state between instances and will cause unexpected behavior.

export function FiltersModal({
  sidebarWidth = 228,
  filters,
  activeTab,
  onClose,
  onTabChange
}: FiltersModalProps) {
  const tableFilters = useAtomValue(activeTableFiltersAtom);
  const availableFilters = useAtomValue(availableFiltersAtom);
  const [filtersModalFilters, setFilterModalFilters] = useAtom(filterModalFiltersAtom);
  const [filtersModalOpen, setFiltersModalOpen] = useAtom(filtersModalOpenAtom);
  const setDraftToDate = useSetAtom(draftToDateAtom);
  const setDraftFromDate = useSetAtom(draftFromDateAtom);
  const customToDate = useAtomValue(filterModalCustomToAtom);
  const customFromDate = useAtomValue(filterModalCustomFromAtom);
  const {
    height,
    width
  } = useAtomValue(windowSizeAtom);
  const horizontalMenu = width <= 600;
  const tabs: Array<TabsInterface> = useMemo(() => filters.map(filterTab => ({
    ...filterTab,
    payload: filtersModalOpen ? filterTab.payload : <></>,
    alert: filterTab.radioType && filtersModalFilters[filterTab.id] ? 1 : filtersModalFilters[filterTab.id]?.length || 0
  })), [filters, filtersModalFilters, filtersModalOpen]);
  return <TabModal activeTab={activeTab} contentWidth={horizontalMenu ? 600 : contentWidth} closeFunction={() => {
    setFiltersModalOpen(false);
    onClose?.();
    if (customFromDate) {
      setDraftFromDate(customFromDate);
    } else {
      setDraftFromDate(undefined);
    }
    if (customToDate) {
      setDraftToDate(customToDate);
    } else {
      setDraftToDate(undefined);
    }
    const newModalFilters = revertFilterModalFilters({
      availableFilters,
      tableFilters,
      tabs
    });
    setFilterModalFilters(newModalFilters);
  }} dividerExtension={0} fixedHeight={horizontalMenu ? height : getModalHeightMode(height)} horizontalMenu={horizontalMenu} horizontalTabPadding="0" modalPadding={TabModalPaddingEnum.Medium} onTabChange={onTabChange} show={filtersModalOpen} sidebarWidth={sidebarWidth} tabs={tabs} title="Filters" verticalTabPadding="0" />;
}