import { UiNode, UiNodeInputAttributes } from "@ory/kratos-client";
import * as yup from "yup";
import type { AnyObject } from "yup/lib/types";

import { GroupType } from "./types";
import { getGroupNodes } from "./getGroupNodes";
import { inputLabelMapping } from "./const";

type GetValidationSchemaParams = {
  nodes: UiNode[];
  renderOnlyGroup?: GroupType;
  shouldApplyPasswordValidation?: boolean;
};

interface FlexibleObjectDictionary {
  [key: string]: string | undefined | AnyObject;
}

const REGEX_NO_SPACE = /^[^\s]*$/;

// Returns dynamic validations based on flow nodes
export const getValidationSchema = ({
  nodes = [],
  renderOnlyGroup,
  shouldApplyPasswordValidation = true,
}: GetValidationSchemaParams) => {
  let schemaObj: FlexibleObjectDictionary = {};
  const groupNodes = getGroupNodes(nodes, renderOnlyGroup);
  for (const item of groupNodes) {
    const attributes = item.attributes as UiNodeInputAttributes;
    const name = attributes.name;
    if (attributes.node_type === "input" && attributes.type !== "hidden") {
      if (attributes.required) {
        const isNested = (name || "").indexOf(".") >= 0; // isNested field something like "traits.email"
        const label = item.meta?.label?.text
          ? inputLabelMapping[item.meta?.label?.text] || item.meta?.label?.text
          : "Field";
        let requiredValidation = yup.string().required(`${label} required.`);
        // if input type is email then add extra validation for email
        if (attributes.type === "email") {
          requiredValidation = requiredValidation
            .email("Invalid email")
            .test("no-special-chars", "Email should not contain special characters", (value) => {
              if (!value) return true; // let required rule handle this case
              const re = /[ '"\(\),;:<>\[\]\\|?=&#!/]/;
              const match = value.match(re);
              if (match) {
                // Create a custom validation error with the specific special character
                const message = `Email should not contain special character: ${match[0]}`;
                throw new yup.ValidationError(message, null, name);
              }
              return true;
            });
        } else if (attributes.type === "password" && shouldApplyPasswordValidation) {
          requiredValidation = requiredValidation
            .min(8, "Minimum of 8 characters.")
            .matches(REGEX_NO_SPACE, "Please remove spaces");
        }
        if (!isNested) {
          // if simple flat name
          schemaObj[name] = requiredValidation;
        } else {
          // if nested name like "traits.email"
          const reverseArr = name.split(".").reverse(); // ["email", "traits"]
          const nestedObjValidation = reverseArr.slice(1).reduce(
            (yupObj, path) => {
              return { [path]: yup.object().shape(yupObj) } as AnyObject;
            },
            { [reverseArr[0]]: requiredValidation },
          );

          schemaObj = { ...schemaObj, ...nestedObjValidation };
        }
      }
    }
  }

  return yup.object().shape(schemaObj as any);
};
