import { styled } from "styled-components";
import { SPINNER_SIZE, Spinner } from "./Spinner";
import { colors, TextVariantEnum, Typography } from "@cerebruminc/cerebellum";
const HALF = SPINNER_SIZE / 2;
const Wrapper = styled.div`
  background-color: ${colors.WHITE};
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SpinnerPosition = styled.div`
  height: ${SPINNER_SIZE}px;
  left: calc(50% - ${HALF}px);
  top: calc(50% - ${HALF}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Message = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: ${colors.BLACK};
`;
interface WholePageLoaderProps {
  message?: string;
}
export const WholePageLoader = (props: WholePageLoaderProps) => {
  return <Wrapper id="whole-page-loader">
      <SpinnerPosition>
        <Spinner />
        {props.message && <Message>
            <Typography variant={TextVariantEnum.BodyMSecondary}>{props.message}</Typography>
          </Message>}
      </SpinnerPosition>
    </Wrapper>;
};