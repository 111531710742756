import { BodyLPrimary, HeadingH1, Modal } from "@cerebruminc/cerebellum";
import { useAtomValue } from "jotai";
import { styled } from "styled-components";
import { noInvitationModalVisibleAtom } from "./../state";
import { ModalMaxWidth } from "./VidAcceptInviteModal";
const Header = styled(HeadingH1)`
  margin: 0 0 15px;
  text-align: center;
`;
const Text = styled(BodyLPrimary)`
  text-align: center;
`;
const VidNoInvitationModal = () => {
  const modalVisible = useAtomValue(noInvitationModalVisibleAtom);
  return <Modal show={modalVisible} paddingString="64px 66px 78px">
      <ModalMaxWidth>
        <Header data-sentry-unmask>Hello!</Header>
        <Text data-sentry-unmask>
          This application is invite only. Please check your email for an invitation, or contact your organization.
        </Text>
      </ModalMaxWidth>
    </Modal>;
};
export default VidNoInvitationModal;