import { ColumnType, TextStyleEnum } from "@cerebruminc/cerebellum";
import { Organization } from "@cerebruminc/neuron-sdk";
import { RoleEnum } from "src/const";
import { RoleCell, OrgCell, NameCell } from "./componentWrappers";
import { rolePrettyNameLib } from "./helpers";
import { UserTableSortEnum, UserTableFilterIds } from "./types";
import { ExtendedTooltipType, FiltersObjectType } from "src/views/types";

export const emailColumn: ColumnType = {
  cellId: UserTableSortEnum.EMAIL,
  label: "Email",
  minWidth: 80,
  textStyle: TextStyleEnum.Tertiary,
  width: 10,
};

export const nameColumn: ColumnType = {
  cellId: UserTableSortEnum.FIRST_NAME,
  label: "Full Name",
  textStyle: TextStyleEnum.Tertiary,
  width: 10,
  minWidth: 240,
  reverseSort: true,
  ComponentWrapper: NameCell,
};

export const roleColumn: ColumnType = {
  cellId: UserTableFilterIds.Role,
  disableSort: true,
  fixedWidth: true,
  label: "Role",
  textStyle: TextStyleEnum.Secondary,
  width: 230,
  ComponentWrapper: RoleCell,
  filters: Object.values(RoleEnum).map((role) => ({ id: role, label: rolePrettyNameLib(role), value: role })),
};

export const orgsColumn = (organizations?: Organization[]): ColumnType => ({
  cellId: UserTableFilterIds.Organization,
  disableSort: true,
  fixedWidth: true,
  label: "Organizations",
  textStyle: TextStyleEnum.Secondary,
  width: 250,
  ComponentWrapper: OrgCell,
  filters: organizations?.map((org) => ({
    id: org.id,
    label: org.name,
    name: org.name,
  })),
});

interface GetUserTableColumnsProps {
  filters: FiltersObjectType;
  handleMouseEnteredRow?: (tooltipOptions: ExtendedTooltipType) => void;
  handleMouseExitedRow?: () => void;
}

export const getUserTableColumns = ({ filters }: GetUserTableColumnsProps): ColumnType[] => {
  const columns: ColumnType[] = [
    nameColumn,
    emailColumn,
    {
      cellId: UserTableFilterIds.Role,
      disableSort: true,
      fixedWidth: true,
      label: "Role",
      textStyle: TextStyleEnum.Secondary,
      width: 230,
      ComponentWrapper: RoleCell,
      filters: filters.roles.map((role) => ({ id: role.id, label: role.label })),
    },
    {
      cellId: UserTableFilterIds.Organization,
      disableSort: true,
      fixedWidth: true,
      label: "Organizations",
      textStyle: TextStyleEnum.Secondary,
      width: 250,
      ComponentWrapper: OrgCell,
      filters: filters.organizations?.map((org) => ({
        id: org.id,
        label: org.label,
      })),
    },
  ];
  return columns;
};
