import { HeadingH1, Modal, PrimaryButton, SubheadingMPrimary, colors, loadingPseudo } from "@cerebruminc/cerebellum";
import { useAtomValue } from "jotai";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { styled } from "styled-components";
import { scrollbarWidthAtom } from "src/atom";
import { WholePageLoader } from "src/components";
import { isOneAndDoneUserAtom, processingVoucherAtom, vidOrganizationAtom } from "./../state";
import { useVidGetBeams, useVidInviteAuthorization } from "./../views/hooks";
const ORG_LOGO_SIZE = 150;
export const ModalMaxWidth = styled.div<{
  $minHeight?: number;
  $narrow?: boolean;
}>`
  min-height: ${({
  $minHeight
}) => $minHeight || 0}px;
  width: ${({
  $narrow
}) => $narrow ? 400 : 489}px;
  @media all and (max-width: 720px) {
    min-width: 390px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
  }
  @media all and (max-width: 550px) {
    min-width: inherit;
  }
`;
export const ModalOrgLogo = styled.div`
  width: 100%;
  height: auto;
  max-width: 150px;
  max-height: 150px;
  margin: 0 auto 40px;
  display: flex;
  justify-content: center;
`;
export const Header = styled(HeadingH1)`
  margin: 24px 0 8px;
  text-align: center;
`;
const HelperText = styled(SubheadingMPrimary)`
  margin-top: 35px;
  text-align: center;
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  margin-top: 50px;
`;
const ButtonBox = styled.div`
  text-align: center;
`;
const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
const ImageBox = styled.div<{
  $display?: boolean;
}>`
  width: ${ORG_LOGO_SIZE}px;
  height: ${ORG_LOGO_SIZE}px;
  display: ${({
  $display = true
}) => $display ? "block" : "none"};
`;
const LogoLoading = styled(ImageBox)`
  width: ${ORG_LOGO_SIZE}px;
  height: ${ORG_LOGO_SIZE}px;
  overflow: hidden;
  border-radius: 50%;
  ${loadingPseudo({
  bgColor: colors.COOL_GREY_4,
  loadingColor: colors.COOL_GREY_15
})}
`;
const TextLoading1 = styled.div`
  width: 100%;
  height: 40px;
  ${loadingPseudo({
  topOffset: 5,
  bgColor: colors.COOL_GREY_4,
  loadingColor: colors.COOL_GREY_15,
  borderRadius: 6
})}
`;
const TextLoading2 = styled.div`
  width: 75%;
  height: 40px;
  margin: 0 auto;
  ${loadingPseudo({
  topOffset: 5,
  bgColor: colors.COOL_GREY_4,
  loadingColor: colors.COOL_GREY_15,
  borderRadius: 6
})}
`;
const VidAcceptInviteModal = () => {
  const router = useRouter();
  const scrollbarWidth = useAtomValue(scrollbarWidthAtom);
  const vidOrganization = useAtomValue(vidOrganizationAtom);
  const isOneAndDoneUser = useAtomValue(isOneAndDoneUserAtom);
  const processingVoucher = useAtomValue(processingVoucherAtom);
  const {
    acceptInvite,
    emailVerified,
    helperText,
    modalVisible,
    isButtonEnabled,
    processingInvite
  } = useVidInviteAuthorization();
  // get beams
  useVidGetBeams();
  const [disableModalAnimation, setDisableModalAnimation] = useState(true);
  const [showOrgLogo, setShowOrgLogo] = useState(true); // we assume the logo loads successfully, and handle the error case
  const [showSpinner, setShowSpinner] = useState(true);
  const headerText = emailVerified ? `You’ve been invited to join as a member of ${vidOrganization?.name}` : "A verification email has been sent to your inbox";
  const show = (!emailVerified || modalVisible) && router.route !== "/vid/success";

  // Prevent scrolling when the modal is open
  useEffect(() => {
    if (show && !showSpinner) {
      document.body.style.overflowY = "hidden";
      if (scrollbarWidth && scrollbarWidth > 0) {
        document.body.style.paddingRight = `${scrollbarWidth}px`;
      }
    } else {
      setTimeout(() => {
        document.body.style.overflowY = "scroll";
        document.body.style.paddingRight = "";
      }, 400);
    }
  }, [show, showSpinner, scrollbarWidth]);
  useEffect(() => {
    const modalIsReady = !processingVoucher;
    if (modalIsReady) {
      setTimeout(() => {
        setShowSpinner(false);
      }, 200);
      setDisableModalAnimation(false);
    }
  }, [processingVoucher]);

  // Portal logic
  const [fallbackNode, setFallbackNode] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setFallbackNode(document?.getElementById("cerebellum_modal_portal") || document?.body);
  }, []);
  const handleOrgLogoLoadError = useCallback(() => {
    // Hide the logo if it fails to load
    setShowOrgLogo(false);
  }, []);
  return <>
      {showSpinner && fallbackNode ? ReactDOM.createPortal(<WholePageLoader />, fallbackNode) : null}
      <Modal show={show} noAnimate={disableModalAnimation} paddingString="64px 30px 78px">
        <ModalMaxWidth $minHeight={366} $narrow={!emailVerified}>
          <ModalOrgLogo>
            {vidOrganization?.logoUrl ? <ImageBox $display={showOrgLogo}>
                <Img src={vidOrganization.logoUrl} onError={handleOrgLogoLoadError} />
              </ImageBox> : <LogoLoading />}
          </ModalOrgLogo>

          {emailVerified && !isOneAndDoneUser && <HelperText data-sentry-unmask>Thank you verifying your email!</HelperText>}
          <Header>
            {emailVerified && !vidOrganization?.name && !disableModalAnimation ? <>
                <TextLoading1 />
                <TextLoading2 />
              </> : headerText}
          </Header>
          {helperText && <HelperText>{helperText}</HelperText>}

          <ButtonGroup>
            <ButtonBox>
              <PrimaryButton text="Accept" disabled={!isButtonEnabled || !emailVerified} loading={processingInvite} onClick={acceptInvite} buttonHeight={46} buttonWidth={189} />
            </ButtonBox>
          </ButtonGroup>
        </ModalMaxWidth>
      </Modal>
    </>;
};
export default VidAcceptInviteModal;