import { BoxedButton } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
interface HeaderH1Props {
  $leftText?: boolean;
}
const HeaderH1 = styled.h1<HeaderH1Props>`
	text-align: ${({
  $leftText
}) => $leftText ? "start" : "center"};
	font-size: 30px;
  line-height: 36px;
	font-weight: 700;
	letter-spacing: 0.8px;
	margin-bottom: 28px;
	text-align: center;
	width: 100%;
`;
const HeaderBackButtonWrapper = styled.div`
	align-self: flex-start;
	font-weight: 200;
	font-size: 14px;
  line-height: 20px;
	margin-bottom: 28px;
	margin-top: -24px;
	margin-left: -24px;
`;
interface ComponentProps {
  backButtonFunction: any;
  headerText: string;
  leftText?: boolean;
  showBackButton: boolean;
}
export function AuthBoxHeader(props: ComponentProps) {
  const {
    headerText,
    leftText,
    showBackButton,
    backButtonFunction
  } = props;
  return <>
      {showBackButton && <HeaderBackButtonWrapper id="back-button">
          <BoxedButton backButton onClick={backButtonFunction} text="Back" />
        </HeaderBackButtonWrapper>}
      <HeaderH1 id="box-header" $leftText={leftText} data-sentry-unmask>
        {headerText}
      </HeaderH1>
    </>;
}