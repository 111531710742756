import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { deepCloneObject } from "src/helpers";
import { formatFilters, getValuesFromIds } from "src/views/filterHelpers";
import {
  activeFiltersAtom,
  activeTableFiltersAtom,
  availableFiltersAtom,
  customDateFilterFromAtom,
  customDateFilterToAtom,
  filterModalFiltersAtom,
} from "../atom";
import type { FilterModalFiltersType } from "../../../views/OrdersView/types";

interface ApplySelectedFiltersProps {
  activeFilterIds?: string[];
  tabId: string;
}

export const useApplySelectedFilters = () => {
  const setActiveFilters = useSetAtom(activeFiltersAtom);
  const availableFilters = useAtomValue(availableFiltersAtom);
  const [tableFilters, setTableFilters] = useAtom(activeTableFiltersAtom);
  const customFrom = useAtomValue(customDateFilterFromAtom);
  const customTo = useAtomValue(customDateFilterToAtom);
  const [filterModalFilters, setFilterModalFilters] = useAtom(filterModalFiltersAtom);

  const applySelectedFilters = ({ activeFilterIds, tabId }: ApplySelectedFiltersProps) => {
    const clonedFilters = deepCloneObject(tableFilters);
    if (activeFilterIds?.length === 0 || !activeFilterIds) {
      delete clonedFilters[tabId];
    } else {
      clonedFilters[tabId] = activeFilterIds;
    }
    setActiveFilters(
      formatFilters({
        availableFilters,
        filtersObject: clonedFilters,
        fromDate: customFrom || undefined,
        toDate: customTo || undefined,
      }),
    );
    setTableFilters(clonedFilters);

    const clonedFilterModalFilters = deepCloneObject(filterModalFilters);
    const valueArray = getValuesFromIds({
      filterIds: activeFilterIds || [],
      filters: availableFilters[tabId],
    });
    clonedFilterModalFilters[tabId as keyof FilterModalFiltersType] = valueArray;
    setFilterModalFilters(clonedFilterModalFilters);
  };

  return applySelectedFilters;
};
