import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { useFetchData } from 'src/graphql/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Byte: any;
  DateTimeISO: any;
  JSON: any;
};

export type Address = {
  __typename?: 'Address';
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  applicantDataId?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  passportId?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  stateIdentificationCardId?: Maybe<Scalars['String']>;
  type?: Maybe<AddressTypeEnum>;
  updatedAt: Scalars['DateTimeISO'];
  userId?: Maybe<Scalars['String']>;
};

export type AddressCreateInput = {
  User?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutAddressesInput>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutAddressInput>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutAddressInput>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutAddressInput>;
  type?: InputMaybe<AddressTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type AddressCreateManyApplicantDataInput = {
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  passportId?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  stateIdentificationCardId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AddressTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type AddressCreateManyApplicantDataInputEnvelope = {
  data: Array<AddressCreateManyApplicantDataInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AddressCreateManyOrganizationInput = {
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  applicantDataId?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  passportId?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  stateIdentificationCardId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AddressTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type AddressCreateManyOrganizationInputEnvelope = {
  data: Array<AddressCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AddressCreateManyUserInput = {
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  applicantDataId?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  passportId?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  stateIdentificationCardId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AddressTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type AddressCreateManyUserInputEnvelope = {
  data: Array<AddressCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AddressCreateNestedManyWithoutApplicantDataInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutApplicantDataInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutApplicantDataInput>>;
  createMany?: InputMaybe<AddressCreateManyApplicantDataInputEnvelope>;
};

export type AddressCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AddressCreateManyOrganizationInputEnvelope>;
};

export type AddressCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutUserInput>>;
  createMany?: InputMaybe<AddressCreateManyUserInputEnvelope>;
};

export type AddressCreateNestedOneWithoutPassportInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutPassportInput>;
  create?: InputMaybe<AddressCreateWithoutPassportInput>;
};

export type AddressCreateNestedOneWithoutStateIdentificationCardInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutStateIdentificationCardInput>;
  create?: InputMaybe<AddressCreateWithoutStateIdentificationCardInput>;
};

export type AddressCreateOrConnectWithoutApplicantDataInput = {
  create: AddressCreateWithoutApplicantDataInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutOrganizationInput = {
  create: AddressCreateWithoutOrganizationInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutPassportInput = {
  create: AddressCreateWithoutPassportInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutStateIdentificationCardInput = {
  create: AddressCreateWithoutStateIdentificationCardInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutUserInput = {
  create: AddressCreateWithoutUserInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateWithoutApplicantDataInput = {
  User?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutAddressInput>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutAddressInput>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutAddressInput>;
  type?: InputMaybe<AddressTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type AddressCreateWithoutOrganizationInput = {
  User?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutAddressesInput>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutAddressInput>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutAddressInput>;
  type?: InputMaybe<AddressTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type AddressCreateWithoutPassportInput = {
  User?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutAddressesInput>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutAddressInput>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutAddressInput>;
  type?: InputMaybe<AddressTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type AddressCreateWithoutStateIdentificationCardInput = {
  User?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutAddressesInput>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutAddressInput>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutAddressInput>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  type?: InputMaybe<AddressTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type AddressCreateWithoutUserInput = {
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutAddressesInput>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutAddressInput>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutAddressInput>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutAddressInput>;
  type?: InputMaybe<AddressTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type AddressListRelationFilter = {
  every?: InputMaybe<AddressWhereInput>;
  none?: InputMaybe<AddressWhereInput>;
  some?: InputMaybe<AddressWhereInput>;
};

export type AddressNullableRelationFilter = {
  is?: InputMaybe<AddressWhereInput>;
  isNot?: InputMaybe<AddressWhereInput>;
};

export type AddressOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AddressOrderByWithRelationInput = {
  User?: InputMaybe<UserOrderByWithRelationInput>;
  addressLineOne?: InputMaybe<SortOrder>;
  addressLineTwo?: InputMaybe<SortOrderInput>;
  applicantData?: InputMaybe<ApplicantDataOrderByWithRelationInput>;
  applicantDataId?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrderInput>;
  passport?: InputMaybe<PassportOrderByWithRelationInput>;
  passportId?: InputMaybe<SortOrderInput>;
  postalCode?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardOrderByWithRelationInput>;
  stateIdentificationCardId?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum AddressScalarFieldEnum {
  AddressLineOne = 'addressLineOne',
  AddressLineTwo = 'addressLineTwo',
  ApplicantDataId = 'applicantDataId',
  City = 'city',
  Country = 'country',
  CreatedAt = 'createdAt',
  Id = 'id',
  OrganizationId = 'organizationId',
  PassportId = 'passportId',
  PostalCode = 'postalCode',
  State = 'state',
  StateIdentificationCardId = 'stateIdentificationCardId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type AddressScalarWhereInput = {
  AND?: InputMaybe<Array<AddressScalarWhereInput>>;
  NOT?: InputMaybe<Array<AddressScalarWhereInput>>;
  OR?: InputMaybe<Array<AddressScalarWhereInput>>;
  addressLineOne?: InputMaybe<StringFilter>;
  addressLineTwo?: InputMaybe<StringNullableFilter>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  passportId?: InputMaybe<StringNullableFilter>;
  postalCode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  stateIdentificationCardId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumAddressTypeEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export enum AddressTypeEnum {
  Former = 'FORMER',
  Manual = 'MANUAL',
  Primary = 'PRIMARY'
}

export type AddressUpdateInput = {
  User?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  addressLineOne?: InputMaybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutAddressesNestedInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutAddressNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutAddressNestedInput>;
  postalCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutAddressNestedInput>;
  type?: InputMaybe<NullableEnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateManyMutationInput = {
  addressLineOne?: InputMaybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateManyWithWhereWithoutApplicantDataInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithWhereWithoutOrganizationInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithWhereWithoutUserInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithoutApplicantDataNestedInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutApplicantDataInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutApplicantDataInput>>;
  createMany?: InputMaybe<AddressCreateManyApplicantDataInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<Array<AddressUpdateWithWhereUniqueWithoutApplicantDataInput>>;
  updateMany?: InputMaybe<Array<AddressUpdateManyWithWhereWithoutApplicantDataInput>>;
  upsert?: InputMaybe<Array<AddressUpsertWithWhereUniqueWithoutApplicantDataInput>>;
};

export type AddressUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AddressCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<Array<AddressUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<AddressUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<AddressUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type AddressUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutUserInput>>;
  createMany?: InputMaybe<AddressCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<Array<AddressUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<AddressUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<AddressUpsertWithWhereUniqueWithoutUserInput>>;
};

export type AddressUpdateOneWithoutPassportNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutPassportInput>;
  create?: InputMaybe<AddressCreateWithoutPassportInput>;
  delete?: InputMaybe<AddressWhereInput>;
  disconnect?: InputMaybe<AddressWhereInput>;
  update?: InputMaybe<AddressUpdateToOneWithWhereWithoutPassportInput>;
  upsert?: InputMaybe<AddressUpsertWithoutPassportInput>;
};

export type AddressUpdateOneWithoutStateIdentificationCardNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutStateIdentificationCardInput>;
  create?: InputMaybe<AddressCreateWithoutStateIdentificationCardInput>;
  delete?: InputMaybe<AddressWhereInput>;
  disconnect?: InputMaybe<AddressWhereInput>;
  update?: InputMaybe<AddressUpdateToOneWithWhereWithoutStateIdentificationCardInput>;
  upsert?: InputMaybe<AddressUpsertWithoutStateIdentificationCardInput>;
};

export type AddressUpdateToOneWithWhereWithoutPassportInput = {
  data: AddressUpdateWithoutPassportInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpdateToOneWithWhereWithoutStateIdentificationCardInput = {
  data: AddressUpdateWithoutStateIdentificationCardInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpdateWithWhereUniqueWithoutApplicantDataInput = {
  data: AddressUpdateWithoutApplicantDataInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: AddressUpdateWithoutOrganizationInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithWhereUniqueWithoutUserInput = {
  data: AddressUpdateWithoutUserInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithoutApplicantDataInput = {
  User?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  addressLineOne?: InputMaybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutAddressNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutAddressNestedInput>;
  postalCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutAddressNestedInput>;
  type?: InputMaybe<NullableEnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutOrganizationInput = {
  User?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  addressLineOne?: InputMaybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutAddressesNestedInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutAddressNestedInput>;
  postalCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutAddressNestedInput>;
  type?: InputMaybe<NullableEnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutPassportInput = {
  User?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  addressLineOne?: InputMaybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutAddressesNestedInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutAddressNestedInput>;
  postalCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutAddressNestedInput>;
  type?: InputMaybe<NullableEnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutStateIdentificationCardInput = {
  User?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  addressLineOne?: InputMaybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutAddressesNestedInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutAddressNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutAddressNestedInput>;
  postalCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutUserInput = {
  addressLineOne?: InputMaybe<StringFieldUpdateOperationsInput>;
  addressLineTwo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutAddressesNestedInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutAddressNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutAddressNestedInput>;
  postalCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutAddressNestedInput>;
  type?: InputMaybe<NullableEnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpsertWithWhereUniqueWithoutApplicantDataInput = {
  create: AddressCreateWithoutApplicantDataInput;
  update: AddressUpdateWithoutApplicantDataInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: AddressCreateWithoutOrganizationInput;
  update: AddressUpdateWithoutOrganizationInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithWhereUniqueWithoutUserInput = {
  create: AddressCreateWithoutUserInput;
  update: AddressUpdateWithoutUserInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithoutPassportInput = {
  create: AddressCreateWithoutPassportInput;
  update: AddressUpdateWithoutPassportInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpsertWithoutStateIdentificationCardInput = {
  create: AddressCreateWithoutStateIdentificationCardInput;
  update: AddressUpdateWithoutStateIdentificationCardInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressWhereInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  User?: InputMaybe<UserNullableRelationFilter>;
  addressLineOne?: InputMaybe<StringFilter>;
  addressLineTwo?: InputMaybe<StringNullableFilter>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  passport?: InputMaybe<PassportNullableRelationFilter>;
  passportId?: InputMaybe<StringNullableFilter>;
  postalCode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumAddressTypeEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type AddressWhereUniqueInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  User?: InputMaybe<UserNullableRelationFilter>;
  addressLineOne?: InputMaybe<StringFilter>;
  addressLineTwo?: InputMaybe<StringNullableFilter>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  passport?: InputMaybe<PassportNullableRelationFilter>;
  passportId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EnumAddressTypeEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateBeamInput = {
  __typename?: 'AggregateBeamInput';
  _count?: Maybe<BeamInputCountAggregate>;
  _max?: Maybe<BeamInputMaxAggregate>;
  _min?: Maybe<BeamInputMinAggregate>;
};

export type AggregateOrder = {
  __typename?: 'AggregateOrder';
  _avg?: Maybe<OrderAvgAggregate>;
  _count?: Maybe<OrderCountAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
};

export type AggregatePackageSet = {
  __typename?: 'AggregatePackageSet';
  _avg?: Maybe<PackageSetAvgAggregate>;
  _count?: Maybe<PackageSetCountAggregate>;
  _max?: Maybe<PackageSetMaxAggregate>;
  _min?: Maybe<PackageSetMinAggregate>;
  _sum?: Maybe<PackageSetSumAggregate>;
};

export type AggregateVidVoucher = {
  __typename?: 'AggregateVIDVoucher';
  _count?: Maybe<VidVoucherCountAggregate>;
  _max?: Maybe<VidVoucherMaxAggregate>;
  _min?: Maybe<VidVoucherMinAggregate>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['DateTimeISO'];
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  expiryDate?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  prefix: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  userId?: Maybe<Scalars['String']>;
};

export type ApiKeyCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  expiryDate?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  name: Scalars['String'];
  prefix: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApiKeyCreateManyUserInputEnvelope = {
  data: Array<ApiKeyCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ApiKeyCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ApiKeyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ApiKeyCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ApiKeyCreateWithoutUserInput>>;
  createMany?: InputMaybe<ApiKeyCreateManyUserInputEnvelope>;
};

export type ApiKeyCreateOrConnectWithoutUserInput = {
  create: ApiKeyCreateWithoutUserInput;
  where: ApiKeyWhereUniqueInput;
};

export type ApiKeyCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  expiryDate?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  name: Scalars['String'];
  prefix: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApiKeyListRelationFilter = {
  every?: InputMaybe<ApiKeyWhereInput>;
  none?: InputMaybe<ApiKeyWhereInput>;
  some?: InputMaybe<ApiKeyWhereInput>;
};

export type ApiKeyOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ApiKeyOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrderInput>;
  expiryDate?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  prefix?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum ApiKeyScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  ExpiryDate = 'expiryDate',
  Id = 'id',
  Key = 'key',
  Name = 'name',
  Prefix = 'prefix',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ApiKeyScalarWhereInput = {
  AND?: InputMaybe<Array<ApiKeyScalarWhereInput>>;
  NOT?: InputMaybe<Array<ApiKeyScalarWhereInput>>;
  OR?: InputMaybe<Array<ApiKeyScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  expiryDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  prefix?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ApiKeyUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prefix?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApiKeyUpdateManyWithWhereWithoutUserInput = {
  data: ApiKeyUpdateManyMutationInput;
  where: ApiKeyScalarWhereInput;
};

export type ApiKeyUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ApiKeyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ApiKeyCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ApiKeyCreateWithoutUserInput>>;
  createMany?: InputMaybe<ApiKeyCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ApiKeyWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ApiKeyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ApiKeyWhereUniqueInput>>;
  set?: InputMaybe<Array<ApiKeyWhereUniqueInput>>;
  update?: InputMaybe<Array<ApiKeyUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ApiKeyUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ApiKeyUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ApiKeyUpdateWithWhereUniqueWithoutUserInput = {
  data: ApiKeyUpdateWithoutUserInput;
  where: ApiKeyWhereUniqueInput;
};

export type ApiKeyUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prefix?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApiKeyUpsertWithWhereUniqueWithoutUserInput = {
  create: ApiKeyCreateWithoutUserInput;
  update: ApiKeyUpdateWithoutUserInput;
  where: ApiKeyWhereUniqueInput;
};

export type ApiKeyWhereInput = {
  AND?: InputMaybe<Array<ApiKeyWhereInput>>;
  NOT?: InputMaybe<Array<ApiKeyWhereInput>>;
  OR?: InputMaybe<Array<ApiKeyWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  expiryDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  prefix?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ApiKeyWhereUniqueInput = {
  AND?: InputMaybe<Array<ApiKeyWhereInput>>;
  NOT?: InputMaybe<Array<ApiKeyWhereInput>>;
  OR?: InputMaybe<Array<ApiKeyWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  expiryDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<StringFilter>;
  prefix?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ApplicantData = {
  __typename?: 'ApplicantData';
  _count?: Maybe<ApplicantDataCount>;
  addresses: Array<Address>;
  applicantIDCard?: Maybe<ApplicantIdCard>;
  applicantIDCardId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  dateOfBirth: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  legacySSN: Scalars['String'];
  names: Array<Name>;
  order: Order;
  orderId: Scalars['String'];
  passport?: Maybe<Passport>;
  phoneNumber?: Maybe<Scalars['String']>;
  reportableIdVerificationResult?: Maybe<ReportableIdVerificationResult>;
  ssn?: Maybe<HighlySensitiveIdentifier>;
  stateIdentificationCard?: Maybe<StateIdentificationCard>;
  updatedAt: Scalars['DateTimeISO'];
};


export type ApplicantDataAddressesArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<AddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type ApplicantDataApplicantIdCardArgs = {
  where?: InputMaybe<ApplicantIdCardWhereInput>;
};


export type ApplicantDataNamesArgs = {
  cursor?: InputMaybe<NameWhereUniqueInput>;
  distinct?: InputMaybe<Array<NameScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NameOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NameWhereInput>;
};


export type ApplicantDataPassportArgs = {
  where?: InputMaybe<PassportWhereInput>;
};


export type ApplicantDataReportableIdVerificationResultArgs = {
  where?: InputMaybe<ReportableIdVerificationResultWhereInput>;
};


export type ApplicantDataSsnArgs = {
  where?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
};


export type ApplicantDataStateIdentificationCardArgs = {
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type ApplicantDataCount = {
  __typename?: 'ApplicantDataCount';
  addresses: Scalars['Int'];
  names: Scalars['Int'];
};


export type ApplicantDataCountAddressesArgs = {
  where?: InputMaybe<AddressWhereInput>;
};


export type ApplicantDataCountNamesArgs = {
  where?: InputMaybe<NameWhereInput>;
};

export type ApplicantDataCreateInput = {
  addresses?: InputMaybe<AddressCreateNestedManyWithoutApplicantDataInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardCreateNestedOneWithoutApplicantDataInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legacySSN: Scalars['String'];
  names?: InputMaybe<NameCreateNestedManyWithoutApplicantDataInput>;
  order: OrderCreateNestedOneWithoutApplicantDataInput;
  passport?: InputMaybe<PassportCreateNestedOneWithoutApplicantDataInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutApplicantAliasDataInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutApplicantDataInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutApplicantDataInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApplicantDataCreateManyInput = {
  applicantIDCardId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legacySSN: Scalars['String'];
  orderId: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApplicantDataCreateNestedOneWithoutAddressesInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutAddressesInput>;
};

export type ApplicantDataCreateNestedOneWithoutNamesInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutNamesInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutNamesInput>;
};

export type ApplicantDataCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutOrderInput>;
};

export type ApplicantDataCreateNestedOneWithoutPassportInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutPassportInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutPassportInput>;
};

export type ApplicantDataCreateNestedOneWithoutReportableIdVerificationResultInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutReportableIdVerificationResultInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutReportableIdVerificationResultInput>;
};

export type ApplicantDataCreateNestedOneWithoutSsnInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutSsnInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutSsnInput>;
};

export type ApplicantDataCreateNestedOneWithoutStateIdentificationCardInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutStateIdentificationCardInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutStateIdentificationCardInput>;
};

export type ApplicantDataCreateOrConnectWithoutAddressesInput = {
  create: ApplicantDataCreateWithoutAddressesInput;
  where: ApplicantDataWhereUniqueInput;
};

export type ApplicantDataCreateOrConnectWithoutNamesInput = {
  create: ApplicantDataCreateWithoutNamesInput;
  where: ApplicantDataWhereUniqueInput;
};

export type ApplicantDataCreateOrConnectWithoutOrderInput = {
  create: ApplicantDataCreateWithoutOrderInput;
  where: ApplicantDataWhereUniqueInput;
};

export type ApplicantDataCreateOrConnectWithoutPassportInput = {
  create: ApplicantDataCreateWithoutPassportInput;
  where: ApplicantDataWhereUniqueInput;
};

export type ApplicantDataCreateOrConnectWithoutReportableIdVerificationResultInput = {
  create: ApplicantDataCreateWithoutReportableIdVerificationResultInput;
  where: ApplicantDataWhereUniqueInput;
};

export type ApplicantDataCreateOrConnectWithoutSsnInput = {
  create: ApplicantDataCreateWithoutSsnInput;
  where: ApplicantDataWhereUniqueInput;
};

export type ApplicantDataCreateOrConnectWithoutStateIdentificationCardInput = {
  create: ApplicantDataCreateWithoutStateIdentificationCardInput;
  where: ApplicantDataWhereUniqueInput;
};

export type ApplicantDataCreateWithoutAddressesInput = {
  applicantIDCard?: InputMaybe<ApplicantIdCardCreateNestedOneWithoutApplicantDataInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legacySSN: Scalars['String'];
  names?: InputMaybe<NameCreateNestedManyWithoutApplicantDataInput>;
  order: OrderCreateNestedOneWithoutApplicantDataInput;
  passport?: InputMaybe<PassportCreateNestedOneWithoutApplicantDataInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutApplicantAliasDataInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutApplicantDataInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutApplicantDataInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApplicantDataCreateWithoutNamesInput = {
  addresses?: InputMaybe<AddressCreateNestedManyWithoutApplicantDataInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardCreateNestedOneWithoutApplicantDataInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legacySSN: Scalars['String'];
  order: OrderCreateNestedOneWithoutApplicantDataInput;
  passport?: InputMaybe<PassportCreateNestedOneWithoutApplicantDataInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutApplicantAliasDataInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutApplicantDataInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutApplicantDataInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApplicantDataCreateWithoutOrderInput = {
  addresses?: InputMaybe<AddressCreateNestedManyWithoutApplicantDataInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardCreateNestedOneWithoutApplicantDataInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legacySSN: Scalars['String'];
  names?: InputMaybe<NameCreateNestedManyWithoutApplicantDataInput>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutApplicantDataInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutApplicantAliasDataInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutApplicantDataInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutApplicantDataInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApplicantDataCreateWithoutPassportInput = {
  addresses?: InputMaybe<AddressCreateNestedManyWithoutApplicantDataInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardCreateNestedOneWithoutApplicantDataInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legacySSN: Scalars['String'];
  names?: InputMaybe<NameCreateNestedManyWithoutApplicantDataInput>;
  order: OrderCreateNestedOneWithoutApplicantDataInput;
  phoneNumber?: InputMaybe<Scalars['String']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutApplicantAliasDataInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutApplicantDataInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutApplicantDataInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApplicantDataCreateWithoutReportableIdVerificationResultInput = {
  addresses?: InputMaybe<AddressCreateNestedManyWithoutApplicantDataInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardCreateNestedOneWithoutApplicantDataInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legacySSN: Scalars['String'];
  names?: InputMaybe<NameCreateNestedManyWithoutApplicantDataInput>;
  order: OrderCreateNestedOneWithoutApplicantDataInput;
  passport?: InputMaybe<PassportCreateNestedOneWithoutApplicantDataInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutApplicantDataInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutApplicantDataInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApplicantDataCreateWithoutSsnInput = {
  addresses?: InputMaybe<AddressCreateNestedManyWithoutApplicantDataInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardCreateNestedOneWithoutApplicantDataInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legacySSN: Scalars['String'];
  names?: InputMaybe<NameCreateNestedManyWithoutApplicantDataInput>;
  order: OrderCreateNestedOneWithoutApplicantDataInput;
  passport?: InputMaybe<PassportCreateNestedOneWithoutApplicantDataInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutApplicantAliasDataInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutApplicantDataInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApplicantDataCreateWithoutStateIdentificationCardInput = {
  addresses?: InputMaybe<AddressCreateNestedManyWithoutApplicantDataInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardCreateNestedOneWithoutApplicantDataInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legacySSN: Scalars['String'];
  names?: InputMaybe<NameCreateNestedManyWithoutApplicantDataInput>;
  order: OrderCreateNestedOneWithoutApplicantDataInput;
  passport?: InputMaybe<PassportCreateNestedOneWithoutApplicantDataInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutApplicantAliasDataInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutApplicantDataInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApplicantDataNullableRelationFilter = {
  is?: InputMaybe<ApplicantDataWhereInput>;
  isNot?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataOrderByWithRelationInput = {
  addresses?: InputMaybe<AddressOrderByRelationAggregateInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardOrderByWithRelationInput>;
  applicantIDCardId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  legacySSN?: InputMaybe<SortOrder>;
  names?: InputMaybe<NameOrderByRelationAggregateInput>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  passport?: InputMaybe<PassportOrderByWithRelationInput>;
  phoneNumber?: InputMaybe<SortOrderInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultOrderByWithRelationInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierOrderByWithRelationInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ApplicantDataUpdateInput = {
  addresses?: InputMaybe<AddressUpdateManyWithoutApplicantDataNestedInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardUpdateOneWithoutApplicantDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legacySSN?: InputMaybe<StringFieldUpdateOperationsInput>;
  names?: InputMaybe<NameUpdateManyWithoutApplicantDataNestedInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutApplicantDataNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutApplicantDataNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutApplicantAliasDataNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutApplicantDataNestedInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutApplicantDataNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApplicantDataUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legacySSN?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApplicantDataUpdateOneWithoutAddressesNestedInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutAddressesInput>;
  delete?: InputMaybe<ApplicantDataWhereInput>;
  disconnect?: InputMaybe<ApplicantDataWhereInput>;
  update?: InputMaybe<ApplicantDataUpdateToOneWithWhereWithoutAddressesInput>;
  upsert?: InputMaybe<ApplicantDataUpsertWithoutAddressesInput>;
};

export type ApplicantDataUpdateOneWithoutNamesNestedInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutNamesInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutNamesInput>;
  delete?: InputMaybe<ApplicantDataWhereInput>;
  disconnect?: InputMaybe<ApplicantDataWhereInput>;
  update?: InputMaybe<ApplicantDataUpdateToOneWithWhereWithoutNamesInput>;
  upsert?: InputMaybe<ApplicantDataUpsertWithoutNamesInput>;
};

export type ApplicantDataUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutOrderInput>;
  delete?: InputMaybe<ApplicantDataWhereInput>;
  disconnect?: InputMaybe<ApplicantDataWhereInput>;
  update?: InputMaybe<ApplicantDataUpdateToOneWithWhereWithoutOrderInput>;
  upsert?: InputMaybe<ApplicantDataUpsertWithoutOrderInput>;
};

export type ApplicantDataUpdateOneWithoutPassportNestedInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutPassportInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutPassportInput>;
  delete?: InputMaybe<ApplicantDataWhereInput>;
  disconnect?: InputMaybe<ApplicantDataWhereInput>;
  update?: InputMaybe<ApplicantDataUpdateToOneWithWhereWithoutPassportInput>;
  upsert?: InputMaybe<ApplicantDataUpsertWithoutPassportInput>;
};

export type ApplicantDataUpdateOneWithoutReportableIdVerificationResultNestedInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutReportableIdVerificationResultInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutReportableIdVerificationResultInput>;
  delete?: InputMaybe<ApplicantDataWhereInput>;
  disconnect?: InputMaybe<ApplicantDataWhereInput>;
  update?: InputMaybe<ApplicantDataUpdateToOneWithWhereWithoutReportableIdVerificationResultInput>;
  upsert?: InputMaybe<ApplicantDataUpsertWithoutReportableIdVerificationResultInput>;
};

export type ApplicantDataUpdateOneWithoutSsnNestedInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutSsnInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutSsnInput>;
  delete?: InputMaybe<ApplicantDataWhereInput>;
  disconnect?: InputMaybe<ApplicantDataWhereInput>;
  update?: InputMaybe<ApplicantDataUpdateToOneWithWhereWithoutSsnInput>;
  upsert?: InputMaybe<ApplicantDataUpsertWithoutSsnInput>;
};

export type ApplicantDataUpdateOneWithoutStateIdentificationCardNestedInput = {
  connect?: InputMaybe<ApplicantDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantDataCreateOrConnectWithoutStateIdentificationCardInput>;
  create?: InputMaybe<ApplicantDataCreateWithoutStateIdentificationCardInput>;
  delete?: InputMaybe<ApplicantDataWhereInput>;
  disconnect?: InputMaybe<ApplicantDataWhereInput>;
  update?: InputMaybe<ApplicantDataUpdateToOneWithWhereWithoutStateIdentificationCardInput>;
  upsert?: InputMaybe<ApplicantDataUpsertWithoutStateIdentificationCardInput>;
};

export type ApplicantDataUpdateToOneWithWhereWithoutAddressesInput = {
  data: ApplicantDataUpdateWithoutAddressesInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpdateToOneWithWhereWithoutNamesInput = {
  data: ApplicantDataUpdateWithoutNamesInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpdateToOneWithWhereWithoutOrderInput = {
  data: ApplicantDataUpdateWithoutOrderInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpdateToOneWithWhereWithoutPassportInput = {
  data: ApplicantDataUpdateWithoutPassportInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpdateToOneWithWhereWithoutReportableIdVerificationResultInput = {
  data: ApplicantDataUpdateWithoutReportableIdVerificationResultInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpdateToOneWithWhereWithoutSsnInput = {
  data: ApplicantDataUpdateWithoutSsnInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpdateToOneWithWhereWithoutStateIdentificationCardInput = {
  data: ApplicantDataUpdateWithoutStateIdentificationCardInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpdateWithoutAddressesInput = {
  applicantIDCard?: InputMaybe<ApplicantIdCardUpdateOneWithoutApplicantDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legacySSN?: InputMaybe<StringFieldUpdateOperationsInput>;
  names?: InputMaybe<NameUpdateManyWithoutApplicantDataNestedInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutApplicantDataNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutApplicantDataNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutApplicantAliasDataNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutApplicantDataNestedInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutApplicantDataNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApplicantDataUpdateWithoutNamesInput = {
  addresses?: InputMaybe<AddressUpdateManyWithoutApplicantDataNestedInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardUpdateOneWithoutApplicantDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legacySSN?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutApplicantDataNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutApplicantDataNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutApplicantAliasDataNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutApplicantDataNestedInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutApplicantDataNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApplicantDataUpdateWithoutOrderInput = {
  addresses?: InputMaybe<AddressUpdateManyWithoutApplicantDataNestedInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardUpdateOneWithoutApplicantDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legacySSN?: InputMaybe<StringFieldUpdateOperationsInput>;
  names?: InputMaybe<NameUpdateManyWithoutApplicantDataNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutApplicantDataNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutApplicantAliasDataNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutApplicantDataNestedInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutApplicantDataNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApplicantDataUpdateWithoutPassportInput = {
  addresses?: InputMaybe<AddressUpdateManyWithoutApplicantDataNestedInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardUpdateOneWithoutApplicantDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legacySSN?: InputMaybe<StringFieldUpdateOperationsInput>;
  names?: InputMaybe<NameUpdateManyWithoutApplicantDataNestedInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutApplicantDataNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutApplicantAliasDataNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutApplicantDataNestedInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutApplicantDataNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApplicantDataUpdateWithoutReportableIdVerificationResultInput = {
  addresses?: InputMaybe<AddressUpdateManyWithoutApplicantDataNestedInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardUpdateOneWithoutApplicantDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legacySSN?: InputMaybe<StringFieldUpdateOperationsInput>;
  names?: InputMaybe<NameUpdateManyWithoutApplicantDataNestedInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutApplicantDataNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutApplicantDataNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutApplicantDataNestedInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutApplicantDataNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApplicantDataUpdateWithoutSsnInput = {
  addresses?: InputMaybe<AddressUpdateManyWithoutApplicantDataNestedInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardUpdateOneWithoutApplicantDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legacySSN?: InputMaybe<StringFieldUpdateOperationsInput>;
  names?: InputMaybe<NameUpdateManyWithoutApplicantDataNestedInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutApplicantDataNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutApplicantDataNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutApplicantAliasDataNestedInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutApplicantDataNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApplicantDataUpdateWithoutStateIdentificationCardInput = {
  addresses?: InputMaybe<AddressUpdateManyWithoutApplicantDataNestedInput>;
  applicantIDCard?: InputMaybe<ApplicantIdCardUpdateOneWithoutApplicantDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legacySSN?: InputMaybe<StringFieldUpdateOperationsInput>;
  names?: InputMaybe<NameUpdateManyWithoutApplicantDataNestedInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutApplicantDataNestedInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutApplicantDataNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutApplicantAliasDataNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutApplicantDataNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApplicantDataUpsertWithoutAddressesInput = {
  create: ApplicantDataCreateWithoutAddressesInput;
  update: ApplicantDataUpdateWithoutAddressesInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpsertWithoutNamesInput = {
  create: ApplicantDataCreateWithoutNamesInput;
  update: ApplicantDataUpdateWithoutNamesInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpsertWithoutOrderInput = {
  create: ApplicantDataCreateWithoutOrderInput;
  update: ApplicantDataUpdateWithoutOrderInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpsertWithoutPassportInput = {
  create: ApplicantDataCreateWithoutPassportInput;
  update: ApplicantDataUpdateWithoutPassportInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpsertWithoutReportableIdVerificationResultInput = {
  create: ApplicantDataCreateWithoutReportableIdVerificationResultInput;
  update: ApplicantDataUpdateWithoutReportableIdVerificationResultInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpsertWithoutSsnInput = {
  create: ApplicantDataCreateWithoutSsnInput;
  update: ApplicantDataUpdateWithoutSsnInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataUpsertWithoutStateIdentificationCardInput = {
  create: ApplicantDataCreateWithoutStateIdentificationCardInput;
  update: ApplicantDataUpdateWithoutStateIdentificationCardInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};

export type ApplicantDataWhereInput = {
  AND?: InputMaybe<Array<ApplicantDataWhereInput>>;
  NOT?: InputMaybe<Array<ApplicantDataWhereInput>>;
  OR?: InputMaybe<Array<ApplicantDataWhereInput>>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  applicantIDCard?: InputMaybe<ApplicantIdCardNullableRelationFilter>;
  applicantIDCardId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  legacySSN?: InputMaybe<StringFilter>;
  names?: InputMaybe<NameListRelationFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  passport?: InputMaybe<PassportNullableRelationFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultNullableRelationFilter>;
  ssn?: InputMaybe<HighlySensitiveIdentifierNullableRelationFilter>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ApplicantDataWhereUniqueInput = {
  AND?: InputMaybe<Array<ApplicantDataWhereInput>>;
  NOT?: InputMaybe<Array<ApplicantDataWhereInput>>;
  OR?: InputMaybe<Array<ApplicantDataWhereInput>>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  applicantIDCard?: InputMaybe<ApplicantIdCardNullableRelationFilter>;
  applicantIDCardId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  legacySSN?: InputMaybe<StringFilter>;
  names?: InputMaybe<NameListRelationFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<PassportNullableRelationFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultNullableRelationFilter>;
  ssn?: InputMaybe<HighlySensitiveIdentifierNullableRelationFilter>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ApplicantIdCard = {
  __typename?: 'ApplicantIDCard';
  county: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['String'];
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type ApplicantIdCardCreateNestedOneWithoutApplicantDataInput = {
  connect?: InputMaybe<ApplicantIdCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantIdCardCreateOrConnectWithoutApplicantDataInput>;
  create?: InputMaybe<ApplicantIdCardCreateWithoutApplicantDataInput>;
};

export type ApplicantIdCardCreateOrConnectWithoutApplicantDataInput = {
  create: ApplicantIdCardCreateWithoutApplicantDataInput;
  where: ApplicantIdCardWhereUniqueInput;
};

export type ApplicantIdCardCreateWithoutApplicantDataInput = {
  county: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  number: Scalars['String'];
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ApplicantIdCardNullableRelationFilter = {
  is?: InputMaybe<ApplicantIdCardWhereInput>;
  isNot?: InputMaybe<ApplicantIdCardWhereInput>;
};

export type ApplicantIdCardOrderByWithRelationInput = {
  applicantData?: InputMaybe<ApplicantDataOrderByWithRelationInput>;
  county?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ApplicantIdCardUpdateOneWithoutApplicantDataNestedInput = {
  connect?: InputMaybe<ApplicantIdCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ApplicantIdCardCreateOrConnectWithoutApplicantDataInput>;
  create?: InputMaybe<ApplicantIdCardCreateWithoutApplicantDataInput>;
  delete?: InputMaybe<ApplicantIdCardWhereInput>;
  disconnect?: InputMaybe<ApplicantIdCardWhereInput>;
  update?: InputMaybe<ApplicantIdCardUpdateToOneWithWhereWithoutApplicantDataInput>;
  upsert?: InputMaybe<ApplicantIdCardUpsertWithoutApplicantDataInput>;
};

export type ApplicantIdCardUpdateToOneWithWhereWithoutApplicantDataInput = {
  data: ApplicantIdCardUpdateWithoutApplicantDataInput;
  where?: InputMaybe<ApplicantIdCardWhereInput>;
};

export type ApplicantIdCardUpdateWithoutApplicantDataInput = {
  county?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ApplicantIdCardUpsertWithoutApplicantDataInput = {
  create: ApplicantIdCardCreateWithoutApplicantDataInput;
  update: ApplicantIdCardUpdateWithoutApplicantDataInput;
  where?: InputMaybe<ApplicantIdCardWhereInput>;
};

export type ApplicantIdCardWhereInput = {
  AND?: InputMaybe<Array<ApplicantIdCardWhereInput>>;
  NOT?: InputMaybe<Array<ApplicantIdCardWhereInput>>;
  OR?: InputMaybe<Array<ApplicantIdCardWhereInput>>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  county?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  number?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ApplicantIdCardWhereUniqueInput = {
  AND?: InputMaybe<Array<ApplicantIdCardWhereInput>>;
  NOT?: InputMaybe<Array<ApplicantIdCardWhereInput>>;
  OR?: InputMaybe<Array<ApplicantIdCardWhereInput>>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  county?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<StringFilter>;
  number?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum AttachmentEnum {
  Letter_613 = 'LETTER_613',
  LetterAa = 'LETTER_AA',
  LetterPaa = 'LETTER_PAA'
}

export type AuditLogCreateManyOrganizationInput = {
  actionContent: Scalars['JSON'];
  date?: InputMaybe<Scalars['DateTimeISO']>;
  expiry?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['JSON']>;
  performerId: Scalars['String'];
  performerType?: InputMaybe<PerformerTypes>;
  reason: Scalars['String'];
  source: Scalars['String'];
  sourceType?: InputMaybe<SourceTypes>;
};

export type AuditLogCreateManyOrganizationInputEnvelope = {
  data: Array<AuditLogCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AuditLogCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AuditLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AuditLogCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AuditLogCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AuditLogCreateManyOrganizationInputEnvelope>;
};

export type AuditLogCreateOrConnectWithoutOrganizationInput = {
  create: AuditLogCreateWithoutOrganizationInput;
  where: AuditLogWhereUniqueInput;
};

export type AuditLogCreateWithoutOrganizationInput = {
  actionContent: Scalars['JSON'];
  date?: InputMaybe<Scalars['DateTimeISO']>;
  expiry?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['JSON']>;
  performerId: Scalars['String'];
  performerType?: InputMaybe<PerformerTypes>;
  reason: Scalars['String'];
  source: Scalars['String'];
  sourceType?: InputMaybe<SourceTypes>;
};

export type AuditLogListRelationFilter = {
  every?: InputMaybe<AuditLogWhereInput>;
  none?: InputMaybe<AuditLogWhereInput>;
  some?: InputMaybe<AuditLogWhereInput>;
};

export type AuditLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AuditLogScalarWhereInput = {
  AND?: InputMaybe<Array<AuditLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<AuditLogScalarWhereInput>>;
  OR?: InputMaybe<Array<AuditLogScalarWhereInput>>;
  actionContent?: InputMaybe<JsonFilter>;
  date?: InputMaybe<DateTimeFilter>;
  expiry?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  performerId?: InputMaybe<StringFilter>;
  performerType?: InputMaybe<EnumPerformerTypesFilter>;
  reason?: InputMaybe<StringFilter>;
  source?: InputMaybe<StringFilter>;
  sourceType?: InputMaybe<EnumSourceTypesFilter>;
};

export type AuditLogUpdateManyMutationInput = {
  actionContent?: InputMaybe<Scalars['JSON']>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiry?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  performerId?: InputMaybe<StringFieldUpdateOperationsInput>;
  performerType?: InputMaybe<EnumPerformerTypesFieldUpdateOperationsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  source?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourceType?: InputMaybe<EnumSourceTypesFieldUpdateOperationsInput>;
};

export type AuditLogUpdateManyWithWhereWithoutOrganizationInput = {
  data: AuditLogUpdateManyMutationInput;
  where: AuditLogScalarWhereInput;
};

export type AuditLogUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<AuditLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AuditLogCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AuditLogCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AuditLogCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<AuditLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AuditLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AuditLogWhereUniqueInput>>;
  set?: InputMaybe<Array<AuditLogWhereUniqueInput>>;
  update?: InputMaybe<Array<AuditLogUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<AuditLogUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<AuditLogUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type AuditLogUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: AuditLogUpdateWithoutOrganizationInput;
  where: AuditLogWhereUniqueInput;
};

export type AuditLogUpdateWithoutOrganizationInput = {
  actionContent?: InputMaybe<Scalars['JSON']>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiry?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']>;
  performerId?: InputMaybe<StringFieldUpdateOperationsInput>;
  performerType?: InputMaybe<EnumPerformerTypesFieldUpdateOperationsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  source?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourceType?: InputMaybe<EnumSourceTypesFieldUpdateOperationsInput>;
};

export type AuditLogUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: AuditLogCreateWithoutOrganizationInput;
  update: AuditLogUpdateWithoutOrganizationInput;
  where: AuditLogWhereUniqueInput;
};

export type AuditLogWhereInput = {
  AND?: InputMaybe<Array<AuditLogWhereInput>>;
  NOT?: InputMaybe<Array<AuditLogWhereInput>>;
  OR?: InputMaybe<Array<AuditLogWhereInput>>;
  actionContent?: InputMaybe<JsonFilter>;
  date?: InputMaybe<DateTimeFilter>;
  expiry?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  performerId?: InputMaybe<StringFilter>;
  performerType?: InputMaybe<EnumPerformerTypesFilter>;
  reason?: InputMaybe<StringFilter>;
  source?: InputMaybe<StringFilter>;
  sourceType?: InputMaybe<EnumSourceTypesFilter>;
};

export type AuditLogWhereUniqueInput = {
  AND?: InputMaybe<Array<AuditLogWhereInput>>;
  NOT?: InputMaybe<Array<AuditLogWhereInput>>;
  OR?: InputMaybe<Array<AuditLogWhereInput>>;
  actionContent?: InputMaybe<JsonFilter>;
  date?: InputMaybe<DateTimeFilter>;
  expiry?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<JsonNullableFilter>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  performerId?: InputMaybe<StringFilter>;
  performerType?: InputMaybe<EnumPerformerTypesFilter>;
  reason?: InputMaybe<StringFilter>;
  source?: InputMaybe<StringFilter>;
  sourceType?: InputMaybe<EnumSourceTypesFilter>;
};

export type Beam = {
  __typename?: 'Beam';
  _count?: Maybe<BeamCount>;
  config?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTimeISO'];
  credentialTypes: Array<CredentialType>;
  description: Scalars['String'];
  id: Scalars['String'];
  inputTypes: Array<BeamInputType>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  passTypes: Array<PassType>;
  processor: ProcessorEnum;
  processorConfig?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['DateTimeISO'];
};


export type BeamCredentialTypesArgs = {
  cursor?: InputMaybe<CredentialTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<CredentialTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CredentialTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CredentialTypeWhereInput>;
};


export type BeamInputTypesArgs = {
  cursor?: InputMaybe<BeamInputTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeamInputTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeamInputTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamInputTypeWhereInput>;
};


export type BeamPassTypesArgs = {
  cursor?: InputMaybe<PassTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PassTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PassTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PassTypeWhereInput>;
};

export type BeamCount = {
  __typename?: 'BeamCount';
  credentialTypes: Scalars['Int'];
  inputTypes: Scalars['Int'];
  passTypes: Scalars['Int'];
};


export type BeamCountCredentialTypesArgs = {
  where?: InputMaybe<CredentialTypeWhereInput>;
};


export type BeamCountInputTypesArgs = {
  where?: InputMaybe<BeamInputTypeWhereInput>;
};


export type BeamCountPassTypesArgs = {
  where?: InputMaybe<PassTypeWhereInput>;
};

export type BeamCreateInput = {
  config?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentialTypes?: InputMaybe<CredentialTypeCreateNestedManyWithoutBeamInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inputTypes?: InputMaybe<BeamInputTypeCreateNestedManyWithoutBeamInput>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutBeamsInput>;
  processor?: InputMaybe<ProcessorEnum>;
  processorConfig?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BeamCreateNestedManyWithoutPassTypesInput = {
  connect?: InputMaybe<Array<BeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamCreateOrConnectWithoutPassTypesInput>>;
  create?: InputMaybe<Array<BeamCreateWithoutPassTypesInput>>;
};

export type BeamCreateNestedOneWithoutCredentialTypesInput = {
  connect?: InputMaybe<BeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamCreateOrConnectWithoutCredentialTypesInput>;
  create?: InputMaybe<BeamCreateWithoutCredentialTypesInput>;
};

export type BeamCreateNestedOneWithoutInputTypesInput = {
  connect?: InputMaybe<BeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamCreateOrConnectWithoutInputTypesInput>;
  create?: InputMaybe<BeamCreateWithoutInputTypesInput>;
};

export type BeamCreateOrConnectWithoutCredentialTypesInput = {
  create: BeamCreateWithoutCredentialTypesInput;
  where: BeamWhereUniqueInput;
};

export type BeamCreateOrConnectWithoutInputTypesInput = {
  create: BeamCreateWithoutInputTypesInput;
  where: BeamWhereUniqueInput;
};

export type BeamCreateOrConnectWithoutPassTypesInput = {
  create: BeamCreateWithoutPassTypesInput;
  where: BeamWhereUniqueInput;
};

export type BeamCreateWithoutCredentialTypesInput = {
  config?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inputTypes?: InputMaybe<BeamInputTypeCreateNestedManyWithoutBeamInput>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutBeamsInput>;
  processor?: InputMaybe<ProcessorEnum>;
  processorConfig?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BeamCreateWithoutInputTypesInput = {
  config?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentialTypes?: InputMaybe<CredentialTypeCreateNestedManyWithoutBeamInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutBeamsInput>;
  processor?: InputMaybe<ProcessorEnum>;
  processorConfig?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BeamCreateWithoutPassTypesInput = {
  config?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentialTypes?: InputMaybe<CredentialTypeCreateNestedManyWithoutBeamInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inputTypes?: InputMaybe<BeamInputTypeCreateNestedManyWithoutBeamInput>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  processor?: InputMaybe<ProcessorEnum>;
  processorConfig?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BeamInput = {
  __typename?: 'BeamInput';
  _count?: Maybe<BeamInputCount>;
  createdAt: Scalars['DateTimeISO'];
  credential?: Maybe<Credential>;
  data: Scalars['JSON'];
  files: Array<File>;
  hsi: Array<HighlySensitiveIdentifier>;
  id: Scalars['String'];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pass?: Maybe<Pass>;
  passId?: Maybe<Scalars['String']>;
  runs: Array<BeamRun>;
  type: BeamInputType;
  typeId: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};


export type BeamInputCredentialArgs = {
  where?: InputMaybe<CredentialWhereInput>;
};


export type BeamInputFilesArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FileWhereInput>;
};


export type BeamInputHsiArgs = {
  cursor?: InputMaybe<HighlySensitiveIdentifierWhereUniqueInput>;
  distinct?: InputMaybe<Array<HighlySensitiveIdentifierScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HighlySensitiveIdentifierOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
};


export type BeamInputOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type BeamInputPassArgs = {
  where?: InputMaybe<PassWhereInput>;
};


export type BeamInputRunsArgs = {
  cursor?: InputMaybe<BeamRunWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeamRunScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeamRunOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamRunWhereInput>;
};

export type BeamInputCount = {
  __typename?: 'BeamInputCount';
  files: Scalars['Int'];
  hsi: Scalars['Int'];
  runs: Scalars['Int'];
};


export type BeamInputCountFilesArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type BeamInputCountHsiArgs = {
  where?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
};


export type BeamInputCountRunsArgs = {
  where?: InputMaybe<BeamRunWhereInput>;
};

export type BeamInputCountAggregate = {
  __typename?: 'BeamInputCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  data: Scalars['Int'];
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  organizationId: Scalars['Int'];
  passId: Scalars['Int'];
  typeId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type BeamInputCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credential?: InputMaybe<CredentialCreateNestedOneWithoutInputInput>;
  data: Scalars['JSON'];
  files?: InputMaybe<FileCreateNestedManyWithoutBeamInputInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierCreateNestedManyWithoutBeamInputInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutBeamInputsInput>;
  organizationId: Scalars['String'];
  pass?: InputMaybe<PassCreateNestedOneWithoutInputsInput>;
  runs?: InputMaybe<BeamRunCreateNestedManyWithoutInputInput>;
  type: BeamInputTypeCreateNestedOneWithoutInputsInput;
  user: UserCreateNestedOneWithoutBeamInputInput;
};

export type BeamInputCreateManyOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  passId?: InputMaybe<Scalars['String']>;
  typeId: Scalars['String'];
  userId: Scalars['String'];
};

export type BeamInputCreateManyOrderInputEnvelope = {
  data: Array<BeamInputCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BeamInputCreateManyPassInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  typeId: Scalars['String'];
  userId: Scalars['String'];
};

export type BeamInputCreateManyPassInputEnvelope = {
  data: Array<BeamInputCreateManyPassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BeamInputCreateManyTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  passId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type BeamInputCreateManyTypeInputEnvelope = {
  data: Array<BeamInputCreateManyTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BeamInputCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  passId?: InputMaybe<Scalars['String']>;
  typeId: Scalars['String'];
};

export type BeamInputCreateManyUserInputEnvelope = {
  data: Array<BeamInputCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BeamInputCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamInputCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<BeamInputCreateWithoutOrderInput>>;
  createMany?: InputMaybe<BeamInputCreateManyOrderInputEnvelope>;
};

export type BeamInputCreateNestedManyWithoutPassInput = {
  connect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamInputCreateOrConnectWithoutPassInput>>;
  create?: InputMaybe<Array<BeamInputCreateWithoutPassInput>>;
  createMany?: InputMaybe<BeamInputCreateManyPassInputEnvelope>;
};

export type BeamInputCreateNestedManyWithoutTypeInput = {
  connect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamInputCreateOrConnectWithoutTypeInput>>;
  create?: InputMaybe<Array<BeamInputCreateWithoutTypeInput>>;
  createMany?: InputMaybe<BeamInputCreateManyTypeInputEnvelope>;
};

export type BeamInputCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamInputCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<BeamInputCreateWithoutUserInput>>;
  createMany?: InputMaybe<BeamInputCreateManyUserInputEnvelope>;
};

export type BeamInputCreateNestedOneWithoutCredentialInput = {
  connect?: InputMaybe<BeamInputWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamInputCreateOrConnectWithoutCredentialInput>;
  create?: InputMaybe<BeamInputCreateWithoutCredentialInput>;
};

export type BeamInputCreateNestedOneWithoutFilesInput = {
  connect?: InputMaybe<BeamInputWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamInputCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<BeamInputCreateWithoutFilesInput>;
};

export type BeamInputCreateNestedOneWithoutHsiInput = {
  connect?: InputMaybe<BeamInputWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamInputCreateOrConnectWithoutHsiInput>;
  create?: InputMaybe<BeamInputCreateWithoutHsiInput>;
};

export type BeamInputCreateNestedOneWithoutRunsInput = {
  connect?: InputMaybe<BeamInputWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamInputCreateOrConnectWithoutRunsInput>;
  create?: InputMaybe<BeamInputCreateWithoutRunsInput>;
};

export type BeamInputCreateOrConnectWithoutCredentialInput = {
  create: BeamInputCreateWithoutCredentialInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputCreateOrConnectWithoutFilesInput = {
  create: BeamInputCreateWithoutFilesInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputCreateOrConnectWithoutHsiInput = {
  create: BeamInputCreateWithoutHsiInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputCreateOrConnectWithoutOrderInput = {
  create: BeamInputCreateWithoutOrderInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputCreateOrConnectWithoutPassInput = {
  create: BeamInputCreateWithoutPassInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputCreateOrConnectWithoutRunsInput = {
  create: BeamInputCreateWithoutRunsInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputCreateOrConnectWithoutTypeInput = {
  create: BeamInputCreateWithoutTypeInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputCreateOrConnectWithoutUserInput = {
  create: BeamInputCreateWithoutUserInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputCreateWithoutCredentialInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  files?: InputMaybe<FileCreateNestedManyWithoutBeamInputInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierCreateNestedManyWithoutBeamInputInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutBeamInputsInput>;
  organizationId: Scalars['String'];
  pass?: InputMaybe<PassCreateNestedOneWithoutInputsInput>;
  runs?: InputMaybe<BeamRunCreateNestedManyWithoutInputInput>;
  type: BeamInputTypeCreateNestedOneWithoutInputsInput;
  user: UserCreateNestedOneWithoutBeamInputInput;
};

export type BeamInputCreateWithoutFilesInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credential?: InputMaybe<CredentialCreateNestedOneWithoutInputInput>;
  data: Scalars['JSON'];
  hsi?: InputMaybe<HighlySensitiveIdentifierCreateNestedManyWithoutBeamInputInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutBeamInputsInput>;
  organizationId: Scalars['String'];
  pass?: InputMaybe<PassCreateNestedOneWithoutInputsInput>;
  runs?: InputMaybe<BeamRunCreateNestedManyWithoutInputInput>;
  type: BeamInputTypeCreateNestedOneWithoutInputsInput;
  user: UserCreateNestedOneWithoutBeamInputInput;
};

export type BeamInputCreateWithoutHsiInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credential?: InputMaybe<CredentialCreateNestedOneWithoutInputInput>;
  data: Scalars['JSON'];
  files?: InputMaybe<FileCreateNestedManyWithoutBeamInputInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutBeamInputsInput>;
  organizationId: Scalars['String'];
  pass?: InputMaybe<PassCreateNestedOneWithoutInputsInput>;
  runs?: InputMaybe<BeamRunCreateNestedManyWithoutInputInput>;
  type: BeamInputTypeCreateNestedOneWithoutInputsInput;
  user: UserCreateNestedOneWithoutBeamInputInput;
};

export type BeamInputCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credential?: InputMaybe<CredentialCreateNestedOneWithoutInputInput>;
  data: Scalars['JSON'];
  files?: InputMaybe<FileCreateNestedManyWithoutBeamInputInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierCreateNestedManyWithoutBeamInputInput>;
  id?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pass?: InputMaybe<PassCreateNestedOneWithoutInputsInput>;
  runs?: InputMaybe<BeamRunCreateNestedManyWithoutInputInput>;
  type: BeamInputTypeCreateNestedOneWithoutInputsInput;
  user: UserCreateNestedOneWithoutBeamInputInput;
};

export type BeamInputCreateWithoutPassInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credential?: InputMaybe<CredentialCreateNestedOneWithoutInputInput>;
  data: Scalars['JSON'];
  files?: InputMaybe<FileCreateNestedManyWithoutBeamInputInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierCreateNestedManyWithoutBeamInputInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutBeamInputsInput>;
  organizationId: Scalars['String'];
  runs?: InputMaybe<BeamRunCreateNestedManyWithoutInputInput>;
  type: BeamInputTypeCreateNestedOneWithoutInputsInput;
  user: UserCreateNestedOneWithoutBeamInputInput;
};

export type BeamInputCreateWithoutRunsInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credential?: InputMaybe<CredentialCreateNestedOneWithoutInputInput>;
  data: Scalars['JSON'];
  files?: InputMaybe<FileCreateNestedManyWithoutBeamInputInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierCreateNestedManyWithoutBeamInputInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutBeamInputsInput>;
  organizationId: Scalars['String'];
  pass?: InputMaybe<PassCreateNestedOneWithoutInputsInput>;
  type: BeamInputTypeCreateNestedOneWithoutInputsInput;
  user: UserCreateNestedOneWithoutBeamInputInput;
};

export type BeamInputCreateWithoutTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credential?: InputMaybe<CredentialCreateNestedOneWithoutInputInput>;
  data: Scalars['JSON'];
  files?: InputMaybe<FileCreateNestedManyWithoutBeamInputInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierCreateNestedManyWithoutBeamInputInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutBeamInputsInput>;
  organizationId: Scalars['String'];
  pass?: InputMaybe<PassCreateNestedOneWithoutInputsInput>;
  runs?: InputMaybe<BeamRunCreateNestedManyWithoutInputInput>;
  user: UserCreateNestedOneWithoutBeamInputInput;
};

export type BeamInputCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credential?: InputMaybe<CredentialCreateNestedOneWithoutInputInput>;
  data: Scalars['JSON'];
  files?: InputMaybe<FileCreateNestedManyWithoutBeamInputInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierCreateNestedManyWithoutBeamInputInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutBeamInputsInput>;
  organizationId: Scalars['String'];
  pass?: InputMaybe<PassCreateNestedOneWithoutInputsInput>;
  runs?: InputMaybe<BeamRunCreateNestedManyWithoutInputInput>;
  type: BeamInputTypeCreateNestedOneWithoutInputsInput;
};

export type BeamInputListRelationFilter = {
  every?: InputMaybe<BeamInputWhereInput>;
  none?: InputMaybe<BeamInputWhereInput>;
  some?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputMaxAggregate = {
  __typename?: 'BeamInputMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  passId?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type BeamInputMinAggregate = {
  __typename?: 'BeamInputMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  passId?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type BeamInputNullableRelationFilter = {
  is?: InputMaybe<BeamInputWhereInput>;
  isNot?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BeamInputOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  credential?: InputMaybe<CredentialOrderByWithRelationInput>;
  data?: InputMaybe<SortOrder>;
  files?: InputMaybe<FileOrderByRelationAggregateInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrderInput>;
  organizationId?: InputMaybe<SortOrder>;
  pass?: InputMaybe<PassOrderByWithRelationInput>;
  passId?: InputMaybe<SortOrderInput>;
  runs?: InputMaybe<BeamRunOrderByRelationAggregateInput>;
  type?: InputMaybe<BeamInputTypeOrderByWithRelationInput>;
  typeId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type BeamInputRelationFilter = {
  is?: InputMaybe<BeamInputWhereInput>;
  isNot?: InputMaybe<BeamInputWhereInput>;
};

export enum BeamInputScalarFieldEnum {
  CreatedAt = 'createdAt',
  Data = 'data',
  Id = 'id',
  OrderId = 'orderId',
  OrganizationId = 'organizationId',
  PassId = 'passId',
  TypeId = 'typeId',
  UserId = 'userId'
}

export type BeamInputScalarWhereInput = {
  AND?: InputMaybe<Array<BeamInputScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeamInputScalarWhereInput>>;
  OR?: InputMaybe<Array<BeamInputScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  organizationId?: InputMaybe<StringFilter>;
  passId?: InputMaybe<StringNullableFilter>;
  typeId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type BeamInputType = {
  __typename?: 'BeamInputType';
  _count?: Maybe<BeamInputTypeCount>;
  beam: Beam;
  beamId: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  inputs: Array<BeamInput>;
  organizationId: Scalars['String'];
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  uiSchema?: Maybe<Scalars['JSON']>;
  version: Scalars['String'];
};


export type BeamInputTypeInputsArgs = {
  cursor?: InputMaybe<BeamInputWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeamInputScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeamInputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputTypeCount = {
  __typename?: 'BeamInputTypeCount';
  inputs: Scalars['Int'];
};


export type BeamInputTypeCountInputsArgs = {
  where?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputTypeCreateInput = {
  beam: BeamCreateNestedOneWithoutInputTypesInput;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<BeamInputCreateNestedManyWithoutTypeInput>;
  organizationId: Scalars['String'];
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  uiSchema?: InputMaybe<Scalars['JSON']>;
  version: Scalars['String'];
};

export type BeamInputTypeCreateManyBeamInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  uiSchema?: InputMaybe<Scalars['JSON']>;
  version: Scalars['String'];
};

export type BeamInputTypeCreateManyBeamInputEnvelope = {
  data: Array<BeamInputTypeCreateManyBeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BeamInputTypeCreateNestedManyWithoutBeamInput = {
  connect?: InputMaybe<Array<BeamInputTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamInputTypeCreateOrConnectWithoutBeamInput>>;
  create?: InputMaybe<Array<BeamInputTypeCreateWithoutBeamInput>>;
  createMany?: InputMaybe<BeamInputTypeCreateManyBeamInputEnvelope>;
};

export type BeamInputTypeCreateNestedOneWithoutInputsInput = {
  connect?: InputMaybe<BeamInputTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamInputTypeCreateOrConnectWithoutInputsInput>;
  create?: InputMaybe<BeamInputTypeCreateWithoutInputsInput>;
};

export type BeamInputTypeCreateOrConnectWithoutBeamInput = {
  create: BeamInputTypeCreateWithoutBeamInput;
  where: BeamInputTypeWhereUniqueInput;
};

export type BeamInputTypeCreateOrConnectWithoutInputsInput = {
  create: BeamInputTypeCreateWithoutInputsInput;
  where: BeamInputTypeWhereUniqueInput;
};

export type BeamInputTypeCreateWithoutBeamInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<BeamInputCreateNestedManyWithoutTypeInput>;
  organizationId: Scalars['String'];
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  uiSchema?: InputMaybe<Scalars['JSON']>;
  version: Scalars['String'];
};

export type BeamInputTypeCreateWithoutInputsInput = {
  beam: BeamCreateNestedOneWithoutInputTypesInput;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  uiSchema?: InputMaybe<Scalars['JSON']>;
  version: Scalars['String'];
};

export type BeamInputTypeListRelationFilter = {
  every?: InputMaybe<BeamInputTypeWhereInput>;
  none?: InputMaybe<BeamInputTypeWhereInput>;
  some?: InputMaybe<BeamInputTypeWhereInput>;
};

export type BeamInputTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BeamInputTypeOrderByWithRelationInput = {
  beam?: InputMaybe<BeamOrderByWithRelationInput>;
  beamId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inputs?: InputMaybe<BeamInputOrderByRelationAggregateInput>;
  organizationId?: InputMaybe<SortOrder>;
  schema?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  uiSchema?: InputMaybe<SortOrderInput>;
  version?: InputMaybe<SortOrder>;
};

export type BeamInputTypeRelationFilter = {
  is?: InputMaybe<BeamInputTypeWhereInput>;
  isNot?: InputMaybe<BeamInputTypeWhereInput>;
};

export enum BeamInputTypeScalarFieldEnum {
  BeamId = 'beamId',
  CreatedAt = 'createdAt',
  Id = 'id',
  OrganizationId = 'organizationId',
  Schema = 'schema',
  Slug = 'slug',
  UiSchema = 'uiSchema',
  Version = 'version'
}

export type BeamInputTypeScalarWhereInput = {
  AND?: InputMaybe<Array<BeamInputTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeamInputTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<BeamInputTypeScalarWhereInput>>;
  beamId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  schema?: InputMaybe<JsonFilter>;
  slug?: InputMaybe<StringFilter>;
  uiSchema?: InputMaybe<JsonNullableFilter>;
  version?: InputMaybe<StringFilter>;
};

export type BeamInputTypeSlugVersionCompoundUniqueInput = {
  slug: Scalars['String'];
  version: Scalars['String'];
};

export type BeamInputTypeUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  schema?: InputMaybe<Scalars['JSON']>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  uiSchema?: InputMaybe<Scalars['JSON']>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BeamInputTypeUpdateManyWithWhereWithoutBeamInput = {
  data: BeamInputTypeUpdateManyMutationInput;
  where: BeamInputTypeScalarWhereInput;
};

export type BeamInputTypeUpdateManyWithoutBeamNestedInput = {
  connect?: InputMaybe<Array<BeamInputTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamInputTypeCreateOrConnectWithoutBeamInput>>;
  create?: InputMaybe<Array<BeamInputTypeCreateWithoutBeamInput>>;
  createMany?: InputMaybe<BeamInputTypeCreateManyBeamInputEnvelope>;
  delete?: InputMaybe<Array<BeamInputTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeamInputTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeamInputTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<BeamInputTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<BeamInputTypeUpdateWithWhereUniqueWithoutBeamInput>>;
  updateMany?: InputMaybe<Array<BeamInputTypeUpdateManyWithWhereWithoutBeamInput>>;
  upsert?: InputMaybe<Array<BeamInputTypeUpsertWithWhereUniqueWithoutBeamInput>>;
};

export type BeamInputTypeUpdateOneRequiredWithoutInputsNestedInput = {
  connect?: InputMaybe<BeamInputTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamInputTypeCreateOrConnectWithoutInputsInput>;
  create?: InputMaybe<BeamInputTypeCreateWithoutInputsInput>;
  update?: InputMaybe<BeamInputTypeUpdateToOneWithWhereWithoutInputsInput>;
  upsert?: InputMaybe<BeamInputTypeUpsertWithoutInputsInput>;
};

export type BeamInputTypeUpdateToOneWithWhereWithoutInputsInput = {
  data: BeamInputTypeUpdateWithoutInputsInput;
  where?: InputMaybe<BeamInputTypeWhereInput>;
};

export type BeamInputTypeUpdateWithWhereUniqueWithoutBeamInput = {
  data: BeamInputTypeUpdateWithoutBeamInput;
  where: BeamInputTypeWhereUniqueInput;
};

export type BeamInputTypeUpdateWithoutBeamInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inputs?: InputMaybe<BeamInputUpdateManyWithoutTypeNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  schema?: InputMaybe<Scalars['JSON']>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  uiSchema?: InputMaybe<Scalars['JSON']>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BeamInputTypeUpdateWithoutInputsInput = {
  beam?: InputMaybe<BeamUpdateOneRequiredWithoutInputTypesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  schema?: InputMaybe<Scalars['JSON']>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  uiSchema?: InputMaybe<Scalars['JSON']>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BeamInputTypeUpsertWithWhereUniqueWithoutBeamInput = {
  create: BeamInputTypeCreateWithoutBeamInput;
  update: BeamInputTypeUpdateWithoutBeamInput;
  where: BeamInputTypeWhereUniqueInput;
};

export type BeamInputTypeUpsertWithoutInputsInput = {
  create: BeamInputTypeCreateWithoutInputsInput;
  update: BeamInputTypeUpdateWithoutInputsInput;
  where?: InputMaybe<BeamInputTypeWhereInput>;
};

export type BeamInputTypeWhereInput = {
  AND?: InputMaybe<Array<BeamInputTypeWhereInput>>;
  NOT?: InputMaybe<Array<BeamInputTypeWhereInput>>;
  OR?: InputMaybe<Array<BeamInputTypeWhereInput>>;
  beam?: InputMaybe<BeamRelationFilter>;
  beamId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputs?: InputMaybe<BeamInputListRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  schema?: InputMaybe<JsonFilter>;
  slug?: InputMaybe<StringFilter>;
  uiSchema?: InputMaybe<JsonNullableFilter>;
  version?: InputMaybe<StringFilter>;
};

export type BeamInputTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<BeamInputTypeWhereInput>>;
  NOT?: InputMaybe<Array<BeamInputTypeWhereInput>>;
  OR?: InputMaybe<Array<BeamInputTypeWhereInput>>;
  beam?: InputMaybe<BeamRelationFilter>;
  beamId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<BeamInputListRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  schema?: InputMaybe<JsonFilter>;
  slug?: InputMaybe<StringFilter>;
  slug_version?: InputMaybe<BeamInputTypeSlugVersionCompoundUniqueInput>;
  uiSchema?: InputMaybe<JsonNullableFilter>;
  version?: InputMaybe<StringFilter>;
};

export type BeamInputUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneWithoutInputNestedInput>;
  data?: InputMaybe<Scalars['JSON']>;
  files?: InputMaybe<FileUpdateManyWithoutBeamInputNestedInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierUpdateManyWithoutBeamInputNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutBeamInputsNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  pass?: InputMaybe<PassUpdateOneWithoutInputsNestedInput>;
  runs?: InputMaybe<BeamRunUpdateManyWithoutInputNestedInput>;
  type?: InputMaybe<BeamInputTypeUpdateOneRequiredWithoutInputsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBeamInputNestedInput>;
};

export type BeamInputUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BeamInputUpdateManyWithWhereWithoutOrderInput = {
  data: BeamInputUpdateManyMutationInput;
  where: BeamInputScalarWhereInput;
};

export type BeamInputUpdateManyWithWhereWithoutPassInput = {
  data: BeamInputUpdateManyMutationInput;
  where: BeamInputScalarWhereInput;
};

export type BeamInputUpdateManyWithWhereWithoutTypeInput = {
  data: BeamInputUpdateManyMutationInput;
  where: BeamInputScalarWhereInput;
};

export type BeamInputUpdateManyWithWhereWithoutUserInput = {
  data: BeamInputUpdateManyMutationInput;
  where: BeamInputScalarWhereInput;
};

export type BeamInputUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamInputCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<BeamInputCreateWithoutOrderInput>>;
  createMany?: InputMaybe<BeamInputCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeamInputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  set?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  update?: InputMaybe<Array<BeamInputUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<BeamInputUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<BeamInputUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type BeamInputUpdateManyWithoutPassNestedInput = {
  connect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamInputCreateOrConnectWithoutPassInput>>;
  create?: InputMaybe<Array<BeamInputCreateWithoutPassInput>>;
  createMany?: InputMaybe<BeamInputCreateManyPassInputEnvelope>;
  delete?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeamInputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  set?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  update?: InputMaybe<Array<BeamInputUpdateWithWhereUniqueWithoutPassInput>>;
  updateMany?: InputMaybe<Array<BeamInputUpdateManyWithWhereWithoutPassInput>>;
  upsert?: InputMaybe<Array<BeamInputUpsertWithWhereUniqueWithoutPassInput>>;
};

export type BeamInputUpdateManyWithoutTypeNestedInput = {
  connect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamInputCreateOrConnectWithoutTypeInput>>;
  create?: InputMaybe<Array<BeamInputCreateWithoutTypeInput>>;
  createMany?: InputMaybe<BeamInputCreateManyTypeInputEnvelope>;
  delete?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeamInputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  set?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  update?: InputMaybe<Array<BeamInputUpdateWithWhereUniqueWithoutTypeInput>>;
  updateMany?: InputMaybe<Array<BeamInputUpdateManyWithWhereWithoutTypeInput>>;
  upsert?: InputMaybe<Array<BeamInputUpsertWithWhereUniqueWithoutTypeInput>>;
};

export type BeamInputUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamInputCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<BeamInputCreateWithoutUserInput>>;
  createMany?: InputMaybe<BeamInputCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeamInputScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  set?: InputMaybe<Array<BeamInputWhereUniqueInput>>;
  update?: InputMaybe<Array<BeamInputUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<BeamInputUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<BeamInputUpsertWithWhereUniqueWithoutUserInput>>;
};

export type BeamInputUpdateOneRequiredWithoutCredentialNestedInput = {
  connect?: InputMaybe<BeamInputWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamInputCreateOrConnectWithoutCredentialInput>;
  create?: InputMaybe<BeamInputCreateWithoutCredentialInput>;
  update?: InputMaybe<BeamInputUpdateToOneWithWhereWithoutCredentialInput>;
  upsert?: InputMaybe<BeamInputUpsertWithoutCredentialInput>;
};

export type BeamInputUpdateOneRequiredWithoutRunsNestedInput = {
  connect?: InputMaybe<BeamInputWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamInputCreateOrConnectWithoutRunsInput>;
  create?: InputMaybe<BeamInputCreateWithoutRunsInput>;
  update?: InputMaybe<BeamInputUpdateToOneWithWhereWithoutRunsInput>;
  upsert?: InputMaybe<BeamInputUpsertWithoutRunsInput>;
};

export type BeamInputUpdateOneWithoutFilesNestedInput = {
  connect?: InputMaybe<BeamInputWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamInputCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<BeamInputCreateWithoutFilesInput>;
  delete?: InputMaybe<BeamInputWhereInput>;
  disconnect?: InputMaybe<BeamInputWhereInput>;
  update?: InputMaybe<BeamInputUpdateToOneWithWhereWithoutFilesInput>;
  upsert?: InputMaybe<BeamInputUpsertWithoutFilesInput>;
};

export type BeamInputUpdateOneWithoutHsiNestedInput = {
  connect?: InputMaybe<BeamInputWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamInputCreateOrConnectWithoutHsiInput>;
  create?: InputMaybe<BeamInputCreateWithoutHsiInput>;
  delete?: InputMaybe<BeamInputWhereInput>;
  disconnect?: InputMaybe<BeamInputWhereInput>;
  update?: InputMaybe<BeamInputUpdateToOneWithWhereWithoutHsiInput>;
  upsert?: InputMaybe<BeamInputUpsertWithoutHsiInput>;
};

export type BeamInputUpdateToOneWithWhereWithoutCredentialInput = {
  data: BeamInputUpdateWithoutCredentialInput;
  where?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputUpdateToOneWithWhereWithoutFilesInput = {
  data: BeamInputUpdateWithoutFilesInput;
  where?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputUpdateToOneWithWhereWithoutHsiInput = {
  data: BeamInputUpdateWithoutHsiInput;
  where?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputUpdateToOneWithWhereWithoutRunsInput = {
  data: BeamInputUpdateWithoutRunsInput;
  where?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputUpdateWithWhereUniqueWithoutOrderInput = {
  data: BeamInputUpdateWithoutOrderInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputUpdateWithWhereUniqueWithoutPassInput = {
  data: BeamInputUpdateWithoutPassInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputUpdateWithWhereUniqueWithoutTypeInput = {
  data: BeamInputUpdateWithoutTypeInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputUpdateWithWhereUniqueWithoutUserInput = {
  data: BeamInputUpdateWithoutUserInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputUpdateWithoutCredentialInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  files?: InputMaybe<FileUpdateManyWithoutBeamInputNestedInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierUpdateManyWithoutBeamInputNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutBeamInputsNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  pass?: InputMaybe<PassUpdateOneWithoutInputsNestedInput>;
  runs?: InputMaybe<BeamRunUpdateManyWithoutInputNestedInput>;
  type?: InputMaybe<BeamInputTypeUpdateOneRequiredWithoutInputsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBeamInputNestedInput>;
};

export type BeamInputUpdateWithoutFilesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneWithoutInputNestedInput>;
  data?: InputMaybe<Scalars['JSON']>;
  hsi?: InputMaybe<HighlySensitiveIdentifierUpdateManyWithoutBeamInputNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutBeamInputsNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  pass?: InputMaybe<PassUpdateOneWithoutInputsNestedInput>;
  runs?: InputMaybe<BeamRunUpdateManyWithoutInputNestedInput>;
  type?: InputMaybe<BeamInputTypeUpdateOneRequiredWithoutInputsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBeamInputNestedInput>;
};

export type BeamInputUpdateWithoutHsiInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneWithoutInputNestedInput>;
  data?: InputMaybe<Scalars['JSON']>;
  files?: InputMaybe<FileUpdateManyWithoutBeamInputNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutBeamInputsNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  pass?: InputMaybe<PassUpdateOneWithoutInputsNestedInput>;
  runs?: InputMaybe<BeamRunUpdateManyWithoutInputNestedInput>;
  type?: InputMaybe<BeamInputTypeUpdateOneRequiredWithoutInputsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBeamInputNestedInput>;
};

export type BeamInputUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneWithoutInputNestedInput>;
  data?: InputMaybe<Scalars['JSON']>;
  files?: InputMaybe<FileUpdateManyWithoutBeamInputNestedInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierUpdateManyWithoutBeamInputNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  pass?: InputMaybe<PassUpdateOneWithoutInputsNestedInput>;
  runs?: InputMaybe<BeamRunUpdateManyWithoutInputNestedInput>;
  type?: InputMaybe<BeamInputTypeUpdateOneRequiredWithoutInputsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBeamInputNestedInput>;
};

export type BeamInputUpdateWithoutPassInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneWithoutInputNestedInput>;
  data?: InputMaybe<Scalars['JSON']>;
  files?: InputMaybe<FileUpdateManyWithoutBeamInputNestedInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierUpdateManyWithoutBeamInputNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutBeamInputsNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  runs?: InputMaybe<BeamRunUpdateManyWithoutInputNestedInput>;
  type?: InputMaybe<BeamInputTypeUpdateOneRequiredWithoutInputsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBeamInputNestedInput>;
};

export type BeamInputUpdateWithoutRunsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneWithoutInputNestedInput>;
  data?: InputMaybe<Scalars['JSON']>;
  files?: InputMaybe<FileUpdateManyWithoutBeamInputNestedInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierUpdateManyWithoutBeamInputNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutBeamInputsNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  pass?: InputMaybe<PassUpdateOneWithoutInputsNestedInput>;
  type?: InputMaybe<BeamInputTypeUpdateOneRequiredWithoutInputsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBeamInputNestedInput>;
};

export type BeamInputUpdateWithoutTypeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneWithoutInputNestedInput>;
  data?: InputMaybe<Scalars['JSON']>;
  files?: InputMaybe<FileUpdateManyWithoutBeamInputNestedInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierUpdateManyWithoutBeamInputNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutBeamInputsNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  pass?: InputMaybe<PassUpdateOneWithoutInputsNestedInput>;
  runs?: InputMaybe<BeamRunUpdateManyWithoutInputNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBeamInputNestedInput>;
};

export type BeamInputUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneWithoutInputNestedInput>;
  data?: InputMaybe<Scalars['JSON']>;
  files?: InputMaybe<FileUpdateManyWithoutBeamInputNestedInput>;
  hsi?: InputMaybe<HighlySensitiveIdentifierUpdateManyWithoutBeamInputNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutBeamInputsNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  pass?: InputMaybe<PassUpdateOneWithoutInputsNestedInput>;
  runs?: InputMaybe<BeamRunUpdateManyWithoutInputNestedInput>;
  type?: InputMaybe<BeamInputTypeUpdateOneRequiredWithoutInputsNestedInput>;
};

export type BeamInputUpsertWithWhereUniqueWithoutOrderInput = {
  create: BeamInputCreateWithoutOrderInput;
  update: BeamInputUpdateWithoutOrderInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputUpsertWithWhereUniqueWithoutPassInput = {
  create: BeamInputCreateWithoutPassInput;
  update: BeamInputUpdateWithoutPassInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputUpsertWithWhereUniqueWithoutTypeInput = {
  create: BeamInputCreateWithoutTypeInput;
  update: BeamInputUpdateWithoutTypeInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputUpsertWithWhereUniqueWithoutUserInput = {
  create: BeamInputCreateWithoutUserInput;
  update: BeamInputUpdateWithoutUserInput;
  where: BeamInputWhereUniqueInput;
};

export type BeamInputUpsertWithoutCredentialInput = {
  create: BeamInputCreateWithoutCredentialInput;
  update: BeamInputUpdateWithoutCredentialInput;
  where?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputUpsertWithoutFilesInput = {
  create: BeamInputCreateWithoutFilesInput;
  update: BeamInputUpdateWithoutFilesInput;
  where?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputUpsertWithoutHsiInput = {
  create: BeamInputCreateWithoutHsiInput;
  update: BeamInputUpdateWithoutHsiInput;
  where?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputUpsertWithoutRunsInput = {
  create: BeamInputCreateWithoutRunsInput;
  update: BeamInputUpdateWithoutRunsInput;
  where?: InputMaybe<BeamInputWhereInput>;
};

export type BeamInputWhereInput = {
  AND?: InputMaybe<Array<BeamInputWhereInput>>;
  NOT?: InputMaybe<Array<BeamInputWhereInput>>;
  OR?: InputMaybe<Array<BeamInputWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credential?: InputMaybe<CredentialNullableRelationFilter>;
  data?: InputMaybe<JsonFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  hsi?: InputMaybe<HighlySensitiveIdentifierListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  organizationId?: InputMaybe<StringFilter>;
  pass?: InputMaybe<PassNullableRelationFilter>;
  passId?: InputMaybe<StringNullableFilter>;
  runs?: InputMaybe<BeamRunListRelationFilter>;
  type?: InputMaybe<BeamInputTypeRelationFilter>;
  typeId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type BeamInputWhereUniqueInput = {
  AND?: InputMaybe<Array<BeamInputWhereInput>>;
  NOT?: InputMaybe<Array<BeamInputWhereInput>>;
  OR?: InputMaybe<Array<BeamInputWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credential?: InputMaybe<CredentialNullableRelationFilter>;
  data?: InputMaybe<JsonFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  hsi?: InputMaybe<HighlySensitiveIdentifierListRelationFilter>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  organizationId?: InputMaybe<StringFilter>;
  pass?: InputMaybe<PassNullableRelationFilter>;
  passId?: InputMaybe<StringNullableFilter>;
  runs?: InputMaybe<BeamRunListRelationFilter>;
  type?: InputMaybe<BeamInputTypeRelationFilter>;
  typeId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type BeamListRelationFilter = {
  every?: InputMaybe<BeamWhereInput>;
  none?: InputMaybe<BeamWhereInput>;
  some?: InputMaybe<BeamWhereInput>;
};

export type BeamOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BeamOrderByWithRelationInput = {
  config?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  credentialTypes?: InputMaybe<CredentialTypeOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inputTypes?: InputMaybe<BeamInputTypeOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrder>;
  passTypes?: InputMaybe<PassTypeOrderByRelationAggregateInput>;
  processor?: InputMaybe<SortOrder>;
  processorConfig?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BeamRelationFilter = {
  is?: InputMaybe<BeamWhereInput>;
  isNot?: InputMaybe<BeamWhereInput>;
};

export type BeamRun = {
  __typename?: 'BeamRun';
  clientReference?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  credentialId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  inputId: Scalars['String'];
  integrationJob?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['JSON']>;
  status: BeamRunStatusEnum;
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
};

export type BeamRunCreateManyInputInput = {
  clientReference?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentialId?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  integrationJob?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<BeamRunStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BeamRunCreateManyInputInputEnvelope = {
  data: Array<BeamRunCreateManyInputInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BeamRunCreateNestedManyWithoutInputInput = {
  connect?: InputMaybe<Array<BeamRunWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamRunCreateOrConnectWithoutInputInput>>;
  create?: InputMaybe<Array<BeamRunCreateWithoutInputInput>>;
  createMany?: InputMaybe<BeamRunCreateManyInputInputEnvelope>;
};

export type BeamRunCreateNestedOneWithoutCredentialInput = {
  connect?: InputMaybe<BeamRunWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamRunCreateOrConnectWithoutCredentialInput>;
  create?: InputMaybe<BeamRunCreateWithoutCredentialInput>;
};

export type BeamRunCreateOrConnectWithoutCredentialInput = {
  create: BeamRunCreateWithoutCredentialInput;
  where: BeamRunWhereUniqueInput;
};

export type BeamRunCreateOrConnectWithoutInputInput = {
  create: BeamRunCreateWithoutInputInput;
  where: BeamRunWhereUniqueInput;
};

export type BeamRunCreateWithoutCredentialInput = {
  clientReference?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  error?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  input: BeamInputCreateNestedOneWithoutRunsInput;
  integrationJob?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<BeamRunStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BeamRunCreateWithoutInputInput = {
  clientReference?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credential?: InputMaybe<CredentialCreateNestedOneWithoutRunInput>;
  error?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  integrationJob?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<BeamRunStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BeamRunListRelationFilter = {
  every?: InputMaybe<BeamRunWhereInput>;
  none?: InputMaybe<BeamRunWhereInput>;
  some?: InputMaybe<BeamRunWhereInput>;
};

export type BeamRunNullableRelationFilter = {
  is?: InputMaybe<BeamRunWhereInput>;
  isNot?: InputMaybe<BeamRunWhereInput>;
};

export type BeamRunOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BeamRunOrderByWithRelationInput = {
  clientReference?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  credential?: InputMaybe<CredentialOrderByWithRelationInput>;
  credentialId?: InputMaybe<SortOrderInput>;
  error?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  input?: InputMaybe<BeamInputOrderByWithRelationInput>;
  inputId?: InputMaybe<SortOrder>;
  integrationJob?: InputMaybe<SortOrderInput>;
  result?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export enum BeamRunScalarFieldEnum {
  ClientReference = 'clientReference',
  CreatedAt = 'createdAt',
  CredentialId = 'credentialId',
  Error = 'error',
  Id = 'id',
  InputId = 'inputId',
  IntegrationJob = 'integrationJob',
  Result = 'result',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type BeamRunScalarWhereInput = {
  AND?: InputMaybe<Array<BeamRunScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeamRunScalarWhereInput>>;
  OR?: InputMaybe<Array<BeamRunScalarWhereInput>>;
  clientReference?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credentialId?: InputMaybe<StringNullableFilter>;
  error?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inputId?: InputMaybe<StringFilter>;
  integrationJob?: InputMaybe<StringNullableFilter>;
  result?: InputMaybe<JsonNullableFilter>;
  status?: InputMaybe<EnumBeamRunStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export enum BeamRunStatusEnum {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type BeamRunUpdateInput = {
  clientReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneWithoutRunNestedInput>;
  error?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  input?: InputMaybe<BeamInputUpdateOneRequiredWithoutRunsNestedInput>;
  integrationJob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<EnumBeamRunStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeamRunUpdateManyMutationInput = {
  clientReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  error?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integrationJob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<EnumBeamRunStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeamRunUpdateManyWithWhereWithoutInputInput = {
  data: BeamRunUpdateManyMutationInput;
  where: BeamRunScalarWhereInput;
};

export type BeamRunUpdateManyWithoutInputNestedInput = {
  connect?: InputMaybe<Array<BeamRunWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamRunCreateOrConnectWithoutInputInput>>;
  create?: InputMaybe<Array<BeamRunCreateWithoutInputInput>>;
  createMany?: InputMaybe<BeamRunCreateManyInputInputEnvelope>;
  delete?: InputMaybe<Array<BeamRunWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeamRunScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeamRunWhereUniqueInput>>;
  set?: InputMaybe<Array<BeamRunWhereUniqueInput>>;
  update?: InputMaybe<Array<BeamRunUpdateWithWhereUniqueWithoutInputInput>>;
  updateMany?: InputMaybe<Array<BeamRunUpdateManyWithWhereWithoutInputInput>>;
  upsert?: InputMaybe<Array<BeamRunUpsertWithWhereUniqueWithoutInputInput>>;
};

export type BeamRunUpdateOneWithoutCredentialNestedInput = {
  connect?: InputMaybe<BeamRunWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamRunCreateOrConnectWithoutCredentialInput>;
  create?: InputMaybe<BeamRunCreateWithoutCredentialInput>;
  delete?: InputMaybe<BeamRunWhereInput>;
  disconnect?: InputMaybe<BeamRunWhereInput>;
  update?: InputMaybe<BeamRunUpdateToOneWithWhereWithoutCredentialInput>;
  upsert?: InputMaybe<BeamRunUpsertWithoutCredentialInput>;
};

export type BeamRunUpdateToOneWithWhereWithoutCredentialInput = {
  data: BeamRunUpdateWithoutCredentialInput;
  where?: InputMaybe<BeamRunWhereInput>;
};

export type BeamRunUpdateWithWhereUniqueWithoutInputInput = {
  data: BeamRunUpdateWithoutInputInput;
  where: BeamRunWhereUniqueInput;
};

export type BeamRunUpdateWithoutCredentialInput = {
  clientReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  error?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  input?: InputMaybe<BeamInputUpdateOneRequiredWithoutRunsNestedInput>;
  integrationJob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<EnumBeamRunStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeamRunUpdateWithoutInputInput = {
  clientReference?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneWithoutRunNestedInput>;
  error?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integrationJob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<EnumBeamRunStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type BeamRunUpsertWithWhereUniqueWithoutInputInput = {
  create: BeamRunCreateWithoutInputInput;
  update: BeamRunUpdateWithoutInputInput;
  where: BeamRunWhereUniqueInput;
};

export type BeamRunUpsertWithoutCredentialInput = {
  create: BeamRunCreateWithoutCredentialInput;
  update: BeamRunUpdateWithoutCredentialInput;
  where?: InputMaybe<BeamRunWhereInput>;
};

export type BeamRunWhereInput = {
  AND?: InputMaybe<Array<BeamRunWhereInput>>;
  NOT?: InputMaybe<Array<BeamRunWhereInput>>;
  OR?: InputMaybe<Array<BeamRunWhereInput>>;
  clientReference?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credential?: InputMaybe<CredentialNullableRelationFilter>;
  credentialId?: InputMaybe<StringNullableFilter>;
  error?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  input?: InputMaybe<BeamInputRelationFilter>;
  inputId?: InputMaybe<StringFilter>;
  integrationJob?: InputMaybe<StringNullableFilter>;
  result?: InputMaybe<JsonNullableFilter>;
  status?: InputMaybe<EnumBeamRunStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type BeamRunWhereUniqueInput = {
  AND?: InputMaybe<Array<BeamRunWhereInput>>;
  NOT?: InputMaybe<Array<BeamRunWhereInput>>;
  OR?: InputMaybe<Array<BeamRunWhereInput>>;
  clientReference?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credential?: InputMaybe<CredentialNullableRelationFilter>;
  credentialId?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<BeamInputRelationFilter>;
  inputId?: InputMaybe<StringFilter>;
  integrationJob?: InputMaybe<StringNullableFilter>;
  result?: InputMaybe<JsonNullableFilter>;
  status?: InputMaybe<EnumBeamRunStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export enum BeamScalarFieldEnum {
  Config = 'config',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organizationId',
  Processor = 'processor',
  ProcessorConfig = 'processorConfig',
  UpdatedAt = 'updatedAt'
}

export type BeamScalarWhereInput = {
  AND?: InputMaybe<Array<BeamScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeamScalarWhereInput>>;
  OR?: InputMaybe<Array<BeamScalarWhereInput>>;
  config?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  processor?: InputMaybe<EnumProcessorEnumFilter>;
  processorConfig?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BeamTypeCreateInput = {
  credentialSchema: Scalars['JSON'];
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  version: Scalars['String'];
};

export type BeamUpdateInput = {
  config?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credentialTypes?: InputMaybe<CredentialTypeUpdateManyWithoutBeamNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inputTypes?: InputMaybe<BeamInputTypeUpdateManyWithoutBeamNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutBeamsNestedInput>;
  processor?: InputMaybe<EnumProcessorEnumFieldUpdateOperationsInput>;
  processorConfig?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeamUpdateManyMutationInput = {
  config?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  processor?: InputMaybe<EnumProcessorEnumFieldUpdateOperationsInput>;
  processorConfig?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeamUpdateManyWithWhereWithoutPassTypesInput = {
  data: BeamUpdateManyMutationInput;
  where: BeamScalarWhereInput;
};

export type BeamUpdateManyWithoutPassTypesNestedInput = {
  connect?: InputMaybe<Array<BeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeamCreateOrConnectWithoutPassTypesInput>>;
  create?: InputMaybe<Array<BeamCreateWithoutPassTypesInput>>;
  delete?: InputMaybe<Array<BeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeamWhereUniqueInput>>;
  set?: InputMaybe<Array<BeamWhereUniqueInput>>;
  update?: InputMaybe<Array<BeamUpdateWithWhereUniqueWithoutPassTypesInput>>;
  updateMany?: InputMaybe<Array<BeamUpdateManyWithWhereWithoutPassTypesInput>>;
  upsert?: InputMaybe<Array<BeamUpsertWithWhereUniqueWithoutPassTypesInput>>;
};

export type BeamUpdateOneRequiredWithoutCredentialTypesNestedInput = {
  connect?: InputMaybe<BeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamCreateOrConnectWithoutCredentialTypesInput>;
  create?: InputMaybe<BeamCreateWithoutCredentialTypesInput>;
  update?: InputMaybe<BeamUpdateToOneWithWhereWithoutCredentialTypesInput>;
  upsert?: InputMaybe<BeamUpsertWithoutCredentialTypesInput>;
};

export type BeamUpdateOneRequiredWithoutInputTypesNestedInput = {
  connect?: InputMaybe<BeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BeamCreateOrConnectWithoutInputTypesInput>;
  create?: InputMaybe<BeamCreateWithoutInputTypesInput>;
  update?: InputMaybe<BeamUpdateToOneWithWhereWithoutInputTypesInput>;
  upsert?: InputMaybe<BeamUpsertWithoutInputTypesInput>;
};

export type BeamUpdateToOneWithWhereWithoutCredentialTypesInput = {
  data: BeamUpdateWithoutCredentialTypesInput;
  where?: InputMaybe<BeamWhereInput>;
};

export type BeamUpdateToOneWithWhereWithoutInputTypesInput = {
  data: BeamUpdateWithoutInputTypesInput;
  where?: InputMaybe<BeamWhereInput>;
};

export type BeamUpdateWithWhereUniqueWithoutPassTypesInput = {
  data: BeamUpdateWithoutPassTypesInput;
  where: BeamWhereUniqueInput;
};

export type BeamUpdateWithoutCredentialTypesInput = {
  config?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inputTypes?: InputMaybe<BeamInputTypeUpdateManyWithoutBeamNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutBeamsNestedInput>;
  processor?: InputMaybe<EnumProcessorEnumFieldUpdateOperationsInput>;
  processorConfig?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeamUpdateWithoutInputTypesInput = {
  config?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credentialTypes?: InputMaybe<CredentialTypeUpdateManyWithoutBeamNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutBeamsNestedInput>;
  processor?: InputMaybe<EnumProcessorEnumFieldUpdateOperationsInput>;
  processorConfig?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeamUpdateWithoutPassTypesInput = {
  config?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credentialTypes?: InputMaybe<CredentialTypeUpdateManyWithoutBeamNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inputTypes?: InputMaybe<BeamInputTypeUpdateManyWithoutBeamNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  processor?: InputMaybe<EnumProcessorEnumFieldUpdateOperationsInput>;
  processorConfig?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BeamUpsertWithWhereUniqueWithoutPassTypesInput = {
  create: BeamCreateWithoutPassTypesInput;
  update: BeamUpdateWithoutPassTypesInput;
  where: BeamWhereUniqueInput;
};

export type BeamUpsertWithoutCredentialTypesInput = {
  create: BeamCreateWithoutCredentialTypesInput;
  update: BeamUpdateWithoutCredentialTypesInput;
  where?: InputMaybe<BeamWhereInput>;
};

export type BeamUpsertWithoutInputTypesInput = {
  create: BeamCreateWithoutInputTypesInput;
  update: BeamUpdateWithoutInputTypesInput;
  where?: InputMaybe<BeamWhereInput>;
};

export type BeamWhereInput = {
  AND?: InputMaybe<Array<BeamWhereInput>>;
  NOT?: InputMaybe<Array<BeamWhereInput>>;
  OR?: InputMaybe<Array<BeamWhereInput>>;
  config?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credentialTypes?: InputMaybe<CredentialTypeListRelationFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  inputTypes?: InputMaybe<BeamInputTypeListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  passTypes?: InputMaybe<PassTypeListRelationFilter>;
  processor?: InputMaybe<EnumProcessorEnumFilter>;
  processorConfig?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BeamWhereUniqueInput = {
  AND?: InputMaybe<Array<BeamWhereInput>>;
  NOT?: InputMaybe<Array<BeamWhereInput>>;
  OR?: InputMaybe<Array<BeamWhereInput>>;
  config?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credentialTypes?: InputMaybe<CredentialTypeListRelationFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']>;
  inputTypes?: InputMaybe<BeamInputTypeListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  passTypes?: InputMaybe<PassTypeListRelationFilter>;
  processor?: InputMaybe<EnumProcessorEnumFilter>;
  processorConfig?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BytesFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Byte']>;
};

export type BytesFilter = {
  equals?: InputMaybe<Scalars['Byte']>;
  in?: InputMaybe<Array<Scalars['Byte']>>;
  not?: InputMaybe<NestedBytesFilter>;
  notIn?: InputMaybe<Array<Scalars['Byte']>>;
};

export type ComplianceLetter = {
  __typename?: 'ComplianceLetter';
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  lastViewedDate?: Maybe<Scalars['DateTimeISO']>;
  name: Scalars['String'];
  orderId: Scalars['String'];
  sentDate: Scalars['DateTimeISO'];
  type: AttachmentEnum;
  updatedAt: Scalars['DateTimeISO'];
};

export type ComplianceLetterCreateManyOrderInput = {
  createdAt: Scalars['DateTimeISO'];
  id?: InputMaybe<Scalars['String']>;
  lastViewedDate?: InputMaybe<Scalars['DateTimeISO']>;
  name: Scalars['String'];
  sentDate: Scalars['DateTimeISO'];
  type: AttachmentEnum;
  updatedAt: Scalars['DateTimeISO'];
};

export type ComplianceLetterCreateManyOrderInputEnvelope = {
  data: Array<ComplianceLetterCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ComplianceLetterCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<ComplianceLetterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ComplianceLetterCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<ComplianceLetterCreateWithoutOrderInput>>;
  createMany?: InputMaybe<ComplianceLetterCreateManyOrderInputEnvelope>;
};

export type ComplianceLetterCreateOrConnectWithoutOrderInput = {
  create: ComplianceLetterCreateWithoutOrderInput;
  where: ComplianceLetterWhereUniqueInput;
};

export type ComplianceLetterCreateWithoutOrderInput = {
  createdAt: Scalars['DateTimeISO'];
  id?: InputMaybe<Scalars['String']>;
  lastViewedDate?: InputMaybe<Scalars['DateTimeISO']>;
  name: Scalars['String'];
  sentDate: Scalars['DateTimeISO'];
  type: AttachmentEnum;
  updatedAt: Scalars['DateTimeISO'];
};

export type ComplianceLetterListRelationFilter = {
  every?: InputMaybe<ComplianceLetterWhereInput>;
  none?: InputMaybe<ComplianceLetterWhereInput>;
  some?: InputMaybe<ComplianceLetterWhereInput>;
};

export type ComplianceLetterOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ComplianceLetterOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastViewedDate?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  sentDate?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ComplianceLetterScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  LastViewedDate = 'lastViewedDate',
  Name = 'name',
  OrderId = 'orderId',
  SentDate = 'sentDate',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ComplianceLetterScalarWhereInput = {
  AND?: InputMaybe<Array<ComplianceLetterScalarWhereInput>>;
  NOT?: InputMaybe<Array<ComplianceLetterScalarWhereInput>>;
  OR?: InputMaybe<Array<ComplianceLetterScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lastViewedDate?: InputMaybe<DateTimeNullableFilter>;
  name?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  sentDate?: InputMaybe<DateTimeFilter>;
  type?: InputMaybe<EnumAttachmentEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ComplianceLetterUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastViewedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sentDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAttachmentEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ComplianceLetterUpdateManyWithWhereWithoutOrderInput = {
  data: ComplianceLetterUpdateManyMutationInput;
  where: ComplianceLetterScalarWhereInput;
};

export type ComplianceLetterUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<ComplianceLetterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ComplianceLetterCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<ComplianceLetterCreateWithoutOrderInput>>;
  createMany?: InputMaybe<ComplianceLetterCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<ComplianceLetterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ComplianceLetterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ComplianceLetterWhereUniqueInput>>;
  set?: InputMaybe<Array<ComplianceLetterWhereUniqueInput>>;
  update?: InputMaybe<Array<ComplianceLetterUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<ComplianceLetterUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<ComplianceLetterUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type ComplianceLetterUpdateWithWhereUniqueWithoutOrderInput = {
  data: ComplianceLetterUpdateWithoutOrderInput;
  where: ComplianceLetterWhereUniqueInput;
};

export type ComplianceLetterUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastViewedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sentDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAttachmentEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ComplianceLetterUpsertWithWhereUniqueWithoutOrderInput = {
  create: ComplianceLetterCreateWithoutOrderInput;
  update: ComplianceLetterUpdateWithoutOrderInput;
  where: ComplianceLetterWhereUniqueInput;
};

export type ComplianceLetterWhereInput = {
  AND?: InputMaybe<Array<ComplianceLetterWhereInput>>;
  NOT?: InputMaybe<Array<ComplianceLetterWhereInput>>;
  OR?: InputMaybe<Array<ComplianceLetterWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lastViewedDate?: InputMaybe<DateTimeNullableFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  sentDate?: InputMaybe<DateTimeFilter>;
  type?: InputMaybe<EnumAttachmentEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ComplianceLetterWhereUniqueInput = {
  AND?: InputMaybe<Array<ComplianceLetterWhereInput>>;
  NOT?: InputMaybe<Array<ComplianceLetterWhereInput>>;
  OR?: InputMaybe<Array<ComplianceLetterWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  lastViewedDate?: InputMaybe<DateTimeNullableFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  sentDate?: InputMaybe<DateTimeFilter>;
  type?: InputMaybe<EnumAttachmentEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContactInformationInput = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  primaryContactMethod?: InputMaybe<UserPrimaryContactEnum>;
  suffix?: InputMaybe<Scalars['String']>;
};

export type ContactInformationOutput = {
  __typename?: 'ContactInformationOutput';
  dateOfBirth?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  primaryContactMethod?: Maybe<UserPrimaryContactEnum>;
  suffix?: Maybe<Scalars['String']>;
};

export type CreateManyVidVoucherRes = {
  __typename?: 'CreateManyVidVoucherRes';
  data: Array<CreateOneVidVoucherRes>;
  errors?: Maybe<Array<InviteErrorResponse>>;
};

export type CreateOneVidVoucherInput = {
  contactInformation: ContactInformationInput;
  deliveryMethod: VIdInviteDeliveryMethodEnum;
  metadata?: InputMaybe<Scalars['JSON']>;
  packageId: Scalars['String'];
  passTypeId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl: Scalars['String'];
  web?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOneVidVoucherRes = {
  __typename?: 'CreateOneVidVoucherRes';
  inviteData: InviteDataOutputRes;
  inviteExpiry: Scalars['String'];
  inviteId: Scalars['String'];
  packageId: Scalars['String'];
  packageName: Scalars['String'];
  vIDInviteUrl: Scalars['String'];
  vIDQRCode: Scalars['String'];
  vIDShortCode: Scalars['String'];
};

export type CreateOneVidWebVoucherRes = {
  __typename?: 'CreateOneVidWebVoucherRes';
  inviteData: InviteDataOutputRes;
  inviteExpiry: Scalars['String'];
  inviteId: Scalars['String'];
  packageId: Scalars['String'];
  packageName: Scalars['String'];
  vIDInviteUrl: Scalars['String'];
  vIDQRCode: Scalars['String'];
  vIDShortCode: Scalars['String'];
  vIDWebUrl?: Maybe<Scalars['String']>;
};

export type CreateSupportTicketResponse = {
  __typename?: 'CreateSupportTicketResponse';
  success: Scalars['Boolean'];
};

export type Credential = {
  __typename?: 'Credential';
  createdAt: Scalars['DateTimeISO'];
  data: Scalars['JSON'];
  id: Scalars['String'];
  input: BeamInput;
  inputId: Scalars['String'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  pass?: Maybe<Pass>;
  passId?: Maybe<Scalars['String']>;
  run?: Maybe<BeamRun>;
  status?: Maybe<CredentialStatus>;
  type: CredentialType;
  typeId: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};


export type CredentialOrganizationArgs = {
  where?: InputMaybe<OrganizationWhereInput>;
};


export type CredentialPassArgs = {
  where?: InputMaybe<PassWhereInput>;
};


export type CredentialRunArgs = {
  where?: InputMaybe<BeamRunWhereInput>;
};


export type CredentialStatusArgs = {
  where?: InputMaybe<CredentialStatusWhereInput>;
};

export type CredentialCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  input: BeamInputCreateNestedOneWithoutCredentialInput;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutCredentialsInput>;
  pass?: InputMaybe<PassCreateNestedOneWithoutCredentialsInput>;
  run?: InputMaybe<BeamRunCreateNestedOneWithoutCredentialInput>;
  status?: InputMaybe<CredentialStatusCreateNestedOneWithoutCredentialInput>;
  type: CredentialTypeCreateNestedOneWithoutCredentialsInput;
  user: UserCreateNestedOneWithoutCredentialsInput;
};

export type CredentialCreateManyOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  inputId: Scalars['String'];
  passId?: InputMaybe<Scalars['String']>;
  typeId: Scalars['String'];
  userId: Scalars['String'];
};

export type CredentialCreateManyOrganizationInputEnvelope = {
  data: Array<CredentialCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CredentialCreateManyPassInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  inputId: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  typeId: Scalars['String'];
  userId: Scalars['String'];
};

export type CredentialCreateManyPassInputEnvelope = {
  data: Array<CredentialCreateManyPassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CredentialCreateManyTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  inputId: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  passId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type CredentialCreateManyTypeInputEnvelope = {
  data: Array<CredentialCreateManyTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CredentialCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  inputId: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  passId?: InputMaybe<Scalars['String']>;
  typeId: Scalars['String'];
};

export type CredentialCreateManyUserInputEnvelope = {
  data: Array<CredentialCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CredentialCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CredentialCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CredentialCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CredentialCreateManyOrganizationInputEnvelope>;
};

export type CredentialCreateNestedManyWithoutPassInput = {
  connect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CredentialCreateOrConnectWithoutPassInput>>;
  create?: InputMaybe<Array<CredentialCreateWithoutPassInput>>;
  createMany?: InputMaybe<CredentialCreateManyPassInputEnvelope>;
};

export type CredentialCreateNestedManyWithoutTypeInput = {
  connect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CredentialCreateOrConnectWithoutTypeInput>>;
  create?: InputMaybe<Array<CredentialCreateWithoutTypeInput>>;
  createMany?: InputMaybe<CredentialCreateManyTypeInputEnvelope>;
};

export type CredentialCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CredentialCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CredentialCreateWithoutUserInput>>;
  createMany?: InputMaybe<CredentialCreateManyUserInputEnvelope>;
};

export type CredentialCreateNestedOneWithoutInputInput = {
  connect?: InputMaybe<CredentialWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CredentialCreateOrConnectWithoutInputInput>;
  create?: InputMaybe<CredentialCreateWithoutInputInput>;
};

export type CredentialCreateNestedOneWithoutRunInput = {
  connect?: InputMaybe<CredentialWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CredentialCreateOrConnectWithoutRunInput>;
  create?: InputMaybe<CredentialCreateWithoutRunInput>;
};

export type CredentialCreateNestedOneWithoutStatusInput = {
  connect?: InputMaybe<CredentialWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CredentialCreateOrConnectWithoutStatusInput>;
  create?: InputMaybe<CredentialCreateWithoutStatusInput>;
};

export type CredentialCreateOrConnectWithoutInputInput = {
  create: CredentialCreateWithoutInputInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialCreateOrConnectWithoutOrganizationInput = {
  create: CredentialCreateWithoutOrganizationInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialCreateOrConnectWithoutPassInput = {
  create: CredentialCreateWithoutPassInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialCreateOrConnectWithoutRunInput = {
  create: CredentialCreateWithoutRunInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialCreateOrConnectWithoutStatusInput = {
  create: CredentialCreateWithoutStatusInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialCreateOrConnectWithoutTypeInput = {
  create: CredentialCreateWithoutTypeInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialCreateOrConnectWithoutUserInput = {
  create: CredentialCreateWithoutUserInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialCreateWithoutInputInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutCredentialsInput>;
  pass?: InputMaybe<PassCreateNestedOneWithoutCredentialsInput>;
  run?: InputMaybe<BeamRunCreateNestedOneWithoutCredentialInput>;
  status?: InputMaybe<CredentialStatusCreateNestedOneWithoutCredentialInput>;
  type: CredentialTypeCreateNestedOneWithoutCredentialsInput;
  user: UserCreateNestedOneWithoutCredentialsInput;
};

export type CredentialCreateWithoutOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  input: BeamInputCreateNestedOneWithoutCredentialInput;
  pass?: InputMaybe<PassCreateNestedOneWithoutCredentialsInput>;
  run?: InputMaybe<BeamRunCreateNestedOneWithoutCredentialInput>;
  status?: InputMaybe<CredentialStatusCreateNestedOneWithoutCredentialInput>;
  type: CredentialTypeCreateNestedOneWithoutCredentialsInput;
  user: UserCreateNestedOneWithoutCredentialsInput;
};

export type CredentialCreateWithoutPassInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  input: BeamInputCreateNestedOneWithoutCredentialInput;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutCredentialsInput>;
  run?: InputMaybe<BeamRunCreateNestedOneWithoutCredentialInput>;
  status?: InputMaybe<CredentialStatusCreateNestedOneWithoutCredentialInput>;
  type: CredentialTypeCreateNestedOneWithoutCredentialsInput;
  user: UserCreateNestedOneWithoutCredentialsInput;
};

export type CredentialCreateWithoutRunInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  input: BeamInputCreateNestedOneWithoutCredentialInput;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutCredentialsInput>;
  pass?: InputMaybe<PassCreateNestedOneWithoutCredentialsInput>;
  status?: InputMaybe<CredentialStatusCreateNestedOneWithoutCredentialInput>;
  type: CredentialTypeCreateNestedOneWithoutCredentialsInput;
  user: UserCreateNestedOneWithoutCredentialsInput;
};

export type CredentialCreateWithoutStatusInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  input: BeamInputCreateNestedOneWithoutCredentialInput;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutCredentialsInput>;
  pass?: InputMaybe<PassCreateNestedOneWithoutCredentialsInput>;
  run?: InputMaybe<BeamRunCreateNestedOneWithoutCredentialInput>;
  type: CredentialTypeCreateNestedOneWithoutCredentialsInput;
  user: UserCreateNestedOneWithoutCredentialsInput;
};

export type CredentialCreateWithoutTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  input: BeamInputCreateNestedOneWithoutCredentialInput;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutCredentialsInput>;
  pass?: InputMaybe<PassCreateNestedOneWithoutCredentialsInput>;
  run?: InputMaybe<BeamRunCreateNestedOneWithoutCredentialInput>;
  status?: InputMaybe<CredentialStatusCreateNestedOneWithoutCredentialInput>;
  user: UserCreateNestedOneWithoutCredentialsInput;
};

export type CredentialCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  input: BeamInputCreateNestedOneWithoutCredentialInput;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutCredentialsInput>;
  pass?: InputMaybe<PassCreateNestedOneWithoutCredentialsInput>;
  run?: InputMaybe<BeamRunCreateNestedOneWithoutCredentialInput>;
  status?: InputMaybe<CredentialStatusCreateNestedOneWithoutCredentialInput>;
  type: CredentialTypeCreateNestedOneWithoutCredentialsInput;
};

export type CredentialListRelationFilter = {
  every?: InputMaybe<CredentialWhereInput>;
  none?: InputMaybe<CredentialWhereInput>;
  some?: InputMaybe<CredentialWhereInput>;
};

export type CredentialNullableRelationFilter = {
  is?: InputMaybe<CredentialWhereInput>;
  isNot?: InputMaybe<CredentialWhereInput>;
};

export type CredentialOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CredentialOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  input?: InputMaybe<BeamInputOrderByWithRelationInput>;
  inputId?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrderInput>;
  pass?: InputMaybe<PassOrderByWithRelationInput>;
  passId?: InputMaybe<SortOrderInput>;
  run?: InputMaybe<BeamRunOrderByWithRelationInput>;
  status?: InputMaybe<CredentialStatusOrderByWithRelationInput>;
  type?: InputMaybe<CredentialTypeOrderByWithRelationInput>;
  typeId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type CredentialRelationFilter = {
  is?: InputMaybe<CredentialWhereInput>;
  isNot?: InputMaybe<CredentialWhereInput>;
};

export enum CredentialScalarFieldEnum {
  CreatedAt = 'createdAt',
  Data = 'data',
  Id = 'id',
  InputId = 'inputId',
  OrganizationId = 'organizationId',
  PassId = 'passId',
  TypeId = 'typeId',
  UserId = 'userId'
}

export type CredentialScalarWhereInput = {
  AND?: InputMaybe<Array<CredentialScalarWhereInput>>;
  NOT?: InputMaybe<Array<CredentialScalarWhereInput>>;
  OR?: InputMaybe<Array<CredentialScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  inputId?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  passId?: InputMaybe<StringNullableFilter>;
  typeId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CredentialStatus = {
  __typename?: 'CredentialStatus';
  createdAt: Scalars['DateTimeISO'];
  credential: Credential;
  credentialId: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  value: CredentialStatusEnum;
};

export type CredentialStatusCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credential: CredentialCreateNestedOneWithoutStatusInput;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  value: CredentialStatusEnum;
};

export type CredentialStatusCreateNestedOneWithoutCredentialInput = {
  connect?: InputMaybe<CredentialStatusWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CredentialStatusCreateOrConnectWithoutCredentialInput>;
  create?: InputMaybe<CredentialStatusCreateWithoutCredentialInput>;
};

export type CredentialStatusCreateOrConnectWithoutCredentialInput = {
  create: CredentialStatusCreateWithoutCredentialInput;
  where: CredentialStatusWhereUniqueInput;
};

export type CredentialStatusCreateWithoutCredentialInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  value: CredentialStatusEnum;
};

export enum CredentialStatusEnum {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Revoked = 'REVOKED'
}

export type CredentialStatusNullableRelationFilter = {
  is?: InputMaybe<CredentialStatusWhereInput>;
  isNot?: InputMaybe<CredentialStatusWhereInput>;
};

export type CredentialStatusOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  credential?: InputMaybe<CredentialOrderByWithRelationInput>;
  credentialId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type CredentialStatusUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credential?: InputMaybe<CredentialUpdateOneRequiredWithoutStatusNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<EnumCredentialStatusEnumFieldUpdateOperationsInput>;
};

export type CredentialStatusUpdateOneWithoutCredentialNestedInput = {
  connect?: InputMaybe<CredentialStatusWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CredentialStatusCreateOrConnectWithoutCredentialInput>;
  create?: InputMaybe<CredentialStatusCreateWithoutCredentialInput>;
  delete?: InputMaybe<CredentialStatusWhereInput>;
  disconnect?: InputMaybe<CredentialStatusWhereInput>;
  update?: InputMaybe<CredentialStatusUpdateToOneWithWhereWithoutCredentialInput>;
  upsert?: InputMaybe<CredentialStatusUpsertWithoutCredentialInput>;
};

export type CredentialStatusUpdateToOneWithWhereWithoutCredentialInput = {
  data: CredentialStatusUpdateWithoutCredentialInput;
  where?: InputMaybe<CredentialStatusWhereInput>;
};

export type CredentialStatusUpdateWithoutCredentialInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<EnumCredentialStatusEnumFieldUpdateOperationsInput>;
};

export type CredentialStatusUpsertWithoutCredentialInput = {
  create: CredentialStatusCreateWithoutCredentialInput;
  update: CredentialStatusUpdateWithoutCredentialInput;
  where?: InputMaybe<CredentialStatusWhereInput>;
};

export type CredentialStatusWhereInput = {
  AND?: InputMaybe<Array<CredentialStatusWhereInput>>;
  NOT?: InputMaybe<Array<CredentialStatusWhereInput>>;
  OR?: InputMaybe<Array<CredentialStatusWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credential?: InputMaybe<CredentialRelationFilter>;
  credentialId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<EnumCredentialStatusEnumFilter>;
};

export type CredentialStatusWhereUniqueInput = {
  AND?: InputMaybe<Array<CredentialStatusWhereInput>>;
  NOT?: InputMaybe<Array<CredentialStatusWhereInput>>;
  OR?: InputMaybe<Array<CredentialStatusWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credential?: InputMaybe<CredentialRelationFilter>;
  credentialId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<EnumCredentialStatusEnumFilter>;
};

export type CredentialType = {
  __typename?: 'CredentialType';
  _count?: Maybe<CredentialTypeCount>;
  beam: Beam;
  beamId: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  credentials: Array<Credential>;
  id: Scalars['String'];
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  version: Scalars['String'];
};


export type CredentialTypeCredentialsArgs = {
  cursor?: InputMaybe<CredentialWhereUniqueInput>;
  distinct?: InputMaybe<Array<CredentialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CredentialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CredentialWhereInput>;
};

export type CredentialTypeCount = {
  __typename?: 'CredentialTypeCount';
  credentials: Scalars['Int'];
};


export type CredentialTypeCountCredentialsArgs = {
  where?: InputMaybe<CredentialWhereInput>;
};

export type CredentialTypeCreateInput = {
  beam: BeamCreateNestedOneWithoutCredentialTypesInput;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutTypeInput>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  version: Scalars['String'];
};

export type CredentialTypeCreateManyBeamInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  version: Scalars['String'];
};

export type CredentialTypeCreateManyBeamInputEnvelope = {
  data: Array<CredentialTypeCreateManyBeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CredentialTypeCreateNestedManyWithoutBeamInput = {
  connect?: InputMaybe<Array<CredentialTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CredentialTypeCreateOrConnectWithoutBeamInput>>;
  create?: InputMaybe<Array<CredentialTypeCreateWithoutBeamInput>>;
  createMany?: InputMaybe<CredentialTypeCreateManyBeamInputEnvelope>;
};

export type CredentialTypeCreateNestedOneWithoutCredentialsInput = {
  connect?: InputMaybe<CredentialTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CredentialTypeCreateOrConnectWithoutCredentialsInput>;
  create?: InputMaybe<CredentialTypeCreateWithoutCredentialsInput>;
};

export type CredentialTypeCreateOrConnectWithoutBeamInput = {
  create: CredentialTypeCreateWithoutBeamInput;
  where: CredentialTypeWhereUniqueInput;
};

export type CredentialTypeCreateOrConnectWithoutCredentialsInput = {
  create: CredentialTypeCreateWithoutCredentialsInput;
  where: CredentialTypeWhereUniqueInput;
};

export type CredentialTypeCreateWithoutBeamInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutTypeInput>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  version: Scalars['String'];
};

export type CredentialTypeCreateWithoutCredentialsInput = {
  beam: BeamCreateNestedOneWithoutCredentialTypesInput;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  schema: Scalars['JSON'];
  slug: Scalars['String'];
  version: Scalars['String'];
};

export type CredentialTypeListRelationFilter = {
  every?: InputMaybe<CredentialTypeWhereInput>;
  none?: InputMaybe<CredentialTypeWhereInput>;
  some?: InputMaybe<CredentialTypeWhereInput>;
};

export type CredentialTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CredentialTypeOrderByWithRelationInput = {
  beam?: InputMaybe<BeamOrderByWithRelationInput>;
  beamId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  credentials?: InputMaybe<CredentialOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrderInput>;
  schema?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export type CredentialTypeRelationFilter = {
  is?: InputMaybe<CredentialTypeWhereInput>;
  isNot?: InputMaybe<CredentialTypeWhereInput>;
};

export enum CredentialTypeScalarFieldEnum {
  BeamId = 'beamId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organizationId',
  Schema = 'schema',
  Slug = 'slug',
  Version = 'version'
}

export type CredentialTypeScalarWhereInput = {
  AND?: InputMaybe<Array<CredentialTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<CredentialTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<CredentialTypeScalarWhereInput>>;
  beamId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  schema?: InputMaybe<JsonFilter>;
  slug?: InputMaybe<StringFilter>;
  version?: InputMaybe<StringFilter>;
};

export type CredentialTypeSlugVersionCompoundUniqueInput = {
  slug: Scalars['String'];
  version: Scalars['String'];
};

export type CredentialTypeUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  schema?: InputMaybe<Scalars['JSON']>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CredentialTypeUpdateManyWithWhereWithoutBeamInput = {
  data: CredentialTypeUpdateManyMutationInput;
  where: CredentialTypeScalarWhereInput;
};

export type CredentialTypeUpdateManyWithoutBeamNestedInput = {
  connect?: InputMaybe<Array<CredentialTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CredentialTypeCreateOrConnectWithoutBeamInput>>;
  create?: InputMaybe<Array<CredentialTypeCreateWithoutBeamInput>>;
  createMany?: InputMaybe<CredentialTypeCreateManyBeamInputEnvelope>;
  delete?: InputMaybe<Array<CredentialTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CredentialTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CredentialTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<CredentialTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<CredentialTypeUpdateWithWhereUniqueWithoutBeamInput>>;
  updateMany?: InputMaybe<Array<CredentialTypeUpdateManyWithWhereWithoutBeamInput>>;
  upsert?: InputMaybe<Array<CredentialTypeUpsertWithWhereUniqueWithoutBeamInput>>;
};

export type CredentialTypeUpdateOneRequiredWithoutCredentialsNestedInput = {
  connect?: InputMaybe<CredentialTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CredentialTypeCreateOrConnectWithoutCredentialsInput>;
  create?: InputMaybe<CredentialTypeCreateWithoutCredentialsInput>;
  update?: InputMaybe<CredentialTypeUpdateToOneWithWhereWithoutCredentialsInput>;
  upsert?: InputMaybe<CredentialTypeUpsertWithoutCredentialsInput>;
};

export type CredentialTypeUpdateToOneWithWhereWithoutCredentialsInput = {
  data: CredentialTypeUpdateWithoutCredentialsInput;
  where?: InputMaybe<CredentialTypeWhereInput>;
};

export type CredentialTypeUpdateWithWhereUniqueWithoutBeamInput = {
  data: CredentialTypeUpdateWithoutBeamInput;
  where: CredentialTypeWhereUniqueInput;
};

export type CredentialTypeUpdateWithoutBeamInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutTypeNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  schema?: InputMaybe<Scalars['JSON']>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CredentialTypeUpdateWithoutCredentialsInput = {
  beam?: InputMaybe<BeamUpdateOneRequiredWithoutCredentialTypesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  schema?: InputMaybe<Scalars['JSON']>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  version?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CredentialTypeUpsertWithWhereUniqueWithoutBeamInput = {
  create: CredentialTypeCreateWithoutBeamInput;
  update: CredentialTypeUpdateWithoutBeamInput;
  where: CredentialTypeWhereUniqueInput;
};

export type CredentialTypeUpsertWithoutCredentialsInput = {
  create: CredentialTypeCreateWithoutCredentialsInput;
  update: CredentialTypeUpdateWithoutCredentialsInput;
  where?: InputMaybe<CredentialTypeWhereInput>;
};

export type CredentialTypeWhereInput = {
  AND?: InputMaybe<Array<CredentialTypeWhereInput>>;
  NOT?: InputMaybe<Array<CredentialTypeWhereInput>>;
  OR?: InputMaybe<Array<CredentialTypeWhereInput>>;
  beam?: InputMaybe<BeamRelationFilter>;
  beamId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credentials?: InputMaybe<CredentialListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  schema?: InputMaybe<JsonFilter>;
  slug?: InputMaybe<StringFilter>;
  version?: InputMaybe<StringFilter>;
};

export type CredentialTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<CredentialTypeWhereInput>>;
  NOT?: InputMaybe<Array<CredentialTypeWhereInput>>;
  OR?: InputMaybe<Array<CredentialTypeWhereInput>>;
  beam?: InputMaybe<BeamRelationFilter>;
  beamId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credentials?: InputMaybe<CredentialListRelationFilter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  schema?: InputMaybe<JsonFilter>;
  slug?: InputMaybe<StringFilter>;
  slug_version?: InputMaybe<CredentialTypeSlugVersionCompoundUniqueInput>;
  version?: InputMaybe<StringFilter>;
};

export type CredentialUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  input?: InputMaybe<BeamInputUpdateOneRequiredWithoutCredentialNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutCredentialsNestedInput>;
  pass?: InputMaybe<PassUpdateOneWithoutCredentialsNestedInput>;
  run?: InputMaybe<BeamRunUpdateOneWithoutCredentialNestedInput>;
  status?: InputMaybe<CredentialStatusUpdateOneWithoutCredentialNestedInput>;
  type?: InputMaybe<CredentialTypeUpdateOneRequiredWithoutCredentialsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCredentialsNestedInput>;
};

export type CredentialUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CredentialUpdateManyWithWhereWithoutOrganizationInput = {
  data: CredentialUpdateManyMutationInput;
  where: CredentialScalarWhereInput;
};

export type CredentialUpdateManyWithWhereWithoutPassInput = {
  data: CredentialUpdateManyMutationInput;
  where: CredentialScalarWhereInput;
};

export type CredentialUpdateManyWithWhereWithoutTypeInput = {
  data: CredentialUpdateManyMutationInput;
  where: CredentialScalarWhereInput;
};

export type CredentialUpdateManyWithWhereWithoutUserInput = {
  data: CredentialUpdateManyMutationInput;
  where: CredentialScalarWhereInput;
};

export type CredentialUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CredentialCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CredentialCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CredentialCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CredentialScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  set?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  update?: InputMaybe<Array<CredentialUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CredentialUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CredentialUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CredentialUpdateManyWithoutPassNestedInput = {
  connect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CredentialCreateOrConnectWithoutPassInput>>;
  create?: InputMaybe<Array<CredentialCreateWithoutPassInput>>;
  createMany?: InputMaybe<CredentialCreateManyPassInputEnvelope>;
  delete?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CredentialScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  set?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  update?: InputMaybe<Array<CredentialUpdateWithWhereUniqueWithoutPassInput>>;
  updateMany?: InputMaybe<Array<CredentialUpdateManyWithWhereWithoutPassInput>>;
  upsert?: InputMaybe<Array<CredentialUpsertWithWhereUniqueWithoutPassInput>>;
};

export type CredentialUpdateManyWithoutTypeNestedInput = {
  connect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CredentialCreateOrConnectWithoutTypeInput>>;
  create?: InputMaybe<Array<CredentialCreateWithoutTypeInput>>;
  createMany?: InputMaybe<CredentialCreateManyTypeInputEnvelope>;
  delete?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CredentialScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  set?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  update?: InputMaybe<Array<CredentialUpdateWithWhereUniqueWithoutTypeInput>>;
  updateMany?: InputMaybe<Array<CredentialUpdateManyWithWhereWithoutTypeInput>>;
  upsert?: InputMaybe<Array<CredentialUpsertWithWhereUniqueWithoutTypeInput>>;
};

export type CredentialUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CredentialCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CredentialCreateWithoutUserInput>>;
  createMany?: InputMaybe<CredentialCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CredentialScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  set?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  update?: InputMaybe<Array<CredentialUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CredentialUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CredentialUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CredentialUpdateOneRequiredWithoutStatusNestedInput = {
  connect?: InputMaybe<CredentialWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CredentialCreateOrConnectWithoutStatusInput>;
  create?: InputMaybe<CredentialCreateWithoutStatusInput>;
  update?: InputMaybe<CredentialUpdateToOneWithWhereWithoutStatusInput>;
  upsert?: InputMaybe<CredentialUpsertWithoutStatusInput>;
};

export type CredentialUpdateOneWithoutInputNestedInput = {
  connect?: InputMaybe<CredentialWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CredentialCreateOrConnectWithoutInputInput>;
  create?: InputMaybe<CredentialCreateWithoutInputInput>;
  delete?: InputMaybe<CredentialWhereInput>;
  disconnect?: InputMaybe<CredentialWhereInput>;
  update?: InputMaybe<CredentialUpdateToOneWithWhereWithoutInputInput>;
  upsert?: InputMaybe<CredentialUpsertWithoutInputInput>;
};

export type CredentialUpdateOneWithoutRunNestedInput = {
  connect?: InputMaybe<CredentialWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CredentialCreateOrConnectWithoutRunInput>;
  create?: InputMaybe<CredentialCreateWithoutRunInput>;
  delete?: InputMaybe<CredentialWhereInput>;
  disconnect?: InputMaybe<CredentialWhereInput>;
  update?: InputMaybe<CredentialUpdateToOneWithWhereWithoutRunInput>;
  upsert?: InputMaybe<CredentialUpsertWithoutRunInput>;
};

export type CredentialUpdateToOneWithWhereWithoutInputInput = {
  data: CredentialUpdateWithoutInputInput;
  where?: InputMaybe<CredentialWhereInput>;
};

export type CredentialUpdateToOneWithWhereWithoutRunInput = {
  data: CredentialUpdateWithoutRunInput;
  where?: InputMaybe<CredentialWhereInput>;
};

export type CredentialUpdateToOneWithWhereWithoutStatusInput = {
  data: CredentialUpdateWithoutStatusInput;
  where?: InputMaybe<CredentialWhereInput>;
};

export type CredentialUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CredentialUpdateWithoutOrganizationInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialUpdateWithWhereUniqueWithoutPassInput = {
  data: CredentialUpdateWithoutPassInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialUpdateWithWhereUniqueWithoutTypeInput = {
  data: CredentialUpdateWithoutTypeInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialUpdateWithWhereUniqueWithoutUserInput = {
  data: CredentialUpdateWithoutUserInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialUpdateWithoutInputInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutCredentialsNestedInput>;
  pass?: InputMaybe<PassUpdateOneWithoutCredentialsNestedInput>;
  run?: InputMaybe<BeamRunUpdateOneWithoutCredentialNestedInput>;
  status?: InputMaybe<CredentialStatusUpdateOneWithoutCredentialNestedInput>;
  type?: InputMaybe<CredentialTypeUpdateOneRequiredWithoutCredentialsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCredentialsNestedInput>;
};

export type CredentialUpdateWithoutOrganizationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  input?: InputMaybe<BeamInputUpdateOneRequiredWithoutCredentialNestedInput>;
  pass?: InputMaybe<PassUpdateOneWithoutCredentialsNestedInput>;
  run?: InputMaybe<BeamRunUpdateOneWithoutCredentialNestedInput>;
  status?: InputMaybe<CredentialStatusUpdateOneWithoutCredentialNestedInput>;
  type?: InputMaybe<CredentialTypeUpdateOneRequiredWithoutCredentialsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCredentialsNestedInput>;
};

export type CredentialUpdateWithoutPassInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  input?: InputMaybe<BeamInputUpdateOneRequiredWithoutCredentialNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutCredentialsNestedInput>;
  run?: InputMaybe<BeamRunUpdateOneWithoutCredentialNestedInput>;
  status?: InputMaybe<CredentialStatusUpdateOneWithoutCredentialNestedInput>;
  type?: InputMaybe<CredentialTypeUpdateOneRequiredWithoutCredentialsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCredentialsNestedInput>;
};

export type CredentialUpdateWithoutRunInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  input?: InputMaybe<BeamInputUpdateOneRequiredWithoutCredentialNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutCredentialsNestedInput>;
  pass?: InputMaybe<PassUpdateOneWithoutCredentialsNestedInput>;
  status?: InputMaybe<CredentialStatusUpdateOneWithoutCredentialNestedInput>;
  type?: InputMaybe<CredentialTypeUpdateOneRequiredWithoutCredentialsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCredentialsNestedInput>;
};

export type CredentialUpdateWithoutStatusInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  input?: InputMaybe<BeamInputUpdateOneRequiredWithoutCredentialNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutCredentialsNestedInput>;
  pass?: InputMaybe<PassUpdateOneWithoutCredentialsNestedInput>;
  run?: InputMaybe<BeamRunUpdateOneWithoutCredentialNestedInput>;
  type?: InputMaybe<CredentialTypeUpdateOneRequiredWithoutCredentialsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCredentialsNestedInput>;
};

export type CredentialUpdateWithoutTypeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  input?: InputMaybe<BeamInputUpdateOneRequiredWithoutCredentialNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutCredentialsNestedInput>;
  pass?: InputMaybe<PassUpdateOneWithoutCredentialsNestedInput>;
  run?: InputMaybe<BeamRunUpdateOneWithoutCredentialNestedInput>;
  status?: InputMaybe<CredentialStatusUpdateOneWithoutCredentialNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCredentialsNestedInput>;
};

export type CredentialUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  input?: InputMaybe<BeamInputUpdateOneRequiredWithoutCredentialNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutCredentialsNestedInput>;
  pass?: InputMaybe<PassUpdateOneWithoutCredentialsNestedInput>;
  run?: InputMaybe<BeamRunUpdateOneWithoutCredentialNestedInput>;
  status?: InputMaybe<CredentialStatusUpdateOneWithoutCredentialNestedInput>;
  type?: InputMaybe<CredentialTypeUpdateOneRequiredWithoutCredentialsNestedInput>;
};

export type CredentialUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CredentialCreateWithoutOrganizationInput;
  update: CredentialUpdateWithoutOrganizationInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialUpsertWithWhereUniqueWithoutPassInput = {
  create: CredentialCreateWithoutPassInput;
  update: CredentialUpdateWithoutPassInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialUpsertWithWhereUniqueWithoutTypeInput = {
  create: CredentialCreateWithoutTypeInput;
  update: CredentialUpdateWithoutTypeInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialUpsertWithWhereUniqueWithoutUserInput = {
  create: CredentialCreateWithoutUserInput;
  update: CredentialUpdateWithoutUserInput;
  where: CredentialWhereUniqueInput;
};

export type CredentialUpsertWithoutInputInput = {
  create: CredentialCreateWithoutInputInput;
  update: CredentialUpdateWithoutInputInput;
  where?: InputMaybe<CredentialWhereInput>;
};

export type CredentialUpsertWithoutRunInput = {
  create: CredentialCreateWithoutRunInput;
  update: CredentialUpdateWithoutRunInput;
  where?: InputMaybe<CredentialWhereInput>;
};

export type CredentialUpsertWithoutStatusInput = {
  create: CredentialCreateWithoutStatusInput;
  update: CredentialUpdateWithoutStatusInput;
  where?: InputMaybe<CredentialWhereInput>;
};

export type CredentialWhereInput = {
  AND?: InputMaybe<Array<CredentialWhereInput>>;
  NOT?: InputMaybe<Array<CredentialWhereInput>>;
  OR?: InputMaybe<Array<CredentialWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  input?: InputMaybe<BeamInputRelationFilter>;
  inputId?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  pass?: InputMaybe<PassNullableRelationFilter>;
  passId?: InputMaybe<StringNullableFilter>;
  run?: InputMaybe<BeamRunNullableRelationFilter>;
  status?: InputMaybe<CredentialStatusNullableRelationFilter>;
  type?: InputMaybe<CredentialTypeRelationFilter>;
  typeId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CredentialWhereUniqueInput = {
  AND?: InputMaybe<Array<CredentialWhereInput>>;
  NOT?: InputMaybe<Array<CredentialWhereInput>>;
  OR?: InputMaybe<Array<CredentialWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonFilter>;
  id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<BeamInputRelationFilter>;
  inputId?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  pass?: InputMaybe<PassNullableRelationFilter>;
  passId?: InputMaybe<StringNullableFilter>;
  run?: InputMaybe<BeamRunNullableRelationFilter>;
  status?: InputMaybe<CredentialStatusNullableRelationFilter>;
  type?: InputMaybe<CredentialTypeRelationFilter>;
  typeId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum CurrencyMultiplierEnum {
  FourDecimals = 'FOUR_DECIMALS',
  SixDecimals = 'SIX_DECIMALS',
  TwoDecimals = 'TWO_DECIMALS',
  ZeroDecimals = 'ZERO_DECIMALS'
}

export enum CurrencyTypeEnum {
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']>;
  gt?: InputMaybe<Scalars['DateTimeISO']>;
  gte?: InputMaybe<Scalars['DateTimeISO']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']>;
  lte?: InputMaybe<Scalars['DateTimeISO']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']>;
  gt?: InputMaybe<Scalars['DateTimeISO']>;
  gte?: InputMaybe<Scalars['DateTimeISO']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']>;
  lte?: InputMaybe<Scalars['DateTimeISO']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']>>;
};

export type Disclosure = {
  __typename?: 'Disclosure';
  _count?: Maybe<DisclosureCount>;
  createdAt: Scalars['DateTimeISO'];
  disclosureAcceptances: Array<DisclosureAcceptance>;
  id: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  locationType?: Maybe<LocationTypeEnum>;
  name: Scalars['String'];
  organizations: Array<Organization>;
  packageSets: Array<PackageSet>;
  text: Scalars['String'];
  type: DisclosureTypeEnum;
  updatedAt: Scalars['DateTimeISO'];
};


export type DisclosureDisclosureAcceptancesArgs = {
  cursor?: InputMaybe<DisclosureAcceptanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<DisclosureAcceptanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DisclosureAcceptanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DisclosureAcceptanceWhereInput>;
};


export type DisclosureOrganizationsArgs = {
  cursor?: InputMaybe<OrganizationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrganizationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type DisclosurePackageSetsArgs = {
  cursor?: InputMaybe<PackageSetWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackageSetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackageSetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackageSetWhereInput>;
};

export type DisclosureAcceptance = {
  __typename?: 'DisclosureAcceptance';
  _count?: Maybe<DisclosureAcceptanceCount>;
  createdAt: Scalars['DateTimeISO'];
  disclosures: Array<Disclosure>;
  id: Scalars['String'];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  pdf?: Maybe<File>;
  signatureSvg: Scalars['Byte'];
  updatedAt: Scalars['DateTimeISO'];
  user: User;
  userId: Scalars['String'];
};


export type DisclosureAcceptanceDisclosuresArgs = {
  cursor?: InputMaybe<DisclosureWhereUniqueInput>;
  distinct?: InputMaybe<Array<DisclosureScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DisclosureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DisclosureWhereInput>;
};


export type DisclosureAcceptanceOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type DisclosureAcceptancePdfArgs = {
  where?: InputMaybe<FileWhereInput>;
};

export type DisclosureAcceptanceArgs = {
  disclosureIds: Array<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  signatureSvg: Scalars['String'];
};

export type DisclosureAcceptanceCount = {
  __typename?: 'DisclosureAcceptanceCount';
  disclosures: Scalars['Int'];
};


export type DisclosureAcceptanceCountDisclosuresArgs = {
  where?: InputMaybe<DisclosureWhereInput>;
};

export type DisclosureAcceptanceCreateManyOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  signatureSvg: Scalars['Byte'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId: Scalars['String'];
};

export type DisclosureAcceptanceCreateManyOrderInputEnvelope = {
  data: Array<DisclosureAcceptanceCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DisclosureAcceptanceCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  signatureSvg: Scalars['Byte'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DisclosureAcceptanceCreateManyUserInputEnvelope = {
  data: Array<DisclosureAcceptanceCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DisclosureAcceptanceCreateNestedManyWithoutDisclosuresInput = {
  connect?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureAcceptanceCreateOrConnectWithoutDisclosuresInput>>;
  create?: InputMaybe<Array<DisclosureAcceptanceCreateWithoutDisclosuresInput>>;
};

export type DisclosureAcceptanceCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureAcceptanceCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<DisclosureAcceptanceCreateWithoutOrderInput>>;
  createMany?: InputMaybe<DisclosureAcceptanceCreateManyOrderInputEnvelope>;
};

export type DisclosureAcceptanceCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureAcceptanceCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<DisclosureAcceptanceCreateWithoutUserInput>>;
  createMany?: InputMaybe<DisclosureAcceptanceCreateManyUserInputEnvelope>;
};

export type DisclosureAcceptanceCreateNestedOneWithoutPdfInput = {
  connect?: InputMaybe<DisclosureAcceptanceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DisclosureAcceptanceCreateOrConnectWithoutPdfInput>;
  create?: InputMaybe<DisclosureAcceptanceCreateWithoutPdfInput>;
};

export type DisclosureAcceptanceCreateOrConnectWithoutDisclosuresInput = {
  create: DisclosureAcceptanceCreateWithoutDisclosuresInput;
  where: DisclosureAcceptanceWhereUniqueInput;
};

export type DisclosureAcceptanceCreateOrConnectWithoutOrderInput = {
  create: DisclosureAcceptanceCreateWithoutOrderInput;
  where: DisclosureAcceptanceWhereUniqueInput;
};

export type DisclosureAcceptanceCreateOrConnectWithoutPdfInput = {
  create: DisclosureAcceptanceCreateWithoutPdfInput;
  where: DisclosureAcceptanceWhereUniqueInput;
};

export type DisclosureAcceptanceCreateOrConnectWithoutUserInput = {
  create: DisclosureAcceptanceCreateWithoutUserInput;
  where: DisclosureAcceptanceWhereUniqueInput;
};

export type DisclosureAcceptanceCreateWithoutDisclosuresInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutDisclosureAcceptancesInput>;
  pdf?: InputMaybe<FileCreateNestedOneWithoutDisclosureAcceptanceInput>;
  signatureSvg: Scalars['Byte'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user: UserCreateNestedOneWithoutDisclosureAcceptancesInput;
};

export type DisclosureAcceptanceCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutDisclosureAcceptancesInput>;
  id?: InputMaybe<Scalars['String']>;
  pdf?: InputMaybe<FileCreateNestedOneWithoutDisclosureAcceptanceInput>;
  signatureSvg: Scalars['Byte'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user: UserCreateNestedOneWithoutDisclosureAcceptancesInput;
};

export type DisclosureAcceptanceCreateWithoutPdfInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutDisclosureAcceptancesInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutDisclosureAcceptancesInput>;
  signatureSvg: Scalars['Byte'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user: UserCreateNestedOneWithoutDisclosureAcceptancesInput;
};

export type DisclosureAcceptanceCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutDisclosureAcceptancesInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutDisclosureAcceptancesInput>;
  pdf?: InputMaybe<FileCreateNestedOneWithoutDisclosureAcceptanceInput>;
  signatureSvg: Scalars['Byte'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DisclosureAcceptanceListRelationFilter = {
  every?: InputMaybe<DisclosureAcceptanceWhereInput>;
  none?: InputMaybe<DisclosureAcceptanceWhereInput>;
  some?: InputMaybe<DisclosureAcceptanceWhereInput>;
};

export type DisclosureAcceptanceNullableRelationFilter = {
  is?: InputMaybe<DisclosureAcceptanceWhereInput>;
  isNot?: InputMaybe<DisclosureAcceptanceWhereInput>;
};

export type DisclosureAcceptanceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DisclosureAcceptanceOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  disclosures?: InputMaybe<DisclosureOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrderInput>;
  pdf?: InputMaybe<FileOrderByWithRelationInput>;
  signatureSvg?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum DisclosureAcceptanceScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  OrderId = 'orderId',
  SignatureSvg = 'signatureSvg',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type DisclosureAcceptanceScalarWhereInput = {
  AND?: InputMaybe<Array<DisclosureAcceptanceScalarWhereInput>>;
  NOT?: InputMaybe<Array<DisclosureAcceptanceScalarWhereInput>>;
  OR?: InputMaybe<Array<DisclosureAcceptanceScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  signatureSvg?: InputMaybe<BytesFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type DisclosureAcceptanceUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  signatureSvg?: InputMaybe<BytesFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DisclosureAcceptanceUpdateManyWithWhereWithoutDisclosuresInput = {
  data: DisclosureAcceptanceUpdateManyMutationInput;
  where: DisclosureAcceptanceScalarWhereInput;
};

export type DisclosureAcceptanceUpdateManyWithWhereWithoutOrderInput = {
  data: DisclosureAcceptanceUpdateManyMutationInput;
  where: DisclosureAcceptanceScalarWhereInput;
};

export type DisclosureAcceptanceUpdateManyWithWhereWithoutUserInput = {
  data: DisclosureAcceptanceUpdateManyMutationInput;
  where: DisclosureAcceptanceScalarWhereInput;
};

export type DisclosureAcceptanceUpdateManyWithoutDisclosuresNestedInput = {
  connect?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureAcceptanceCreateOrConnectWithoutDisclosuresInput>>;
  create?: InputMaybe<Array<DisclosureAcceptanceCreateWithoutDisclosuresInput>>;
  delete?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DisclosureAcceptanceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  set?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  update?: InputMaybe<Array<DisclosureAcceptanceUpdateWithWhereUniqueWithoutDisclosuresInput>>;
  updateMany?: InputMaybe<Array<DisclosureAcceptanceUpdateManyWithWhereWithoutDisclosuresInput>>;
  upsert?: InputMaybe<Array<DisclosureAcceptanceUpsertWithWhereUniqueWithoutDisclosuresInput>>;
};

export type DisclosureAcceptanceUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureAcceptanceCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<DisclosureAcceptanceCreateWithoutOrderInput>>;
  createMany?: InputMaybe<DisclosureAcceptanceCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DisclosureAcceptanceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  set?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  update?: InputMaybe<Array<DisclosureAcceptanceUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<DisclosureAcceptanceUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<DisclosureAcceptanceUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type DisclosureAcceptanceUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureAcceptanceCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<DisclosureAcceptanceCreateWithoutUserInput>>;
  createMany?: InputMaybe<DisclosureAcceptanceCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DisclosureAcceptanceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  set?: InputMaybe<Array<DisclosureAcceptanceWhereUniqueInput>>;
  update?: InputMaybe<Array<DisclosureAcceptanceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<DisclosureAcceptanceUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<DisclosureAcceptanceUpsertWithWhereUniqueWithoutUserInput>>;
};

export type DisclosureAcceptanceUpdateOneWithoutPdfNestedInput = {
  connect?: InputMaybe<DisclosureAcceptanceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DisclosureAcceptanceCreateOrConnectWithoutPdfInput>;
  create?: InputMaybe<DisclosureAcceptanceCreateWithoutPdfInput>;
  delete?: InputMaybe<DisclosureAcceptanceWhereInput>;
  disconnect?: InputMaybe<DisclosureAcceptanceWhereInput>;
  update?: InputMaybe<DisclosureAcceptanceUpdateToOneWithWhereWithoutPdfInput>;
  upsert?: InputMaybe<DisclosureAcceptanceUpsertWithoutPdfInput>;
};

export type DisclosureAcceptanceUpdateToOneWithWhereWithoutPdfInput = {
  data: DisclosureAcceptanceUpdateWithoutPdfInput;
  where?: InputMaybe<DisclosureAcceptanceWhereInput>;
};

export type DisclosureAcceptanceUpdateWithWhereUniqueWithoutDisclosuresInput = {
  data: DisclosureAcceptanceUpdateWithoutDisclosuresInput;
  where: DisclosureAcceptanceWhereUniqueInput;
};

export type DisclosureAcceptanceUpdateWithWhereUniqueWithoutOrderInput = {
  data: DisclosureAcceptanceUpdateWithoutOrderInput;
  where: DisclosureAcceptanceWhereUniqueInput;
};

export type DisclosureAcceptanceUpdateWithWhereUniqueWithoutUserInput = {
  data: DisclosureAcceptanceUpdateWithoutUserInput;
  where: DisclosureAcceptanceWhereUniqueInput;
};

export type DisclosureAcceptanceUpdateWithoutDisclosuresInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutDisclosureAcceptancesNestedInput>;
  pdf?: InputMaybe<FileUpdateOneWithoutDisclosureAcceptanceNestedInput>;
  signatureSvg?: InputMaybe<BytesFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDisclosureAcceptancesNestedInput>;
};

export type DisclosureAcceptanceUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutDisclosureAcceptancesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  pdf?: InputMaybe<FileUpdateOneWithoutDisclosureAcceptanceNestedInput>;
  signatureSvg?: InputMaybe<BytesFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDisclosureAcceptancesNestedInput>;
};

export type DisclosureAcceptanceUpdateWithoutPdfInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutDisclosureAcceptancesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutDisclosureAcceptancesNestedInput>;
  signatureSvg?: InputMaybe<BytesFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDisclosureAcceptancesNestedInput>;
};

export type DisclosureAcceptanceUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutDisclosureAcceptancesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutDisclosureAcceptancesNestedInput>;
  pdf?: InputMaybe<FileUpdateOneWithoutDisclosureAcceptanceNestedInput>;
  signatureSvg?: InputMaybe<BytesFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DisclosureAcceptanceUpsertWithWhereUniqueWithoutDisclosuresInput = {
  create: DisclosureAcceptanceCreateWithoutDisclosuresInput;
  update: DisclosureAcceptanceUpdateWithoutDisclosuresInput;
  where: DisclosureAcceptanceWhereUniqueInput;
};

export type DisclosureAcceptanceUpsertWithWhereUniqueWithoutOrderInput = {
  create: DisclosureAcceptanceCreateWithoutOrderInput;
  update: DisclosureAcceptanceUpdateWithoutOrderInput;
  where: DisclosureAcceptanceWhereUniqueInput;
};

export type DisclosureAcceptanceUpsertWithWhereUniqueWithoutUserInput = {
  create: DisclosureAcceptanceCreateWithoutUserInput;
  update: DisclosureAcceptanceUpdateWithoutUserInput;
  where: DisclosureAcceptanceWhereUniqueInput;
};

export type DisclosureAcceptanceUpsertWithoutPdfInput = {
  create: DisclosureAcceptanceCreateWithoutPdfInput;
  update: DisclosureAcceptanceUpdateWithoutPdfInput;
  where?: InputMaybe<DisclosureAcceptanceWhereInput>;
};

export type DisclosureAcceptanceWhereInput = {
  AND?: InputMaybe<Array<DisclosureAcceptanceWhereInput>>;
  NOT?: InputMaybe<Array<DisclosureAcceptanceWhereInput>>;
  OR?: InputMaybe<Array<DisclosureAcceptanceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  disclosures?: InputMaybe<DisclosureListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  pdf?: InputMaybe<FileNullableRelationFilter>;
  signatureSvg?: InputMaybe<BytesFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type DisclosureAcceptanceWhereUniqueInput = {
  AND?: InputMaybe<Array<DisclosureAcceptanceWhereInput>>;
  NOT?: InputMaybe<Array<DisclosureAcceptanceWhereInput>>;
  OR?: InputMaybe<Array<DisclosureAcceptanceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  disclosures?: InputMaybe<DisclosureListRelationFilter>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<Scalars['String']>;
  pdf?: InputMaybe<FileNullableRelationFilter>;
  signatureSvg?: InputMaybe<BytesFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type DisclosureAddress = {
  addressLineOne: Scalars['String'];
  addressLineTwo?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type DisclosureCount = {
  __typename?: 'DisclosureCount';
  disclosureAcceptances: Scalars['Int'];
  organizations: Scalars['Int'];
  packageSets: Scalars['Int'];
};


export type DisclosureCountDisclosureAcceptancesArgs = {
  where?: InputMaybe<DisclosureAcceptanceWhereInput>;
};


export type DisclosureCountOrganizationsArgs = {
  where?: InputMaybe<OrganizationWhereInput>;
};


export type DisclosureCountPackageSetsArgs = {
  where?: InputMaybe<PackageSetWhereInput>;
};

export type DisclosureCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutDisclosuresInput>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationTypeEnum>;
  name: Scalars['String'];
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutDisclosuresInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutDisclosuresInput>;
  text: Scalars['String'];
  type: DisclosureTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DisclosureCreateNestedManyWithoutDisclosureAcceptancesInput = {
  connect?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureCreateOrConnectWithoutDisclosureAcceptancesInput>>;
  create?: InputMaybe<Array<DisclosureCreateWithoutDisclosureAcceptancesInput>>;
};

export type DisclosureCreateNestedManyWithoutOrganizationsInput = {
  connect?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureCreateOrConnectWithoutOrganizationsInput>>;
  create?: InputMaybe<Array<DisclosureCreateWithoutOrganizationsInput>>;
};

export type DisclosureCreateNestedManyWithoutPackageSetsInput = {
  connect?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureCreateOrConnectWithoutPackageSetsInput>>;
  create?: InputMaybe<Array<DisclosureCreateWithoutPackageSetsInput>>;
};

export type DisclosureCreateOrConnectWithoutDisclosureAcceptancesInput = {
  create: DisclosureCreateWithoutDisclosureAcceptancesInput;
  where: DisclosureWhereUniqueInput;
};

export type DisclosureCreateOrConnectWithoutOrganizationsInput = {
  create: DisclosureCreateWithoutOrganizationsInput;
  where: DisclosureWhereUniqueInput;
};

export type DisclosureCreateOrConnectWithoutPackageSetsInput = {
  create: DisclosureCreateWithoutPackageSetsInput;
  where: DisclosureWhereUniqueInput;
};

export type DisclosureCreateWithoutDisclosureAcceptancesInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationTypeEnum>;
  name: Scalars['String'];
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutDisclosuresInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutDisclosuresInput>;
  text: Scalars['String'];
  type: DisclosureTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DisclosureCreateWithoutOrganizationsInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutDisclosuresInput>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationTypeEnum>;
  name: Scalars['String'];
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutDisclosuresInput>;
  text: Scalars['String'];
  type: DisclosureTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DisclosureCreateWithoutPackageSetsInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutDisclosuresInput>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationTypeEnum>;
  name: Scalars['String'];
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutDisclosuresInput>;
  text: Scalars['String'];
  type: DisclosureTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DisclosureListRelationFilter = {
  every?: InputMaybe<DisclosureWhereInput>;
  none?: InputMaybe<DisclosureWhereInput>;
  some?: InputMaybe<DisclosureWhereInput>;
};

export type DisclosureOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DisclosureOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrderInput>;
  locationType?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  organizations?: InputMaybe<OrganizationOrderByRelationAggregateInput>;
  packageSets?: InputMaybe<PackageSetOrderByRelationAggregateInput>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum DisclosureScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Location = 'location',
  LocationType = 'locationType',
  Name = 'name',
  Text = 'text',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type DisclosureScalarWhereInput = {
  AND?: InputMaybe<Array<DisclosureScalarWhereInput>>;
  NOT?: InputMaybe<Array<DisclosureScalarWhereInput>>;
  OR?: InputMaybe<Array<DisclosureScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<StringNullableFilter>;
  locationType?: InputMaybe<EnumLocationTypeEnumNullableFilter>;
  name?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumDisclosureTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum DisclosureTypeEnum {
  Cra = 'CRA',
  Custom = 'CUSTOM',
  General = 'GENERAL',
  LocationBased = 'LOCATION_BASED',
  Organization = 'ORGANIZATION',
  Package = 'PACKAGE'
}

export type DisclosureUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutDisclosuresNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationType?: InputMaybe<NullableEnumLocationTypeEnumFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutDisclosuresNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutDisclosuresNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDisclosureTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DisclosureUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationType?: InputMaybe<NullableEnumLocationTypeEnumFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDisclosureTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DisclosureUpdateManyWithWhereWithoutDisclosureAcceptancesInput = {
  data: DisclosureUpdateManyMutationInput;
  where: DisclosureScalarWhereInput;
};

export type DisclosureUpdateManyWithWhereWithoutOrganizationsInput = {
  data: DisclosureUpdateManyMutationInput;
  where: DisclosureScalarWhereInput;
};

export type DisclosureUpdateManyWithWhereWithoutPackageSetsInput = {
  data: DisclosureUpdateManyMutationInput;
  where: DisclosureScalarWhereInput;
};

export type DisclosureUpdateManyWithoutDisclosureAcceptancesNestedInput = {
  connect?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureCreateOrConnectWithoutDisclosureAcceptancesInput>>;
  create?: InputMaybe<Array<DisclosureCreateWithoutDisclosureAcceptancesInput>>;
  delete?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DisclosureScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  set?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  update?: InputMaybe<Array<DisclosureUpdateWithWhereUniqueWithoutDisclosureAcceptancesInput>>;
  updateMany?: InputMaybe<Array<DisclosureUpdateManyWithWhereWithoutDisclosureAcceptancesInput>>;
  upsert?: InputMaybe<Array<DisclosureUpsertWithWhereUniqueWithoutDisclosureAcceptancesInput>>;
};

export type DisclosureUpdateManyWithoutOrganizationsNestedInput = {
  connect?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureCreateOrConnectWithoutOrganizationsInput>>;
  create?: InputMaybe<Array<DisclosureCreateWithoutOrganizationsInput>>;
  delete?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DisclosureScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  set?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  update?: InputMaybe<Array<DisclosureUpdateWithWhereUniqueWithoutOrganizationsInput>>;
  updateMany?: InputMaybe<Array<DisclosureUpdateManyWithWhereWithoutOrganizationsInput>>;
  upsert?: InputMaybe<Array<DisclosureUpsertWithWhereUniqueWithoutOrganizationsInput>>;
};

export type DisclosureUpdateManyWithoutPackageSetsNestedInput = {
  connect?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DisclosureCreateOrConnectWithoutPackageSetsInput>>;
  create?: InputMaybe<Array<DisclosureCreateWithoutPackageSetsInput>>;
  delete?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DisclosureScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  set?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  update?: InputMaybe<Array<DisclosureUpdateWithWhereUniqueWithoutPackageSetsInput>>;
  updateMany?: InputMaybe<Array<DisclosureUpdateManyWithWhereWithoutPackageSetsInput>>;
  upsert?: InputMaybe<Array<DisclosureUpsertWithWhereUniqueWithoutPackageSetsInput>>;
};

export type DisclosureUpdateWithWhereUniqueWithoutDisclosureAcceptancesInput = {
  data: DisclosureUpdateWithoutDisclosureAcceptancesInput;
  where: DisclosureWhereUniqueInput;
};

export type DisclosureUpdateWithWhereUniqueWithoutOrganizationsInput = {
  data: DisclosureUpdateWithoutOrganizationsInput;
  where: DisclosureWhereUniqueInput;
};

export type DisclosureUpdateWithWhereUniqueWithoutPackageSetsInput = {
  data: DisclosureUpdateWithoutPackageSetsInput;
  where: DisclosureWhereUniqueInput;
};

export type DisclosureUpdateWithoutDisclosureAcceptancesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationType?: InputMaybe<NullableEnumLocationTypeEnumFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutDisclosuresNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutDisclosuresNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDisclosureTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DisclosureUpdateWithoutOrganizationsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutDisclosuresNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationType?: InputMaybe<NullableEnumLocationTypeEnumFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutDisclosuresNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDisclosureTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DisclosureUpdateWithoutPackageSetsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutDisclosuresNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationType?: InputMaybe<NullableEnumLocationTypeEnumFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutDisclosuresNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDisclosureTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DisclosureUpsertWithWhereUniqueWithoutDisclosureAcceptancesInput = {
  create: DisclosureCreateWithoutDisclosureAcceptancesInput;
  update: DisclosureUpdateWithoutDisclosureAcceptancesInput;
  where: DisclosureWhereUniqueInput;
};

export type DisclosureUpsertWithWhereUniqueWithoutOrganizationsInput = {
  create: DisclosureCreateWithoutOrganizationsInput;
  update: DisclosureUpdateWithoutOrganizationsInput;
  where: DisclosureWhereUniqueInput;
};

export type DisclosureUpsertWithWhereUniqueWithoutPackageSetsInput = {
  create: DisclosureCreateWithoutPackageSetsInput;
  update: DisclosureUpdateWithoutPackageSetsInput;
  where: DisclosureWhereUniqueInput;
};

export type DisclosureWhereInput = {
  AND?: InputMaybe<Array<DisclosureWhereInput>>;
  NOT?: InputMaybe<Array<DisclosureWhereInput>>;
  OR?: InputMaybe<Array<DisclosureWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<StringNullableFilter>;
  locationType?: InputMaybe<EnumLocationTypeEnumNullableFilter>;
  name?: InputMaybe<StringFilter>;
  organizations?: InputMaybe<OrganizationListRelationFilter>;
  packageSets?: InputMaybe<PackageSetListRelationFilter>;
  text?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumDisclosureTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DisclosureWhereUniqueInput = {
  AND?: InputMaybe<Array<DisclosureWhereInput>>;
  NOT?: InputMaybe<Array<DisclosureWhereInput>>;
  OR?: InputMaybe<Array<DisclosureWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceListRelationFilter>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<StringNullableFilter>;
  locationType?: InputMaybe<EnumLocationTypeEnumNullableFilter>;
  name?: InputMaybe<StringFilter>;
  organizations?: InputMaybe<OrganizationListRelationFilter>;
  packageSets?: InputMaybe<PackageSetListRelationFilter>;
  text?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumDisclosureTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DispatchedSearch = {
  __typename?: 'DispatchedSearch';
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  order: Order;
  orderId: Scalars['String'];
  requestedSearch: RequestedSearch;
  requestedSearchId: Scalars['String'];
  searchType: SearchType;
  searchTypeId: Scalars['String'];
  status: SearchStatusTypeEnum;
  updatedAt: Scalars['DateTimeISO'];
};

export type DispatchedSearchCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  order: OrderCreateNestedOneWithoutDispatchedSearchesInput;
  requestedSearch: RequestedSearchCreateNestedOneWithoutDispatchedSearchesInput;
  searchType: SearchTypeCreateNestedOneWithoutDispatchedSearchesInput;
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DispatchedSearchCreateManyOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  requestedSearchId: Scalars['String'];
  searchTypeId: Scalars['String'];
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DispatchedSearchCreateManyOrderInputEnvelope = {
  data: Array<DispatchedSearchCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DispatchedSearchCreateManyRequestedSearchInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orderId: Scalars['String'];
  searchTypeId: Scalars['String'];
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DispatchedSearchCreateManyRequestedSearchInputEnvelope = {
  data: Array<DispatchedSearchCreateManyRequestedSearchInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DispatchedSearchCreateManySearchTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orderId: Scalars['String'];
  requestedSearchId: Scalars['String'];
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DispatchedSearchCreateManySearchTypeInputEnvelope = {
  data: Array<DispatchedSearchCreateManySearchTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DispatchedSearchCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispatchedSearchCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<DispatchedSearchCreateWithoutOrderInput>>;
  createMany?: InputMaybe<DispatchedSearchCreateManyOrderInputEnvelope>;
};

export type DispatchedSearchCreateNestedManyWithoutRequestedSearchInput = {
  connect?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispatchedSearchCreateOrConnectWithoutRequestedSearchInput>>;
  create?: InputMaybe<Array<DispatchedSearchCreateWithoutRequestedSearchInput>>;
  createMany?: InputMaybe<DispatchedSearchCreateManyRequestedSearchInputEnvelope>;
};

export type DispatchedSearchCreateNestedManyWithoutSearchTypeInput = {
  connect?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispatchedSearchCreateOrConnectWithoutSearchTypeInput>>;
  create?: InputMaybe<Array<DispatchedSearchCreateWithoutSearchTypeInput>>;
  createMany?: InputMaybe<DispatchedSearchCreateManySearchTypeInputEnvelope>;
};

export type DispatchedSearchCreateOrConnectWithoutOrderInput = {
  create: DispatchedSearchCreateWithoutOrderInput;
  where: DispatchedSearchWhereUniqueInput;
};

export type DispatchedSearchCreateOrConnectWithoutRequestedSearchInput = {
  create: DispatchedSearchCreateWithoutRequestedSearchInput;
  where: DispatchedSearchWhereUniqueInput;
};

export type DispatchedSearchCreateOrConnectWithoutSearchTypeInput = {
  create: DispatchedSearchCreateWithoutSearchTypeInput;
  where: DispatchedSearchWhereUniqueInput;
};

export type DispatchedSearchCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  requestedSearch: RequestedSearchCreateNestedOneWithoutDispatchedSearchesInput;
  searchType: SearchTypeCreateNestedOneWithoutDispatchedSearchesInput;
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DispatchedSearchCreateWithoutRequestedSearchInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  order: OrderCreateNestedOneWithoutDispatchedSearchesInput;
  searchType: SearchTypeCreateNestedOneWithoutDispatchedSearchesInput;
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DispatchedSearchCreateWithoutSearchTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  order: OrderCreateNestedOneWithoutDispatchedSearchesInput;
  requestedSearch: RequestedSearchCreateNestedOneWithoutDispatchedSearchesInput;
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type DispatchedSearchListRelationFilter = {
  every?: InputMaybe<DispatchedSearchWhereInput>;
  none?: InputMaybe<DispatchedSearchWhereInput>;
  some?: InputMaybe<DispatchedSearchWhereInput>;
};

export type DispatchedSearchOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DispatchedSearchOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  requestedSearch?: InputMaybe<RequestedSearchOrderByWithRelationInput>;
  requestedSearchId?: InputMaybe<SortOrder>;
  searchType?: InputMaybe<SearchTypeOrderByWithRelationInput>;
  searchTypeId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum DispatchedSearchScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Metadata = 'metadata',
  OrderId = 'orderId',
  RequestedSearchId = 'requestedSearchId',
  SearchTypeId = 'searchTypeId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type DispatchedSearchScalarWhereInput = {
  AND?: InputMaybe<Array<DispatchedSearchScalarWhereInput>>;
  NOT?: InputMaybe<Array<DispatchedSearchScalarWhereInput>>;
  OR?: InputMaybe<Array<DispatchedSearchScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  orderId?: InputMaybe<StringFilter>;
  requestedSearchId?: InputMaybe<StringFilter>;
  searchTypeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DispatchedSearchUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutDispatchedSearchesNestedInput>;
  requestedSearch?: InputMaybe<RequestedSearchUpdateOneRequiredWithoutDispatchedSearchesNestedInput>;
  searchType?: InputMaybe<SearchTypeUpdateOneRequiredWithoutDispatchedSearchesNestedInput>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DispatchedSearchUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DispatchedSearchUpdateManyWithWhereWithoutOrderInput = {
  data: DispatchedSearchUpdateManyMutationInput;
  where: DispatchedSearchScalarWhereInput;
};

export type DispatchedSearchUpdateManyWithWhereWithoutRequestedSearchInput = {
  data: DispatchedSearchUpdateManyMutationInput;
  where: DispatchedSearchScalarWhereInput;
};

export type DispatchedSearchUpdateManyWithWhereWithoutSearchTypeInput = {
  data: DispatchedSearchUpdateManyMutationInput;
  where: DispatchedSearchScalarWhereInput;
};

export type DispatchedSearchUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispatchedSearchCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<DispatchedSearchCreateWithoutOrderInput>>;
  createMany?: InputMaybe<DispatchedSearchCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DispatchedSearchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  set?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  update?: InputMaybe<Array<DispatchedSearchUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<DispatchedSearchUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<DispatchedSearchUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type DispatchedSearchUpdateManyWithoutRequestedSearchNestedInput = {
  connect?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispatchedSearchCreateOrConnectWithoutRequestedSearchInput>>;
  create?: InputMaybe<Array<DispatchedSearchCreateWithoutRequestedSearchInput>>;
  createMany?: InputMaybe<DispatchedSearchCreateManyRequestedSearchInputEnvelope>;
  delete?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DispatchedSearchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  set?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  update?: InputMaybe<Array<DispatchedSearchUpdateWithWhereUniqueWithoutRequestedSearchInput>>;
  updateMany?: InputMaybe<Array<DispatchedSearchUpdateManyWithWhereWithoutRequestedSearchInput>>;
  upsert?: InputMaybe<Array<DispatchedSearchUpsertWithWhereUniqueWithoutRequestedSearchInput>>;
};

export type DispatchedSearchUpdateManyWithoutSearchTypeNestedInput = {
  connect?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispatchedSearchCreateOrConnectWithoutSearchTypeInput>>;
  create?: InputMaybe<Array<DispatchedSearchCreateWithoutSearchTypeInput>>;
  createMany?: InputMaybe<DispatchedSearchCreateManySearchTypeInputEnvelope>;
  delete?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DispatchedSearchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  set?: InputMaybe<Array<DispatchedSearchWhereUniqueInput>>;
  update?: InputMaybe<Array<DispatchedSearchUpdateWithWhereUniqueWithoutSearchTypeInput>>;
  updateMany?: InputMaybe<Array<DispatchedSearchUpdateManyWithWhereWithoutSearchTypeInput>>;
  upsert?: InputMaybe<Array<DispatchedSearchUpsertWithWhereUniqueWithoutSearchTypeInput>>;
};

export type DispatchedSearchUpdateWithWhereUniqueWithoutOrderInput = {
  data: DispatchedSearchUpdateWithoutOrderInput;
  where: DispatchedSearchWhereUniqueInput;
};

export type DispatchedSearchUpdateWithWhereUniqueWithoutRequestedSearchInput = {
  data: DispatchedSearchUpdateWithoutRequestedSearchInput;
  where: DispatchedSearchWhereUniqueInput;
};

export type DispatchedSearchUpdateWithWhereUniqueWithoutSearchTypeInput = {
  data: DispatchedSearchUpdateWithoutSearchTypeInput;
  where: DispatchedSearchWhereUniqueInput;
};

export type DispatchedSearchUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  requestedSearch?: InputMaybe<RequestedSearchUpdateOneRequiredWithoutDispatchedSearchesNestedInput>;
  searchType?: InputMaybe<SearchTypeUpdateOneRequiredWithoutDispatchedSearchesNestedInput>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DispatchedSearchUpdateWithoutRequestedSearchInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutDispatchedSearchesNestedInput>;
  searchType?: InputMaybe<SearchTypeUpdateOneRequiredWithoutDispatchedSearchesNestedInput>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DispatchedSearchUpdateWithoutSearchTypeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutDispatchedSearchesNestedInput>;
  requestedSearch?: InputMaybe<RequestedSearchUpdateOneRequiredWithoutDispatchedSearchesNestedInput>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DispatchedSearchUpsertWithWhereUniqueWithoutOrderInput = {
  create: DispatchedSearchCreateWithoutOrderInput;
  update: DispatchedSearchUpdateWithoutOrderInput;
  where: DispatchedSearchWhereUniqueInput;
};

export type DispatchedSearchUpsertWithWhereUniqueWithoutRequestedSearchInput = {
  create: DispatchedSearchCreateWithoutRequestedSearchInput;
  update: DispatchedSearchUpdateWithoutRequestedSearchInput;
  where: DispatchedSearchWhereUniqueInput;
};

export type DispatchedSearchUpsertWithWhereUniqueWithoutSearchTypeInput = {
  create: DispatchedSearchCreateWithoutSearchTypeInput;
  update: DispatchedSearchUpdateWithoutSearchTypeInput;
  where: DispatchedSearchWhereUniqueInput;
};

export type DispatchedSearchWhereInput = {
  AND?: InputMaybe<Array<DispatchedSearchWhereInput>>;
  NOT?: InputMaybe<Array<DispatchedSearchWhereInput>>;
  OR?: InputMaybe<Array<DispatchedSearchWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  requestedSearch?: InputMaybe<RequestedSearchRelationFilter>;
  requestedSearchId?: InputMaybe<StringFilter>;
  searchType?: InputMaybe<SearchTypeRelationFilter>;
  searchTypeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DispatchedSearchWhereUniqueInput = {
  AND?: InputMaybe<Array<DispatchedSearchWhereInput>>;
  NOT?: InputMaybe<Array<DispatchedSearchWhereInput>>;
  OR?: InputMaybe<Array<DispatchedSearchWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  requestedSearch?: InputMaybe<RequestedSearchRelationFilter>;
  requestedSearchId?: InputMaybe<StringFilter>;
  searchType?: InputMaybe<SearchTypeRelationFilter>;
  searchTypeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EnumAddressTypeEnumNullableFilter = {
  equals?: InputMaybe<AddressTypeEnum>;
  in?: InputMaybe<Array<AddressTypeEnum>>;
  not?: InputMaybe<NestedEnumAddressTypeEnumNullableFilter>;
  notIn?: InputMaybe<Array<AddressTypeEnum>>;
};

export type EnumAttachmentEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<AttachmentEnum>;
};

export type EnumAttachmentEnumFilter = {
  equals?: InputMaybe<AttachmentEnum>;
  in?: InputMaybe<Array<AttachmentEnum>>;
  not?: InputMaybe<NestedEnumAttachmentEnumFilter>;
  notIn?: InputMaybe<Array<AttachmentEnum>>;
};

export type EnumBeamRunStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<BeamRunStatusEnum>;
};

export type EnumBeamRunStatusEnumFilter = {
  equals?: InputMaybe<BeamRunStatusEnum>;
  in?: InputMaybe<Array<BeamRunStatusEnum>>;
  not?: InputMaybe<NestedEnumBeamRunStatusEnumFilter>;
  notIn?: InputMaybe<Array<BeamRunStatusEnum>>;
};

export type EnumCredentialStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<CredentialStatusEnum>;
};

export type EnumCredentialStatusEnumFilter = {
  equals?: InputMaybe<CredentialStatusEnum>;
  in?: InputMaybe<Array<CredentialStatusEnum>>;
  not?: InputMaybe<NestedEnumCredentialStatusEnumFilter>;
  notIn?: InputMaybe<Array<CredentialStatusEnum>>;
};

export type EnumCurrencyMultiplierEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<CurrencyMultiplierEnum>;
};

export type EnumCurrencyMultiplierEnumFilter = {
  equals?: InputMaybe<CurrencyMultiplierEnum>;
  in?: InputMaybe<Array<CurrencyMultiplierEnum>>;
  not?: InputMaybe<NestedEnumCurrencyMultiplierEnumFilter>;
  notIn?: InputMaybe<Array<CurrencyMultiplierEnum>>;
};

export type EnumCurrencyTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<CurrencyTypeEnum>;
};

export type EnumCurrencyTypeEnumFilter = {
  equals?: InputMaybe<CurrencyTypeEnum>;
  in?: InputMaybe<Array<CurrencyTypeEnum>>;
  not?: InputMaybe<NestedEnumCurrencyTypeEnumFilter>;
  notIn?: InputMaybe<Array<CurrencyTypeEnum>>;
};

export type EnumDisclosureTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<DisclosureTypeEnum>;
};

export type EnumDisclosureTypeEnumFilter = {
  equals?: InputMaybe<DisclosureTypeEnum>;
  in?: InputMaybe<Array<DisclosureTypeEnum>>;
  not?: InputMaybe<NestedEnumDisclosureTypeEnumFilter>;
  notIn?: InputMaybe<Array<DisclosureTypeEnum>>;
};

export type EnumFilePurposeTypeEnumNullableFilter = {
  equals?: InputMaybe<FilePurposeTypeEnum>;
  in?: InputMaybe<Array<FilePurposeTypeEnum>>;
  not?: InputMaybe<NestedEnumFilePurposeTypeEnumNullableFilter>;
  notIn?: InputMaybe<Array<FilePurposeTypeEnum>>;
};

export type EnumHighlySensitiveIdentifierTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<HighlySensitiveIdentifierType>;
};

export type EnumHighlySensitiveIdentifierTypeFilter = {
  equals?: InputMaybe<HighlySensitiveIdentifierType>;
  in?: InputMaybe<Array<HighlySensitiveIdentifierType>>;
  not?: InputMaybe<NestedEnumHighlySensitiveIdentifierTypeFilter>;
  notIn?: InputMaybe<Array<HighlySensitiveIdentifierType>>;
};

export type EnumIdCardRotationEnumNullableFilter = {
  equals?: InputMaybe<IdCardRotationEnum>;
  in?: InputMaybe<Array<IdCardRotationEnum>>;
  not?: InputMaybe<NestedEnumIdCardRotationEnumNullableFilter>;
  notIn?: InputMaybe<Array<IdCardRotationEnum>>;
};

export type EnumIdVerificationResultCheckEnumNullableFilter = {
  equals?: InputMaybe<IdVerificationResultCheckEnum>;
  in?: InputMaybe<Array<IdVerificationResultCheckEnum>>;
  not?: InputMaybe<NestedEnumIdVerificationResultCheckEnumNullableFilter>;
  notIn?: InputMaybe<Array<IdVerificationResultCheckEnum>>;
};

export type EnumIdentifierTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<IdentifierType>;
};

export type EnumIdentifierTypeFilter = {
  equals?: InputMaybe<IdentifierType>;
  in?: InputMaybe<Array<IdentifierType>>;
  not?: InputMaybe<NestedEnumIdentifierTypeFilter>;
  notIn?: InputMaybe<Array<IdentifierType>>;
};

export type EnumLocationTypeEnumNullableFilter = {
  equals?: InputMaybe<LocationTypeEnum>;
  in?: InputMaybe<Array<LocationTypeEnum>>;
  not?: InputMaybe<NestedEnumLocationTypeEnumNullableFilter>;
  notIn?: InputMaybe<Array<LocationTypeEnum>>;
};

export type EnumNameTypeEnumNullableFilter = {
  equals?: InputMaybe<NameTypeEnum>;
  in?: InputMaybe<Array<NameTypeEnum>>;
  not?: InputMaybe<NestedEnumNameTypeEnumNullableFilter>;
  notIn?: InputMaybe<Array<NameTypeEnum>>;
};

export type EnumOrderAlertEnumNullableFilter = {
  equals?: InputMaybe<OrderAlertEnum>;
  in?: InputMaybe<Array<OrderAlertEnum>>;
  not?: InputMaybe<NestedEnumOrderAlertEnumNullableFilter>;
  notIn?: InputMaybe<Array<OrderAlertEnum>>;
};

export type EnumOrderDeliveryMethodNullableFilter = {
  equals?: InputMaybe<OrderDeliveryMethod>;
  in?: InputMaybe<Array<OrderDeliveryMethod>>;
  not?: InputMaybe<NestedEnumOrderDeliveryMethodNullableFilter>;
  notIn?: InputMaybe<Array<OrderDeliveryMethod>>;
};

export type EnumOrderScoreEnumNullableFilter = {
  equals?: InputMaybe<OrderScoreEnum>;
  in?: InputMaybe<Array<OrderScoreEnum>>;
  not?: InputMaybe<NestedEnumOrderScoreEnumNullableFilter>;
  notIn?: InputMaybe<Array<OrderScoreEnum>>;
};

export type EnumOrderStatusEnumNullableFilter = {
  equals?: InputMaybe<OrderStatusEnum>;
  in?: InputMaybe<Array<OrderStatusEnum>>;
  not?: InputMaybe<NestedEnumOrderStatusEnumNullableFilter>;
  notIn?: InputMaybe<Array<OrderStatusEnum>>;
};

export type EnumOrgRelationTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<OrgRelationTypeEnum>;
};

export type EnumOrgRelationTypeEnumFilter = {
  equals?: InputMaybe<OrgRelationTypeEnum>;
  in?: InputMaybe<Array<OrgRelationTypeEnum>>;
  not?: InputMaybe<NestedEnumOrgRelationTypeEnumFilter>;
  notIn?: InputMaybe<Array<OrgRelationTypeEnum>>;
};

export type EnumPerformerTypesFieldUpdateOperationsInput = {
  set?: InputMaybe<PerformerTypes>;
};

export type EnumPerformerTypesFilter = {
  equals?: InputMaybe<PerformerTypes>;
  in?: InputMaybe<Array<PerformerTypes>>;
  not?: InputMaybe<NestedEnumPerformerTypesFilter>;
  notIn?: InputMaybe<Array<PerformerTypes>>;
};

export type EnumProcessorEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<ProcessorEnum>;
};

export type EnumProcessorEnumFilter = {
  equals?: InputMaybe<ProcessorEnum>;
  in?: InputMaybe<Array<ProcessorEnum>>;
  not?: InputMaybe<NestedEnumProcessorEnumFilter>;
  notIn?: InputMaybe<Array<ProcessorEnum>>;
};

export type EnumResultAlertTypeEnumNullableFilter = {
  equals?: InputMaybe<ResultAlertTypeEnum>;
  in?: InputMaybe<Array<ResultAlertTypeEnum>>;
  not?: InputMaybe<NestedEnumResultAlertTypeEnumNullableFilter>;
  notIn?: InputMaybe<Array<ResultAlertTypeEnum>>;
};

export type EnumResultSearchTypeNullableFilter = {
  equals?: InputMaybe<ResultSearchType>;
  in?: InputMaybe<Array<ResultSearchType>>;
  not?: InputMaybe<NestedEnumResultSearchTypeNullableFilter>;
  notIn?: InputMaybe<Array<ResultSearchType>>;
};

export type EnumSearchStatusTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<SearchStatusTypeEnum>;
};

export type EnumSearchStatusTypeEnumFilter = {
  equals?: InputMaybe<SearchStatusTypeEnum>;
  in?: InputMaybe<Array<SearchStatusTypeEnum>>;
  not?: InputMaybe<NestedEnumSearchStatusTypeEnumFilter>;
  notIn?: InputMaybe<Array<SearchStatusTypeEnum>>;
};

export type EnumSourceTypesFieldUpdateOperationsInput = {
  set?: InputMaybe<SourceTypes>;
};

export type EnumSourceTypesFilter = {
  equals?: InputMaybe<SourceTypes>;
  in?: InputMaybe<Array<SourceTypes>>;
  not?: InputMaybe<NestedEnumSourceTypesFilter>;
  notIn?: InputMaybe<Array<SourceTypes>>;
};

export type EnumUserPrimaryContactEnumNullableFilter = {
  equals?: InputMaybe<UserPrimaryContactEnum>;
  in?: InputMaybe<Array<UserPrimaryContactEnum>>;
  not?: InputMaybe<NestedEnumUserPrimaryContactEnumNullableFilter>;
  notIn?: InputMaybe<Array<UserPrimaryContactEnum>>;
};

export type EnumUserStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<UserStatusEnum>;
};

export type EnumUserStatusEnumFilter = {
  equals?: InputMaybe<UserStatusEnum>;
  in?: InputMaybe<Array<UserStatusEnum>>;
  not?: InputMaybe<NestedEnumUserStatusEnumFilter>;
  notIn?: InputMaybe<Array<UserStatusEnum>>;
};

export type EnumVoucherStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<VoucherStatusEnum>;
};

export type EnumVoucherStatusEnumFilter = {
  equals?: InputMaybe<VoucherStatusEnum>;
  in?: InputMaybe<Array<VoucherStatusEnum>>;
  not?: InputMaybe<NestedEnumVoucherStatusEnumFilter>;
  notIn?: InputMaybe<Array<VoucherStatusEnum>>;
};

export type ExtendedOrderCreateInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type File = {
  __typename?: 'File';
  backIdUser?: Maybe<User>;
  backIdUserId?: Maybe<Scalars['String']>;
  beamInput?: Maybe<BeamInput>;
  beamInputId?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  disclosureAcceptance?: Maybe<DisclosureAcceptance>;
  disclosureAcceptanceId?: Maybe<Scalars['String']>;
  frontIdUser?: Maybe<User>;
  frontIdUserId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  passportBack?: Maybe<Passport>;
  passportBackId?: Maybe<Scalars['String']>;
  passportCardSelfie?: Maybe<Passport>;
  passportCardSelfieId?: Maybe<Scalars['String']>;
  passportExtractPhoto?: Maybe<Passport>;
  passportExtractPhotoId?: Maybe<Scalars['String']>;
  passportFront?: Maybe<Passport>;
  passportFrontId?: Maybe<Scalars['String']>;
  purpose?: Maybe<FilePurposeTypeEnum>;
  region?: Maybe<Scalars['String']>;
  result?: Maybe<Result>;
  resultId?: Maybe<Scalars['String']>;
  selfieUser?: Maybe<User>;
  selfieUserId?: Maybe<Scalars['String']>;
  stateIdentificationCardBack?: Maybe<StateIdentificationCard>;
  stateIdentificationCardBackId?: Maybe<Scalars['String']>;
  stateIdentificationCardExtract?: Maybe<StateIdentificationCard>;
  stateIdentificationCardExtractId?: Maybe<Scalars['String']>;
  stateIdentificationCardFront?: Maybe<StateIdentificationCard>;
  stateIdentificationCardFrontId?: Maybe<Scalars['String']>;
  stateIdentificationCardSelfie?: Maybe<StateIdentificationCard>;
  stateIdentificationCardSelfieId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTimeISO'];
  url: Scalars['String'];
};


export type FileBackIdUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type FileBeamInputArgs = {
  where?: InputMaybe<BeamInputWhereInput>;
};


export type FileDisclosureAcceptanceArgs = {
  where?: InputMaybe<DisclosureAcceptanceWhereInput>;
};


export type FileFrontIdUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type FileOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type FileOrganizationArgs = {
  where?: InputMaybe<OrganizationWhereInput>;
};


export type FilePassportBackArgs = {
  where?: InputMaybe<PassportWhereInput>;
};


export type FilePassportCardSelfieArgs = {
  where?: InputMaybe<PassportWhereInput>;
};


export type FilePassportExtractPhotoArgs = {
  where?: InputMaybe<PassportWhereInput>;
};


export type FilePassportFrontArgs = {
  where?: InputMaybe<PassportWhereInput>;
};


export type FileResultArgs = {
  where?: InputMaybe<ResultWhereInput>;
};


export type FileSelfieUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type FileStateIdentificationCardBackArgs = {
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};


export type FileStateIdentificationCardExtractArgs = {
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};


export type FileStateIdentificationCardFrontArgs = {
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};


export type FileStateIdentificationCardSelfieArgs = {
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type FileCreateInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateManyBeamInputInput = {
  backIdUserId?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptanceId?: InputMaybe<Scalars['String']>;
  frontIdUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  orderId?: InputMaybe<Scalars['String']>;
  passportBackId?: InputMaybe<Scalars['String']>;
  passportCardSelfieId?: InputMaybe<Scalars['String']>;
  passportExtractPhotoId?: InputMaybe<Scalars['String']>;
  passportFrontId?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  resultId?: InputMaybe<Scalars['String']>;
  selfieUserId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardBackId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardExtractId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardFrontId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardSelfieId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateManyBeamInputInputEnvelope = {
  data: Array<FileCreateManyBeamInputInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FileCreateManyOrderInput = {
  backIdUserId?: InputMaybe<Scalars['String']>;
  beamInputId?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptanceId?: InputMaybe<Scalars['String']>;
  frontIdUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  passportBackId?: InputMaybe<Scalars['String']>;
  passportCardSelfieId?: InputMaybe<Scalars['String']>;
  passportExtractPhotoId?: InputMaybe<Scalars['String']>;
  passportFrontId?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  resultId?: InputMaybe<Scalars['String']>;
  selfieUserId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardBackId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardExtractId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardFrontId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardSelfieId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateManyOrderInputEnvelope = {
  data: Array<FileCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FileCreateManyResultInput = {
  backIdUserId?: InputMaybe<Scalars['String']>;
  beamInputId?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptanceId?: InputMaybe<Scalars['String']>;
  frontIdUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  orderId?: InputMaybe<Scalars['String']>;
  passportBackId?: InputMaybe<Scalars['String']>;
  passportCardSelfieId?: InputMaybe<Scalars['String']>;
  passportExtractPhotoId?: InputMaybe<Scalars['String']>;
  passportFrontId?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  selfieUserId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardBackId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardExtractId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardFrontId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardSelfieId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateManyResultInputEnvelope = {
  data: Array<FileCreateManyResultInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FileCreateNestedManyWithoutBeamInputInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutBeamInputInput>>;
  create?: InputMaybe<Array<FileCreateWithoutBeamInputInput>>;
  createMany?: InputMaybe<FileCreateManyBeamInputInputEnvelope>;
};

export type FileCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<FileCreateWithoutOrderInput>>;
  createMany?: InputMaybe<FileCreateManyOrderInputEnvelope>;
};

export type FileCreateNestedManyWithoutResultInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutResultInput>>;
  create?: InputMaybe<Array<FileCreateWithoutResultInput>>;
  createMany?: InputMaybe<FileCreateManyResultInputEnvelope>;
};

export type FileCreateNestedOneWithoutBackIdUserInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutBackIdUserInput>;
  create?: InputMaybe<FileCreateWithoutBackIdUserInput>;
};

export type FileCreateNestedOneWithoutDisclosureAcceptanceInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutDisclosureAcceptanceInput>;
  create?: InputMaybe<FileCreateWithoutDisclosureAcceptanceInput>;
};

export type FileCreateNestedOneWithoutFrontIdUserInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutFrontIdUserInput>;
  create?: InputMaybe<FileCreateWithoutFrontIdUserInput>;
};

export type FileCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<FileCreateWithoutOrganizationInput>;
};

export type FileCreateNestedOneWithoutPassportBackInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutPassportBackInput>;
  create?: InputMaybe<FileCreateWithoutPassportBackInput>;
};

export type FileCreateNestedOneWithoutPassportCardSelfieInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutPassportCardSelfieInput>;
  create?: InputMaybe<FileCreateWithoutPassportCardSelfieInput>;
};

export type FileCreateNestedOneWithoutPassportExtractPhotoInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutPassportExtractPhotoInput>;
  create?: InputMaybe<FileCreateWithoutPassportExtractPhotoInput>;
};

export type FileCreateNestedOneWithoutPassportFrontInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutPassportFrontInput>;
  create?: InputMaybe<FileCreateWithoutPassportFrontInput>;
};

export type FileCreateNestedOneWithoutSelfieUserInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutSelfieUserInput>;
  create?: InputMaybe<FileCreateWithoutSelfieUserInput>;
};

export type FileCreateNestedOneWithoutStateIdentificationCardBackInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutStateIdentificationCardBackInput>;
  create?: InputMaybe<FileCreateWithoutStateIdentificationCardBackInput>;
};

export type FileCreateNestedOneWithoutStateIdentificationCardExtractInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutStateIdentificationCardExtractInput>;
  create?: InputMaybe<FileCreateWithoutStateIdentificationCardExtractInput>;
};

export type FileCreateNestedOneWithoutStateIdentificationCardFrontInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutStateIdentificationCardFrontInput>;
  create?: InputMaybe<FileCreateWithoutStateIdentificationCardFrontInput>;
};

export type FileCreateNestedOneWithoutStateIdentificationCardSelfieInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutStateIdentificationCardSelfieInput>;
  create?: InputMaybe<FileCreateWithoutStateIdentificationCardSelfieInput>;
};

export type FileCreateOrConnectWithoutBackIdUserInput = {
  create: FileCreateWithoutBackIdUserInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutBeamInputInput = {
  create: FileCreateWithoutBeamInputInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutDisclosureAcceptanceInput = {
  create: FileCreateWithoutDisclosureAcceptanceInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutFrontIdUserInput = {
  create: FileCreateWithoutFrontIdUserInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutOrderInput = {
  create: FileCreateWithoutOrderInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutOrganizationInput = {
  create: FileCreateWithoutOrganizationInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutPassportBackInput = {
  create: FileCreateWithoutPassportBackInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutPassportCardSelfieInput = {
  create: FileCreateWithoutPassportCardSelfieInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutPassportExtractPhotoInput = {
  create: FileCreateWithoutPassportExtractPhotoInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutPassportFrontInput = {
  create: FileCreateWithoutPassportFrontInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutResultInput = {
  create: FileCreateWithoutResultInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutSelfieUserInput = {
  create: FileCreateWithoutSelfieUserInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutStateIdentificationCardBackInput = {
  create: FileCreateWithoutStateIdentificationCardBackInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutStateIdentificationCardExtractInput = {
  create: FileCreateWithoutStateIdentificationCardExtractInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutStateIdentificationCardFrontInput = {
  create: FileCreateWithoutStateIdentificationCardFrontInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutStateIdentificationCardSelfieInput = {
  create: FileCreateWithoutStateIdentificationCardSelfieInput;
  where: FileWhereUniqueInput;
};

export type FileCreateWithoutBackIdUserInput = {
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutBeamInputInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutDisclosureAcceptanceInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutFrontIdUserInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutOrderInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutOrganizationInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutPassportBackInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutPassportCardSelfieInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutPassportExtractPhotoInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutPassportFrontInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutResultInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutSelfieUserInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutStateIdentificationCardBackInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutStateIdentificationCardExtractInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutStateIdentificationCardFrontInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutSelfieImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileCreateWithoutStateIdentificationCardSelfieInput = {
  backIdUser?: InputMaybe<UserCreateNestedOneWithoutBackIdInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedOneWithoutFilesInput>;
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceCreateNestedOneWithoutPdfInput>;
  frontIdUser?: InputMaybe<UserCreateNestedOneWithoutFrontIdInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<OrderCreateNestedOneWithoutFilesInput>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLogoInput>;
  passportBack?: InputMaybe<PassportCreateNestedOneWithoutBackImageInput>;
  passportCardSelfie?: InputMaybe<PassportCreateNestedOneWithoutSelfieImageInput>;
  passportExtractPhoto?: InputMaybe<PassportCreateNestedOneWithoutExtractedPhotoInput>;
  passportFront?: InputMaybe<PassportCreateNestedOneWithoutFrontImageInput>;
  purpose?: InputMaybe<FilePurposeTypeEnum>;
  region?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<ResultCreateNestedOneWithoutAttachmentsInput>;
  selfieUser?: InputMaybe<UserCreateNestedOneWithoutSelfieInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutBackImageInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutFrontImageInput>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type FileListRelationFilter = {
  every?: InputMaybe<FileWhereInput>;
  none?: InputMaybe<FileWhereInput>;
  some?: InputMaybe<FileWhereInput>;
};

export type FileNullableRelationFilter = {
  is?: InputMaybe<FileWhereInput>;
  isNot?: InputMaybe<FileWhereInput>;
};

export type FileOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FileOrderByWithRelationInput = {
  backIdUser?: InputMaybe<UserOrderByWithRelationInput>;
  backIdUserId?: InputMaybe<SortOrderInput>;
  beamInput?: InputMaybe<BeamInputOrderByWithRelationInput>;
  beamInputId?: InputMaybe<SortOrderInput>;
  bucket?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceOrderByWithRelationInput>;
  disclosureAcceptanceId?: InputMaybe<SortOrderInput>;
  frontIdUser?: InputMaybe<UserOrderByWithRelationInput>;
  frontIdUserId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrderInput>;
  key?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrderInput>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  passportBack?: InputMaybe<PassportOrderByWithRelationInput>;
  passportBackId?: InputMaybe<SortOrderInput>;
  passportCardSelfie?: InputMaybe<PassportOrderByWithRelationInput>;
  passportCardSelfieId?: InputMaybe<SortOrderInput>;
  passportExtractPhoto?: InputMaybe<PassportOrderByWithRelationInput>;
  passportExtractPhotoId?: InputMaybe<SortOrderInput>;
  passportFront?: InputMaybe<PassportOrderByWithRelationInput>;
  passportFrontId?: InputMaybe<SortOrderInput>;
  purpose?: InputMaybe<SortOrderInput>;
  region?: InputMaybe<SortOrderInput>;
  result?: InputMaybe<ResultOrderByWithRelationInput>;
  resultId?: InputMaybe<SortOrderInput>;
  selfieUser?: InputMaybe<UserOrderByWithRelationInput>;
  selfieUserId?: InputMaybe<SortOrderInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardOrderByWithRelationInput>;
  stateIdentificationCardBackId?: InputMaybe<SortOrderInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardOrderByWithRelationInput>;
  stateIdentificationCardExtractId?: InputMaybe<SortOrderInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardOrderByWithRelationInput>;
  stateIdentificationCardFrontId?: InputMaybe<SortOrderInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardOrderByWithRelationInput>;
  stateIdentificationCardSelfieId?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum FilePurposeTypeEnum {
  Attachment = 'ATTACHMENT',
  Signature = 'SIGNATURE',
  StateIdCard = 'STATE_ID_CARD'
}

export enum FileScalarFieldEnum {
  BackIdUserId = 'backIdUserId',
  BeamInputId = 'beamInputId',
  Bucket = 'bucket',
  CreatedAt = 'createdAt',
  DisclosureAcceptanceId = 'disclosureAcceptanceId',
  FrontIdUserId = 'frontIdUserId',
  Id = 'id',
  IsActive = 'isActive',
  Key = 'key',
  Name = 'name',
  OrderId = 'orderId',
  PassportBackId = 'passportBackId',
  PassportCardSelfieId = 'passportCardSelfieId',
  PassportExtractPhotoId = 'passportExtractPhotoId',
  PassportFrontId = 'passportFrontId',
  Purpose = 'purpose',
  Region = 'region',
  ResultId = 'resultId',
  SelfieUserId = 'selfieUserId',
  StateIdentificationCardBackId = 'stateIdentificationCardBackId',
  StateIdentificationCardExtractId = 'stateIdentificationCardExtractId',
  StateIdentificationCardFrontId = 'stateIdentificationCardFrontId',
  StateIdentificationCardSelfieId = 'stateIdentificationCardSelfieId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type FileScalarWhereInput = {
  AND?: InputMaybe<Array<FileScalarWhereInput>>;
  NOT?: InputMaybe<Array<FileScalarWhereInput>>;
  OR?: InputMaybe<Array<FileScalarWhereInput>>;
  backIdUserId?: InputMaybe<StringNullableFilter>;
  beamInputId?: InputMaybe<StringNullableFilter>;
  bucket?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  disclosureAcceptanceId?: InputMaybe<StringNullableFilter>;
  frontIdUserId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolNullableFilter>;
  key?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  passportBackId?: InputMaybe<StringNullableFilter>;
  passportCardSelfieId?: InputMaybe<StringNullableFilter>;
  passportExtractPhotoId?: InputMaybe<StringNullableFilter>;
  passportFrontId?: InputMaybe<StringNullableFilter>;
  purpose?: InputMaybe<EnumFilePurposeTypeEnumNullableFilter>;
  region?: InputMaybe<StringNullableFilter>;
  resultId?: InputMaybe<StringNullableFilter>;
  selfieUserId?: InputMaybe<StringNullableFilter>;
  stateIdentificationCardBackId?: InputMaybe<StringNullableFilter>;
  stateIdentificationCardExtractId?: InputMaybe<StringNullableFilter>;
  stateIdentificationCardFrontId?: InputMaybe<StringNullableFilter>;
  stateIdentificationCardSelfieId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FileUpdateInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateManyMutationInput = {
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateManyWithWhereWithoutBeamInputInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithWhereWithoutOrderInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithWhereWithoutResultInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithoutBeamInputNestedInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutBeamInputInput>>;
  create?: InputMaybe<Array<FileCreateWithoutBeamInputInput>>;
  createMany?: InputMaybe<FileCreateManyBeamInputInputEnvelope>;
  delete?: InputMaybe<Array<FileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileWhereUniqueInput>>;
  set?: InputMaybe<Array<FileWhereUniqueInput>>;
  update?: InputMaybe<Array<FileUpdateWithWhereUniqueWithoutBeamInputInput>>;
  updateMany?: InputMaybe<Array<FileUpdateManyWithWhereWithoutBeamInputInput>>;
  upsert?: InputMaybe<Array<FileUpsertWithWhereUniqueWithoutBeamInputInput>>;
};

export type FileUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<FileCreateWithoutOrderInput>>;
  createMany?: InputMaybe<FileCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<FileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileWhereUniqueInput>>;
  set?: InputMaybe<Array<FileWhereUniqueInput>>;
  update?: InputMaybe<Array<FileUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<FileUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<FileUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type FileUpdateManyWithoutResultNestedInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutResultInput>>;
  create?: InputMaybe<Array<FileCreateWithoutResultInput>>;
  createMany?: InputMaybe<FileCreateManyResultInputEnvelope>;
  delete?: InputMaybe<Array<FileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileWhereUniqueInput>>;
  set?: InputMaybe<Array<FileWhereUniqueInput>>;
  update?: InputMaybe<Array<FileUpdateWithWhereUniqueWithoutResultInput>>;
  updateMany?: InputMaybe<Array<FileUpdateManyWithWhereWithoutResultInput>>;
  upsert?: InputMaybe<Array<FileUpsertWithWhereUniqueWithoutResultInput>>;
};

export type FileUpdateOneWithoutBackIdUserNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutBackIdUserInput>;
  create?: InputMaybe<FileCreateWithoutBackIdUserInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutBackIdUserInput>;
  upsert?: InputMaybe<FileUpsertWithoutBackIdUserInput>;
};

export type FileUpdateOneWithoutDisclosureAcceptanceNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutDisclosureAcceptanceInput>;
  create?: InputMaybe<FileCreateWithoutDisclosureAcceptanceInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutDisclosureAcceptanceInput>;
  upsert?: InputMaybe<FileUpsertWithoutDisclosureAcceptanceInput>;
};

export type FileUpdateOneWithoutFrontIdUserNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutFrontIdUserInput>;
  create?: InputMaybe<FileCreateWithoutFrontIdUserInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutFrontIdUserInput>;
  upsert?: InputMaybe<FileUpsertWithoutFrontIdUserInput>;
};

export type FileUpdateOneWithoutOrganizationNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<FileCreateWithoutOrganizationInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutOrganizationInput>;
  upsert?: InputMaybe<FileUpsertWithoutOrganizationInput>;
};

export type FileUpdateOneWithoutPassportBackNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutPassportBackInput>;
  create?: InputMaybe<FileCreateWithoutPassportBackInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutPassportBackInput>;
  upsert?: InputMaybe<FileUpsertWithoutPassportBackInput>;
};

export type FileUpdateOneWithoutPassportCardSelfieNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutPassportCardSelfieInput>;
  create?: InputMaybe<FileCreateWithoutPassportCardSelfieInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutPassportCardSelfieInput>;
  upsert?: InputMaybe<FileUpsertWithoutPassportCardSelfieInput>;
};

export type FileUpdateOneWithoutPassportExtractPhotoNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutPassportExtractPhotoInput>;
  create?: InputMaybe<FileCreateWithoutPassportExtractPhotoInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutPassportExtractPhotoInput>;
  upsert?: InputMaybe<FileUpsertWithoutPassportExtractPhotoInput>;
};

export type FileUpdateOneWithoutPassportFrontNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutPassportFrontInput>;
  create?: InputMaybe<FileCreateWithoutPassportFrontInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutPassportFrontInput>;
  upsert?: InputMaybe<FileUpsertWithoutPassportFrontInput>;
};

export type FileUpdateOneWithoutSelfieUserNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutSelfieUserInput>;
  create?: InputMaybe<FileCreateWithoutSelfieUserInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutSelfieUserInput>;
  upsert?: InputMaybe<FileUpsertWithoutSelfieUserInput>;
};

export type FileUpdateOneWithoutStateIdentificationCardBackNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutStateIdentificationCardBackInput>;
  create?: InputMaybe<FileCreateWithoutStateIdentificationCardBackInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutStateIdentificationCardBackInput>;
  upsert?: InputMaybe<FileUpsertWithoutStateIdentificationCardBackInput>;
};

export type FileUpdateOneWithoutStateIdentificationCardExtractNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutStateIdentificationCardExtractInput>;
  create?: InputMaybe<FileCreateWithoutStateIdentificationCardExtractInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutStateIdentificationCardExtractInput>;
  upsert?: InputMaybe<FileUpsertWithoutStateIdentificationCardExtractInput>;
};

export type FileUpdateOneWithoutStateIdentificationCardFrontNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutStateIdentificationCardFrontInput>;
  create?: InputMaybe<FileCreateWithoutStateIdentificationCardFrontInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutStateIdentificationCardFrontInput>;
  upsert?: InputMaybe<FileUpsertWithoutStateIdentificationCardFrontInput>;
};

export type FileUpdateOneWithoutStateIdentificationCardSelfieNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutStateIdentificationCardSelfieInput>;
  create?: InputMaybe<FileCreateWithoutStateIdentificationCardSelfieInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutStateIdentificationCardSelfieInput>;
  upsert?: InputMaybe<FileUpsertWithoutStateIdentificationCardSelfieInput>;
};

export type FileUpdateToOneWithWhereWithoutBackIdUserInput = {
  data: FileUpdateWithoutBackIdUserInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutDisclosureAcceptanceInput = {
  data: FileUpdateWithoutDisclosureAcceptanceInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutFrontIdUserInput = {
  data: FileUpdateWithoutFrontIdUserInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutOrganizationInput = {
  data: FileUpdateWithoutOrganizationInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutPassportBackInput = {
  data: FileUpdateWithoutPassportBackInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutPassportCardSelfieInput = {
  data: FileUpdateWithoutPassportCardSelfieInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutPassportExtractPhotoInput = {
  data: FileUpdateWithoutPassportExtractPhotoInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutPassportFrontInput = {
  data: FileUpdateWithoutPassportFrontInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutSelfieUserInput = {
  data: FileUpdateWithoutSelfieUserInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutStateIdentificationCardBackInput = {
  data: FileUpdateWithoutStateIdentificationCardBackInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutStateIdentificationCardExtractInput = {
  data: FileUpdateWithoutStateIdentificationCardExtractInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutStateIdentificationCardFrontInput = {
  data: FileUpdateWithoutStateIdentificationCardFrontInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutStateIdentificationCardSelfieInput = {
  data: FileUpdateWithoutStateIdentificationCardSelfieInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateWithWhereUniqueWithoutBeamInputInput = {
  data: FileUpdateWithoutBeamInputInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithWhereUniqueWithoutOrderInput = {
  data: FileUpdateWithoutOrderInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithWhereUniqueWithoutResultInput = {
  data: FileUpdateWithoutResultInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithoutBackIdUserInput = {
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutBeamInputInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutDisclosureAcceptanceInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutFrontIdUserInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutOrderInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutOrganizationInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutPassportBackInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutPassportCardSelfieInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutPassportExtractPhotoInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutPassportFrontInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutResultInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutSelfieUserInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutStateIdentificationCardBackInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutStateIdentificationCardExtractInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutStateIdentificationCardFrontInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutStateIdentificationCardSelfieInput = {
  backIdUser?: InputMaybe<UserUpdateOneWithoutBackIdNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateOneWithoutFilesNestedInput>;
  bucket?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceUpdateOneWithoutPdfNestedInput>;
  frontIdUser?: InputMaybe<UserUpdateOneWithoutFrontIdNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutFilesNestedInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLogoNestedInput>;
  passportBack?: InputMaybe<PassportUpdateOneWithoutBackImageNestedInput>;
  passportCardSelfie?: InputMaybe<PassportUpdateOneWithoutSelfieImageNestedInput>;
  passportExtractPhoto?: InputMaybe<PassportUpdateOneWithoutExtractedPhotoNestedInput>;
  passportFront?: InputMaybe<PassportUpdateOneWithoutFrontImageNestedInput>;
  purpose?: InputMaybe<NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput>;
  region?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  result?: InputMaybe<ResultUpdateOneWithoutAttachmentsNestedInput>;
  selfieUser?: InputMaybe<UserUpdateOneWithoutSelfieNestedInput>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardUpdateOneWithoutBackImageNestedInput>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardUpdateOneWithoutFrontImageNestedInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUpsertWithWhereUniqueWithoutBeamInputInput = {
  create: FileCreateWithoutBeamInputInput;
  update: FileUpdateWithoutBeamInputInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithWhereUniqueWithoutOrderInput = {
  create: FileCreateWithoutOrderInput;
  update: FileUpdateWithoutOrderInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithWhereUniqueWithoutResultInput = {
  create: FileCreateWithoutResultInput;
  update: FileUpdateWithoutResultInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithoutBackIdUserInput = {
  create: FileCreateWithoutBackIdUserInput;
  update: FileUpdateWithoutBackIdUserInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutDisclosureAcceptanceInput = {
  create: FileCreateWithoutDisclosureAcceptanceInput;
  update: FileUpdateWithoutDisclosureAcceptanceInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutFrontIdUserInput = {
  create: FileCreateWithoutFrontIdUserInput;
  update: FileUpdateWithoutFrontIdUserInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutOrganizationInput = {
  create: FileCreateWithoutOrganizationInput;
  update: FileUpdateWithoutOrganizationInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutPassportBackInput = {
  create: FileCreateWithoutPassportBackInput;
  update: FileUpdateWithoutPassportBackInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutPassportCardSelfieInput = {
  create: FileCreateWithoutPassportCardSelfieInput;
  update: FileUpdateWithoutPassportCardSelfieInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutPassportExtractPhotoInput = {
  create: FileCreateWithoutPassportExtractPhotoInput;
  update: FileUpdateWithoutPassportExtractPhotoInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutPassportFrontInput = {
  create: FileCreateWithoutPassportFrontInput;
  update: FileUpdateWithoutPassportFrontInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutSelfieUserInput = {
  create: FileCreateWithoutSelfieUserInput;
  update: FileUpdateWithoutSelfieUserInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutStateIdentificationCardBackInput = {
  create: FileCreateWithoutStateIdentificationCardBackInput;
  update: FileUpdateWithoutStateIdentificationCardBackInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutStateIdentificationCardExtractInput = {
  create: FileCreateWithoutStateIdentificationCardExtractInput;
  update: FileUpdateWithoutStateIdentificationCardExtractInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutStateIdentificationCardFrontInput = {
  create: FileCreateWithoutStateIdentificationCardFrontInput;
  update: FileUpdateWithoutStateIdentificationCardFrontInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutStateIdentificationCardSelfieInput = {
  create: FileCreateWithoutStateIdentificationCardSelfieInput;
  update: FileUpdateWithoutStateIdentificationCardSelfieInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileWhereInput = {
  AND?: InputMaybe<Array<FileWhereInput>>;
  NOT?: InputMaybe<Array<FileWhereInput>>;
  OR?: InputMaybe<Array<FileWhereInput>>;
  backIdUser?: InputMaybe<UserNullableRelationFilter>;
  backIdUserId?: InputMaybe<StringNullableFilter>;
  beamInput?: InputMaybe<BeamInputNullableRelationFilter>;
  beamInputId?: InputMaybe<StringNullableFilter>;
  bucket?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceNullableRelationFilter>;
  disclosureAcceptanceId?: InputMaybe<StringNullableFilter>;
  frontIdUser?: InputMaybe<UserNullableRelationFilter>;
  frontIdUserId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolNullableFilter>;
  key?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  passportBack?: InputMaybe<PassportNullableRelationFilter>;
  passportBackId?: InputMaybe<StringNullableFilter>;
  passportCardSelfie?: InputMaybe<PassportNullableRelationFilter>;
  passportCardSelfieId?: InputMaybe<StringNullableFilter>;
  passportExtractPhoto?: InputMaybe<PassportNullableRelationFilter>;
  passportExtractPhotoId?: InputMaybe<StringNullableFilter>;
  passportFront?: InputMaybe<PassportNullableRelationFilter>;
  passportFrontId?: InputMaybe<StringNullableFilter>;
  purpose?: InputMaybe<EnumFilePurposeTypeEnumNullableFilter>;
  region?: InputMaybe<StringNullableFilter>;
  result?: InputMaybe<ResultNullableRelationFilter>;
  resultId?: InputMaybe<StringNullableFilter>;
  selfieUser?: InputMaybe<UserNullableRelationFilter>;
  selfieUserId?: InputMaybe<StringNullableFilter>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardBackId?: InputMaybe<StringNullableFilter>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardExtractId?: InputMaybe<StringNullableFilter>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardFrontId?: InputMaybe<StringNullableFilter>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardSelfieId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FileWhereUniqueInput = {
  AND?: InputMaybe<Array<FileWhereInput>>;
  NOT?: InputMaybe<Array<FileWhereInput>>;
  OR?: InputMaybe<Array<FileWhereInput>>;
  backIdUser?: InputMaybe<UserNullableRelationFilter>;
  backIdUserId?: InputMaybe<Scalars['String']>;
  beamInput?: InputMaybe<BeamInputNullableRelationFilter>;
  beamInputId?: InputMaybe<StringNullableFilter>;
  bucket?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  disclosureAcceptance?: InputMaybe<DisclosureAcceptanceNullableRelationFilter>;
  disclosureAcceptanceId?: InputMaybe<Scalars['String']>;
  frontIdUser?: InputMaybe<UserNullableRelationFilter>;
  frontIdUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<BoolNullableFilter>;
  key?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  passportBack?: InputMaybe<PassportNullableRelationFilter>;
  passportBackId?: InputMaybe<Scalars['String']>;
  passportCardSelfie?: InputMaybe<PassportNullableRelationFilter>;
  passportCardSelfieId?: InputMaybe<Scalars['String']>;
  passportExtractPhoto?: InputMaybe<PassportNullableRelationFilter>;
  passportExtractPhotoId?: InputMaybe<Scalars['String']>;
  passportFront?: InputMaybe<PassportNullableRelationFilter>;
  passportFrontId?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<EnumFilePurposeTypeEnumNullableFilter>;
  region?: InputMaybe<StringNullableFilter>;
  result?: InputMaybe<ResultNullableRelationFilter>;
  resultId?: InputMaybe<StringNullableFilter>;
  selfieUser?: InputMaybe<UserNullableRelationFilter>;
  selfieUserId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardBack?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardBackId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardExtract?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardExtractId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardFront?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardFrontId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardSelfie?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardSelfieId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type GenerateSignedDisclosurePdfResponse = {
  __typename?: 'GenerateSignedDisclosurePdfResponse';
  pdfId: Scalars['String'];
  pdfUrl: Scalars['String'];
};

export type HighlySensitiveIdentifier = {
  __typename?: 'HighlySensitiveIdentifier';
  applicantDataId?: Maybe<Scalars['String']>;
  beamInputId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  type: HighlySensitiveIdentifierType;
  userId?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type HighlySensitiveIdentifierCreateInput = {
  BeamInput?: InputMaybe<BeamInputCreateNestedOneWithoutHsiInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutSsnInput>;
  id?: InputMaybe<Scalars['String']>;
  type: HighlySensitiveIdentifierType;
  user?: InputMaybe<UserCreateNestedOneWithoutSsnInput>;
  value: Scalars['String'];
};

export type HighlySensitiveIdentifierCreateManyBeamInputInput = {
  applicantDataId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  type: HighlySensitiveIdentifierType;
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type HighlySensitiveIdentifierCreateManyBeamInputInputEnvelope = {
  data: Array<HighlySensitiveIdentifierCreateManyBeamInputInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type HighlySensitiveIdentifierCreateNestedManyWithoutBeamInputInput = {
  connect?: InputMaybe<Array<HighlySensitiveIdentifierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HighlySensitiveIdentifierCreateOrConnectWithoutBeamInputInput>>;
  create?: InputMaybe<Array<HighlySensitiveIdentifierCreateWithoutBeamInputInput>>;
  createMany?: InputMaybe<HighlySensitiveIdentifierCreateManyBeamInputInputEnvelope>;
};

export type HighlySensitiveIdentifierCreateNestedOneWithoutApplicantDataInput = {
  connect?: InputMaybe<HighlySensitiveIdentifierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HighlySensitiveIdentifierCreateOrConnectWithoutApplicantDataInput>;
  create?: InputMaybe<HighlySensitiveIdentifierCreateWithoutApplicantDataInput>;
};

export type HighlySensitiveIdentifierCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<HighlySensitiveIdentifierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HighlySensitiveIdentifierCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<HighlySensitiveIdentifierCreateWithoutUserInput>;
};

export type HighlySensitiveIdentifierCreateOrConnectWithoutApplicantDataInput = {
  create: HighlySensitiveIdentifierCreateWithoutApplicantDataInput;
  where: HighlySensitiveIdentifierWhereUniqueInput;
};

export type HighlySensitiveIdentifierCreateOrConnectWithoutBeamInputInput = {
  create: HighlySensitiveIdentifierCreateWithoutBeamInputInput;
  where: HighlySensitiveIdentifierWhereUniqueInput;
};

export type HighlySensitiveIdentifierCreateOrConnectWithoutUserInput = {
  create: HighlySensitiveIdentifierCreateWithoutUserInput;
  where: HighlySensitiveIdentifierWhereUniqueInput;
};

export type HighlySensitiveIdentifierCreateWithoutApplicantDataInput = {
  BeamInput?: InputMaybe<BeamInputCreateNestedOneWithoutHsiInput>;
  id?: InputMaybe<Scalars['String']>;
  type: HighlySensitiveIdentifierType;
  user?: InputMaybe<UserCreateNestedOneWithoutSsnInput>;
  value: Scalars['String'];
};

export type HighlySensitiveIdentifierCreateWithoutBeamInputInput = {
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutSsnInput>;
  id?: InputMaybe<Scalars['String']>;
  type: HighlySensitiveIdentifierType;
  user?: InputMaybe<UserCreateNestedOneWithoutSsnInput>;
  value: Scalars['String'];
};

export type HighlySensitiveIdentifierCreateWithoutUserInput = {
  BeamInput?: InputMaybe<BeamInputCreateNestedOneWithoutHsiInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutSsnInput>;
  id?: InputMaybe<Scalars['String']>;
  type: HighlySensitiveIdentifierType;
  value: Scalars['String'];
};

export type HighlySensitiveIdentifierListRelationFilter = {
  every?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
  none?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
  some?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
};

export type HighlySensitiveIdentifierNullableRelationFilter = {
  is?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
  isNot?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
};

export type HighlySensitiveIdentifierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type HighlySensitiveIdentifierOrderByWithRelationInput = {
  BeamInput?: InputMaybe<BeamInputOrderByWithRelationInput>;
  applicantData?: InputMaybe<ApplicantDataOrderByWithRelationInput>;
  applicantDataId?: InputMaybe<SortOrderInput>;
  beamInputId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
  value?: InputMaybe<SortOrder>;
};

export enum HighlySensitiveIdentifierScalarFieldEnum {
  ApplicantDataId = 'applicantDataId',
  BeamInputId = 'beamInputId',
  Id = 'id',
  Type = 'type',
  UserId = 'userId',
  Value = 'value'
}

export type HighlySensitiveIdentifierScalarWhereInput = {
  AND?: InputMaybe<Array<HighlySensitiveIdentifierScalarWhereInput>>;
  NOT?: InputMaybe<Array<HighlySensitiveIdentifierScalarWhereInput>>;
  OR?: InputMaybe<Array<HighlySensitiveIdentifierScalarWhereInput>>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  beamInputId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumHighlySensitiveIdentifierTypeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export enum HighlySensitiveIdentifierType {
  Passport = 'PASSPORT',
  Ssn = 'SSN'
}

export type HighlySensitiveIdentifierUpdateInput = {
  BeamInput?: InputMaybe<BeamInputUpdateOneWithoutHsiNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutSsnNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumHighlySensitiveIdentifierTypeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSsnNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HighlySensitiveIdentifierUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumHighlySensitiveIdentifierTypeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HighlySensitiveIdentifierUpdateManyWithWhereWithoutBeamInputInput = {
  data: HighlySensitiveIdentifierUpdateManyMutationInput;
  where: HighlySensitiveIdentifierScalarWhereInput;
};

export type HighlySensitiveIdentifierUpdateManyWithoutBeamInputNestedInput = {
  connect?: InputMaybe<Array<HighlySensitiveIdentifierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HighlySensitiveIdentifierCreateOrConnectWithoutBeamInputInput>>;
  create?: InputMaybe<Array<HighlySensitiveIdentifierCreateWithoutBeamInputInput>>;
  createMany?: InputMaybe<HighlySensitiveIdentifierCreateManyBeamInputInputEnvelope>;
  delete?: InputMaybe<Array<HighlySensitiveIdentifierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HighlySensitiveIdentifierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HighlySensitiveIdentifierWhereUniqueInput>>;
  set?: InputMaybe<Array<HighlySensitiveIdentifierWhereUniqueInput>>;
  update?: InputMaybe<Array<HighlySensitiveIdentifierUpdateWithWhereUniqueWithoutBeamInputInput>>;
  updateMany?: InputMaybe<Array<HighlySensitiveIdentifierUpdateManyWithWhereWithoutBeamInputInput>>;
  upsert?: InputMaybe<Array<HighlySensitiveIdentifierUpsertWithWhereUniqueWithoutBeamInputInput>>;
};

export type HighlySensitiveIdentifierUpdateOneWithoutApplicantDataNestedInput = {
  connect?: InputMaybe<HighlySensitiveIdentifierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HighlySensitiveIdentifierCreateOrConnectWithoutApplicantDataInput>;
  create?: InputMaybe<HighlySensitiveIdentifierCreateWithoutApplicantDataInput>;
  delete?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
  disconnect?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
  update?: InputMaybe<HighlySensitiveIdentifierUpdateToOneWithWhereWithoutApplicantDataInput>;
  upsert?: InputMaybe<HighlySensitiveIdentifierUpsertWithoutApplicantDataInput>;
};

export type HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<HighlySensitiveIdentifierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HighlySensitiveIdentifierCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<HighlySensitiveIdentifierCreateWithoutUserInput>;
  delete?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
  disconnect?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
  update?: InputMaybe<HighlySensitiveIdentifierUpdateToOneWithWhereWithoutUserInput>;
  upsert?: InputMaybe<HighlySensitiveIdentifierUpsertWithoutUserInput>;
};

export type HighlySensitiveIdentifierUpdateToOneWithWhereWithoutApplicantDataInput = {
  data: HighlySensitiveIdentifierUpdateWithoutApplicantDataInput;
  where?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
};

export type HighlySensitiveIdentifierUpdateToOneWithWhereWithoutUserInput = {
  data: HighlySensitiveIdentifierUpdateWithoutUserInput;
  where?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
};

export type HighlySensitiveIdentifierUpdateWithWhereUniqueWithoutBeamInputInput = {
  data: HighlySensitiveIdentifierUpdateWithoutBeamInputInput;
  where: HighlySensitiveIdentifierWhereUniqueInput;
};

export type HighlySensitiveIdentifierUpdateWithoutApplicantDataInput = {
  BeamInput?: InputMaybe<BeamInputUpdateOneWithoutHsiNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumHighlySensitiveIdentifierTypeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSsnNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HighlySensitiveIdentifierUpdateWithoutBeamInputInput = {
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutSsnNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumHighlySensitiveIdentifierTypeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSsnNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HighlySensitiveIdentifierUpdateWithoutUserInput = {
  BeamInput?: InputMaybe<BeamInputUpdateOneWithoutHsiNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutSsnNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumHighlySensitiveIdentifierTypeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HighlySensitiveIdentifierUpsertWithWhereUniqueWithoutBeamInputInput = {
  create: HighlySensitiveIdentifierCreateWithoutBeamInputInput;
  update: HighlySensitiveIdentifierUpdateWithoutBeamInputInput;
  where: HighlySensitiveIdentifierWhereUniqueInput;
};

export type HighlySensitiveIdentifierUpsertWithoutApplicantDataInput = {
  create: HighlySensitiveIdentifierCreateWithoutApplicantDataInput;
  update: HighlySensitiveIdentifierUpdateWithoutApplicantDataInput;
  where?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
};

export type HighlySensitiveIdentifierUpsertWithoutUserInput = {
  create: HighlySensitiveIdentifierCreateWithoutUserInput;
  update: HighlySensitiveIdentifierUpdateWithoutUserInput;
  where?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
};

export type HighlySensitiveIdentifierWhereInput = {
  AND?: InputMaybe<Array<HighlySensitiveIdentifierWhereInput>>;
  BeamInput?: InputMaybe<BeamInputNullableRelationFilter>;
  NOT?: InputMaybe<Array<HighlySensitiveIdentifierWhereInput>>;
  OR?: InputMaybe<Array<HighlySensitiveIdentifierWhereInput>>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  beamInputId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumHighlySensitiveIdentifierTypeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export type HighlySensitiveIdentifierWhereUniqueInput = {
  AND?: InputMaybe<Array<HighlySensitiveIdentifierWhereInput>>;
  BeamInput?: InputMaybe<BeamInputNullableRelationFilter>;
  NOT?: InputMaybe<Array<HighlySensitiveIdentifierWhereInput>>;
  OR?: InputMaybe<Array<HighlySensitiveIdentifierWhereInput>>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantDataId?: InputMaybe<Scalars['String']>;
  beamInputId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EnumHighlySensitiveIdentifierTypeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<StringFilter>;
};

export enum IdCardRotationEnum {
  Clockwise_90 = 'CLOCKWISE_90',
  CounterClockwise_90 = 'COUNTER_CLOCKWISE_90',
  UpsideDown = 'UPSIDE_DOWN',
  Zero = 'ZERO'
}

export type IdVerificationResult = {
  __typename?: 'IdVerificationResult';
  additionalPersonalIdNumberFormat?: Maybe<IdVerificationResultCheckEnum>;
  addressMatch?: Maybe<IdVerificationResultCheckEnum>;
  backCardRotation?: Maybe<IdCardRotationEnum>;
  blob: Scalars['JSON'];
  certainty: Scalars['String'];
  classEfectiveDateFormat?: Maybe<IdVerificationResultCheckEnum>;
  classExpiryDateFormat?: Maybe<IdVerificationResultCheckEnum>;
  createdAt: Scalars['DateTimeISO'];
  dataBarcodeAnomalyContent?: Maybe<IdVerificationResultCheckEnum>;
  dataBarcodeAnomalyOverall?: Maybe<IdVerificationResultCheckEnum>;
  dataBarcodeAnomalyRead?: Maybe<IdVerificationResultCheckEnum>;
  dataFormatOverall?: Maybe<IdVerificationResultCheckEnum>;
  dataIntegrity?: Maybe<IdVerificationResultCheckEnum>;
  dataLogicDateOverall?: Maybe<IdVerificationResultCheckEnum>;
  dataLogicDocumentNumber?: Maybe<IdVerificationResultCheckEnum>;
  dataLogicMRZOverall?: Maybe<IdVerificationResultCheckEnum>;
  dataLogicOverall?: Maybe<IdVerificationResultCheckEnum>;
  dataLogicPersonalIdNumber?: Maybe<IdVerificationResultCheckEnum>;
  dataMatchOverall?: Maybe<IdVerificationResultCheckEnum>;
  dataOverall?: Maybe<IdVerificationResultCheckEnum>;
  dataSuspiciousNumber?: Maybe<IdVerificationResultCheckEnum>;
  dataSuspiciousOverall?: Maybe<IdVerificationResultCheckEnum>;
  dataSuspiciousSampleString?: Maybe<IdVerificationResultCheckEnum>;
  dateOfBirthBeforeDateOfExpiryCheck?: Maybe<IdVerificationResultCheckEnum>;
  dateOfBirthBeforeDateOfIssueCheck?: Maybe<IdVerificationResultCheckEnum>;
  dateOfBirthFormat?: Maybe<IdVerificationResultCheckEnum>;
  dateOfBirthInPastCheck?: Maybe<IdVerificationResultCheckEnum>;
  dateOfBirthMatch?: Maybe<IdVerificationResultCheckEnum>;
  dateOfExpiryFormat?: Maybe<IdVerificationResultCheckEnum>;
  dateOfExpiryMatch?: Maybe<IdVerificationResultCheckEnum>;
  dateOfIssueBeforeDateOfExpiryCheck?: Maybe<IdVerificationResultCheckEnum>;
  dateOfIssueFormat?: Maybe<IdVerificationResultCheckEnum>;
  dateOfIssueInPastCheck?: Maybe<IdVerificationResultCheckEnum>;
  dateOfIssueMatch?: Maybe<IdVerificationResultCheckEnum>;
  documentAdditionalNumberFormat?: Maybe<IdVerificationResultCheckEnum>;
  documentAdditionalNumberMatch?: Maybe<IdVerificationResultCheckEnum>;
  documentExpired?: Maybe<IdVerificationResultCheckEnum>;
  documentLivenessHandPresence?: Maybe<IdVerificationResultCheckEnum>;
  documentLivenessOverall?: Maybe<IdVerificationResultCheckEnum>;
  documentLivenessPhotocopy?: Maybe<IdVerificationResultCheckEnum>;
  documentLivenessScreen?: Maybe<IdVerificationResultCheckEnum>;
  documentNumberFormat?: Maybe<IdVerificationResultCheckEnum>;
  documentNumberMatch?: Maybe<IdVerificationResultCheckEnum>;
  documentOptionalAdditionalNumberFormat?: Maybe<IdVerificationResultCheckEnum>;
  documentOptionalAdditionalNumberMatch?: Maybe<IdVerificationResultCheckEnum>;
  documentVersion?: Maybe<IdVerificationResultCheckEnum>;
  employerMatch?: Maybe<IdVerificationResultCheckEnum>;
  firstNameMatch?: Maybe<IdVerificationResultCheckEnum>;
  frontCardRotation?: Maybe<IdCardRotationEnum>;
  fullNameMatch?: Maybe<IdVerificationResultCheckEnum>;
  id: Scalars['String'];
  imageQualityBlur?: Maybe<IdVerificationResultCheckEnum>;
  imageQualityClarity?: Maybe<IdVerificationResultCheckEnum>;
  imageQualityOverall?: Maybe<IdVerificationResultCheckEnum>;
  issueAuthorityFormat?: Maybe<IdVerificationResultCheckEnum>;
  lastNameMatch?: Maybe<IdVerificationResultCheckEnum>;
  maritalStatusFormat?: Maybe<IdVerificationResultCheckEnum>;
  maritalStatusMatch?: Maybe<IdVerificationResultCheckEnum>;
  mrzCheckDigits?: Maybe<IdVerificationResultCheckEnum>;
  mrzParsed?: Maybe<IdVerificationResultCheckEnum>;
  nationalityFormat?: Maybe<IdVerificationResultCheckEnum>;
  overall?: Maybe<IdVerificationResultCheckEnum>;
  passportId?: Maybe<Scalars['String']>;
  personalIdNumberFormat?: Maybe<IdVerificationResultCheckEnum>;
  personalIdNumberMatch?: Maybe<IdVerificationResultCheckEnum>;
  placeOfBirthMatch?: Maybe<IdVerificationResultCheckEnum>;
  professionMatch?: Maybe<IdVerificationResultCheckEnum>;
  raceMatch?: Maybe<IdVerificationResultCheckEnum>;
  religionFormat?: Maybe<IdVerificationResultCheckEnum>;
  religionMatch?: Maybe<IdVerificationResultCheckEnum>;
  residentialStatusMatch?: Maybe<IdVerificationResultCheckEnum>;
  sexFormat?: Maybe<IdVerificationResultCheckEnum>;
  stateIdentificationCardId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTimeISO'];
  visualOverall?: Maybe<IdVerificationResultCheckEnum>;
  visualPhotoForgery?: Maybe<IdVerificationResultCheckEnum>;
  visualSecurityFeatures?: Maybe<IdVerificationResultCheckEnum>;
};

export enum IdVerificationResultCheckEnum {
  Fail = 'FAIL',
  Pass = 'PASS'
}

export type IdVerificationResultCreateManyPassportInput = {
  additionalPersonalIdNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  addressMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  backCardRotation?: InputMaybe<IdCardRotationEnum>;
  blob: Scalars['JSON'];
  certainty: Scalars['String'];
  classEfectiveDateFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  classExpiryDateFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dataBarcodeAnomalyContent?: InputMaybe<IdVerificationResultCheckEnum>;
  dataBarcodeAnomalyOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataBarcodeAnomalyRead?: InputMaybe<IdVerificationResultCheckEnum>;
  dataFormatOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataIntegrity?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicDateOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicDocumentNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicMRZOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicPersonalIdNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataMatchOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousSampleString?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthInPastCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfExpiryFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfExpiryMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueInPastCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentAdditionalNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentAdditionalNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentExpired?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessHandPresence?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessPhotocopy?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessScreen?: InputMaybe<IdVerificationResultCheckEnum>;
  documentNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentVersion?: InputMaybe<IdVerificationResultCheckEnum>;
  employerMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  firstNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  frontCardRotation?: InputMaybe<IdCardRotationEnum>;
  fullNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  id?: InputMaybe<Scalars['String']>;
  imageQualityBlur?: InputMaybe<IdVerificationResultCheckEnum>;
  imageQualityClarity?: InputMaybe<IdVerificationResultCheckEnum>;
  imageQualityOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  issueAuthorityFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  lastNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  maritalStatusFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  maritalStatusMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  mrzCheckDigits?: InputMaybe<IdVerificationResultCheckEnum>;
  mrzParsed?: InputMaybe<IdVerificationResultCheckEnum>;
  nationalityFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  overall?: InputMaybe<IdVerificationResultCheckEnum>;
  personalIdNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  personalIdNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  placeOfBirthMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  professionMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  raceMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  religionFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  religionMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  residentialStatusMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  sexFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  stateIdentificationCardId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  visualOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  visualPhotoForgery?: InputMaybe<IdVerificationResultCheckEnum>;
  visualSecurityFeatures?: InputMaybe<IdVerificationResultCheckEnum>;
};

export type IdVerificationResultCreateManyPassportInputEnvelope = {
  data: Array<IdVerificationResultCreateManyPassportInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IdVerificationResultCreateManyStateIdentificationCardInput = {
  additionalPersonalIdNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  addressMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  backCardRotation?: InputMaybe<IdCardRotationEnum>;
  blob: Scalars['JSON'];
  certainty: Scalars['String'];
  classEfectiveDateFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  classExpiryDateFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dataBarcodeAnomalyContent?: InputMaybe<IdVerificationResultCheckEnum>;
  dataBarcodeAnomalyOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataBarcodeAnomalyRead?: InputMaybe<IdVerificationResultCheckEnum>;
  dataFormatOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataIntegrity?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicDateOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicDocumentNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicMRZOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicPersonalIdNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataMatchOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousSampleString?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthInPastCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfExpiryFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfExpiryMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueInPastCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentAdditionalNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentAdditionalNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentExpired?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessHandPresence?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessPhotocopy?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessScreen?: InputMaybe<IdVerificationResultCheckEnum>;
  documentNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentVersion?: InputMaybe<IdVerificationResultCheckEnum>;
  employerMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  firstNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  frontCardRotation?: InputMaybe<IdCardRotationEnum>;
  fullNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  id?: InputMaybe<Scalars['String']>;
  imageQualityBlur?: InputMaybe<IdVerificationResultCheckEnum>;
  imageQualityClarity?: InputMaybe<IdVerificationResultCheckEnum>;
  imageQualityOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  issueAuthorityFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  lastNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  maritalStatusFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  maritalStatusMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  mrzCheckDigits?: InputMaybe<IdVerificationResultCheckEnum>;
  mrzParsed?: InputMaybe<IdVerificationResultCheckEnum>;
  nationalityFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  overall?: InputMaybe<IdVerificationResultCheckEnum>;
  passportId?: InputMaybe<Scalars['String']>;
  personalIdNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  personalIdNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  placeOfBirthMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  professionMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  raceMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  religionFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  religionMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  residentialStatusMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  sexFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  visualOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  visualPhotoForgery?: InputMaybe<IdVerificationResultCheckEnum>;
  visualSecurityFeatures?: InputMaybe<IdVerificationResultCheckEnum>;
};

export type IdVerificationResultCreateManyStateIdentificationCardInputEnvelope = {
  data: Array<IdVerificationResultCreateManyStateIdentificationCardInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IdVerificationResultCreateNestedManyWithoutPassportInput = {
  connect?: InputMaybe<Array<IdVerificationResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IdVerificationResultCreateOrConnectWithoutPassportInput>>;
  create?: InputMaybe<Array<IdVerificationResultCreateWithoutPassportInput>>;
  createMany?: InputMaybe<IdVerificationResultCreateManyPassportInputEnvelope>;
};

export type IdVerificationResultCreateNestedManyWithoutStateIdentificationCardInput = {
  connect?: InputMaybe<Array<IdVerificationResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IdVerificationResultCreateOrConnectWithoutStateIdentificationCardInput>>;
  create?: InputMaybe<Array<IdVerificationResultCreateWithoutStateIdentificationCardInput>>;
  createMany?: InputMaybe<IdVerificationResultCreateManyStateIdentificationCardInputEnvelope>;
};

export type IdVerificationResultCreateOrConnectWithoutPassportInput = {
  create: IdVerificationResultCreateWithoutPassportInput;
  where: IdVerificationResultWhereUniqueInput;
};

export type IdVerificationResultCreateOrConnectWithoutStateIdentificationCardInput = {
  create: IdVerificationResultCreateWithoutStateIdentificationCardInput;
  where: IdVerificationResultWhereUniqueInput;
};

export type IdVerificationResultCreateWithoutPassportInput = {
  additionalPersonalIdNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  addressMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  backCardRotation?: InputMaybe<IdCardRotationEnum>;
  blob: Scalars['JSON'];
  certainty: Scalars['String'];
  classEfectiveDateFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  classExpiryDateFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dataBarcodeAnomalyContent?: InputMaybe<IdVerificationResultCheckEnum>;
  dataBarcodeAnomalyOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataBarcodeAnomalyRead?: InputMaybe<IdVerificationResultCheckEnum>;
  dataFormatOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataIntegrity?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicDateOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicDocumentNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicMRZOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicPersonalIdNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataMatchOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousSampleString?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthInPastCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfExpiryFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfExpiryMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueInPastCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentAdditionalNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentAdditionalNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentExpired?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessHandPresence?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessPhotocopy?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessScreen?: InputMaybe<IdVerificationResultCheckEnum>;
  documentNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentVersion?: InputMaybe<IdVerificationResultCheckEnum>;
  employerMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  firstNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  frontCardRotation?: InputMaybe<IdCardRotationEnum>;
  fullNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  id?: InputMaybe<Scalars['String']>;
  imageQualityBlur?: InputMaybe<IdVerificationResultCheckEnum>;
  imageQualityClarity?: InputMaybe<IdVerificationResultCheckEnum>;
  imageQualityOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  issueAuthorityFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  lastNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  maritalStatusFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  maritalStatusMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  mrzCheckDigits?: InputMaybe<IdVerificationResultCheckEnum>;
  mrzParsed?: InputMaybe<IdVerificationResultCheckEnum>;
  nationalityFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  overall?: InputMaybe<IdVerificationResultCheckEnum>;
  personalIdNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  personalIdNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  placeOfBirthMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  professionMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  raceMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  religionFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  religionMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  residentialStatusMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  sexFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutIdVerificationResultInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  visualOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  visualPhotoForgery?: InputMaybe<IdVerificationResultCheckEnum>;
  visualSecurityFeatures?: InputMaybe<IdVerificationResultCheckEnum>;
};

export type IdVerificationResultCreateWithoutStateIdentificationCardInput = {
  additionalPersonalIdNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  addressMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  backCardRotation?: InputMaybe<IdCardRotationEnum>;
  blob: Scalars['JSON'];
  certainty: Scalars['String'];
  classEfectiveDateFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  classExpiryDateFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dataBarcodeAnomalyContent?: InputMaybe<IdVerificationResultCheckEnum>;
  dataBarcodeAnomalyOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataBarcodeAnomalyRead?: InputMaybe<IdVerificationResultCheckEnum>;
  dataFormatOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataIntegrity?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicDateOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicDocumentNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicMRZOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataLogicPersonalIdNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataMatchOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousNumber?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  dataSuspiciousSampleString?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthInPastCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfBirthMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfExpiryFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfExpiryMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueInPastCheck?: InputMaybe<IdVerificationResultCheckEnum>;
  dateOfIssueMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentAdditionalNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentAdditionalNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentExpired?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessHandPresence?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessPhotocopy?: InputMaybe<IdVerificationResultCheckEnum>;
  documentLivenessScreen?: InputMaybe<IdVerificationResultCheckEnum>;
  documentNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  documentVersion?: InputMaybe<IdVerificationResultCheckEnum>;
  employerMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  firstNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  frontCardRotation?: InputMaybe<IdCardRotationEnum>;
  fullNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  id?: InputMaybe<Scalars['String']>;
  imageQualityBlur?: InputMaybe<IdVerificationResultCheckEnum>;
  imageQualityClarity?: InputMaybe<IdVerificationResultCheckEnum>;
  imageQualityOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  issueAuthorityFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  lastNameMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  maritalStatusFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  maritalStatusMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  mrzCheckDigits?: InputMaybe<IdVerificationResultCheckEnum>;
  mrzParsed?: InputMaybe<IdVerificationResultCheckEnum>;
  nationalityFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  overall?: InputMaybe<IdVerificationResultCheckEnum>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutIdVerificationResultInput>;
  personalIdNumberFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  personalIdNumberMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  placeOfBirthMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  professionMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  raceMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  religionFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  religionMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  residentialStatusMatch?: InputMaybe<IdVerificationResultCheckEnum>;
  sexFormat?: InputMaybe<IdVerificationResultCheckEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  visualOverall?: InputMaybe<IdVerificationResultCheckEnum>;
  visualPhotoForgery?: InputMaybe<IdVerificationResultCheckEnum>;
  visualSecurityFeatures?: InputMaybe<IdVerificationResultCheckEnum>;
};

export type IdVerificationResultListRelationFilter = {
  every?: InputMaybe<IdVerificationResultWhereInput>;
  none?: InputMaybe<IdVerificationResultWhereInput>;
  some?: InputMaybe<IdVerificationResultWhereInput>;
};

export type IdVerificationResultOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type IdVerificationResultOrderByWithRelationInput = {
  additionalPersonalIdNumberFormat?: InputMaybe<SortOrderInput>;
  addressMatch?: InputMaybe<SortOrderInput>;
  backCardRotation?: InputMaybe<SortOrderInput>;
  blob?: InputMaybe<SortOrder>;
  certainty?: InputMaybe<SortOrder>;
  classEfectiveDateFormat?: InputMaybe<SortOrderInput>;
  classExpiryDateFormat?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  dataBarcodeAnomalyContent?: InputMaybe<SortOrderInput>;
  dataBarcodeAnomalyOverall?: InputMaybe<SortOrderInput>;
  dataBarcodeAnomalyRead?: InputMaybe<SortOrderInput>;
  dataFormatOverall?: InputMaybe<SortOrderInput>;
  dataIntegrity?: InputMaybe<SortOrderInput>;
  dataLogicDateOverall?: InputMaybe<SortOrderInput>;
  dataLogicDocumentNumber?: InputMaybe<SortOrderInput>;
  dataLogicMRZOverall?: InputMaybe<SortOrderInput>;
  dataLogicOverall?: InputMaybe<SortOrderInput>;
  dataLogicPersonalIdNumber?: InputMaybe<SortOrderInput>;
  dataMatchOverall?: InputMaybe<SortOrderInput>;
  dataOverall?: InputMaybe<SortOrderInput>;
  dataSuspiciousNumber?: InputMaybe<SortOrderInput>;
  dataSuspiciousOverall?: InputMaybe<SortOrderInput>;
  dataSuspiciousSampleString?: InputMaybe<SortOrderInput>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<SortOrderInput>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<SortOrderInput>;
  dateOfBirthFormat?: InputMaybe<SortOrderInput>;
  dateOfBirthInPastCheck?: InputMaybe<SortOrderInput>;
  dateOfBirthMatch?: InputMaybe<SortOrderInput>;
  dateOfExpiryFormat?: InputMaybe<SortOrderInput>;
  dateOfExpiryMatch?: InputMaybe<SortOrderInput>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<SortOrderInput>;
  dateOfIssueFormat?: InputMaybe<SortOrderInput>;
  dateOfIssueInPastCheck?: InputMaybe<SortOrderInput>;
  dateOfIssueMatch?: InputMaybe<SortOrderInput>;
  documentAdditionalNumberFormat?: InputMaybe<SortOrderInput>;
  documentAdditionalNumberMatch?: InputMaybe<SortOrderInput>;
  documentExpired?: InputMaybe<SortOrderInput>;
  documentLivenessHandPresence?: InputMaybe<SortOrderInput>;
  documentLivenessOverall?: InputMaybe<SortOrderInput>;
  documentLivenessPhotocopy?: InputMaybe<SortOrderInput>;
  documentLivenessScreen?: InputMaybe<SortOrderInput>;
  documentNumberFormat?: InputMaybe<SortOrderInput>;
  documentNumberMatch?: InputMaybe<SortOrderInput>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<SortOrderInput>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<SortOrderInput>;
  documentVersion?: InputMaybe<SortOrderInput>;
  employerMatch?: InputMaybe<SortOrderInput>;
  firstNameMatch?: InputMaybe<SortOrderInput>;
  frontCardRotation?: InputMaybe<SortOrderInput>;
  fullNameMatch?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  imageQualityBlur?: InputMaybe<SortOrderInput>;
  imageQualityClarity?: InputMaybe<SortOrderInput>;
  imageQualityOverall?: InputMaybe<SortOrderInput>;
  issueAuthorityFormat?: InputMaybe<SortOrderInput>;
  lastNameMatch?: InputMaybe<SortOrderInput>;
  maritalStatusFormat?: InputMaybe<SortOrderInput>;
  maritalStatusMatch?: InputMaybe<SortOrderInput>;
  mrzCheckDigits?: InputMaybe<SortOrderInput>;
  mrzParsed?: InputMaybe<SortOrderInput>;
  nationalityFormat?: InputMaybe<SortOrderInput>;
  overall?: InputMaybe<SortOrderInput>;
  passport?: InputMaybe<PassportOrderByWithRelationInput>;
  passportId?: InputMaybe<SortOrderInput>;
  personalIdNumberFormat?: InputMaybe<SortOrderInput>;
  personalIdNumberMatch?: InputMaybe<SortOrderInput>;
  placeOfBirthMatch?: InputMaybe<SortOrderInput>;
  professionMatch?: InputMaybe<SortOrderInput>;
  raceMatch?: InputMaybe<SortOrderInput>;
  religionFormat?: InputMaybe<SortOrderInput>;
  religionMatch?: InputMaybe<SortOrderInput>;
  residentialStatusMatch?: InputMaybe<SortOrderInput>;
  sexFormat?: InputMaybe<SortOrderInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardOrderByWithRelationInput>;
  stateIdentificationCardId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  visualOverall?: InputMaybe<SortOrderInput>;
  visualPhotoForgery?: InputMaybe<SortOrderInput>;
  visualSecurityFeatures?: InputMaybe<SortOrderInput>;
};

export enum IdVerificationResultScalarFieldEnum {
  AdditionalPersonalIdNumberFormat = 'additionalPersonalIdNumberFormat',
  AddressMatch = 'addressMatch',
  BackCardRotation = 'backCardRotation',
  Blob = 'blob',
  Certainty = 'certainty',
  ClassEfectiveDateFormat = 'classEfectiveDateFormat',
  ClassExpiryDateFormat = 'classExpiryDateFormat',
  CreatedAt = 'createdAt',
  DataBarcodeAnomalyContent = 'dataBarcodeAnomalyContent',
  DataBarcodeAnomalyOverall = 'dataBarcodeAnomalyOverall',
  DataBarcodeAnomalyRead = 'dataBarcodeAnomalyRead',
  DataFormatOverall = 'dataFormatOverall',
  DataIntegrity = 'dataIntegrity',
  DataLogicDateOverall = 'dataLogicDateOverall',
  DataLogicDocumentNumber = 'dataLogicDocumentNumber',
  DataLogicMrzOverall = 'dataLogicMRZOverall',
  DataLogicOverall = 'dataLogicOverall',
  DataLogicPersonalIdNumber = 'dataLogicPersonalIdNumber',
  DataMatchOverall = 'dataMatchOverall',
  DataOverall = 'dataOverall',
  DataSuspiciousNumber = 'dataSuspiciousNumber',
  DataSuspiciousOverall = 'dataSuspiciousOverall',
  DataSuspiciousSampleString = 'dataSuspiciousSampleString',
  DateOfBirthBeforeDateOfExpiryCheck = 'dateOfBirthBeforeDateOfExpiryCheck',
  DateOfBirthBeforeDateOfIssueCheck = 'dateOfBirthBeforeDateOfIssueCheck',
  DateOfBirthFormat = 'dateOfBirthFormat',
  DateOfBirthInPastCheck = 'dateOfBirthInPastCheck',
  DateOfBirthMatch = 'dateOfBirthMatch',
  DateOfExpiryFormat = 'dateOfExpiryFormat',
  DateOfExpiryMatch = 'dateOfExpiryMatch',
  DateOfIssueBeforeDateOfExpiryCheck = 'dateOfIssueBeforeDateOfExpiryCheck',
  DateOfIssueFormat = 'dateOfIssueFormat',
  DateOfIssueInPastCheck = 'dateOfIssueInPastCheck',
  DateOfIssueMatch = 'dateOfIssueMatch',
  DocumentAdditionalNumberFormat = 'documentAdditionalNumberFormat',
  DocumentAdditionalNumberMatch = 'documentAdditionalNumberMatch',
  DocumentExpired = 'documentExpired',
  DocumentLivenessHandPresence = 'documentLivenessHandPresence',
  DocumentLivenessOverall = 'documentLivenessOverall',
  DocumentLivenessPhotocopy = 'documentLivenessPhotocopy',
  DocumentLivenessScreen = 'documentLivenessScreen',
  DocumentNumberFormat = 'documentNumberFormat',
  DocumentNumberMatch = 'documentNumberMatch',
  DocumentOptionalAdditionalNumberFormat = 'documentOptionalAdditionalNumberFormat',
  DocumentOptionalAdditionalNumberMatch = 'documentOptionalAdditionalNumberMatch',
  DocumentVersion = 'documentVersion',
  EmployerMatch = 'employerMatch',
  FirstNameMatch = 'firstNameMatch',
  FrontCardRotation = 'frontCardRotation',
  FullNameMatch = 'fullNameMatch',
  Id = 'id',
  ImageQualityBlur = 'imageQualityBlur',
  ImageQualityClarity = 'imageQualityClarity',
  ImageQualityOverall = 'imageQualityOverall',
  IssueAuthorityFormat = 'issueAuthorityFormat',
  LastNameMatch = 'lastNameMatch',
  MaritalStatusFormat = 'maritalStatusFormat',
  MaritalStatusMatch = 'maritalStatusMatch',
  MrzCheckDigits = 'mrzCheckDigits',
  MrzParsed = 'mrzParsed',
  NationalityFormat = 'nationalityFormat',
  Overall = 'overall',
  PassportId = 'passportId',
  PersonalIdNumberFormat = 'personalIdNumberFormat',
  PersonalIdNumberMatch = 'personalIdNumberMatch',
  PlaceOfBirthMatch = 'placeOfBirthMatch',
  ProfessionMatch = 'professionMatch',
  RaceMatch = 'raceMatch',
  ReligionFormat = 'religionFormat',
  ReligionMatch = 'religionMatch',
  ResidentialStatusMatch = 'residentialStatusMatch',
  SexFormat = 'sexFormat',
  StateIdentificationCardId = 'stateIdentificationCardId',
  UpdatedAt = 'updatedAt',
  VisualOverall = 'visualOverall',
  VisualPhotoForgery = 'visualPhotoForgery',
  VisualSecurityFeatures = 'visualSecurityFeatures'
}

export type IdVerificationResultScalarWhereInput = {
  AND?: InputMaybe<Array<IdVerificationResultScalarWhereInput>>;
  NOT?: InputMaybe<Array<IdVerificationResultScalarWhereInput>>;
  OR?: InputMaybe<Array<IdVerificationResultScalarWhereInput>>;
  additionalPersonalIdNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  addressMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  backCardRotation?: InputMaybe<EnumIdCardRotationEnumNullableFilter>;
  blob?: InputMaybe<JsonFilter>;
  certainty?: InputMaybe<StringFilter>;
  classEfectiveDateFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  classExpiryDateFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataBarcodeAnomalyContent?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataBarcodeAnomalyOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataBarcodeAnomalyRead?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataFormatOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataIntegrity?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicDateOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicDocumentNumber?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicMRZOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicPersonalIdNumber?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataMatchOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataSuspiciousNumber?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataSuspiciousOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataSuspiciousSampleString?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthInPastCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfExpiryFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfExpiryMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueInPastCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentAdditionalNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentAdditionalNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentExpired?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessHandPresence?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessPhotocopy?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessScreen?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentVersion?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  employerMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  firstNameMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  frontCardRotation?: InputMaybe<EnumIdCardRotationEnumNullableFilter>;
  fullNameMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  id?: InputMaybe<StringFilter>;
  imageQualityBlur?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  imageQualityClarity?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  imageQualityOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  issueAuthorityFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  lastNameMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  maritalStatusFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  maritalStatusMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  mrzCheckDigits?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  mrzParsed?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  nationalityFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  overall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  passportId?: InputMaybe<StringNullableFilter>;
  personalIdNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  personalIdNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  placeOfBirthMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  professionMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  raceMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  religionFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  religionMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  residentialStatusMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  sexFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  stateIdentificationCardId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  visualOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  visualPhotoForgery?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  visualSecurityFeatures?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
};

export type IdVerificationResultUpdateManyMutationInput = {
  additionalPersonalIdNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  addressMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  backCardRotation?: InputMaybe<NullableEnumIdCardRotationEnumFieldUpdateOperationsInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  certainty?: InputMaybe<StringFieldUpdateOperationsInput>;
  classEfectiveDateFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  classExpiryDateFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataBarcodeAnomalyContent?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataBarcodeAnomalyOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataBarcodeAnomalyRead?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataFormatOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataIntegrity?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicDateOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicDocumentNumber?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicMRZOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicPersonalIdNumber?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataMatchOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataSuspiciousNumber?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataSuspiciousOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataSuspiciousSampleString?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthInPastCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfExpiryFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfExpiryMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueInPastCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentAdditionalNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentAdditionalNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentExpired?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessHandPresence?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessPhotocopy?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessScreen?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentVersion?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  employerMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  firstNameMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  frontCardRotation?: InputMaybe<NullableEnumIdCardRotationEnumFieldUpdateOperationsInput>;
  fullNameMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageQualityBlur?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  imageQualityClarity?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  imageQualityOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  issueAuthorityFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  lastNameMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  maritalStatusFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  maritalStatusMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  mrzCheckDigits?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  mrzParsed?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  nationalityFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  overall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  personalIdNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  personalIdNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  placeOfBirthMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  professionMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  raceMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  religionFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  religionMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  residentialStatusMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  sexFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visualOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  visualPhotoForgery?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  visualSecurityFeatures?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
};

export type IdVerificationResultUpdateManyWithWhereWithoutPassportInput = {
  data: IdVerificationResultUpdateManyMutationInput;
  where: IdVerificationResultScalarWhereInput;
};

export type IdVerificationResultUpdateManyWithWhereWithoutStateIdentificationCardInput = {
  data: IdVerificationResultUpdateManyMutationInput;
  where: IdVerificationResultScalarWhereInput;
};

export type IdVerificationResultUpdateManyWithoutPassportNestedInput = {
  connect?: InputMaybe<Array<IdVerificationResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IdVerificationResultCreateOrConnectWithoutPassportInput>>;
  create?: InputMaybe<Array<IdVerificationResultCreateWithoutPassportInput>>;
  createMany?: InputMaybe<IdVerificationResultCreateManyPassportInputEnvelope>;
  delete?: InputMaybe<Array<IdVerificationResultWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IdVerificationResultScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IdVerificationResultWhereUniqueInput>>;
  set?: InputMaybe<Array<IdVerificationResultWhereUniqueInput>>;
  update?: InputMaybe<Array<IdVerificationResultUpdateWithWhereUniqueWithoutPassportInput>>;
  updateMany?: InputMaybe<Array<IdVerificationResultUpdateManyWithWhereWithoutPassportInput>>;
  upsert?: InputMaybe<Array<IdVerificationResultUpsertWithWhereUniqueWithoutPassportInput>>;
};

export type IdVerificationResultUpdateManyWithoutStateIdentificationCardNestedInput = {
  connect?: InputMaybe<Array<IdVerificationResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IdVerificationResultCreateOrConnectWithoutStateIdentificationCardInput>>;
  create?: InputMaybe<Array<IdVerificationResultCreateWithoutStateIdentificationCardInput>>;
  createMany?: InputMaybe<IdVerificationResultCreateManyStateIdentificationCardInputEnvelope>;
  delete?: InputMaybe<Array<IdVerificationResultWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IdVerificationResultScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IdVerificationResultWhereUniqueInput>>;
  set?: InputMaybe<Array<IdVerificationResultWhereUniqueInput>>;
  update?: InputMaybe<Array<IdVerificationResultUpdateWithWhereUniqueWithoutStateIdentificationCardInput>>;
  updateMany?: InputMaybe<Array<IdVerificationResultUpdateManyWithWhereWithoutStateIdentificationCardInput>>;
  upsert?: InputMaybe<Array<IdVerificationResultUpsertWithWhereUniqueWithoutStateIdentificationCardInput>>;
};

export type IdVerificationResultUpdateWithWhereUniqueWithoutPassportInput = {
  data: IdVerificationResultUpdateWithoutPassportInput;
  where: IdVerificationResultWhereUniqueInput;
};

export type IdVerificationResultUpdateWithWhereUniqueWithoutStateIdentificationCardInput = {
  data: IdVerificationResultUpdateWithoutStateIdentificationCardInput;
  where: IdVerificationResultWhereUniqueInput;
};

export type IdVerificationResultUpdateWithoutPassportInput = {
  additionalPersonalIdNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  addressMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  backCardRotation?: InputMaybe<NullableEnumIdCardRotationEnumFieldUpdateOperationsInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  certainty?: InputMaybe<StringFieldUpdateOperationsInput>;
  classEfectiveDateFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  classExpiryDateFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataBarcodeAnomalyContent?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataBarcodeAnomalyOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataBarcodeAnomalyRead?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataFormatOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataIntegrity?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicDateOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicDocumentNumber?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicMRZOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicPersonalIdNumber?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataMatchOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataSuspiciousNumber?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataSuspiciousOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataSuspiciousSampleString?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthInPastCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfExpiryFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfExpiryMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueInPastCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentAdditionalNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentAdditionalNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentExpired?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessHandPresence?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessPhotocopy?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessScreen?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentVersion?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  employerMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  firstNameMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  frontCardRotation?: InputMaybe<NullableEnumIdCardRotationEnumFieldUpdateOperationsInput>;
  fullNameMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageQualityBlur?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  imageQualityClarity?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  imageQualityOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  issueAuthorityFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  lastNameMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  maritalStatusFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  maritalStatusMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  mrzCheckDigits?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  mrzParsed?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  nationalityFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  overall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  personalIdNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  personalIdNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  placeOfBirthMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  professionMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  raceMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  religionFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  religionMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  residentialStatusMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  sexFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutIdVerificationResultNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visualOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  visualPhotoForgery?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  visualSecurityFeatures?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
};

export type IdVerificationResultUpdateWithoutStateIdentificationCardInput = {
  additionalPersonalIdNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  addressMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  backCardRotation?: InputMaybe<NullableEnumIdCardRotationEnumFieldUpdateOperationsInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  certainty?: InputMaybe<StringFieldUpdateOperationsInput>;
  classEfectiveDateFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  classExpiryDateFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataBarcodeAnomalyContent?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataBarcodeAnomalyOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataBarcodeAnomalyRead?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataFormatOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataIntegrity?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicDateOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicDocumentNumber?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicMRZOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataLogicPersonalIdNumber?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataMatchOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataSuspiciousNumber?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataSuspiciousOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dataSuspiciousSampleString?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthInPastCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfBirthMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfExpiryFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfExpiryMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueInPastCheck?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  dateOfIssueMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentAdditionalNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentAdditionalNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentExpired?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessHandPresence?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessPhotocopy?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentLivenessScreen?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  documentVersion?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  employerMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  firstNameMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  frontCardRotation?: InputMaybe<NullableEnumIdCardRotationEnumFieldUpdateOperationsInput>;
  fullNameMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageQualityBlur?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  imageQualityClarity?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  imageQualityOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  issueAuthorityFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  lastNameMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  maritalStatusFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  maritalStatusMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  mrzCheckDigits?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  mrzParsed?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  nationalityFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  overall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutIdVerificationResultNestedInput>;
  personalIdNumberFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  personalIdNumberMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  placeOfBirthMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  professionMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  raceMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  religionFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  religionMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  residentialStatusMatch?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  sexFormat?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visualOverall?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  visualPhotoForgery?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
  visualSecurityFeatures?: InputMaybe<NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput>;
};

export type IdVerificationResultUpsertWithWhereUniqueWithoutPassportInput = {
  create: IdVerificationResultCreateWithoutPassportInput;
  update: IdVerificationResultUpdateWithoutPassportInput;
  where: IdVerificationResultWhereUniqueInput;
};

export type IdVerificationResultUpsertWithWhereUniqueWithoutStateIdentificationCardInput = {
  create: IdVerificationResultCreateWithoutStateIdentificationCardInput;
  update: IdVerificationResultUpdateWithoutStateIdentificationCardInput;
  where: IdVerificationResultWhereUniqueInput;
};

export type IdVerificationResultWhereInput = {
  AND?: InputMaybe<Array<IdVerificationResultWhereInput>>;
  NOT?: InputMaybe<Array<IdVerificationResultWhereInput>>;
  OR?: InputMaybe<Array<IdVerificationResultWhereInput>>;
  additionalPersonalIdNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  addressMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  backCardRotation?: InputMaybe<EnumIdCardRotationEnumNullableFilter>;
  blob?: InputMaybe<JsonFilter>;
  certainty?: InputMaybe<StringFilter>;
  classEfectiveDateFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  classExpiryDateFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataBarcodeAnomalyContent?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataBarcodeAnomalyOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataBarcodeAnomalyRead?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataFormatOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataIntegrity?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicDateOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicDocumentNumber?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicMRZOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicPersonalIdNumber?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataMatchOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataSuspiciousNumber?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataSuspiciousOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataSuspiciousSampleString?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthInPastCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfExpiryFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfExpiryMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueInPastCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentAdditionalNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentAdditionalNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentExpired?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessHandPresence?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessPhotocopy?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessScreen?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentVersion?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  employerMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  firstNameMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  frontCardRotation?: InputMaybe<EnumIdCardRotationEnumNullableFilter>;
  fullNameMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  id?: InputMaybe<StringFilter>;
  imageQualityBlur?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  imageQualityClarity?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  imageQualityOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  issueAuthorityFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  lastNameMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  maritalStatusFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  maritalStatusMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  mrzCheckDigits?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  mrzParsed?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  nationalityFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  overall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  passport?: InputMaybe<PassportNullableRelationFilter>;
  passportId?: InputMaybe<StringNullableFilter>;
  personalIdNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  personalIdNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  placeOfBirthMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  professionMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  raceMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  religionFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  religionMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  residentialStatusMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  sexFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  visualOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  visualPhotoForgery?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  visualSecurityFeatures?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
};

export type IdVerificationResultWhereUniqueInput = {
  AND?: InputMaybe<Array<IdVerificationResultWhereInput>>;
  NOT?: InputMaybe<Array<IdVerificationResultWhereInput>>;
  OR?: InputMaybe<Array<IdVerificationResultWhereInput>>;
  additionalPersonalIdNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  addressMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  backCardRotation?: InputMaybe<EnumIdCardRotationEnumNullableFilter>;
  blob?: InputMaybe<JsonFilter>;
  certainty?: InputMaybe<StringFilter>;
  classEfectiveDateFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  classExpiryDateFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataBarcodeAnomalyContent?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataBarcodeAnomalyOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataBarcodeAnomalyRead?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataFormatOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataIntegrity?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicDateOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicDocumentNumber?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicMRZOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataLogicPersonalIdNumber?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataMatchOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataSuspiciousNumber?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataSuspiciousOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dataSuspiciousSampleString?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthBeforeDateOfExpiryCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthBeforeDateOfIssueCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthInPastCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfBirthMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfExpiryFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfExpiryMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueBeforeDateOfExpiryCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueInPastCheck?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  dateOfIssueMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentAdditionalNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentAdditionalNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentExpired?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessHandPresence?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessPhotocopy?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentLivenessScreen?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentOptionalAdditionalNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentOptionalAdditionalNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  documentVersion?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  employerMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  firstNameMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  frontCardRotation?: InputMaybe<EnumIdCardRotationEnumNullableFilter>;
  fullNameMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  imageQualityBlur?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  imageQualityClarity?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  imageQualityOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  issueAuthorityFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  lastNameMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  maritalStatusFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  maritalStatusMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  mrzCheckDigits?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  mrzParsed?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  nationalityFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  overall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  passport?: InputMaybe<PassportNullableRelationFilter>;
  passportId?: InputMaybe<StringNullableFilter>;
  personalIdNumberFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  personalIdNumberMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  placeOfBirthMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  professionMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  raceMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  religionFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  religionMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  residentialStatusMatch?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  sexFormat?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  visualOverall?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  visualPhotoForgery?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
  visualSecurityFeatures?: InputMaybe<EnumIdVerificationResultCheckEnumNullableFilter>;
};

export type Identifier = {
  __typename?: 'Identifier';
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  isPrimary: Scalars['Boolean'];
  type: IdentifierType;
  updatedAt: Scalars['DateTimeISO'];
  userId?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type IdentifierCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  type: IdentifierType;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  value: Scalars['String'];
};

export type IdentifierCreateManyUserInputEnvelope = {
  data: Array<IdentifierCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type IdentifierCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<IdentifierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IdentifierCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<IdentifierCreateWithoutUserInput>>;
  createMany?: InputMaybe<IdentifierCreateManyUserInputEnvelope>;
};

export type IdentifierCreateOrConnectWithoutUserInput = {
  create: IdentifierCreateWithoutUserInput;
  where: IdentifierWhereUniqueInput;
};

export type IdentifierCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  type: IdentifierType;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  value: Scalars['String'];
};

export type IdentifierListRelationFilter = {
  every?: InputMaybe<IdentifierWhereInput>;
  none?: InputMaybe<IdentifierWhereInput>;
  some?: InputMaybe<IdentifierWhereInput>;
};

export type IdentifierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type IdentifierOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPrimary?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
  value?: InputMaybe<SortOrder>;
};

export enum IdentifierScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsPrimary = 'isPrimary',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  Value = 'value'
}

export type IdentifierScalarWhereInput = {
  AND?: InputMaybe<Array<IdentifierScalarWhereInput>>;
  NOT?: InputMaybe<Array<IdentifierScalarWhereInput>>;
  OR?: InputMaybe<Array<IdentifierScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BoolFilter>;
  type?: InputMaybe<EnumIdentifierTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export enum IdentifierType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type IdentifierUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPrimary?: InputMaybe<BoolFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumIdentifierTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type IdentifierUpdateManyWithWhereWithoutUserInput = {
  data: IdentifierUpdateManyMutationInput;
  where: IdentifierScalarWhereInput;
};

export type IdentifierUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<IdentifierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IdentifierCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<IdentifierCreateWithoutUserInput>>;
  createMany?: InputMaybe<IdentifierCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<IdentifierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IdentifierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IdentifierWhereUniqueInput>>;
  set?: InputMaybe<Array<IdentifierWhereUniqueInput>>;
  update?: InputMaybe<Array<IdentifierUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<IdentifierUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<IdentifierUpsertWithWhereUniqueWithoutUserInput>>;
};

export type IdentifierUpdateWithWhereUniqueWithoutUserInput = {
  data: IdentifierUpdateWithoutUserInput;
  where: IdentifierWhereUniqueInput;
};

export type IdentifierUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPrimary?: InputMaybe<BoolFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumIdentifierTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type IdentifierUpsertWithWhereUniqueWithoutUserInput = {
  create: IdentifierCreateWithoutUserInput;
  update: IdentifierUpdateWithoutUserInput;
  where: IdentifierWhereUniqueInput;
};

export type IdentifierWhereInput = {
  AND?: InputMaybe<Array<IdentifierWhereInput>>;
  NOT?: InputMaybe<Array<IdentifierWhereInput>>;
  OR?: InputMaybe<Array<IdentifierWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BoolFilter>;
  type?: InputMaybe<EnumIdentifierTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export type IdentifierWhereUniqueInput = {
  AND?: InputMaybe<Array<IdentifierWhereInput>>;
  NOT?: InputMaybe<Array<IdentifierWhereInput>>;
  OR?: InputMaybe<Array<IdentifierWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  isPrimary?: InputMaybe<BoolFilter>;
  type?: InputMaybe<EnumIdentifierTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Invite = {
  __typename?: 'Invite';
  createdAt: Scalars['DateTimeISO'];
  email: Scalars['String'];
  id: Scalars['String'];
  organizationId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  redeemedAt?: Maybe<Scalars['DateTimeISO']>;
  redeemedById?: Maybe<Scalars['String']>;
  senderId: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  vidVoucherId?: Maybe<Scalars['String']>;
};

export type InviteCreateManyOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  redeemedAt?: InputMaybe<Scalars['DateTimeISO']>;
  redeemedById?: InputMaybe<Scalars['String']>;
  senderId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vidVoucherId?: InputMaybe<Scalars['String']>;
};

export type InviteCreateManyOrganizationInputEnvelope = {
  data: Array<InviteCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InviteCreateManyRedeemedByInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  redeemedAt?: InputMaybe<Scalars['DateTimeISO']>;
  senderId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vidVoucherId?: InputMaybe<Scalars['String']>;
};

export type InviteCreateManyRedeemedByInputEnvelope = {
  data: Array<InviteCreateManyRedeemedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InviteCreateManySenderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  redeemedAt?: InputMaybe<Scalars['DateTimeISO']>;
  redeemedById?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vidVoucherId?: InputMaybe<Scalars['String']>;
};

export type InviteCreateManySenderInputEnvelope = {
  data: Array<InviteCreateManySenderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InviteCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<InviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<InviteCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<InviteCreateManyOrganizationInputEnvelope>;
};

export type InviteCreateNestedManyWithoutRedeemedByInput = {
  connect?: InputMaybe<Array<InviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCreateOrConnectWithoutRedeemedByInput>>;
  create?: InputMaybe<Array<InviteCreateWithoutRedeemedByInput>>;
  createMany?: InputMaybe<InviteCreateManyRedeemedByInputEnvelope>;
};

export type InviteCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<InviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<InviteCreateWithoutSenderInput>>;
  createMany?: InputMaybe<InviteCreateManySenderInputEnvelope>;
};

export type InviteCreateOrConnectWithoutOrganizationInput = {
  create: InviteCreateWithoutOrganizationInput;
  where: InviteWhereUniqueInput;
};

export type InviteCreateOrConnectWithoutRedeemedByInput = {
  create: InviteCreateWithoutRedeemedByInput;
  where: InviteWhereUniqueInput;
};

export type InviteCreateOrConnectWithoutSenderInput = {
  create: InviteCreateWithoutSenderInput;
  where: InviteWhereUniqueInput;
};

export type InviteCreateWithoutOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  redeemedAt?: InputMaybe<Scalars['DateTimeISO']>;
  redeemedBy?: InputMaybe<UserCreateNestedOneWithoutRedeemedInvitesInput>;
  sender: UserCreateNestedOneWithoutSentInvitesInput;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vidVoucherId?: InputMaybe<Scalars['String']>;
};

export type InviteCreateWithoutRedeemedByInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutInvitesInput;
  phoneNumber?: InputMaybe<Scalars['String']>;
  redeemedAt?: InputMaybe<Scalars['DateTimeISO']>;
  sender: UserCreateNestedOneWithoutSentInvitesInput;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vidVoucherId?: InputMaybe<Scalars['String']>;
};

export type InviteCreateWithoutSenderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutInvitesInput;
  phoneNumber?: InputMaybe<Scalars['String']>;
  redeemedAt?: InputMaybe<Scalars['DateTimeISO']>;
  redeemedBy?: InputMaybe<UserCreateNestedOneWithoutRedeemedInvitesInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vidVoucherId?: InputMaybe<Scalars['String']>;
};

export type InviteDataOutputRes = {
  __typename?: 'InviteDataOutputRes';
  contactInformation: ContactInformationOutput;
  metadata?: Maybe<Scalars['JSON']>;
};

export type InviteEmailOrganizationIdCompoundUniqueInput = {
  email: Scalars['String'];
  organizationId: Scalars['String'];
};

export type InviteErrorResponse = {
  __typename?: 'InviteErrorResponse';
  email: Scalars['String'];
  error: Scalars['String'];
};

export type InviteListRelationFilter = {
  every?: InputMaybe<InviteWhereInput>;
  none?: InputMaybe<InviteWhereInput>;
  some?: InputMaybe<InviteWhereInput>;
};

export type InviteOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InviteOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrderInput>;
  redeemedAt?: InputMaybe<SortOrderInput>;
  redeemedBy?: InputMaybe<UserOrderByWithRelationInput>;
  redeemedById?: InputMaybe<SortOrderInput>;
  sender?: InputMaybe<UserOrderByWithRelationInput>;
  senderId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  vidVoucherId?: InputMaybe<SortOrderInput>;
};

export type InviteRes = {
  __typename?: 'InviteRes';
  email: Scalars['String'];
  id: Scalars['String'];
  organizationId: Scalars['String'];
  redeemedAt?: Maybe<Scalars['String']>;
  redeemedById?: Maybe<Scalars['String']>;
  senderId: Scalars['String'];
  vidVoucherId?: Maybe<Scalars['String']>;
};

export enum InviteScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  OrganizationId = 'organizationId',
  PhoneNumber = 'phoneNumber',
  RedeemedAt = 'redeemedAt',
  RedeemedById = 'redeemedById',
  SenderId = 'senderId',
  UpdatedAt = 'updatedAt',
  VidVoucherId = 'vidVoucherId'
}

export type InviteScalarWhereInput = {
  AND?: InputMaybe<Array<InviteScalarWhereInput>>;
  NOT?: InputMaybe<Array<InviteScalarWhereInput>>;
  OR?: InputMaybe<Array<InviteScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  redeemedAt?: InputMaybe<DateTimeNullableFilter>;
  redeemedById?: InputMaybe<StringNullableFilter>;
  senderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vidVoucherId?: InputMaybe<StringNullableFilter>;
};

export type InviteUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  redeemedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vidVoucherId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type InviteUpdateManyWithWhereWithoutOrganizationInput = {
  data: InviteUpdateManyMutationInput;
  where: InviteScalarWhereInput;
};

export type InviteUpdateManyWithWhereWithoutRedeemedByInput = {
  data: InviteUpdateManyMutationInput;
  where: InviteScalarWhereInput;
};

export type InviteUpdateManyWithWhereWithoutSenderInput = {
  data: InviteUpdateManyMutationInput;
  where: InviteScalarWhereInput;
};

export type InviteUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<InviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<InviteCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<InviteCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<InviteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InviteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InviteWhereUniqueInput>>;
  set?: InputMaybe<Array<InviteWhereUniqueInput>>;
  update?: InputMaybe<Array<InviteUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<InviteUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<InviteUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type InviteUpdateManyWithoutRedeemedByNestedInput = {
  connect?: InputMaybe<Array<InviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCreateOrConnectWithoutRedeemedByInput>>;
  create?: InputMaybe<Array<InviteCreateWithoutRedeemedByInput>>;
  createMany?: InputMaybe<InviteCreateManyRedeemedByInputEnvelope>;
  delete?: InputMaybe<Array<InviteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InviteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InviteWhereUniqueInput>>;
  set?: InputMaybe<Array<InviteWhereUniqueInput>>;
  update?: InputMaybe<Array<InviteUpdateWithWhereUniqueWithoutRedeemedByInput>>;
  updateMany?: InputMaybe<Array<InviteUpdateManyWithWhereWithoutRedeemedByInput>>;
  upsert?: InputMaybe<Array<InviteUpsertWithWhereUniqueWithoutRedeemedByInput>>;
};

export type InviteUpdateManyWithoutSenderNestedInput = {
  connect?: InputMaybe<Array<InviteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<InviteCreateWithoutSenderInput>>;
  createMany?: InputMaybe<InviteCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<InviteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InviteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InviteWhereUniqueInput>>;
  set?: InputMaybe<Array<InviteWhereUniqueInput>>;
  update?: InputMaybe<Array<InviteUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<InviteUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<InviteUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type InviteUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: InviteUpdateWithoutOrganizationInput;
  where: InviteWhereUniqueInput;
};

export type InviteUpdateWithWhereUniqueWithoutRedeemedByInput = {
  data: InviteUpdateWithoutRedeemedByInput;
  where: InviteWhereUniqueInput;
};

export type InviteUpdateWithWhereUniqueWithoutSenderInput = {
  data: InviteUpdateWithoutSenderInput;
  where: InviteWhereUniqueInput;
};

export type InviteUpdateWithoutOrganizationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  redeemedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  redeemedBy?: InputMaybe<UserUpdateOneWithoutRedeemedInvitesNestedInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutSentInvitesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vidVoucherId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type InviteUpdateWithoutRedeemedByInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutInvitesNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  redeemedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutSentInvitesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vidVoucherId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type InviteUpdateWithoutSenderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutInvitesNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  redeemedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  redeemedBy?: InputMaybe<UserUpdateOneWithoutRedeemedInvitesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vidVoucherId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type InviteUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: InviteCreateWithoutOrganizationInput;
  update: InviteUpdateWithoutOrganizationInput;
  where: InviteWhereUniqueInput;
};

export type InviteUpsertWithWhereUniqueWithoutRedeemedByInput = {
  create: InviteCreateWithoutRedeemedByInput;
  update: InviteUpdateWithoutRedeemedByInput;
  where: InviteWhereUniqueInput;
};

export type InviteUpsertWithWhereUniqueWithoutSenderInput = {
  create: InviteCreateWithoutSenderInput;
  update: InviteUpdateWithoutSenderInput;
  where: InviteWhereUniqueInput;
};

export type InviteWhereInput = {
  AND?: InputMaybe<Array<InviteWhereInput>>;
  NOT?: InputMaybe<Array<InviteWhereInput>>;
  OR?: InputMaybe<Array<InviteWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  redeemedAt?: InputMaybe<DateTimeNullableFilter>;
  redeemedBy?: InputMaybe<UserNullableRelationFilter>;
  redeemedById?: InputMaybe<StringNullableFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  senderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vidVoucherId?: InputMaybe<StringNullableFilter>;
};

export type InviteWhereUniqueInput = {
  AND?: InputMaybe<Array<InviteWhereInput>>;
  NOT?: InputMaybe<Array<InviteWhereInput>>;
  OR?: InputMaybe<Array<InviteWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  email_organizationId?: InputMaybe<InviteEmailOrganizationIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  redeemedAt?: InputMaybe<DateTimeNullableFilter>;
  redeemedBy?: InputMaybe<UserNullableRelationFilter>;
  redeemedById?: InputMaybe<StringNullableFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  senderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vidVoucherId?: InputMaybe<StringNullableFilter>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type LegacyCredential = {
  __typename?: 'LegacyCredential';
  createdAt: Scalars['DateTimeISO'];
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTimeISO'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  validatorHash?: Maybe<Scalars['String']>;
  validatorLink: Scalars['String'];
};


export type LegacyCredentialUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type LegacyCredentialCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  validatorHash?: InputMaybe<Scalars['String']>;
};

export type LegacyCredentialCreateManyUserInputEnvelope = {
  data: Array<LegacyCredentialCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LegacyCredentialCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<LegacyCredentialWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LegacyCredentialCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LegacyCredentialCreateWithoutUserInput>>;
  createMany?: InputMaybe<LegacyCredentialCreateManyUserInputEnvelope>;
};

export type LegacyCredentialCreateOrConnectWithoutUserInput = {
  create: LegacyCredentialCreateWithoutUserInput;
  where: LegacyCredentialWhereUniqueInput;
};

export type LegacyCredentialCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  validatorHash?: InputMaybe<Scalars['String']>;
};

export type LegacyCredentialListRelationFilter = {
  every?: InputMaybe<LegacyCredentialWhereInput>;
  none?: InputMaybe<LegacyCredentialWhereInput>;
  some?: InputMaybe<LegacyCredentialWhereInput>;
};

export type LegacyCredentialOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LegacyCredentialOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
  validatorHash?: InputMaybe<SortOrderInput>;
};

export enum LegacyCredentialScalarFieldEnum {
  CreatedAt = 'createdAt',
  Data = 'data',
  Id = 'id',
  OrganizationId = 'organizationId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  ValidatorHash = 'validatorHash'
}

export type LegacyCredentialScalarWhereInput = {
  AND?: InputMaybe<Array<LegacyCredentialScalarWhereInput>>;
  NOT?: InputMaybe<Array<LegacyCredentialScalarWhereInput>>;
  OR?: InputMaybe<Array<LegacyCredentialScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  validatorHash?: InputMaybe<StringNullableFilter>;
};

export type LegacyCredentialUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  validatorHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LegacyCredentialUpdateManyWithWhereWithoutUserInput = {
  data: LegacyCredentialUpdateManyMutationInput;
  where: LegacyCredentialScalarWhereInput;
};

export type LegacyCredentialUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<LegacyCredentialWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LegacyCredentialCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LegacyCredentialCreateWithoutUserInput>>;
  createMany?: InputMaybe<LegacyCredentialCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<LegacyCredentialWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LegacyCredentialScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LegacyCredentialWhereUniqueInput>>;
  set?: InputMaybe<Array<LegacyCredentialWhereUniqueInput>>;
  update?: InputMaybe<Array<LegacyCredentialUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<LegacyCredentialUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<LegacyCredentialUpsertWithWhereUniqueWithoutUserInput>>;
};

export type LegacyCredentialUpdateWithWhereUniqueWithoutUserInput = {
  data: LegacyCredentialUpdateWithoutUserInput;
  where: LegacyCredentialWhereUniqueInput;
};

export type LegacyCredentialUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  validatorHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LegacyCredentialUpsertWithWhereUniqueWithoutUserInput = {
  create: LegacyCredentialCreateWithoutUserInput;
  update: LegacyCredentialUpdateWithoutUserInput;
  where: LegacyCredentialWhereUniqueInput;
};

export type LegacyCredentialWhereInput = {
  AND?: InputMaybe<Array<LegacyCredentialWhereInput>>;
  NOT?: InputMaybe<Array<LegacyCredentialWhereInput>>;
  OR?: InputMaybe<Array<LegacyCredentialWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  validatorHash?: InputMaybe<StringNullableFilter>;
};

export type LegacyCredentialWhereUniqueInput = {
  AND?: InputMaybe<Array<LegacyCredentialWhereInput>>;
  NOT?: InputMaybe<Array<LegacyCredentialWhereInput>>;
  OR?: InputMaybe<Array<LegacyCredentialWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  validatorHash?: InputMaybe<StringNullableFilter>;
};

export enum LocationTypeEnum {
  City = 'CITY',
  County = 'COUNTY',
  State = 'STATE'
}

export type MultipleSignedUrlFileInputV2 = {
  fileId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite?: Maybe<InviteRes>;
  addSecondaryEmail?: Maybe<SimpleResponse>;
  changePrimaryEmail?: Maybe<SimpleResponse>;
  clonePackage: PackageSet;
  createBeam: Beam;
  createDemoCredential: LegacyCredential;
  createDisclosureAcceptance: DisclosureAcceptance;
  createManyApplicantData: AffectedRowsOutput;
  createManyOrder: StatusResponse;
  createManyVIDVoucher?: Maybe<CreateManyVidVoucherRes>;
  createMultipleUploadSignedURL: Array<SignedUrlResponseV2>;
  createOneAddress: Address;
  createOneApplicantData: ApplicantData;
  createOneBeam: Beam;
  createOneBeamInput: BeamInput;
  createOneBeamInputType: BeamInputType;
  createOneCredential: Credential;
  createOneCredentialStatus: CredentialStatus;
  createOneCredentialType: CredentialType;
  createOneDisclosure: Disclosure;
  createOneDispatchedSearch: DispatchedSearch;
  createOneFile: File;
  createOneHighlySensitiveIdentifier: HighlySensitiveIdentifier;
  createOneName: Name;
  createOneOrder: Order;
  createOneOrderNote: OrderNote;
  createOnePackageSet: PackageSet;
  createOnePass: Pass;
  createOnePassType: PassType;
  createOnePassport: Passport;
  createOneReportableIdVerificationResult: ReportableIdVerificationResult;
  createOneRequestedSearch: RequestedSearch;
  createOneResultRevision: ResultRevision;
  createOneShareOrderRecord: ShareOrderRecord;
  createOneStateIdentificationCard: StateIdentificationCard;
  createOneVIDVoucher?: Maybe<CreateOneVidVoucherRes>;
  createOneVIDWebVoucher?: Maybe<CreateOneVidWebVoucherRes>;
  createOrder: Order;
  createReportableResults: StatusResponse;
  createSupportTicket: CreateSupportTicketResponse;
  createUploadSignedURL: SignedUrlResponseV2;
  createUploadTempFileSignedURL: TempFileSignedUrlResponse;
  deleteManyBeamInput: AffectedRowsOutput;
  deleteManyDispatchedSearch: AffectedRowsOutput;
  deleteManyOrder: StatusResponse;
  deleteManyRequestedSearch: AffectedRowsOutput;
  deleteManyResult: AffectedRowsOutput;
  deleteOneAddress?: Maybe<Address>;
  deleteOneApplicantData?: Maybe<ApplicantData>;
  deleteOneBeam?: Maybe<Beam>;
  deleteOneBeamInput?: Maybe<BeamInput>;
  deleteOneDisclosure?: Maybe<Disclosure>;
  deleteOneDispatchedSearch?: Maybe<DispatchedSearch>;
  deleteOneFile?: Maybe<File>;
  deleteOneHighlySensitiveIdentifier?: Maybe<HighlySensitiveIdentifier>;
  deleteOneName?: Maybe<Name>;
  deleteOneOrder?: Maybe<Order>;
  deleteOneOrderNote?: Maybe<OrderNote>;
  deleteOnePassport?: Maybe<Passport>;
  deleteOneReportableIdVerificationResult?: Maybe<ReportableIdVerificationResult>;
  deleteOneRequestedSearch?: Maybe<RequestedSearch>;
  deleteOneResult?: Maybe<Result>;
  deleteOneShareOrderRecord?: Maybe<ShareOrderRecord>;
  deleteOneStateIdentificationCard?: Maybe<StateIdentificationCard>;
  deleteOneVIDVoucher?: Maybe<VidVoucher>;
  deleteOrder: Order;
  deleteSecondaryEmail?: Maybe<SimpleResponse>;
  extractBeamDataFromImage?: Maybe<Scalars['JSON']>;
  optOutVoucher?: Maybe<VidVoucher>;
  updateManyApplicantData: AffectedRowsOutput;
  updateManyOrder: AffectedRowsOutput;
  updateOneAddress?: Maybe<Address>;
  updateOneApplicantData?: Maybe<ApplicantData>;
  updateOneBeam?: Maybe<Beam>;
  updateOneBeamInput?: Maybe<BeamInput>;
  updateOneBeamRun?: Maybe<BeamRun>;
  updateOneCredential?: Maybe<Credential>;
  updateOneCredentialStatus?: Maybe<CredentialStatus>;
  updateOneDisclosure?: Maybe<Disclosure>;
  updateOneDispatchedSearch?: Maybe<DispatchedSearch>;
  updateOneFile?: Maybe<File>;
  updateOneHighlySensitiveIdentifier?: Maybe<HighlySensitiveIdentifier>;
  updateOneName?: Maybe<Name>;
  updateOneOrder?: Maybe<Order>;
  updateOneOrderNote?: Maybe<OrderNote>;
  updateOnePackageSet?: Maybe<PackageSet>;
  updateOnePassType?: Maybe<PassType>;
  updateOnePassport?: Maybe<Passport>;
  updateOneReportableIdVerificationResult?: Maybe<ReportableIdVerificationResult>;
  updateOneResult?: Maybe<Result>;
  updateOneShareOrderRecord?: Maybe<ShareOrderRecord>;
  updateOneStateIdentificationCard?: Maybe<StateIdentificationCard>;
  updateOneUser?: Maybe<User>;
  updateOneVIDVoucher?: Maybe<VidVoucher>;
  updateOrder: Order;
  updatePushToken: StatusResponse;
  updateSecondaryEmail?: Maybe<SimpleResponse>;
  updateUser: User;
  updateVoucherEmail?: Maybe<UpdateVoucherEmailRes>;
  upsertOneApplicantData: ApplicantData;
  upsertOneName: Name;
};


export type MutationAcceptInviteArgs = {
  inviteId: Scalars['String'];
};


export type MutationAddSecondaryEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationChangePrimaryEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationClonePackageArgs = {
  id: Scalars['String'];
};


export type MutationCreateBeamArgs = {
  config?: InputMaybe<Scalars['JSON']>;
  data: BeamTypeCreateInput;
  description: Scalars['String'];
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateDemoCredentialArgs = {
  credentialName: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationCreateDisclosureAcceptanceArgs = {
  data: DisclosureAcceptanceArgs;
};


export type MutationCreateManyApplicantDataArgs = {
  data: Array<ApplicantDataCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyOrderArgs = {
  applicants: Array<Scalars['JSON']>;
  deliveryMethod: OrderDeliveryMethod;
  orderFrom?: InputMaybe<Platform>;
  organizationId: Scalars['String'];
  packageId: Scalars['String'];
};


export type MutationCreateManyVidVoucherArgs = {
  data: Array<CreateOneVidVoucherInput>;
};


export type MutationCreateMultipleUploadSignedUrlArgs = {
  files: Array<MultipleSignedUrlFileInputV2>;
};


export type MutationCreateOneAddressArgs = {
  data: AddressCreateInput;
};


export type MutationCreateOneApplicantDataArgs = {
  data: ApplicantDataCreateInput;
};


export type MutationCreateOneBeamArgs = {
  data: BeamCreateInput;
};


export type MutationCreateOneBeamInputArgs = {
  data: BeamInputCreateInput;
};


export type MutationCreateOneBeamInputTypeArgs = {
  data: BeamInputTypeCreateInput;
};


export type MutationCreateOneCredentialArgs = {
  data: CredentialCreateInput;
};


export type MutationCreateOneCredentialStatusArgs = {
  data: CredentialStatusCreateInput;
};


export type MutationCreateOneCredentialTypeArgs = {
  data: CredentialTypeCreateInput;
};


export type MutationCreateOneDisclosureArgs = {
  data: DisclosureCreateInput;
};


export type MutationCreateOneDispatchedSearchArgs = {
  data: DispatchedSearchCreateInput;
};


export type MutationCreateOneFileArgs = {
  data: FileCreateInput;
};


export type MutationCreateOneHighlySensitiveIdentifierArgs = {
  data: HighlySensitiveIdentifierCreateInput;
};


export type MutationCreateOneNameArgs = {
  data: NameCreateInput;
};


export type MutationCreateOneOrderArgs = {
  data: OrderCreateInput;
};


export type MutationCreateOneOrderNoteArgs = {
  data: OrderNoteCreateInput;
};


export type MutationCreateOnePackageSetArgs = {
  data: PackageSetCreateInput;
};


export type MutationCreateOnePassArgs = {
  data: PassCreateInput;
};


export type MutationCreateOnePassTypeArgs = {
  data: PassTypeCreateInput;
};


export type MutationCreateOnePassportArgs = {
  data: PassportCreateInput;
};


export type MutationCreateOneReportableIdVerificationResultArgs = {
  data: ReportableIdVerificationResultCreateInput;
};


export type MutationCreateOneRequestedSearchArgs = {
  data: RequestedSearchCreateInput;
};


export type MutationCreateOneResultRevisionArgs = {
  data: ResultRevisionCreateInput;
};


export type MutationCreateOneShareOrderRecordArgs = {
  data: ShareOrderRecordCreateInput;
};


export type MutationCreateOneStateIdentificationCardArgs = {
  data: StateIdentificationCardCreateInput;
};


export type MutationCreateOneVidVoucherArgs = {
  data: CreateOneVidVoucherInput;
};


export type MutationCreateOneVidWebVoucherArgs = {
  data: CreateOneVidVoucherInput;
};


export type MutationCreateOrderArgs = {
  beamData?: InputMaybe<Array<OrderBeamData>>;
  data: ExtendedOrderCreateInput;
  documentId?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  orderFrom?: InputMaybe<Platform>;
  stateIdentificationCardId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateReportableResultsArgs = {
  orderId: Scalars['String'];
};


export type MutationCreateSupportTicketArgs = {
  captchaResponse?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
};


export type MutationCreateUploadSignedUrlArgs = {
  fileId: Scalars['String'];
};


export type MutationCreateUploadTempFileSignedUrlArgs = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
};


export type MutationDeleteManyBeamInputArgs = {
  where?: InputMaybe<BeamInputWhereInput>;
};


export type MutationDeleteManyDispatchedSearchArgs = {
  where?: InputMaybe<DispatchedSearchWhereInput>;
};


export type MutationDeleteManyOrderArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteManyRequestedSearchArgs = {
  where?: InputMaybe<RequestedSearchWhereInput>;
};


export type MutationDeleteManyResultArgs = {
  where?: InputMaybe<ResultWhereInput>;
};


export type MutationDeleteOneAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type MutationDeleteOneApplicantDataArgs = {
  where: ApplicantDataWhereUniqueInput;
};


export type MutationDeleteOneBeamArgs = {
  where: BeamWhereUniqueInput;
};


export type MutationDeleteOneBeamInputArgs = {
  where: BeamInputWhereUniqueInput;
};


export type MutationDeleteOneDisclosureArgs = {
  where: DisclosureWhereUniqueInput;
};


export type MutationDeleteOneDispatchedSearchArgs = {
  where: DispatchedSearchWhereUniqueInput;
};


export type MutationDeleteOneFileArgs = {
  where: FileWhereUniqueInput;
};


export type MutationDeleteOneHighlySensitiveIdentifierArgs = {
  where: HighlySensitiveIdentifierWhereUniqueInput;
};


export type MutationDeleteOneNameArgs = {
  where: NameWhereUniqueInput;
};


export type MutationDeleteOneOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationDeleteOneOrderNoteArgs = {
  where: OrderNoteWhereUniqueInput;
};


export type MutationDeleteOnePassportArgs = {
  where: PassportWhereUniqueInput;
};


export type MutationDeleteOneReportableIdVerificationResultArgs = {
  where: ReportableIdVerificationResultWhereUniqueInput;
};


export type MutationDeleteOneRequestedSearchArgs = {
  where: RequestedSearchWhereUniqueInput;
};


export type MutationDeleteOneResultArgs = {
  where: ResultWhereUniqueInput;
};


export type MutationDeleteOneShareOrderRecordArgs = {
  where: ShareOrderRecordWhereUniqueInput;
};


export type MutationDeleteOneStateIdentificationCardArgs = {
  where: StateIdentificationCardWhereUniqueInput;
};


export type MutationDeleteOneVidVoucherArgs = {
  where: VidVoucherWhereUniqueInput;
};


export type MutationDeleteOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationDeleteSecondaryEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationExtractBeamDataFromImageArgs = {
  beamInputTypeId: Scalars['String'];
  imageUrl: Scalars['String'];
};


export type MutationOptOutVoucherArgs = {
  voucherId: Scalars['String'];
};


export type MutationUpdateManyApplicantDataArgs = {
  data: ApplicantDataUpdateManyMutationInput;
  where?: InputMaybe<ApplicantDataWhereInput>;
};


export type MutationUpdateManyOrderArgs = {
  data: OrderUpdateManyMutationInput;
  where?: InputMaybe<OrderWhereInput>;
};


export type MutationUpdateOneAddressArgs = {
  data: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};


export type MutationUpdateOneApplicantDataArgs = {
  data: ApplicantDataUpdateInput;
  where: ApplicantDataWhereUniqueInput;
};


export type MutationUpdateOneBeamArgs = {
  data: BeamUpdateInput;
  where: BeamWhereUniqueInput;
};


export type MutationUpdateOneBeamInputArgs = {
  data: BeamInputUpdateInput;
  where: BeamInputWhereUniqueInput;
};


export type MutationUpdateOneBeamRunArgs = {
  data: BeamRunUpdateInput;
  where: BeamRunWhereUniqueInput;
};


export type MutationUpdateOneCredentialArgs = {
  data: CredentialUpdateInput;
  where: CredentialWhereUniqueInput;
};


export type MutationUpdateOneCredentialStatusArgs = {
  data: CredentialStatusUpdateInput;
  where: CredentialStatusWhereUniqueInput;
};


export type MutationUpdateOneDisclosureArgs = {
  data: DisclosureUpdateInput;
  where: DisclosureWhereUniqueInput;
};


export type MutationUpdateOneDispatchedSearchArgs = {
  data: DispatchedSearchUpdateInput;
  where: DispatchedSearchWhereUniqueInput;
};


export type MutationUpdateOneFileArgs = {
  data: FileUpdateInput;
  where: FileWhereUniqueInput;
};


export type MutationUpdateOneHighlySensitiveIdentifierArgs = {
  data: HighlySensitiveIdentifierUpdateInput;
  where: HighlySensitiveIdentifierWhereUniqueInput;
};


export type MutationUpdateOneNameArgs = {
  data: NameUpdateInput;
  where: NameWhereUniqueInput;
};


export type MutationUpdateOneOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateOneOrderNoteArgs = {
  data: OrderNoteUpdateInput;
  where: OrderNoteWhereUniqueInput;
};


export type MutationUpdateOnePackageSetArgs = {
  data: PackageSetUpdateInput;
  where: PackageSetWhereUniqueInput;
};


export type MutationUpdateOnePassTypeArgs = {
  data: PassTypeUpdateInput;
  where: PassTypeWhereUniqueInput;
};


export type MutationUpdateOnePassportArgs = {
  data: PassportUpdateInput;
  where: PassportWhereUniqueInput;
};


export type MutationUpdateOneReportableIdVerificationResultArgs = {
  data: ReportableIdVerificationResultUpdateInput;
  where: ReportableIdVerificationResultWhereUniqueInput;
};


export type MutationUpdateOneResultArgs = {
  data: ResultUpdateInput;
  where: ResultWhereUniqueInput;
};


export type MutationUpdateOneShareOrderRecordArgs = {
  data: ShareOrderRecordUpdateInput;
  where: ShareOrderRecordWhereUniqueInput;
};


export type MutationUpdateOneStateIdentificationCardArgs = {
  data: StateIdentificationCardUpdateInput;
  where: StateIdentificationCardWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateOneVidVoucherArgs = {
  data: VidVoucherUpdateInput;
  where: VidVoucherWhereUniqueInput;
};


export type MutationUpdateOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdatePushTokenArgs = {
  pushToken: Scalars['String'];
};


export type MutationUpdateSecondaryEmailArgs = {
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateVoucherEmailArgs = {
  newEmail: Scalars['String'];
  voucherId: Scalars['String'];
};


export type MutationUpsertOneApplicantDataArgs = {
  create: ApplicantDataCreateInput;
  update: ApplicantDataUpdateInput;
  where: ApplicantDataWhereUniqueInput;
};


export type MutationUpsertOneNameArgs = {
  create: NameCreateInput;
  update: NameUpdateInput;
  where: NameWhereUniqueInput;
};

export type Name = {
  __typename?: 'Name';
  applicantDataId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  passportId?: Maybe<Scalars['String']>;
  stateIdentificationCardId?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  type?: Maybe<NameTypeEnum>;
  updatedAt: Scalars['DateTimeISO'];
  userId?: Maybe<Scalars['String']>;
};

export type NameCreateInput = {
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutNamesInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutNameInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutNameInput>;
  suffix?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NameTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutNamesInput>;
};

export type NameCreateManyApplicantDataInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  passportId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardId?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NameTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type NameCreateManyApplicantDataInputEnvelope = {
  data: Array<NameCreateManyApplicantDataInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type NameCreateManyUserInput = {
  applicantDataId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  passportId?: InputMaybe<Scalars['String']>;
  stateIdentificationCardId?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NameTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type NameCreateManyUserInputEnvelope = {
  data: Array<NameCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type NameCreateNestedManyWithoutApplicantDataInput = {
  connect?: InputMaybe<Array<NameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NameCreateOrConnectWithoutApplicantDataInput>>;
  create?: InputMaybe<Array<NameCreateWithoutApplicantDataInput>>;
  createMany?: InputMaybe<NameCreateManyApplicantDataInputEnvelope>;
};

export type NameCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<NameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NameCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<NameCreateWithoutUserInput>>;
  createMany?: InputMaybe<NameCreateManyUserInputEnvelope>;
};

export type NameCreateNestedOneWithoutPassportInput = {
  connect?: InputMaybe<NameWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NameCreateOrConnectWithoutPassportInput>;
  create?: InputMaybe<NameCreateWithoutPassportInput>;
};

export type NameCreateNestedOneWithoutStateIdentificationCardInput = {
  connect?: InputMaybe<NameWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NameCreateOrConnectWithoutStateIdentificationCardInput>;
  create?: InputMaybe<NameCreateWithoutStateIdentificationCardInput>;
};

export type NameCreateOrConnectWithoutApplicantDataInput = {
  create: NameCreateWithoutApplicantDataInput;
  where: NameWhereUniqueInput;
};

export type NameCreateOrConnectWithoutPassportInput = {
  create: NameCreateWithoutPassportInput;
  where: NameWhereUniqueInput;
};

export type NameCreateOrConnectWithoutStateIdentificationCardInput = {
  create: NameCreateWithoutStateIdentificationCardInput;
  where: NameWhereUniqueInput;
};

export type NameCreateOrConnectWithoutUserInput = {
  create: NameCreateWithoutUserInput;
  where: NameWhereUniqueInput;
};

export type NameCreateWithoutApplicantDataInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutNameInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutNameInput>;
  suffix?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NameTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutNamesInput>;
};

export type NameCreateWithoutPassportInput = {
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutNamesInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutNameInput>;
  suffix?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NameTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutNamesInput>;
};

export type NameCreateWithoutStateIdentificationCardInput = {
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutNamesInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutNameInput>;
  suffix?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NameTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutNamesInput>;
};

export type NameCreateWithoutUserInput = {
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutNamesInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<PassportCreateNestedOneWithoutNameInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardCreateNestedOneWithoutNameInput>;
  suffix?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NameTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type NameListRelationFilter = {
  every?: InputMaybe<NameWhereInput>;
  none?: InputMaybe<NameWhereInput>;
  some?: InputMaybe<NameWhereInput>;
};

export type NameNullableRelationFilter = {
  is?: InputMaybe<NameWhereInput>;
  isNot?: InputMaybe<NameWhereInput>;
};

export type NameOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NameOrderByWithRelationInput = {
  applicantData?: InputMaybe<ApplicantDataOrderByWithRelationInput>;
  applicantDataId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrderInput>;
  middleName?: InputMaybe<SortOrderInput>;
  passport?: InputMaybe<PassportOrderByWithRelationInput>;
  passportId?: InputMaybe<SortOrderInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardOrderByWithRelationInput>;
  stateIdentificationCardId?: InputMaybe<SortOrderInput>;
  suffix?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum NameScalarFieldEnum {
  ApplicantDataId = 'applicantDataId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  MiddleName = 'middleName',
  PassportId = 'passportId',
  StateIdentificationCardId = 'stateIdentificationCardId',
  Suffix = 'suffix',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type NameScalarWhereInput = {
  AND?: InputMaybe<Array<NameScalarWhereInput>>;
  NOT?: InputMaybe<Array<NameScalarWhereInput>>;
  OR?: InputMaybe<Array<NameScalarWhereInput>>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  passportId?: InputMaybe<StringNullableFilter>;
  stateIdentificationCardId?: InputMaybe<StringNullableFilter>;
  suffix?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumNameTypeEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export enum NameTypeEnum {
  Alias = 'ALIAS',
  Manual = 'MANUAL',
  Primary = 'PRIMARY'
}

export type NameUpdateInput = {
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutNamesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutNameNestedInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutNameNestedInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumNameTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutNamesNestedInput>;
};

export type NameUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumNameTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NameUpdateManyWithWhereWithoutApplicantDataInput = {
  data: NameUpdateManyMutationInput;
  where: NameScalarWhereInput;
};

export type NameUpdateManyWithWhereWithoutUserInput = {
  data: NameUpdateManyMutationInput;
  where: NameScalarWhereInput;
};

export type NameUpdateManyWithoutApplicantDataNestedInput = {
  connect?: InputMaybe<Array<NameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NameCreateOrConnectWithoutApplicantDataInput>>;
  create?: InputMaybe<Array<NameCreateWithoutApplicantDataInput>>;
  createMany?: InputMaybe<NameCreateManyApplicantDataInputEnvelope>;
  delete?: InputMaybe<Array<NameWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NameScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NameWhereUniqueInput>>;
  set?: InputMaybe<Array<NameWhereUniqueInput>>;
  update?: InputMaybe<Array<NameUpdateWithWhereUniqueWithoutApplicantDataInput>>;
  updateMany?: InputMaybe<Array<NameUpdateManyWithWhereWithoutApplicantDataInput>>;
  upsert?: InputMaybe<Array<NameUpsertWithWhereUniqueWithoutApplicantDataInput>>;
};

export type NameUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<NameWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NameCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<NameCreateWithoutUserInput>>;
  createMany?: InputMaybe<NameCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<NameWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NameScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NameWhereUniqueInput>>;
  set?: InputMaybe<Array<NameWhereUniqueInput>>;
  update?: InputMaybe<Array<NameUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<NameUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<NameUpsertWithWhereUniqueWithoutUserInput>>;
};

export type NameUpdateOneWithoutPassportNestedInput = {
  connect?: InputMaybe<NameWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NameCreateOrConnectWithoutPassportInput>;
  create?: InputMaybe<NameCreateWithoutPassportInput>;
  delete?: InputMaybe<NameWhereInput>;
  disconnect?: InputMaybe<NameWhereInput>;
  update?: InputMaybe<NameUpdateToOneWithWhereWithoutPassportInput>;
  upsert?: InputMaybe<NameUpsertWithoutPassportInput>;
};

export type NameUpdateOneWithoutStateIdentificationCardNestedInput = {
  connect?: InputMaybe<NameWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NameCreateOrConnectWithoutStateIdentificationCardInput>;
  create?: InputMaybe<NameCreateWithoutStateIdentificationCardInput>;
  delete?: InputMaybe<NameWhereInput>;
  disconnect?: InputMaybe<NameWhereInput>;
  update?: InputMaybe<NameUpdateToOneWithWhereWithoutStateIdentificationCardInput>;
  upsert?: InputMaybe<NameUpsertWithoutStateIdentificationCardInput>;
};

export type NameUpdateToOneWithWhereWithoutPassportInput = {
  data: NameUpdateWithoutPassportInput;
  where?: InputMaybe<NameWhereInput>;
};

export type NameUpdateToOneWithWhereWithoutStateIdentificationCardInput = {
  data: NameUpdateWithoutStateIdentificationCardInput;
  where?: InputMaybe<NameWhereInput>;
};

export type NameUpdateWithWhereUniqueWithoutApplicantDataInput = {
  data: NameUpdateWithoutApplicantDataInput;
  where: NameWhereUniqueInput;
};

export type NameUpdateWithWhereUniqueWithoutUserInput = {
  data: NameUpdateWithoutUserInput;
  where: NameWhereUniqueInput;
};

export type NameUpdateWithoutApplicantDataInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutNameNestedInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutNameNestedInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumNameTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutNamesNestedInput>;
};

export type NameUpdateWithoutPassportInput = {
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutNamesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutNameNestedInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumNameTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutNamesNestedInput>;
};

export type NameUpdateWithoutStateIdentificationCardInput = {
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutNamesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutNameNestedInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumNameTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutNamesNestedInput>;
};

export type NameUpdateWithoutUserInput = {
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutNamesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passport?: InputMaybe<PassportUpdateOneWithoutNameNestedInput>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardUpdateOneWithoutNameNestedInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumNameTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NameUpsertWithWhereUniqueWithoutApplicantDataInput = {
  create: NameCreateWithoutApplicantDataInput;
  update: NameUpdateWithoutApplicantDataInput;
  where: NameWhereUniqueInput;
};

export type NameUpsertWithWhereUniqueWithoutUserInput = {
  create: NameCreateWithoutUserInput;
  update: NameUpdateWithoutUserInput;
  where: NameWhereUniqueInput;
};

export type NameUpsertWithoutPassportInput = {
  create: NameCreateWithoutPassportInput;
  update: NameUpdateWithoutPassportInput;
  where?: InputMaybe<NameWhereInput>;
};

export type NameUpsertWithoutStateIdentificationCardInput = {
  create: NameCreateWithoutStateIdentificationCardInput;
  update: NameUpdateWithoutStateIdentificationCardInput;
  where?: InputMaybe<NameWhereInput>;
};

export type NameWhereInput = {
  AND?: InputMaybe<Array<NameWhereInput>>;
  NOT?: InputMaybe<Array<NameWhereInput>>;
  OR?: InputMaybe<Array<NameWhereInput>>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  passport?: InputMaybe<PassportNullableRelationFilter>;
  passportId?: InputMaybe<StringNullableFilter>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardId?: InputMaybe<StringNullableFilter>;
  suffix?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumNameTypeEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type NameWhereUniqueInput = {
  AND?: InputMaybe<Array<NameWhereInput>>;
  NOT?: InputMaybe<Array<NameWhereInput>>;
  OR?: InputMaybe<Array<NameWhereInput>>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<StringNullableFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  passport?: InputMaybe<PassportNullableRelationFilter>;
  passportId?: InputMaybe<Scalars['String']>;
  stateIdentificationCard?: InputMaybe<StateIdentificationCardNullableRelationFilter>;
  stateIdentificationCardId?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumNameTypeEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBytesFilter = {
  equals?: InputMaybe<Scalars['Byte']>;
  in?: InputMaybe<Array<Scalars['Byte']>>;
  not?: InputMaybe<NestedBytesFilter>;
  notIn?: InputMaybe<Array<Scalars['Byte']>>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']>;
  gt?: InputMaybe<Scalars['DateTimeISO']>;
  gte?: InputMaybe<Scalars['DateTimeISO']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']>;
  lte?: InputMaybe<Scalars['DateTimeISO']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']>;
  gt?: InputMaybe<Scalars['DateTimeISO']>;
  gte?: InputMaybe<Scalars['DateTimeISO']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']>;
  lte?: InputMaybe<Scalars['DateTimeISO']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']>>;
};

export type NestedEnumAddressTypeEnumNullableFilter = {
  equals?: InputMaybe<AddressTypeEnum>;
  in?: InputMaybe<Array<AddressTypeEnum>>;
  not?: InputMaybe<NestedEnumAddressTypeEnumNullableFilter>;
  notIn?: InputMaybe<Array<AddressTypeEnum>>;
};

export type NestedEnumAttachmentEnumFilter = {
  equals?: InputMaybe<AttachmentEnum>;
  in?: InputMaybe<Array<AttachmentEnum>>;
  not?: InputMaybe<NestedEnumAttachmentEnumFilter>;
  notIn?: InputMaybe<Array<AttachmentEnum>>;
};

export type NestedEnumBeamRunStatusEnumFilter = {
  equals?: InputMaybe<BeamRunStatusEnum>;
  in?: InputMaybe<Array<BeamRunStatusEnum>>;
  not?: InputMaybe<NestedEnumBeamRunStatusEnumFilter>;
  notIn?: InputMaybe<Array<BeamRunStatusEnum>>;
};

export type NestedEnumCredentialStatusEnumFilter = {
  equals?: InputMaybe<CredentialStatusEnum>;
  in?: InputMaybe<Array<CredentialStatusEnum>>;
  not?: InputMaybe<NestedEnumCredentialStatusEnumFilter>;
  notIn?: InputMaybe<Array<CredentialStatusEnum>>;
};

export type NestedEnumCurrencyMultiplierEnumFilter = {
  equals?: InputMaybe<CurrencyMultiplierEnum>;
  in?: InputMaybe<Array<CurrencyMultiplierEnum>>;
  not?: InputMaybe<NestedEnumCurrencyMultiplierEnumFilter>;
  notIn?: InputMaybe<Array<CurrencyMultiplierEnum>>;
};

export type NestedEnumCurrencyTypeEnumFilter = {
  equals?: InputMaybe<CurrencyTypeEnum>;
  in?: InputMaybe<Array<CurrencyTypeEnum>>;
  not?: InputMaybe<NestedEnumCurrencyTypeEnumFilter>;
  notIn?: InputMaybe<Array<CurrencyTypeEnum>>;
};

export type NestedEnumDisclosureTypeEnumFilter = {
  equals?: InputMaybe<DisclosureTypeEnum>;
  in?: InputMaybe<Array<DisclosureTypeEnum>>;
  not?: InputMaybe<NestedEnumDisclosureTypeEnumFilter>;
  notIn?: InputMaybe<Array<DisclosureTypeEnum>>;
};

export type NestedEnumFilePurposeTypeEnumNullableFilter = {
  equals?: InputMaybe<FilePurposeTypeEnum>;
  in?: InputMaybe<Array<FilePurposeTypeEnum>>;
  not?: InputMaybe<NestedEnumFilePurposeTypeEnumNullableFilter>;
  notIn?: InputMaybe<Array<FilePurposeTypeEnum>>;
};

export type NestedEnumHighlySensitiveIdentifierTypeFilter = {
  equals?: InputMaybe<HighlySensitiveIdentifierType>;
  in?: InputMaybe<Array<HighlySensitiveIdentifierType>>;
  not?: InputMaybe<NestedEnumHighlySensitiveIdentifierTypeFilter>;
  notIn?: InputMaybe<Array<HighlySensitiveIdentifierType>>;
};

export type NestedEnumIdCardRotationEnumNullableFilter = {
  equals?: InputMaybe<IdCardRotationEnum>;
  in?: InputMaybe<Array<IdCardRotationEnum>>;
  not?: InputMaybe<NestedEnumIdCardRotationEnumNullableFilter>;
  notIn?: InputMaybe<Array<IdCardRotationEnum>>;
};

export type NestedEnumIdVerificationResultCheckEnumNullableFilter = {
  equals?: InputMaybe<IdVerificationResultCheckEnum>;
  in?: InputMaybe<Array<IdVerificationResultCheckEnum>>;
  not?: InputMaybe<NestedEnumIdVerificationResultCheckEnumNullableFilter>;
  notIn?: InputMaybe<Array<IdVerificationResultCheckEnum>>;
};

export type NestedEnumIdentifierTypeFilter = {
  equals?: InputMaybe<IdentifierType>;
  in?: InputMaybe<Array<IdentifierType>>;
  not?: InputMaybe<NestedEnumIdentifierTypeFilter>;
  notIn?: InputMaybe<Array<IdentifierType>>;
};

export type NestedEnumLocationTypeEnumNullableFilter = {
  equals?: InputMaybe<LocationTypeEnum>;
  in?: InputMaybe<Array<LocationTypeEnum>>;
  not?: InputMaybe<NestedEnumLocationTypeEnumNullableFilter>;
  notIn?: InputMaybe<Array<LocationTypeEnum>>;
};

export type NestedEnumNameTypeEnumNullableFilter = {
  equals?: InputMaybe<NameTypeEnum>;
  in?: InputMaybe<Array<NameTypeEnum>>;
  not?: InputMaybe<NestedEnumNameTypeEnumNullableFilter>;
  notIn?: InputMaybe<Array<NameTypeEnum>>;
};

export type NestedEnumOrderAlertEnumNullableFilter = {
  equals?: InputMaybe<OrderAlertEnum>;
  in?: InputMaybe<Array<OrderAlertEnum>>;
  not?: InputMaybe<NestedEnumOrderAlertEnumNullableFilter>;
  notIn?: InputMaybe<Array<OrderAlertEnum>>;
};

export type NestedEnumOrderDeliveryMethodNullableFilter = {
  equals?: InputMaybe<OrderDeliveryMethod>;
  in?: InputMaybe<Array<OrderDeliveryMethod>>;
  not?: InputMaybe<NestedEnumOrderDeliveryMethodNullableFilter>;
  notIn?: InputMaybe<Array<OrderDeliveryMethod>>;
};

export type NestedEnumOrderScoreEnumNullableFilter = {
  equals?: InputMaybe<OrderScoreEnum>;
  in?: InputMaybe<Array<OrderScoreEnum>>;
  not?: InputMaybe<NestedEnumOrderScoreEnumNullableFilter>;
  notIn?: InputMaybe<Array<OrderScoreEnum>>;
};

export type NestedEnumOrderStatusEnumNullableFilter = {
  equals?: InputMaybe<OrderStatusEnum>;
  in?: InputMaybe<Array<OrderStatusEnum>>;
  not?: InputMaybe<NestedEnumOrderStatusEnumNullableFilter>;
  notIn?: InputMaybe<Array<OrderStatusEnum>>;
};

export type NestedEnumOrgRelationTypeEnumFilter = {
  equals?: InputMaybe<OrgRelationTypeEnum>;
  in?: InputMaybe<Array<OrgRelationTypeEnum>>;
  not?: InputMaybe<NestedEnumOrgRelationTypeEnumFilter>;
  notIn?: InputMaybe<Array<OrgRelationTypeEnum>>;
};

export type NestedEnumPerformerTypesFilter = {
  equals?: InputMaybe<PerformerTypes>;
  in?: InputMaybe<Array<PerformerTypes>>;
  not?: InputMaybe<NestedEnumPerformerTypesFilter>;
  notIn?: InputMaybe<Array<PerformerTypes>>;
};

export type NestedEnumProcessorEnumFilter = {
  equals?: InputMaybe<ProcessorEnum>;
  in?: InputMaybe<Array<ProcessorEnum>>;
  not?: InputMaybe<NestedEnumProcessorEnumFilter>;
  notIn?: InputMaybe<Array<ProcessorEnum>>;
};

export type NestedEnumResultAlertTypeEnumNullableFilter = {
  equals?: InputMaybe<ResultAlertTypeEnum>;
  in?: InputMaybe<Array<ResultAlertTypeEnum>>;
  not?: InputMaybe<NestedEnumResultAlertTypeEnumNullableFilter>;
  notIn?: InputMaybe<Array<ResultAlertTypeEnum>>;
};

export type NestedEnumResultSearchTypeNullableFilter = {
  equals?: InputMaybe<ResultSearchType>;
  in?: InputMaybe<Array<ResultSearchType>>;
  not?: InputMaybe<NestedEnumResultSearchTypeNullableFilter>;
  notIn?: InputMaybe<Array<ResultSearchType>>;
};

export type NestedEnumSearchStatusTypeEnumFilter = {
  equals?: InputMaybe<SearchStatusTypeEnum>;
  in?: InputMaybe<Array<SearchStatusTypeEnum>>;
  not?: InputMaybe<NestedEnumSearchStatusTypeEnumFilter>;
  notIn?: InputMaybe<Array<SearchStatusTypeEnum>>;
};

export type NestedEnumSourceTypesFilter = {
  equals?: InputMaybe<SourceTypes>;
  in?: InputMaybe<Array<SourceTypes>>;
  not?: InputMaybe<NestedEnumSourceTypesFilter>;
  notIn?: InputMaybe<Array<SourceTypes>>;
};

export type NestedEnumUserPrimaryContactEnumNullableFilter = {
  equals?: InputMaybe<UserPrimaryContactEnum>;
  in?: InputMaybe<Array<UserPrimaryContactEnum>>;
  not?: InputMaybe<NestedEnumUserPrimaryContactEnumNullableFilter>;
  notIn?: InputMaybe<Array<UserPrimaryContactEnum>>;
};

export type NestedEnumUserStatusEnumFilter = {
  equals?: InputMaybe<UserStatusEnum>;
  in?: InputMaybe<Array<UserStatusEnum>>;
  not?: InputMaybe<NestedEnumUserStatusEnumFilter>;
  notIn?: InputMaybe<Array<UserStatusEnum>>;
};

export type NestedEnumVoucherStatusEnumFilter = {
  equals?: InputMaybe<VoucherStatusEnum>;
  in?: InputMaybe<Array<VoucherStatusEnum>>;
  not?: InputMaybe<NestedEnumVoucherStatusEnumFilter>;
  notIn?: InputMaybe<Array<VoucherStatusEnum>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']>;
};

export type NullableEnumAddressTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<AddressTypeEnum>;
};

export type NullableEnumFilePurposeTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<FilePurposeTypeEnum>;
};

export type NullableEnumIdCardRotationEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<IdCardRotationEnum>;
};

export type NullableEnumIdVerificationResultCheckEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<IdVerificationResultCheckEnum>;
};

export type NullableEnumLocationTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<LocationTypeEnum>;
};

export type NullableEnumNameTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<NameTypeEnum>;
};

export type NullableEnumOrderAlertEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<OrderAlertEnum>;
};

export type NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput = {
  set?: InputMaybe<OrderDeliveryMethod>;
};

export type NullableEnumOrderScoreEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<OrderScoreEnum>;
};

export type NullableEnumOrderStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<OrderStatusEnum>;
};

export type NullableEnumResultAlertTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<ResultAlertTypeEnum>;
};

export type NullableEnumResultSearchTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ResultSearchType>;
};

export type NullableEnumUserPrimaryContactEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<UserPrimaryContactEnum>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Order = {
  __typename?: 'Order';
  _count?: Maybe<OrderCount>;
  additionalMetadata?: Maybe<Scalars['JSON']>;
  alert?: Maybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: Maybe<ApplicantData>;
  beamInputs: Array<BeamInput>;
  complianceLetters: Array<ComplianceLetter>;
  createdAt: Scalars['DateTimeISO'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deliveryMethod?: Maybe<OrderDeliveryMethod>;
  disclosureAcceptances: Array<DisclosureAcceptance>;
  dispatchedSearches: Array<DispatchedSearch>;
  fileNumber: Scalars['String'];
  files: Array<File>;
  flagged?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  internalMetadata?: Maybe<Scalars['JSON']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipMetadata?: Maybe<Scalars['JSON']>;
  notes: Array<OrderNote>;
  optOut: Scalars['Boolean'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  packageSets: Array<PackageSet>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: Maybe<Scalars['String']>;
  postbackWebhookUrl?: Maybe<Scalars['String']>;
  reportCompletedAt?: Maybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: Maybe<ReportableIdVerificationResult>;
  reportableResults?: Maybe<Scalars['JSON']>;
  reportedAt?: Maybe<Scalars['DateTimeISO']>;
  requestedSearches: Array<RequestedSearch>;
  results: Array<Result>;
  score?: Maybe<OrderScoreEnum>;
  shareOrderRecord?: Maybe<ShareOrderRecord>;
  status?: Maybe<OrderStatusEnum>;
  updatedAt: Scalars['DateTimeISO'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  vIDVoucher?: Maybe<VidVoucher>;
};


export type OrderApplicantDataArgs = {
  where?: InputMaybe<ApplicantDataWhereInput>;
};


export type OrderBeamInputsArgs = {
  cursor?: InputMaybe<BeamInputWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeamInputScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeamInputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamInputWhereInput>;
};


export type OrderComplianceLettersArgs = {
  cursor?: InputMaybe<ComplianceLetterWhereUniqueInput>;
  distinct?: InputMaybe<Array<ComplianceLetterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ComplianceLetterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ComplianceLetterWhereInput>;
};


export type OrderCreatedByArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type OrderDisclosureAcceptancesArgs = {
  cursor?: InputMaybe<DisclosureAcceptanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<DisclosureAcceptanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DisclosureAcceptanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DisclosureAcceptanceWhereInput>;
};


export type OrderDispatchedSearchesArgs = {
  cursor?: InputMaybe<DispatchedSearchWhereUniqueInput>;
  distinct?: InputMaybe<Array<DispatchedSearchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DispatchedSearchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DispatchedSearchWhereInput>;
};


export type OrderFilesArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FileWhereInput>;
};


export type OrderNotesArgs = {
  cursor?: InputMaybe<OrderNoteWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderNoteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderNoteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderNoteWhereInput>;
};


export type OrderOrganizationArgs = {
  where?: InputMaybe<OrganizationWhereInput>;
};


export type OrderPackageSetsArgs = {
  cursor?: InputMaybe<PackageSetWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackageSetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackageSetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackageSetWhereInput>;
};


export type OrderReportableIdVerificationResultArgs = {
  where?: InputMaybe<ReportableIdVerificationResultWhereInput>;
};


export type OrderRequestedSearchesArgs = {
  cursor?: InputMaybe<RequestedSearchWhereUniqueInput>;
  distinct?: InputMaybe<Array<RequestedSearchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RequestedSearchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RequestedSearchWhereInput>;
};


export type OrderResultsArgs = {
  cursor?: InputMaybe<ResultWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResultScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResultWhereInput>;
};


export type OrderShareOrderRecordArgs = {
  where?: InputMaybe<ShareOrderRecordWhereInput>;
};


export type OrderUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type OrderVIdVoucherArgs = {
  where?: InputMaybe<VidVoucherWhereInput>;
};

export enum OrderAlertEnum {
  AlertsFound = 'ALERTS_FOUND',
  NoAlertsFound = 'NO_ALERTS_FOUND'
}

export type OrderAvgAggregate = {
  __typename?: 'OrderAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type OrderBeamData = {
  beamId: Scalars['String'];
  data: Scalars['JSON'];
};

export type OrderCount = {
  __typename?: 'OrderCount';
  beamInputs: Scalars['Int'];
  complianceLetters: Scalars['Int'];
  disclosureAcceptances: Scalars['Int'];
  dispatchedSearches: Scalars['Int'];
  files: Scalars['Int'];
  notes: Scalars['Int'];
  packageSets: Scalars['Int'];
  requestedSearches: Scalars['Int'];
  results: Scalars['Int'];
};


export type OrderCountBeamInputsArgs = {
  where?: InputMaybe<BeamInputWhereInput>;
};


export type OrderCountComplianceLettersArgs = {
  where?: InputMaybe<ComplianceLetterWhereInput>;
};


export type OrderCountDisclosureAcceptancesArgs = {
  where?: InputMaybe<DisclosureAcceptanceWhereInput>;
};


export type OrderCountDispatchedSearchesArgs = {
  where?: InputMaybe<DispatchedSearchWhereInput>;
};


export type OrderCountFilesArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type OrderCountNotesArgs = {
  where?: InputMaybe<OrderNoteWhereInput>;
};


export type OrderCountPackageSetsArgs = {
  where?: InputMaybe<PackageSetWhereInput>;
};


export type OrderCountRequestedSearchesArgs = {
  where?: InputMaybe<RequestedSearchWhereInput>;
};


export type OrderCountResultsArgs = {
  where?: InputMaybe<ResultWhereInput>;
};

export type OrderCountAggregate = {
  __typename?: 'OrderCountAggregate';
  _all: Scalars['Int'];
  additionalMetadata: Scalars['Int'];
  alert: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdById: Scalars['Int'];
  deleted: Scalars['Int'];
  deliveryMethod: Scalars['Int'];
  fileNumber: Scalars['Int'];
  flagged: Scalars['Int'];
  id: Scalars['Int'];
  internalMetadata: Scalars['Int'];
  ipAddress: Scalars['Int'];
  ipMetadata: Scalars['Int'];
  optOut: Scalars['Int'];
  organizationId: Scalars['Int'];
  paid: Scalars['Int'];
  parentOrganizationId: Scalars['Int'];
  postbackWebhookUrl: Scalars['Int'];
  reportCompletedAt: Scalars['Int'];
  reportableResults: Scalars['Int'];
  reportedAt: Scalars['Int'];
  score: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type OrderCreateInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateManyCreatedByInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  fileNumber?: InputMaybe<Scalars['String']>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  score?: InputMaybe<OrderScoreEnum>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type OrderCreateManyCreatedByInputEnvelope = {
  data: Array<OrderCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderCreateManyOrganizationInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdById?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  fileNumber?: InputMaybe<Scalars['String']>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  score?: InputMaybe<OrderScoreEnum>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type OrderCreateManyOrganizationInputEnvelope = {
  data: Array<OrderCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderCreateManyUserInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdById?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  fileNumber?: InputMaybe<Scalars['String']>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  score?: InputMaybe<OrderScoreEnum>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrderCreateManyUserInputEnvelope = {
  data: Array<OrderCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<OrderCreateManyCreatedByInputEnvelope>;
};

export type OrderCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrderCreateManyOrganizationInputEnvelope>;
};

export type OrderCreateNestedManyWithoutPackageSetsInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutPackageSetsInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutPackageSetsInput>>;
};

export type OrderCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutUserInput>>;
  createMany?: InputMaybe<OrderCreateManyUserInputEnvelope>;
};

export type OrderCreateNestedOneWithoutApplicantDataInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutApplicantDataInput>;
  create?: InputMaybe<OrderCreateWithoutApplicantDataInput>;
};

export type OrderCreateNestedOneWithoutBeamInputsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutBeamInputsInput>;
  create?: InputMaybe<OrderCreateWithoutBeamInputsInput>;
};

export type OrderCreateNestedOneWithoutDisclosureAcceptancesInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutDisclosureAcceptancesInput>;
  create?: InputMaybe<OrderCreateWithoutDisclosureAcceptancesInput>;
};

export type OrderCreateNestedOneWithoutDispatchedSearchesInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutDispatchedSearchesInput>;
  create?: InputMaybe<OrderCreateWithoutDispatchedSearchesInput>;
};

export type OrderCreateNestedOneWithoutFilesInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<OrderCreateWithoutFilesInput>;
};

export type OrderCreateNestedOneWithoutNotesInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutNotesInput>;
  create?: InputMaybe<OrderCreateWithoutNotesInput>;
};

export type OrderCreateNestedOneWithoutReportableIdVerificationResultInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutReportableIdVerificationResultInput>;
  create?: InputMaybe<OrderCreateWithoutReportableIdVerificationResultInput>;
};

export type OrderCreateNestedOneWithoutRequestedSearchesInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutRequestedSearchesInput>;
  create?: InputMaybe<OrderCreateWithoutRequestedSearchesInput>;
};

export type OrderCreateNestedOneWithoutResultsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutResultsInput>;
  create?: InputMaybe<OrderCreateWithoutResultsInput>;
};

export type OrderCreateNestedOneWithoutShareOrderRecordInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutShareOrderRecordInput>;
  create?: InputMaybe<OrderCreateWithoutShareOrderRecordInput>;
};

export type OrderCreateNestedOneWithoutVidVoucherInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutVidVoucherInput>;
  create?: InputMaybe<OrderCreateWithoutVidVoucherInput>;
};

export type OrderCreateOrConnectWithoutApplicantDataInput = {
  create: OrderCreateWithoutApplicantDataInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutBeamInputsInput = {
  create: OrderCreateWithoutBeamInputsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutCreatedByInput = {
  create: OrderCreateWithoutCreatedByInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutDisclosureAcceptancesInput = {
  create: OrderCreateWithoutDisclosureAcceptancesInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutDispatchedSearchesInput = {
  create: OrderCreateWithoutDispatchedSearchesInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutFilesInput = {
  create: OrderCreateWithoutFilesInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutNotesInput = {
  create: OrderCreateWithoutNotesInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutOrganizationInput = {
  create: OrderCreateWithoutOrganizationInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutPackageSetsInput = {
  create: OrderCreateWithoutPackageSetsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutReportableIdVerificationResultInput = {
  create: OrderCreateWithoutReportableIdVerificationResultInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutRequestedSearchesInput = {
  create: OrderCreateWithoutRequestedSearchesInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutResultsInput = {
  create: OrderCreateWithoutResultsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutShareOrderRecordInput = {
  create: OrderCreateWithoutShareOrderRecordInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutUserInput = {
  create: OrderCreateWithoutUserInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutVidVoucherInput = {
  create: OrderCreateWithoutVidVoucherInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutApplicantDataInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutBeamInputsInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutCreatedByInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutDisclosureAcceptancesInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutDispatchedSearchesInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutFilesInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutNotesInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutOrganizationInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutPackageSetsInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutReportableIdVerificationResultInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutRequestedSearchesInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutResultsInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutShareOrderRecordInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutUserInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVoucher?: InputMaybe<VidVoucherCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutVidVoucherInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<OrderAlertEnum>;
  amount: Scalars['Float'];
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutOrderInput>;
  beamInputs?: InputMaybe<BeamInputCreateNestedManyWithoutOrderInput>;
  complianceLetters?: InputMaybe<ComplianceLetterCreateNestedManyWithoutOrderInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrdersInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deliveryMethod?: InputMaybe<OrderDeliveryMethod>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutOrderInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutOrderInput>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileCreateNestedManyWithoutOrderInput>;
  flagged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteCreateNestedManyWithoutOrderInput>;
  optOut?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutOrdersInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutOrdersInput>;
  paid: Scalars['Boolean'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  reportCompletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultCreateNestedOneWithoutOrderInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<Scalars['DateTimeISO']>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutOrderInput>;
  results?: InputMaybe<ResultCreateNestedManyWithoutOrderInput>;
  score?: InputMaybe<OrderScoreEnum>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedOneWithoutOrderInput>;
  status?: InputMaybe<OrderStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutMyOrdersInput>;
};

export enum OrderDeliveryMethod {
  RapidRequest = 'rapidRequest',
  Standard = 'standard',
  VIdWeb = 'vIDWeb',
  VidScreen = 'vidScreen'
}

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderMaxAggregate = {
  __typename?: 'OrderMaxAggregate';
  alert?: Maybe<OrderAlertEnum>;
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  createdById?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deliveryMethod?: Maybe<OrderDeliveryMethod>;
  fileNumber?: Maybe<Scalars['String']>;
  flagged?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  optOut?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  parentOrganizationId?: Maybe<Scalars['String']>;
  postbackWebhookUrl?: Maybe<Scalars['String']>;
  reportCompletedAt?: Maybe<Scalars['DateTimeISO']>;
  reportedAt?: Maybe<Scalars['DateTimeISO']>;
  score?: Maybe<OrderScoreEnum>;
  status?: Maybe<OrderStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  userId?: Maybe<Scalars['String']>;
};

export type OrderMinAggregate = {
  __typename?: 'OrderMinAggregate';
  alert?: Maybe<OrderAlertEnum>;
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  createdById?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deliveryMethod?: Maybe<OrderDeliveryMethod>;
  fileNumber?: Maybe<Scalars['String']>;
  flagged?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  optOut?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  parentOrganizationId?: Maybe<Scalars['String']>;
  postbackWebhookUrl?: Maybe<Scalars['String']>;
  reportCompletedAt?: Maybe<Scalars['DateTimeISO']>;
  reportedAt?: Maybe<Scalars['DateTimeISO']>;
  score?: Maybe<OrderScoreEnum>;
  status?: Maybe<OrderStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  userId?: Maybe<Scalars['String']>;
};

export type OrderNote = {
  __typename?: 'OrderNote';
  createdAt: Scalars['DateTimeISO'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  order: Order;
  orderId: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};


export type OrderNoteCreatedByArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type OrderNoteCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutOrderNotesInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  order: OrderCreateNestedOneWithoutNotesInput;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrderNoteCreateManyCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  orderId: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrderNoteCreateManyCreatedByInputEnvelope = {
  data: Array<OrderNoteCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderNoteCreateManyOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdById?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrderNoteCreateManyOrderInputEnvelope = {
  data: Array<OrderNoteCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderNoteCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<OrderNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderNoteCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<OrderNoteCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<OrderNoteCreateManyCreatedByInputEnvelope>;
};

export type OrderNoteCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<OrderNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderNoteCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<OrderNoteCreateWithoutOrderInput>>;
  createMany?: InputMaybe<OrderNoteCreateManyOrderInputEnvelope>;
};

export type OrderNoteCreateOrConnectWithoutCreatedByInput = {
  create: OrderNoteCreateWithoutCreatedByInput;
  where: OrderNoteWhereUniqueInput;
};

export type OrderNoteCreateOrConnectWithoutOrderInput = {
  create: OrderNoteCreateWithoutOrderInput;
  where: OrderNoteWhereUniqueInput;
};

export type OrderNoteCreateWithoutCreatedByInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  order: OrderCreateNestedOneWithoutNotesInput;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrderNoteCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutOrderNotesInput>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrderNoteListRelationFilter = {
  every?: InputMaybe<OrderNoteWhereInput>;
  none?: InputMaybe<OrderNoteWhereInput>;
  some?: InputMaybe<OrderNoteWhereInput>;
};

export type OrderNoteOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderNoteOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationInput>;
  createdById?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrderInput>;
  message?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum OrderNoteScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Id = 'id',
  IsActive = 'isActive',
  Message = 'message',
  Metadata = 'metadata',
  OrderId = 'orderId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type OrderNoteScalarWhereInput = {
  AND?: InputMaybe<Array<OrderNoteScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderNoteScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderNoteScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolNullableFilter>;
  message?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  orderId?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderNoteUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutOrderNotesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutNotesNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderNoteUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderNoteUpdateManyWithWhereWithoutCreatedByInput = {
  data: OrderNoteUpdateManyMutationInput;
  where: OrderNoteScalarWhereInput;
};

export type OrderNoteUpdateManyWithWhereWithoutOrderInput = {
  data: OrderNoteUpdateManyMutationInput;
  where: OrderNoteScalarWhereInput;
};

export type OrderNoteUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<OrderNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderNoteCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<OrderNoteCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<OrderNoteCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<OrderNoteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderNoteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderNoteWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderNoteWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderNoteUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<OrderNoteUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<OrderNoteUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type OrderNoteUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<OrderNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderNoteCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<OrderNoteCreateWithoutOrderInput>>;
  createMany?: InputMaybe<OrderNoteCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<OrderNoteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderNoteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderNoteWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderNoteWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderNoteUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<OrderNoteUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<OrderNoteUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type OrderNoteUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: OrderNoteUpdateWithoutCreatedByInput;
  where: OrderNoteWhereUniqueInput;
};

export type OrderNoteUpdateWithWhereUniqueWithoutOrderInput = {
  data: OrderNoteUpdateWithoutOrderInput;
  where: OrderNoteWhereUniqueInput;
};

export type OrderNoteUpdateWithoutCreatedByInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutNotesNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderNoteUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutOrderNotesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderNoteUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: OrderNoteCreateWithoutCreatedByInput;
  update: OrderNoteUpdateWithoutCreatedByInput;
  where: OrderNoteWhereUniqueInput;
};

export type OrderNoteUpsertWithWhereUniqueWithoutOrderInput = {
  create: OrderNoteCreateWithoutOrderInput;
  update: OrderNoteUpdateWithoutOrderInput;
  where: OrderNoteWhereUniqueInput;
};

export type OrderNoteWhereInput = {
  AND?: InputMaybe<Array<OrderNoteWhereInput>>;
  NOT?: InputMaybe<Array<OrderNoteWhereInput>>;
  OR?: InputMaybe<Array<OrderNoteWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserNullableRelationFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolNullableFilter>;
  message?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderNoteWhereUniqueInput = {
  AND?: InputMaybe<Array<OrderNoteWhereInput>>;
  NOT?: InputMaybe<Array<OrderNoteWhereInput>>;
  OR?: InputMaybe<Array<OrderNoteWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserNullableRelationFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<BoolNullableFilter>;
  message?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderNullableRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithRelationInput = {
  additionalMetadata?: InputMaybe<SortOrderInput>;
  alert?: InputMaybe<SortOrderInput>;
  amount?: InputMaybe<SortOrder>;
  applicantData?: InputMaybe<ApplicantDataOrderByWithRelationInput>;
  beamInputs?: InputMaybe<BeamInputOrderByRelationAggregateInput>;
  complianceLetters?: InputMaybe<ComplianceLetterOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationInput>;
  createdById?: InputMaybe<SortOrderInput>;
  deleted?: InputMaybe<SortOrderInput>;
  deliveryMethod?: InputMaybe<SortOrderInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceOrderByRelationAggregateInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchOrderByRelationAggregateInput>;
  fileNumber?: InputMaybe<SortOrder>;
  files?: InputMaybe<FileOrderByRelationAggregateInput>;
  flagged?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  internalMetadata?: InputMaybe<SortOrderInput>;
  ipAddress?: InputMaybe<SortOrderInput>;
  ipMetadata?: InputMaybe<SortOrderInput>;
  notes?: InputMaybe<OrderNoteOrderByRelationAggregateInput>;
  optOut?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrderInput>;
  packageSets?: InputMaybe<PackageSetOrderByRelationAggregateInput>;
  paid?: InputMaybe<SortOrder>;
  parentOrganizationId?: InputMaybe<SortOrderInput>;
  postbackWebhookUrl?: InputMaybe<SortOrderInput>;
  reportCompletedAt?: InputMaybe<SortOrderInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultOrderByWithRelationInput>;
  reportableResults?: InputMaybe<SortOrderInput>;
  reportedAt?: InputMaybe<SortOrderInput>;
  requestedSearches?: InputMaybe<RequestedSearchOrderByRelationAggregateInput>;
  results?: InputMaybe<ResultOrderByRelationAggregateInput>;
  score?: InputMaybe<SortOrderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordOrderByWithRelationInput>;
  status?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
  vIDVoucher?: InputMaybe<VidVoucherOrderByWithRelationInput>;
};

export type OrderRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  AdditionalMetadata = 'additionalMetadata',
  Alert = 'alert',
  Amount = 'amount',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Deleted = 'deleted',
  DeliveryMethod = 'deliveryMethod',
  FileNumber = 'fileNumber',
  Flagged = 'flagged',
  Id = 'id',
  InternalMetadata = 'internalMetadata',
  IpAddress = 'ipAddress',
  IpMetadata = 'ipMetadata',
  OptOut = 'optOut',
  OrganizationId = 'organizationId',
  Paid = 'paid',
  ParentOrganizationId = 'parentOrganizationId',
  PostbackWebhookUrl = 'postbackWebhookUrl',
  ReportCompletedAt = 'reportCompletedAt',
  ReportableResults = 'reportableResults',
  ReportedAt = 'reportedAt',
  Score = 'score',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type OrderScalarWhereInput = {
  AND?: InputMaybe<Array<OrderScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderScalarWhereInput>>;
  additionalMetadata?: InputMaybe<JsonNullableFilter>;
  alert?: InputMaybe<EnumOrderAlertEnumNullableFilter>;
  amount?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  deleted?: InputMaybe<BoolNullableFilter>;
  deliveryMethod?: InputMaybe<EnumOrderDeliveryMethodNullableFilter>;
  fileNumber?: InputMaybe<StringFilter>;
  flagged?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  internalMetadata?: InputMaybe<JsonNullableFilter>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  ipMetadata?: InputMaybe<JsonNullableFilter>;
  optOut?: InputMaybe<BoolFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  paid?: InputMaybe<BoolFilter>;
  parentOrganizationId?: InputMaybe<StringNullableFilter>;
  postbackWebhookUrl?: InputMaybe<StringNullableFilter>;
  reportCompletedAt?: InputMaybe<DateTimeNullableFilter>;
  reportableResults?: InputMaybe<JsonNullableFilter>;
  reportedAt?: InputMaybe<DateTimeNullableFilter>;
  score?: InputMaybe<EnumOrderScoreEnumNullableFilter>;
  status?: InputMaybe<EnumOrderStatusEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export enum OrderScoreEnum {
  AdverseAction = 'ADVERSE_ACTION',
  AlertsFound = 'ALERTS_FOUND',
  ClientReview = 'CLIENT_REVIEW',
  IdNotVerified = 'ID_NOT_VERIFIED',
  IdVerified = 'ID_VERIFIED',
  NoAlerts = 'NO_ALERTS',
  NoDrugTest = 'NO_DRUG_TEST',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  PreAdverse = 'PRE_ADVERSE',
  ReviewRequired = 'REVIEW_REQUIRED',
  Withdrawn = 'WITHDRAWN'
}

export enum OrderStatusEnum {
  Complete = 'COMPLETE',
  Pending = 'PENDING'
}

export type OrderSumAggregate = {
  __typename?: 'OrderSumAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type OrderUpdateInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateManyMutationInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateManyWithWhereWithoutCreatedByInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutOrganizationInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutPackageSetsInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutUserInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<OrderCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type OrderUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrderCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrderUpdateManyWithoutPackageSetsNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutPackageSetsInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutPackageSetsInput>>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutPackageSetsInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutPackageSetsInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutPackageSetsInput>>;
};

export type OrderUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutUserInput>>;
  createMany?: InputMaybe<OrderCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutUserInput>>;
};

export type OrderUpdateOneRequiredWithoutApplicantDataNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutApplicantDataInput>;
  create?: InputMaybe<OrderCreateWithoutApplicantDataInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutApplicantDataInput>;
  upsert?: InputMaybe<OrderUpsertWithoutApplicantDataInput>;
};

export type OrderUpdateOneRequiredWithoutDispatchedSearchesNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutDispatchedSearchesInput>;
  create?: InputMaybe<OrderCreateWithoutDispatchedSearchesInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutDispatchedSearchesInput>;
  upsert?: InputMaybe<OrderUpsertWithoutDispatchedSearchesInput>;
};

export type OrderUpdateOneRequiredWithoutNotesNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutNotesInput>;
  create?: InputMaybe<OrderCreateWithoutNotesInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutNotesInput>;
  upsert?: InputMaybe<OrderUpsertWithoutNotesInput>;
};

export type OrderUpdateOneRequiredWithoutReportableIdVerificationResultNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutReportableIdVerificationResultInput>;
  create?: InputMaybe<OrderCreateWithoutReportableIdVerificationResultInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutReportableIdVerificationResultInput>;
  upsert?: InputMaybe<OrderUpsertWithoutReportableIdVerificationResultInput>;
};

export type OrderUpdateOneRequiredWithoutRequestedSearchesNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutRequestedSearchesInput>;
  create?: InputMaybe<OrderCreateWithoutRequestedSearchesInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutRequestedSearchesInput>;
  upsert?: InputMaybe<OrderUpsertWithoutRequestedSearchesInput>;
};

export type OrderUpdateOneRequiredWithoutShareOrderRecordNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutShareOrderRecordInput>;
  create?: InputMaybe<OrderCreateWithoutShareOrderRecordInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutShareOrderRecordInput>;
  upsert?: InputMaybe<OrderUpsertWithoutShareOrderRecordInput>;
};

export type OrderUpdateOneWithoutBeamInputsNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutBeamInputsInput>;
  create?: InputMaybe<OrderCreateWithoutBeamInputsInput>;
  delete?: InputMaybe<OrderWhereInput>;
  disconnect?: InputMaybe<OrderWhereInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutBeamInputsInput>;
  upsert?: InputMaybe<OrderUpsertWithoutBeamInputsInput>;
};

export type OrderUpdateOneWithoutDisclosureAcceptancesNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutDisclosureAcceptancesInput>;
  create?: InputMaybe<OrderCreateWithoutDisclosureAcceptancesInput>;
  delete?: InputMaybe<OrderWhereInput>;
  disconnect?: InputMaybe<OrderWhereInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutDisclosureAcceptancesInput>;
  upsert?: InputMaybe<OrderUpsertWithoutDisclosureAcceptancesInput>;
};

export type OrderUpdateOneWithoutFilesNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<OrderCreateWithoutFilesInput>;
  delete?: InputMaybe<OrderWhereInput>;
  disconnect?: InputMaybe<OrderWhereInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutFilesInput>;
  upsert?: InputMaybe<OrderUpsertWithoutFilesInput>;
};

export type OrderUpdateOneWithoutResultsNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutResultsInput>;
  create?: InputMaybe<OrderCreateWithoutResultsInput>;
  delete?: InputMaybe<OrderWhereInput>;
  disconnect?: InputMaybe<OrderWhereInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutResultsInput>;
  upsert?: InputMaybe<OrderUpsertWithoutResultsInput>;
};

export type OrderUpdateOneWithoutVidVoucherNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutVidVoucherInput>;
  create?: InputMaybe<OrderCreateWithoutVidVoucherInput>;
  delete?: InputMaybe<OrderWhereInput>;
  disconnect?: InputMaybe<OrderWhereInput>;
  update?: InputMaybe<OrderUpdateToOneWithWhereWithoutVidVoucherInput>;
  upsert?: InputMaybe<OrderUpsertWithoutVidVoucherInput>;
};

export type OrderUpdateToOneWithWhereWithoutApplicantDataInput = {
  data: OrderUpdateWithoutApplicantDataInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateToOneWithWhereWithoutBeamInputsInput = {
  data: OrderUpdateWithoutBeamInputsInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateToOneWithWhereWithoutDisclosureAcceptancesInput = {
  data: OrderUpdateWithoutDisclosureAcceptancesInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateToOneWithWhereWithoutDispatchedSearchesInput = {
  data: OrderUpdateWithoutDispatchedSearchesInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateToOneWithWhereWithoutFilesInput = {
  data: OrderUpdateWithoutFilesInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateToOneWithWhereWithoutNotesInput = {
  data: OrderUpdateWithoutNotesInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateToOneWithWhereWithoutReportableIdVerificationResultInput = {
  data: OrderUpdateWithoutReportableIdVerificationResultInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateToOneWithWhereWithoutRequestedSearchesInput = {
  data: OrderUpdateWithoutRequestedSearchesInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateToOneWithWhereWithoutResultsInput = {
  data: OrderUpdateWithoutResultsInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateToOneWithWhereWithoutShareOrderRecordInput = {
  data: OrderUpdateWithoutShareOrderRecordInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateToOneWithWhereWithoutVidVoucherInput = {
  data: OrderUpdateWithoutVidVoucherInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: OrderUpdateWithoutCreatedByInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: OrderUpdateWithoutOrganizationInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutPackageSetsInput = {
  data: OrderUpdateWithoutPackageSetsInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutUserInput = {
  data: OrderUpdateWithoutUserInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithoutApplicantDataInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutBeamInputsInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutCreatedByInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutDisclosureAcceptancesInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutDispatchedSearchesInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutFilesInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutNotesInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutOrganizationInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutPackageSetsInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutReportableIdVerificationResultInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutRequestedSearchesInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutResultsInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutShareOrderRecordInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutUserInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVoucher?: InputMaybe<VidVoucherUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutVidVoucherInput = {
  additionalMetadata?: InputMaybe<Scalars['JSON']>;
  alert?: InputMaybe<NullableEnumOrderAlertEnumFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutOrderNestedInput>;
  beamInputs?: InputMaybe<BeamInputUpdateManyWithoutOrderNestedInput>;
  complianceLetters?: InputMaybe<ComplianceLetterUpdateManyWithoutOrderNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrdersNestedInput>;
  deleted?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  deliveryMethod?: InputMaybe<NullableEnumOrderDeliveryMethodFieldUpdateOperationsInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutOrderNestedInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutOrderNestedInput>;
  fileNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutOrderNestedInput>;
  flagged?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  internalMetadata?: InputMaybe<Scalars['JSON']>;
  ipAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ipMetadata?: InputMaybe<Scalars['JSON']>;
  notes?: InputMaybe<OrderNoteUpdateManyWithoutOrderNestedInput>;
  optOut?: InputMaybe<BoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutOrdersNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutOrdersNestedInput>;
  paid?: InputMaybe<BoolFieldUpdateOperationsInput>;
  parentOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reportCompletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput>;
  reportableResults?: InputMaybe<Scalars['JSON']>;
  reportedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutOrderNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutOrderNestedInput>;
  score?: InputMaybe<NullableEnumOrderScoreEnumFieldUpdateOperationsInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateOneWithoutOrderNestedInput>;
  status?: InputMaybe<NullableEnumOrderStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutMyOrdersNestedInput>;
};

export type OrderUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: OrderCreateWithoutCreatedByInput;
  update: OrderUpdateWithoutCreatedByInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: OrderCreateWithoutOrganizationInput;
  update: OrderUpdateWithoutOrganizationInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutPackageSetsInput = {
  create: OrderCreateWithoutPackageSetsInput;
  update: OrderUpdateWithoutPackageSetsInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutUserInput = {
  create: OrderCreateWithoutUserInput;
  update: OrderUpdateWithoutUserInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithoutApplicantDataInput = {
  create: OrderCreateWithoutApplicantDataInput;
  update: OrderUpdateWithoutApplicantDataInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpsertWithoutBeamInputsInput = {
  create: OrderCreateWithoutBeamInputsInput;
  update: OrderUpdateWithoutBeamInputsInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpsertWithoutDisclosureAcceptancesInput = {
  create: OrderCreateWithoutDisclosureAcceptancesInput;
  update: OrderUpdateWithoutDisclosureAcceptancesInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpsertWithoutDispatchedSearchesInput = {
  create: OrderCreateWithoutDispatchedSearchesInput;
  update: OrderUpdateWithoutDispatchedSearchesInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpsertWithoutFilesInput = {
  create: OrderCreateWithoutFilesInput;
  update: OrderUpdateWithoutFilesInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpsertWithoutNotesInput = {
  create: OrderCreateWithoutNotesInput;
  update: OrderUpdateWithoutNotesInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpsertWithoutReportableIdVerificationResultInput = {
  create: OrderCreateWithoutReportableIdVerificationResultInput;
  update: OrderUpdateWithoutReportableIdVerificationResultInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpsertWithoutRequestedSearchesInput = {
  create: OrderCreateWithoutRequestedSearchesInput;
  update: OrderUpdateWithoutRequestedSearchesInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpsertWithoutResultsInput = {
  create: OrderCreateWithoutResultsInput;
  update: OrderUpdateWithoutResultsInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpsertWithoutShareOrderRecordInput = {
  create: OrderCreateWithoutShareOrderRecordInput;
  update: OrderUpdateWithoutShareOrderRecordInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderUpsertWithoutVidVoucherInput = {
  create: OrderCreateWithoutVidVoucherInput;
  update: OrderUpdateWithoutVidVoucherInput;
  where?: InputMaybe<OrderWhereInput>;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  additionalMetadata?: InputMaybe<JsonNullableFilter>;
  alert?: InputMaybe<EnumOrderAlertEnumNullableFilter>;
  amount?: InputMaybe<FloatFilter>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  beamInputs?: InputMaybe<BeamInputListRelationFilter>;
  complianceLetters?: InputMaybe<ComplianceLetterListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserNullableRelationFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  deleted?: InputMaybe<BoolNullableFilter>;
  deliveryMethod?: InputMaybe<EnumOrderDeliveryMethodNullableFilter>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceListRelationFilter>;
  dispatchedSearches?: InputMaybe<DispatchedSearchListRelationFilter>;
  fileNumber?: InputMaybe<StringFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  flagged?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  internalMetadata?: InputMaybe<JsonNullableFilter>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  ipMetadata?: InputMaybe<JsonNullableFilter>;
  notes?: InputMaybe<OrderNoteListRelationFilter>;
  optOut?: InputMaybe<BoolFilter>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  packageSets?: InputMaybe<PackageSetListRelationFilter>;
  paid?: InputMaybe<BoolFilter>;
  parentOrganizationId?: InputMaybe<StringNullableFilter>;
  postbackWebhookUrl?: InputMaybe<StringNullableFilter>;
  reportCompletedAt?: InputMaybe<DateTimeNullableFilter>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultNullableRelationFilter>;
  reportableResults?: InputMaybe<JsonNullableFilter>;
  reportedAt?: InputMaybe<DateTimeNullableFilter>;
  requestedSearches?: InputMaybe<RequestedSearchListRelationFilter>;
  results?: InputMaybe<ResultListRelationFilter>;
  score?: InputMaybe<EnumOrderScoreEnumNullableFilter>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordNullableRelationFilter>;
  status?: InputMaybe<EnumOrderStatusEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  vIDVoucher?: InputMaybe<VidVoucherNullableRelationFilter>;
};

export type OrderWhereUniqueInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  additionalMetadata?: InputMaybe<JsonNullableFilter>;
  alert?: InputMaybe<EnumOrderAlertEnumNullableFilter>;
  amount?: InputMaybe<FloatFilter>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  beamInputs?: InputMaybe<BeamInputListRelationFilter>;
  complianceLetters?: InputMaybe<ComplianceLetterListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserNullableRelationFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  deleted?: InputMaybe<BoolNullableFilter>;
  deliveryMethod?: InputMaybe<EnumOrderDeliveryMethodNullableFilter>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceListRelationFilter>;
  dispatchedSearches?: InputMaybe<DispatchedSearchListRelationFilter>;
  fileNumber?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<FileListRelationFilter>;
  flagged?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  internalMetadata?: InputMaybe<JsonNullableFilter>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  ipMetadata?: InputMaybe<JsonNullableFilter>;
  notes?: InputMaybe<OrderNoteListRelationFilter>;
  optOut?: InputMaybe<BoolFilter>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  packageSets?: InputMaybe<PackageSetListRelationFilter>;
  paid?: InputMaybe<BoolFilter>;
  parentOrganizationId?: InputMaybe<StringNullableFilter>;
  postbackWebhookUrl?: InputMaybe<StringNullableFilter>;
  reportCompletedAt?: InputMaybe<DateTimeNullableFilter>;
  reportableIdVerificationResult?: InputMaybe<ReportableIdVerificationResultNullableRelationFilter>;
  reportableResults?: InputMaybe<JsonNullableFilter>;
  reportedAt?: InputMaybe<DateTimeNullableFilter>;
  requestedSearches?: InputMaybe<RequestedSearchListRelationFilter>;
  results?: InputMaybe<ResultListRelationFilter>;
  score?: InputMaybe<EnumOrderScoreEnumNullableFilter>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordNullableRelationFilter>;
  status?: InputMaybe<EnumOrderStatusEnumNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  vIDVoucher?: InputMaybe<VidVoucherNullableRelationFilter>;
};

export enum OrgRelationTypeEnum {
  Cra = 'CRA'
}

export type Organization = {
  __typename?: 'Organization';
  _count?: Maybe<OrganizationCount>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  createdById?: Maybe<Scalars['String']>;
  dateOnboarded?: Maybe<Scalars['DateTimeISO']>;
  deletedAt?: Maybe<Scalars['DateTimeISO']>;
  hexColor?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  legalName: Scalars['String'];
  logoId?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  parentOrganizationId?: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
  staticSiteEnabled: Scalars['Boolean'];
  updatedAt: Scalars['DateTimeISO'];
};

export type OrganizationCount = {
  __typename?: 'OrganizationCount';
  address: Scalars['Int'];
  affiliateOrgTo: Scalars['Int'];
  auditLogs: Scalars['Int'];
  credentials: Scalars['Int'];
  disclosures: Scalars['Int'];
  invites: Scalars['Int'];
  members: Scalars['Int'];
  orders: Scalars['Int'];
  passTypes: Scalars['Int'];
  primaryOrgFor: Scalars['Int'];
  roleAssignment: Scalars['Int'];
  subOrganizations: Scalars['Int'];
};


export type OrganizationCountAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};


export type OrganizationCountAffiliateOrgToArgs = {
  where?: InputMaybe<OrganizationRelationWhereInput>;
};


export type OrganizationCountAuditLogsArgs = {
  where?: InputMaybe<AuditLogWhereInput>;
};


export type OrganizationCountCredentialsArgs = {
  where?: InputMaybe<CredentialWhereInput>;
};


export type OrganizationCountDisclosuresArgs = {
  where?: InputMaybe<DisclosureWhereInput>;
};


export type OrganizationCountInvitesArgs = {
  where?: InputMaybe<InviteWhereInput>;
};


export type OrganizationCountMembersArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type OrganizationCountOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type OrganizationCountPassTypesArgs = {
  where?: InputMaybe<PassTypeWhereInput>;
};


export type OrganizationCountPrimaryOrgForArgs = {
  where?: InputMaybe<OrganizationRelationWhereInput>;
};


export type OrganizationCountRoleAssignmentArgs = {
  where?: InputMaybe<RoleAssignmentWhereInput>;
};


export type OrganizationCountSubOrganizationsArgs = {
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationCreateManyCreatedByInput = {
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  logoId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  parentOrganizationId?: InputMaybe<Scalars['String']>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateManyCreatedByInputEnvelope = {
  data: Array<OrganizationCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationCreateManyParentOrganizationInput = {
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdById?: InputMaybe<Scalars['String']>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  logoId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateManyParentOrganizationInputEnvelope = {
  data: Array<OrganizationCreateManyParentOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<OrganizationCreateManyCreatedByInputEnvelope>;
};

export type OrganizationCreateNestedManyWithoutDisclosuresInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutDisclosuresInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutDisclosuresInput>>;
};

export type OrganizationCreateNestedManyWithoutMembersInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutMembersInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutMembersInput>>;
};

export type OrganizationCreateNestedManyWithoutParentOrganizationInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutParentOrganizationInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutParentOrganizationInput>>;
  createMany?: InputMaybe<OrganizationCreateManyParentOrganizationInputEnvelope>;
};

export type OrganizationCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<OrganizationCreateWithoutAddressInput>;
};

export type OrganizationCreateNestedOneWithoutAffiliateOrgToInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAffiliateOrgToInput>;
  create?: InputMaybe<OrganizationCreateWithoutAffiliateOrgToInput>;
};

export type OrganizationCreateNestedOneWithoutCredentialsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCredentialsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCredentialsInput>;
};

export type OrganizationCreateNestedOneWithoutInvitesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutInvitesInput>;
  create?: InputMaybe<OrganizationCreateWithoutInvitesInput>;
};

export type OrganizationCreateNestedOneWithoutLogoInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLogoInput>;
  create?: InputMaybe<OrganizationCreateWithoutLogoInput>;
};

export type OrganizationCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<OrganizationCreateWithoutOrdersInput>;
};

export type OrganizationCreateNestedOneWithoutPassTypesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutPassTypesInput>;
  create?: InputMaybe<OrganizationCreateWithoutPassTypesInput>;
};

export type OrganizationCreateNestedOneWithoutPrimaryOrgForInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutPrimaryOrgForInput>;
  create?: InputMaybe<OrganizationCreateWithoutPrimaryOrgForInput>;
};

export type OrganizationCreateNestedOneWithoutRoleAssignmentInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutRoleAssignmentInput>;
  create?: InputMaybe<OrganizationCreateWithoutRoleAssignmentInput>;
};

export type OrganizationCreateNestedOneWithoutSubOrganizationsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSubOrganizationsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSubOrganizationsInput>;
};

export type OrganizationCreateOrConnectWithoutAddressInput = {
  create: OrganizationCreateWithoutAddressInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutAffiliateOrgToInput = {
  create: OrganizationCreateWithoutAffiliateOrgToInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCreatedByInput = {
  create: OrganizationCreateWithoutCreatedByInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCredentialsInput = {
  create: OrganizationCreateWithoutCredentialsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutDisclosuresInput = {
  create: OrganizationCreateWithoutDisclosuresInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutInvitesInput = {
  create: OrganizationCreateWithoutInvitesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLogoInput = {
  create: OrganizationCreateWithoutLogoInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutMembersInput = {
  create: OrganizationCreateWithoutMembersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutOrdersInput = {
  create: OrganizationCreateWithoutOrdersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutParentOrganizationInput = {
  create: OrganizationCreateWithoutParentOrganizationInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutPassTypesInput = {
  create: OrganizationCreateWithoutPassTypesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutPrimaryOrgForInput = {
  create: OrganizationCreateWithoutPrimaryOrgForInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutRoleAssignmentInput = {
  create: OrganizationCreateWithoutRoleAssignmentInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSubOrganizationsInput = {
  create: OrganizationCreateWithoutSubOrganizationsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateWithoutAddressInput = {
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutAffiliateOrgToInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutCreatedByInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutCredentialsInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutDisclosuresInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutInvitesInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutLogoInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutMembersInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutOrdersInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutParentOrganizationInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutPassTypesInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutPrimaryOrgForInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutRoleAssignmentInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  subOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutParentOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationCreateWithoutSubOrganizationsInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutOrganizationInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput>;
  auditLogs?: InputMaybe<AuditLogCreateNestedManyWithoutOrganizationInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutCreatedOrganizationsInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutOrganizationInput>;
  dateOnboarded?: InputMaybe<Scalars['DateTimeISO']>;
  deletedAt?: InputMaybe<Scalars['DateTimeISO']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutOrganizationsInput>;
  hexColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteCreateNestedManyWithoutOrganizationInput>;
  legalName?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<FileCreateNestedOneWithoutOrganizationInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutOrganizationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutOrganizationInput>;
  parentOrganization?: InputMaybe<OrganizationCreateNestedOneWithoutSubOrganizationsInput>;
  passTypes?: InputMaybe<PassTypeCreateNestedManyWithoutOrganizationInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutOrganizationInput>;
  shortName: Scalars['String'];
  staticSiteEnabled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationListRelationFilter = {
  every?: InputMaybe<OrganizationWhereInput>;
  none?: InputMaybe<OrganizationWhereInput>;
  some?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationNullableRelationFilter = {
  is?: InputMaybe<OrganizationWhereInput>;
  isNot?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrganizationOrderByWithRelationInput = {
  address?: InputMaybe<AddressOrderByRelationAggregateInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationOrderByRelationAggregateInput>;
  auditLogs?: InputMaybe<AuditLogOrderByRelationAggregateInput>;
  contactEmail?: InputMaybe<SortOrderInput>;
  contactName?: InputMaybe<SortOrderInput>;
  contactPhone?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationInput>;
  createdById?: InputMaybe<SortOrderInput>;
  credentials?: InputMaybe<CredentialOrderByRelationAggregateInput>;
  dateOnboarded?: InputMaybe<SortOrderInput>;
  deletedAt?: InputMaybe<SortOrderInput>;
  disclosures?: InputMaybe<DisclosureOrderByRelationAggregateInput>;
  hexColor?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  invites?: InputMaybe<InviteOrderByRelationAggregateInput>;
  legalName?: InputMaybe<SortOrder>;
  logo?: InputMaybe<FileOrderByWithRelationInput>;
  logoId?: InputMaybe<SortOrderInput>;
  members?: InputMaybe<UserOrderByRelationAggregateInput>;
  metadata?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  parentOrganization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  parentOrganizationId?: InputMaybe<SortOrderInput>;
  passTypes?: InputMaybe<PassTypeOrderByRelationAggregateInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationOrderByRelationAggregateInput>;
  roleAssignment?: InputMaybe<RoleAssignmentOrderByRelationAggregateInput>;
  shortName?: InputMaybe<SortOrder>;
  staticSiteEnabled?: InputMaybe<SortOrder>;
  subOrganizations?: InputMaybe<OrganizationOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrganizationRelationCreateManyAffiliateOrgInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  primaryOrgId: Scalars['String'];
  type: OrgRelationTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationRelationCreateManyAffiliateOrgInputEnvelope = {
  data: Array<OrganizationRelationCreateManyAffiliateOrgInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationRelationCreateManyPrimaryOrgInput = {
  affiliateOrgId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  type: OrgRelationTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationRelationCreateManyPrimaryOrgInputEnvelope = {
  data: Array<OrganizationRelationCreateManyPrimaryOrgInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationRelationCreateNestedManyWithoutAffiliateOrgInput = {
  connect?: InputMaybe<Array<OrganizationRelationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationRelationCreateOrConnectWithoutAffiliateOrgInput>>;
  create?: InputMaybe<Array<OrganizationRelationCreateWithoutAffiliateOrgInput>>;
  createMany?: InputMaybe<OrganizationRelationCreateManyAffiliateOrgInputEnvelope>;
};

export type OrganizationRelationCreateNestedManyWithoutPrimaryOrgInput = {
  connect?: InputMaybe<Array<OrganizationRelationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationRelationCreateOrConnectWithoutPrimaryOrgInput>>;
  create?: InputMaybe<Array<OrganizationRelationCreateWithoutPrimaryOrgInput>>;
  createMany?: InputMaybe<OrganizationRelationCreateManyPrimaryOrgInputEnvelope>;
};

export type OrganizationRelationCreateOrConnectWithoutAffiliateOrgInput = {
  create: OrganizationRelationCreateWithoutAffiliateOrgInput;
  where: OrganizationRelationWhereUniqueInput;
};

export type OrganizationRelationCreateOrConnectWithoutPrimaryOrgInput = {
  create: OrganizationRelationCreateWithoutPrimaryOrgInput;
  where: OrganizationRelationWhereUniqueInput;
};

export type OrganizationRelationCreateWithoutAffiliateOrgInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  primaryOrg: OrganizationCreateNestedOneWithoutPrimaryOrgForInput;
  type: OrgRelationTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationRelationCreateWithoutPrimaryOrgInput = {
  affiliateOrg: OrganizationCreateNestedOneWithoutAffiliateOrgToInput;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  type: OrgRelationTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type OrganizationRelationFilter = {
  is?: InputMaybe<OrganizationWhereInput>;
  isNot?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationRelationListRelationFilter = {
  every?: InputMaybe<OrganizationRelationWhereInput>;
  none?: InputMaybe<OrganizationRelationWhereInput>;
  some?: InputMaybe<OrganizationRelationWhereInput>;
};

export type OrganizationRelationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrganizationRelationPrimaryOrgIdAffiliateOrgIdCompoundUniqueInput = {
  affiliateOrgId: Scalars['String'];
  primaryOrgId: Scalars['String'];
};

export type OrganizationRelationScalarWhereInput = {
  AND?: InputMaybe<Array<OrganizationRelationScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationRelationScalarWhereInput>>;
  OR?: InputMaybe<Array<OrganizationRelationScalarWhereInput>>;
  affiliateOrgId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  primaryOrgId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumOrgRelationTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrganizationRelationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumOrgRelationTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationRelationUpdateManyWithWhereWithoutAffiliateOrgInput = {
  data: OrganizationRelationUpdateManyMutationInput;
  where: OrganizationRelationScalarWhereInput;
};

export type OrganizationRelationUpdateManyWithWhereWithoutPrimaryOrgInput = {
  data: OrganizationRelationUpdateManyMutationInput;
  where: OrganizationRelationScalarWhereInput;
};

export type OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput = {
  connect?: InputMaybe<Array<OrganizationRelationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationRelationCreateOrConnectWithoutAffiliateOrgInput>>;
  create?: InputMaybe<Array<OrganizationRelationCreateWithoutAffiliateOrgInput>>;
  createMany?: InputMaybe<OrganizationRelationCreateManyAffiliateOrgInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationRelationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationRelationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationRelationWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationRelationWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationRelationUpdateWithWhereUniqueWithoutAffiliateOrgInput>>;
  updateMany?: InputMaybe<Array<OrganizationRelationUpdateManyWithWhereWithoutAffiliateOrgInput>>;
  upsert?: InputMaybe<Array<OrganizationRelationUpsertWithWhereUniqueWithoutAffiliateOrgInput>>;
};

export type OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput = {
  connect?: InputMaybe<Array<OrganizationRelationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationRelationCreateOrConnectWithoutPrimaryOrgInput>>;
  create?: InputMaybe<Array<OrganizationRelationCreateWithoutPrimaryOrgInput>>;
  createMany?: InputMaybe<OrganizationRelationCreateManyPrimaryOrgInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationRelationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationRelationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationRelationWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationRelationWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationRelationUpdateWithWhereUniqueWithoutPrimaryOrgInput>>;
  updateMany?: InputMaybe<Array<OrganizationRelationUpdateManyWithWhereWithoutPrimaryOrgInput>>;
  upsert?: InputMaybe<Array<OrganizationRelationUpsertWithWhereUniqueWithoutPrimaryOrgInput>>;
};

export type OrganizationRelationUpdateWithWhereUniqueWithoutAffiliateOrgInput = {
  data: OrganizationRelationUpdateWithoutAffiliateOrgInput;
  where: OrganizationRelationWhereUniqueInput;
};

export type OrganizationRelationUpdateWithWhereUniqueWithoutPrimaryOrgInput = {
  data: OrganizationRelationUpdateWithoutPrimaryOrgInput;
  where: OrganizationRelationWhereUniqueInput;
};

export type OrganizationRelationUpdateWithoutAffiliateOrgInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  primaryOrg?: InputMaybe<OrganizationUpdateOneRequiredWithoutPrimaryOrgForNestedInput>;
  type?: InputMaybe<EnumOrgRelationTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationRelationUpdateWithoutPrimaryOrgInput = {
  affiliateOrg?: InputMaybe<OrganizationUpdateOneRequiredWithoutAffiliateOrgToNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumOrgRelationTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationRelationUpsertWithWhereUniqueWithoutAffiliateOrgInput = {
  create: OrganizationRelationCreateWithoutAffiliateOrgInput;
  update: OrganizationRelationUpdateWithoutAffiliateOrgInput;
  where: OrganizationRelationWhereUniqueInput;
};

export type OrganizationRelationUpsertWithWhereUniqueWithoutPrimaryOrgInput = {
  create: OrganizationRelationCreateWithoutPrimaryOrgInput;
  update: OrganizationRelationUpdateWithoutPrimaryOrgInput;
  where: OrganizationRelationWhereUniqueInput;
};

export type OrganizationRelationWhereInput = {
  AND?: InputMaybe<Array<OrganizationRelationWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationRelationWhereInput>>;
  OR?: InputMaybe<Array<OrganizationRelationWhereInput>>;
  affiliateOrg?: InputMaybe<OrganizationRelationFilter>;
  affiliateOrgId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  primaryOrg?: InputMaybe<OrganizationRelationFilter>;
  primaryOrgId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumOrgRelationTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrganizationRelationWhereUniqueInput = {
  AND?: InputMaybe<Array<OrganizationRelationWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationRelationWhereInput>>;
  OR?: InputMaybe<Array<OrganizationRelationWhereInput>>;
  affiliateOrg?: InputMaybe<OrganizationRelationFilter>;
  affiliateOrgId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  primaryOrg?: InputMaybe<OrganizationRelationFilter>;
  primaryOrgId?: InputMaybe<StringFilter>;
  primaryOrgId_affiliateOrgId?: InputMaybe<OrganizationRelationPrimaryOrgIdAffiliateOrgIdCompoundUniqueInput>;
  type?: InputMaybe<EnumOrgRelationTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrganizationRes = {
  __typename?: 'OrganizationRes';
  id?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum OrganizationScalarFieldEnum {
  ContactEmail = 'contactEmail',
  ContactName = 'contactName',
  ContactPhone = 'contactPhone',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  DateOnboarded = 'dateOnboarded',
  DeletedAt = 'deletedAt',
  HexColor = 'hexColor',
  Id = 'id',
  LegalName = 'legalName',
  LogoId = 'logoId',
  Metadata = 'metadata',
  Name = 'name',
  ParentOrganizationId = 'parentOrganizationId',
  ShortName = 'shortName',
  StaticSiteEnabled = 'staticSiteEnabled',
  UpdatedAt = 'updatedAt'
}

export type OrganizationScalarWhereInput = {
  AND?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  OR?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  contactEmail?: InputMaybe<StringNullableFilter>;
  contactName?: InputMaybe<StringNullableFilter>;
  contactPhone?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  dateOnboarded?: InputMaybe<DateTimeNullableFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  hexColor?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  legalName?: InputMaybe<StringFilter>;
  logoId?: InputMaybe<StringNullableFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentOrganizationId?: InputMaybe<StringNullableFilter>;
  shortName?: InputMaybe<StringFilter>;
  staticSiteEnabled?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrganizationUpdateManyMutationInput = {
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateManyWithWhereWithoutCreatedByInput = {
  data: OrganizationUpdateManyMutationInput;
  where: OrganizationScalarWhereInput;
};

export type OrganizationUpdateManyWithWhereWithoutDisclosuresInput = {
  data: OrganizationUpdateManyMutationInput;
  where: OrganizationScalarWhereInput;
};

export type OrganizationUpdateManyWithWhereWithoutMembersInput = {
  data: OrganizationUpdateManyMutationInput;
  where: OrganizationScalarWhereInput;
};

export type OrganizationUpdateManyWithWhereWithoutParentOrganizationInput = {
  data: OrganizationUpdateManyMutationInput;
  where: OrganizationScalarWhereInput;
};

export type OrganizationUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<OrganizationCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<OrganizationUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<OrganizationUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type OrganizationUpdateManyWithoutDisclosuresNestedInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutDisclosuresInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutDisclosuresInput>>;
  delete?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationUpdateWithWhereUniqueWithoutDisclosuresInput>>;
  updateMany?: InputMaybe<Array<OrganizationUpdateManyWithWhereWithoutDisclosuresInput>>;
  upsert?: InputMaybe<Array<OrganizationUpsertWithWhereUniqueWithoutDisclosuresInput>>;
};

export type OrganizationUpdateManyWithoutMembersNestedInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutMembersInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutMembersInput>>;
  delete?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationUpdateWithWhereUniqueWithoutMembersInput>>;
  updateMany?: InputMaybe<Array<OrganizationUpdateManyWithWhereWithoutMembersInput>>;
  upsert?: InputMaybe<Array<OrganizationUpsertWithWhereUniqueWithoutMembersInput>>;
};

export type OrganizationUpdateManyWithoutParentOrganizationNestedInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutParentOrganizationInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutParentOrganizationInput>>;
  createMany?: InputMaybe<OrganizationCreateManyParentOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationUpdateWithWhereUniqueWithoutParentOrganizationInput>>;
  updateMany?: InputMaybe<Array<OrganizationUpdateManyWithWhereWithoutParentOrganizationInput>>;
  upsert?: InputMaybe<Array<OrganizationUpsertWithWhereUniqueWithoutParentOrganizationInput>>;
};

export type OrganizationUpdateOneRequiredWithoutAffiliateOrgToNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAffiliateOrgToInput>;
  create?: InputMaybe<OrganizationCreateWithoutAffiliateOrgToInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutAffiliateOrgToInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutAffiliateOrgToInput>;
};

export type OrganizationUpdateOneRequiredWithoutInvitesNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutInvitesInput>;
  create?: InputMaybe<OrganizationCreateWithoutInvitesInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutInvitesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutInvitesInput>;
};

export type OrganizationUpdateOneRequiredWithoutPassTypesNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutPassTypesInput>;
  create?: InputMaybe<OrganizationCreateWithoutPassTypesInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutPassTypesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutPassTypesInput>;
};

export type OrganizationUpdateOneRequiredWithoutPrimaryOrgForNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutPrimaryOrgForInput>;
  create?: InputMaybe<OrganizationCreateWithoutPrimaryOrgForInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutPrimaryOrgForInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutPrimaryOrgForInput>;
};

export type OrganizationUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<OrganizationCreateWithoutAddressInput>;
  delete?: InputMaybe<OrganizationWhereInput>;
  disconnect?: InputMaybe<OrganizationWhereInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutAddressInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutAddressInput>;
};

export type OrganizationUpdateOneWithoutCredentialsNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCredentialsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCredentialsInput>;
  delete?: InputMaybe<OrganizationWhereInput>;
  disconnect?: InputMaybe<OrganizationWhereInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutCredentialsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCredentialsInput>;
};

export type OrganizationUpdateOneWithoutLogoNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLogoInput>;
  create?: InputMaybe<OrganizationCreateWithoutLogoInput>;
  delete?: InputMaybe<OrganizationWhereInput>;
  disconnect?: InputMaybe<OrganizationWhereInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutLogoInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLogoInput>;
};

export type OrganizationUpdateOneWithoutOrdersNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<OrganizationCreateWithoutOrdersInput>;
  delete?: InputMaybe<OrganizationWhereInput>;
  disconnect?: InputMaybe<OrganizationWhereInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutOrdersInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutOrdersInput>;
};

export type OrganizationUpdateOneWithoutRoleAssignmentNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutRoleAssignmentInput>;
  create?: InputMaybe<OrganizationCreateWithoutRoleAssignmentInput>;
  delete?: InputMaybe<OrganizationWhereInput>;
  disconnect?: InputMaybe<OrganizationWhereInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutRoleAssignmentInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutRoleAssignmentInput>;
};

export type OrganizationUpdateOneWithoutSubOrganizationsNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSubOrganizationsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSubOrganizationsInput>;
  delete?: InputMaybe<OrganizationWhereInput>;
  disconnect?: InputMaybe<OrganizationWhereInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutSubOrganizationsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSubOrganizationsInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutAddressInput = {
  data: OrganizationUpdateWithoutAddressInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutAffiliateOrgToInput = {
  data: OrganizationUpdateWithoutAffiliateOrgToInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutCredentialsInput = {
  data: OrganizationUpdateWithoutCredentialsInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutInvitesInput = {
  data: OrganizationUpdateWithoutInvitesInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutLogoInput = {
  data: OrganizationUpdateWithoutLogoInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutOrdersInput = {
  data: OrganizationUpdateWithoutOrdersInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutPassTypesInput = {
  data: OrganizationUpdateWithoutPassTypesInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutPrimaryOrgForInput = {
  data: OrganizationUpdateWithoutPrimaryOrgForInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutRoleAssignmentInput = {
  data: OrganizationUpdateWithoutRoleAssignmentInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutSubOrganizationsInput = {
  data: OrganizationUpdateWithoutSubOrganizationsInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: OrganizationUpdateWithoutCreatedByInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpdateWithWhereUniqueWithoutDisclosuresInput = {
  data: OrganizationUpdateWithoutDisclosuresInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpdateWithWhereUniqueWithoutMembersInput = {
  data: OrganizationUpdateWithoutMembersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpdateWithWhereUniqueWithoutParentOrganizationInput = {
  data: OrganizationUpdateWithoutParentOrganizationInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpdateWithoutAddressInput = {
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutAffiliateOrgToInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCreatedByInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCredentialsInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutDisclosuresInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutInvitesInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLogoInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutMembersInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutOrdersInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutParentOrganizationInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutPassTypesInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutPrimaryOrgForInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutRoleAssignmentInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subOrganizations?: InputMaybe<OrganizationUpdateManyWithoutParentOrganizationNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSubOrganizationsInput = {
  address?: InputMaybe<AddressUpdateManyWithoutOrganizationNestedInput>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationUpdateManyWithoutAffiliateOrgNestedInput>;
  auditLogs?: InputMaybe<AuditLogUpdateManyWithoutOrganizationNestedInput>;
  contactEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contactPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutCreatedOrganizationsNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutOrganizationNestedInput>;
  dateOnboarded?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutOrganizationsNestedInput>;
  hexColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invites?: InputMaybe<InviteUpdateManyWithoutOrganizationNestedInput>;
  legalName?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<FileUpdateOneWithoutOrganizationNestedInput>;
  members?: InputMaybe<UserUpdateManyWithoutOrganizationsNestedInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutOrganizationNestedInput>;
  parentOrganization?: InputMaybe<OrganizationUpdateOneWithoutSubOrganizationsNestedInput>;
  passTypes?: InputMaybe<PassTypeUpdateManyWithoutOrganizationNestedInput>;
  primaryOrgFor?: InputMaybe<OrganizationRelationUpdateManyWithoutPrimaryOrgNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutOrganizationNestedInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  staticSiteEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: OrganizationCreateWithoutCreatedByInput;
  update: OrganizationUpdateWithoutCreatedByInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpsertWithWhereUniqueWithoutDisclosuresInput = {
  create: OrganizationCreateWithoutDisclosuresInput;
  update: OrganizationUpdateWithoutDisclosuresInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpsertWithWhereUniqueWithoutMembersInput = {
  create: OrganizationCreateWithoutMembersInput;
  update: OrganizationUpdateWithoutMembersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpsertWithWhereUniqueWithoutParentOrganizationInput = {
  create: OrganizationCreateWithoutParentOrganizationInput;
  update: OrganizationUpdateWithoutParentOrganizationInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpsertWithoutAddressInput = {
  create: OrganizationCreateWithoutAddressInput;
  update: OrganizationUpdateWithoutAddressInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutAffiliateOrgToInput = {
  create: OrganizationCreateWithoutAffiliateOrgToInput;
  update: OrganizationUpdateWithoutAffiliateOrgToInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutCredentialsInput = {
  create: OrganizationCreateWithoutCredentialsInput;
  update: OrganizationUpdateWithoutCredentialsInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutInvitesInput = {
  create: OrganizationCreateWithoutInvitesInput;
  update: OrganizationUpdateWithoutInvitesInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutLogoInput = {
  create: OrganizationCreateWithoutLogoInput;
  update: OrganizationUpdateWithoutLogoInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutOrdersInput = {
  create: OrganizationCreateWithoutOrdersInput;
  update: OrganizationUpdateWithoutOrdersInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutPassTypesInput = {
  create: OrganizationCreateWithoutPassTypesInput;
  update: OrganizationUpdateWithoutPassTypesInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutPrimaryOrgForInput = {
  create: OrganizationCreateWithoutPrimaryOrgForInput;
  update: OrganizationUpdateWithoutPrimaryOrgForInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutRoleAssignmentInput = {
  create: OrganizationCreateWithoutRoleAssignmentInput;
  update: OrganizationUpdateWithoutRoleAssignmentInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutSubOrganizationsInput = {
  create: OrganizationCreateWithoutSubOrganizationsInput;
  update: OrganizationUpdateWithoutSubOrganizationsInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationWhereInput = {
  AND?: InputMaybe<Array<OrganizationWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationWhereInput>>;
  OR?: InputMaybe<Array<OrganizationWhereInput>>;
  address?: InputMaybe<AddressListRelationFilter>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationListRelationFilter>;
  auditLogs?: InputMaybe<AuditLogListRelationFilter>;
  contactEmail?: InputMaybe<StringNullableFilter>;
  contactName?: InputMaybe<StringNullableFilter>;
  contactPhone?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserNullableRelationFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  credentials?: InputMaybe<CredentialListRelationFilter>;
  dateOnboarded?: InputMaybe<DateTimeNullableFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  disclosures?: InputMaybe<DisclosureListRelationFilter>;
  hexColor?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invites?: InputMaybe<InviteListRelationFilter>;
  legalName?: InputMaybe<StringFilter>;
  logo?: InputMaybe<FileNullableRelationFilter>;
  logoId?: InputMaybe<StringNullableFilter>;
  members?: InputMaybe<UserListRelationFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  parentOrganization?: InputMaybe<OrganizationNullableRelationFilter>;
  parentOrganizationId?: InputMaybe<StringNullableFilter>;
  passTypes?: InputMaybe<PassTypeListRelationFilter>;
  primaryOrgFor?: InputMaybe<OrganizationRelationListRelationFilter>;
  roleAssignment?: InputMaybe<RoleAssignmentListRelationFilter>;
  shortName?: InputMaybe<StringFilter>;
  staticSiteEnabled?: InputMaybe<BoolFilter>;
  subOrganizations?: InputMaybe<OrganizationListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrganizationWhereUniqueInput = {
  AND?: InputMaybe<Array<OrganizationWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationWhereInput>>;
  OR?: InputMaybe<Array<OrganizationWhereInput>>;
  address?: InputMaybe<AddressListRelationFilter>;
  affiliateOrgTo?: InputMaybe<OrganizationRelationListRelationFilter>;
  auditLogs?: InputMaybe<AuditLogListRelationFilter>;
  contactEmail?: InputMaybe<StringNullableFilter>;
  contactName?: InputMaybe<StringNullableFilter>;
  contactPhone?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserNullableRelationFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  credentials?: InputMaybe<CredentialListRelationFilter>;
  dateOnboarded?: InputMaybe<DateTimeNullableFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  disclosures?: InputMaybe<DisclosureListRelationFilter>;
  hexColor?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteListRelationFilter>;
  legalName?: InputMaybe<StringFilter>;
  logo?: InputMaybe<FileNullableRelationFilter>;
  logoId?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserListRelationFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  parentOrganization?: InputMaybe<OrganizationNullableRelationFilter>;
  parentOrganizationId?: InputMaybe<StringNullableFilter>;
  passTypes?: InputMaybe<PassTypeListRelationFilter>;
  primaryOrgFor?: InputMaybe<OrganizationRelationListRelationFilter>;
  roleAssignment?: InputMaybe<RoleAssignmentListRelationFilter>;
  shortName?: InputMaybe<Scalars['String']>;
  staticSiteEnabled?: InputMaybe<BoolFilter>;
  subOrganizations?: InputMaybe<OrganizationListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PackageSet = {
  __typename?: 'PackageSet';
  _count?: Maybe<PackageSetCount>;
  amount: Scalars['Int'];
  amountPrecision: CurrencyMultiplierEnum;
  collectSSN?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTimeISO'];
  currency: CurrencyTypeEnum;
  description?: Maybe<Scalars['String']>;
  disclosures: Array<Disclosure>;
  id: Scalars['String'];
  inviteReminderPeriod?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  oneAndDone?: Maybe<Scalars['Boolean']>;
  orders: Array<Order>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  packageSetType?: Maybe<PackageSetType>;
  packageSetTypeId?: Maybe<Scalars['String']>;
  postbackWebhookUrl?: Maybe<Scalars['String']>;
  searchTypes: Array<SearchType>;
  sendInviteReminders?: Maybe<Scalars['Boolean']>;
  shortName: Scalars['String'];
  showDisclosures?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTimeISO'];
  useSampleId?: Maybe<Scalars['Boolean']>;
  vIDVouchers: Array<VidVoucher>;
};


export type PackageSetDisclosuresArgs = {
  cursor?: InputMaybe<DisclosureWhereUniqueInput>;
  distinct?: InputMaybe<Array<DisclosureScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DisclosureOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DisclosureWhereInput>;
};


export type PackageSetOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type PackageSetPackageSetTypeArgs = {
  where?: InputMaybe<PackageSetTypeWhereInput>;
};


export type PackageSetSearchTypesArgs = {
  cursor?: InputMaybe<SearchTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<SearchTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SearchTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SearchTypeWhereInput>;
};


export type PackageSetVIdVouchersArgs = {
  cursor?: InputMaybe<VidVoucherWhereUniqueInput>;
  distinct?: InputMaybe<Array<VidVoucherScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VidVoucherOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VidVoucherWhereInput>;
};

export type PackageSetAvgAggregate = {
  __typename?: 'PackageSetAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
  inviteReminderPeriod?: Maybe<Scalars['Float']>;
};

export type PackageSetCount = {
  __typename?: 'PackageSetCount';
  disclosures: Scalars['Int'];
  orders: Scalars['Int'];
  searchTypes: Scalars['Int'];
  vIDVouchers: Scalars['Int'];
};


export type PackageSetCountDisclosuresArgs = {
  where?: InputMaybe<DisclosureWhereInput>;
};


export type PackageSetCountOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type PackageSetCountSearchTypesArgs = {
  where?: InputMaybe<SearchTypeWhereInput>;
};


export type PackageSetCountVIdVouchersArgs = {
  where?: InputMaybe<VidVoucherWhereInput>;
};

export type PackageSetCountAggregate = {
  __typename?: 'PackageSetCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  amountPrecision: Scalars['Int'];
  collectSSN: Scalars['Int'];
  createdAt: Scalars['Int'];
  currency: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  inviteReminderPeriod: Scalars['Int'];
  isActive: Scalars['Int'];
  metadata: Scalars['Int'];
  name: Scalars['Int'];
  oneAndDone: Scalars['Int'];
  organizationId: Scalars['Int'];
  packageSetTypeId: Scalars['Int'];
  postbackWebhookUrl: Scalars['Int'];
  sendInviteReminders: Scalars['Int'];
  shortName: Scalars['Int'];
  showDisclosures: Scalars['Int'];
  updatedAt: Scalars['Int'];
  useSampleId: Scalars['Int'];
};

export type PackageSetCreateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  amountPrecision?: InputMaybe<CurrencyMultiplierEnum>;
  collectSSN?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  currency?: InputMaybe<CurrencyTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutPackageSetsInput>;
  id?: InputMaybe<Scalars['String']>;
  inviteReminderPeriod?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  oneAndDone?: InputMaybe<Scalars['Boolean']>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutPackageSetsInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  packageSetType?: InputMaybe<PackageSetTypeCreateNestedOneWithoutPackageSetsInput>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  searchTypes?: InputMaybe<SearchTypeCreateNestedManyWithoutPackageSetsInput>;
  sendInviteReminders?: InputMaybe<Scalars['Boolean']>;
  shortName: Scalars['String'];
  showDisclosures?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  useSampleId?: InputMaybe<Scalars['Boolean']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutPackageSetInput>;
};

export type PackageSetCreateManyPackageSetTypeInput = {
  amount?: InputMaybe<Scalars['Int']>;
  amountPrecision?: InputMaybe<CurrencyMultiplierEnum>;
  collectSSN?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  currency?: InputMaybe<CurrencyTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inviteReminderPeriod?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  oneAndDone?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  sendInviteReminders?: InputMaybe<Scalars['Boolean']>;
  shortName: Scalars['String'];
  showDisclosures?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  useSampleId?: InputMaybe<Scalars['Boolean']>;
};

export type PackageSetCreateManyPackageSetTypeInputEnvelope = {
  data: Array<PackageSetCreateManyPackageSetTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PackageSetCreateNestedManyWithoutDisclosuresInput = {
  connect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSetCreateOrConnectWithoutDisclosuresInput>>;
  create?: InputMaybe<Array<PackageSetCreateWithoutDisclosuresInput>>;
};

export type PackageSetCreateNestedManyWithoutOrdersInput = {
  connect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSetCreateOrConnectWithoutOrdersInput>>;
  create?: InputMaybe<Array<PackageSetCreateWithoutOrdersInput>>;
};

export type PackageSetCreateNestedManyWithoutPackageSetTypeInput = {
  connect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSetCreateOrConnectWithoutPackageSetTypeInput>>;
  create?: InputMaybe<Array<PackageSetCreateWithoutPackageSetTypeInput>>;
  createMany?: InputMaybe<PackageSetCreateManyPackageSetTypeInputEnvelope>;
};

export type PackageSetCreateNestedManyWithoutSearchTypesInput = {
  connect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSetCreateOrConnectWithoutSearchTypesInput>>;
  create?: InputMaybe<Array<PackageSetCreateWithoutSearchTypesInput>>;
};

export type PackageSetCreateNestedOneWithoutVidVouchersInput = {
  connect?: InputMaybe<PackageSetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackageSetCreateOrConnectWithoutVidVouchersInput>;
  create?: InputMaybe<PackageSetCreateWithoutVidVouchersInput>;
};

export type PackageSetCreateOrConnectWithoutDisclosuresInput = {
  create: PackageSetCreateWithoutDisclosuresInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetCreateOrConnectWithoutOrdersInput = {
  create: PackageSetCreateWithoutOrdersInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetCreateOrConnectWithoutPackageSetTypeInput = {
  create: PackageSetCreateWithoutPackageSetTypeInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetCreateOrConnectWithoutSearchTypesInput = {
  create: PackageSetCreateWithoutSearchTypesInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetCreateOrConnectWithoutVidVouchersInput = {
  create: PackageSetCreateWithoutVidVouchersInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetCreateWithoutDisclosuresInput = {
  amount?: InputMaybe<Scalars['Int']>;
  amountPrecision?: InputMaybe<CurrencyMultiplierEnum>;
  collectSSN?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  currency?: InputMaybe<CurrencyTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inviteReminderPeriod?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  oneAndDone?: InputMaybe<Scalars['Boolean']>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutPackageSetsInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  packageSetType?: InputMaybe<PackageSetTypeCreateNestedOneWithoutPackageSetsInput>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  searchTypes?: InputMaybe<SearchTypeCreateNestedManyWithoutPackageSetsInput>;
  sendInviteReminders?: InputMaybe<Scalars['Boolean']>;
  shortName: Scalars['String'];
  showDisclosures?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  useSampleId?: InputMaybe<Scalars['Boolean']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutPackageSetInput>;
};

export type PackageSetCreateWithoutOrdersInput = {
  amount?: InputMaybe<Scalars['Int']>;
  amountPrecision?: InputMaybe<CurrencyMultiplierEnum>;
  collectSSN?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  currency?: InputMaybe<CurrencyTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutPackageSetsInput>;
  id?: InputMaybe<Scalars['String']>;
  inviteReminderPeriod?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  oneAndDone?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  packageSetType?: InputMaybe<PackageSetTypeCreateNestedOneWithoutPackageSetsInput>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  searchTypes?: InputMaybe<SearchTypeCreateNestedManyWithoutPackageSetsInput>;
  sendInviteReminders?: InputMaybe<Scalars['Boolean']>;
  shortName: Scalars['String'];
  showDisclosures?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  useSampleId?: InputMaybe<Scalars['Boolean']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutPackageSetInput>;
};

export type PackageSetCreateWithoutPackageSetTypeInput = {
  amount?: InputMaybe<Scalars['Int']>;
  amountPrecision?: InputMaybe<CurrencyMultiplierEnum>;
  collectSSN?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  currency?: InputMaybe<CurrencyTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutPackageSetsInput>;
  id?: InputMaybe<Scalars['String']>;
  inviteReminderPeriod?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  oneAndDone?: InputMaybe<Scalars['Boolean']>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutPackageSetsInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  searchTypes?: InputMaybe<SearchTypeCreateNestedManyWithoutPackageSetsInput>;
  sendInviteReminders?: InputMaybe<Scalars['Boolean']>;
  shortName: Scalars['String'];
  showDisclosures?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  useSampleId?: InputMaybe<Scalars['Boolean']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutPackageSetInput>;
};

export type PackageSetCreateWithoutSearchTypesInput = {
  amount?: InputMaybe<Scalars['Int']>;
  amountPrecision?: InputMaybe<CurrencyMultiplierEnum>;
  collectSSN?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  currency?: InputMaybe<CurrencyTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutPackageSetsInput>;
  id?: InputMaybe<Scalars['String']>;
  inviteReminderPeriod?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  oneAndDone?: InputMaybe<Scalars['Boolean']>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutPackageSetsInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  packageSetType?: InputMaybe<PackageSetTypeCreateNestedOneWithoutPackageSetsInput>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  sendInviteReminders?: InputMaybe<Scalars['Boolean']>;
  shortName: Scalars['String'];
  showDisclosures?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  useSampleId?: InputMaybe<Scalars['Boolean']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutPackageSetInput>;
};

export type PackageSetCreateWithoutVidVouchersInput = {
  amount?: InputMaybe<Scalars['Int']>;
  amountPrecision?: InputMaybe<CurrencyMultiplierEnum>;
  collectSSN?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  currency?: InputMaybe<CurrencyTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  disclosures?: InputMaybe<DisclosureCreateNestedManyWithoutPackageSetsInput>;
  id?: InputMaybe<Scalars['String']>;
  inviteReminderPeriod?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  oneAndDone?: InputMaybe<Scalars['Boolean']>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutPackageSetsInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  packageSetType?: InputMaybe<PackageSetTypeCreateNestedOneWithoutPackageSetsInput>;
  postbackWebhookUrl?: InputMaybe<Scalars['String']>;
  searchTypes?: InputMaybe<SearchTypeCreateNestedManyWithoutPackageSetsInput>;
  sendInviteReminders?: InputMaybe<Scalars['Boolean']>;
  shortName: Scalars['String'];
  showDisclosures?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  useSampleId?: InputMaybe<Scalars['Boolean']>;
};

export type PackageSetListRelationFilter = {
  every?: InputMaybe<PackageSetWhereInput>;
  none?: InputMaybe<PackageSetWhereInput>;
  some?: InputMaybe<PackageSetWhereInput>;
};

export type PackageSetMaxAggregate = {
  __typename?: 'PackageSetMaxAggregate';
  amount?: Maybe<Scalars['Int']>;
  amountPrecision?: Maybe<CurrencyMultiplierEnum>;
  collectSSN?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  currency?: Maybe<CurrencyTypeEnum>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inviteReminderPeriod?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  oneAndDone?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  packageSetTypeId?: Maybe<Scalars['String']>;
  postbackWebhookUrl?: Maybe<Scalars['String']>;
  sendInviteReminders?: Maybe<Scalars['Boolean']>;
  shortName?: Maybe<Scalars['String']>;
  showDisclosures?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  useSampleId?: Maybe<Scalars['Boolean']>;
};

export type PackageSetMinAggregate = {
  __typename?: 'PackageSetMinAggregate';
  amount?: Maybe<Scalars['Int']>;
  amountPrecision?: Maybe<CurrencyMultiplierEnum>;
  collectSSN?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  currency?: Maybe<CurrencyTypeEnum>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inviteReminderPeriod?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  oneAndDone?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  packageSetTypeId?: Maybe<Scalars['String']>;
  postbackWebhookUrl?: Maybe<Scalars['String']>;
  sendInviteReminders?: Maybe<Scalars['Boolean']>;
  shortName?: Maybe<Scalars['String']>;
  showDisclosures?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  useSampleId?: Maybe<Scalars['Boolean']>;
};

export type PackageSetNameOrganizationIdCompoundUniqueInput = {
  name: Scalars['String'];
  organizationId: Scalars['String'];
};

export type PackageSetOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PackageSetOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  amountPrecision?: InputMaybe<SortOrder>;
  collectSSN?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  disclosures?: InputMaybe<DisclosureOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  inviteReminderPeriod?: InputMaybe<SortOrderInput>;
  isActive?: InputMaybe<SortOrderInput>;
  metadata?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  oneAndDone?: InputMaybe<SortOrderInput>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  organizationId?: InputMaybe<SortOrderInput>;
  packageSetType?: InputMaybe<PackageSetTypeOrderByWithRelationInput>;
  packageSetTypeId?: InputMaybe<SortOrderInput>;
  postbackWebhookUrl?: InputMaybe<SortOrderInput>;
  searchTypes?: InputMaybe<SearchTypeOrderByRelationAggregateInput>;
  sendInviteReminders?: InputMaybe<SortOrderInput>;
  shortName?: InputMaybe<SortOrder>;
  showDisclosures?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  useSampleId?: InputMaybe<SortOrderInput>;
  vIDVouchers?: InputMaybe<VidVoucherOrderByRelationAggregateInput>;
};

export type PackageSetRelationFilter = {
  is?: InputMaybe<PackageSetWhereInput>;
  isNot?: InputMaybe<PackageSetWhereInput>;
};

export enum PackageSetScalarFieldEnum {
  Amount = 'amount',
  AmountPrecision = 'amountPrecision',
  CollectSsn = 'collectSSN',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  Description = 'description',
  Id = 'id',
  InviteReminderPeriod = 'inviteReminderPeriod',
  IsActive = 'isActive',
  Metadata = 'metadata',
  Name = 'name',
  OneAndDone = 'oneAndDone',
  OrganizationId = 'organizationId',
  PackageSetTypeId = 'packageSetTypeId',
  PostbackWebhookUrl = 'postbackWebhookUrl',
  SendInviteReminders = 'sendInviteReminders',
  ShortName = 'shortName',
  ShowDisclosures = 'showDisclosures',
  UpdatedAt = 'updatedAt',
  UseSampleId = 'useSampleId'
}

export type PackageSetScalarWhereInput = {
  AND?: InputMaybe<Array<PackageSetScalarWhereInput>>;
  NOT?: InputMaybe<Array<PackageSetScalarWhereInput>>;
  OR?: InputMaybe<Array<PackageSetScalarWhereInput>>;
  amount?: InputMaybe<IntFilter>;
  amountPrecision?: InputMaybe<EnumCurrencyMultiplierEnumFilter>;
  collectSSN?: InputMaybe<BoolNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumCurrencyTypeEnumFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inviteReminderPeriod?: InputMaybe<IntNullableFilter>;
  isActive?: InputMaybe<BoolNullableFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  oneAndDone?: InputMaybe<BoolNullableFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  packageSetTypeId?: InputMaybe<StringNullableFilter>;
  postbackWebhookUrl?: InputMaybe<StringNullableFilter>;
  sendInviteReminders?: InputMaybe<BoolNullableFilter>;
  shortName?: InputMaybe<StringFilter>;
  showDisclosures?: InputMaybe<BoolNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  useSampleId?: InputMaybe<BoolNullableFilter>;
};

export type PackageSetShortNameOrganizationIdCompoundUniqueInput = {
  organizationId: Scalars['String'];
  shortName: Scalars['String'];
};

export type PackageSetSumAggregate = {
  __typename?: 'PackageSetSumAggregate';
  amount?: Maybe<Scalars['Int']>;
  inviteReminderPeriod?: Maybe<Scalars['Int']>;
};

export type PackageSetType = {
  __typename?: 'PackageSetType';
  _count?: Maybe<PackageSetTypeCount>;
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type PackageSetTypeCount = {
  __typename?: 'PackageSetTypeCount';
  packageSets: Scalars['Int'];
  searchTypes: Scalars['Int'];
};


export type PackageSetTypeCountPackageSetsArgs = {
  where?: InputMaybe<PackageSetWhereInput>;
};


export type PackageSetTypeCountSearchTypesArgs = {
  where?: InputMaybe<SearchTypeWhereInput>;
};

export type PackageSetTypeCreateNestedManyWithoutSearchTypesInput = {
  connect?: InputMaybe<Array<PackageSetTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSetTypeCreateOrConnectWithoutSearchTypesInput>>;
  create?: InputMaybe<Array<PackageSetTypeCreateWithoutSearchTypesInput>>;
};

export type PackageSetTypeCreateNestedOneWithoutPackageSetsInput = {
  connect?: InputMaybe<PackageSetTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackageSetTypeCreateOrConnectWithoutPackageSetsInput>;
  create?: InputMaybe<PackageSetTypeCreateWithoutPackageSetsInput>;
};

export type PackageSetTypeCreateOrConnectWithoutPackageSetsInput = {
  create: PackageSetTypeCreateWithoutPackageSetsInput;
  where: PackageSetTypeWhereUniqueInput;
};

export type PackageSetTypeCreateOrConnectWithoutSearchTypesInput = {
  create: PackageSetTypeCreateWithoutSearchTypesInput;
  where: PackageSetTypeWhereUniqueInput;
};

export type PackageSetTypeCreateWithoutPackageSetsInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  searchTypes?: InputMaybe<SearchTypeCreateNestedManyWithoutPackageSetTypesInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type PackageSetTypeCreateWithoutSearchTypesInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutPackageSetTypeInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type PackageSetTypeListRelationFilter = {
  every?: InputMaybe<PackageSetTypeWhereInput>;
  none?: InputMaybe<PackageSetTypeWhereInput>;
  some?: InputMaybe<PackageSetTypeWhereInput>;
};

export type PackageSetTypeNullableRelationFilter = {
  is?: InputMaybe<PackageSetTypeWhereInput>;
  isNot?: InputMaybe<PackageSetTypeWhereInput>;
};

export type PackageSetTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PackageSetTypeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  packageSets?: InputMaybe<PackageSetOrderByRelationAggregateInput>;
  searchTypes?: InputMaybe<SearchTypeOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PackageSetTypeScalarWhereInput = {
  AND?: InputMaybe<Array<PackageSetTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<PackageSetTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<PackageSetTypeScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PackageSetTypeUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PackageSetTypeUpdateManyWithWhereWithoutSearchTypesInput = {
  data: PackageSetTypeUpdateManyMutationInput;
  where: PackageSetTypeScalarWhereInput;
};

export type PackageSetTypeUpdateManyWithoutSearchTypesNestedInput = {
  connect?: InputMaybe<Array<PackageSetTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSetTypeCreateOrConnectWithoutSearchTypesInput>>;
  create?: InputMaybe<Array<PackageSetTypeCreateWithoutSearchTypesInput>>;
  delete?: InputMaybe<Array<PackageSetTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackageSetTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackageSetTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<PackageSetTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<PackageSetTypeUpdateWithWhereUniqueWithoutSearchTypesInput>>;
  updateMany?: InputMaybe<Array<PackageSetTypeUpdateManyWithWhereWithoutSearchTypesInput>>;
  upsert?: InputMaybe<Array<PackageSetTypeUpsertWithWhereUniqueWithoutSearchTypesInput>>;
};

export type PackageSetTypeUpdateOneWithoutPackageSetsNestedInput = {
  connect?: InputMaybe<PackageSetTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackageSetTypeCreateOrConnectWithoutPackageSetsInput>;
  create?: InputMaybe<PackageSetTypeCreateWithoutPackageSetsInput>;
  delete?: InputMaybe<PackageSetTypeWhereInput>;
  disconnect?: InputMaybe<PackageSetTypeWhereInput>;
  update?: InputMaybe<PackageSetTypeUpdateToOneWithWhereWithoutPackageSetsInput>;
  upsert?: InputMaybe<PackageSetTypeUpsertWithoutPackageSetsInput>;
};

export type PackageSetTypeUpdateToOneWithWhereWithoutPackageSetsInput = {
  data: PackageSetTypeUpdateWithoutPackageSetsInput;
  where?: InputMaybe<PackageSetTypeWhereInput>;
};

export type PackageSetTypeUpdateWithWhereUniqueWithoutSearchTypesInput = {
  data: PackageSetTypeUpdateWithoutSearchTypesInput;
  where: PackageSetTypeWhereUniqueInput;
};

export type PackageSetTypeUpdateWithoutPackageSetsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  searchTypes?: InputMaybe<SearchTypeUpdateManyWithoutPackageSetTypesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PackageSetTypeUpdateWithoutSearchTypesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutPackageSetTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PackageSetTypeUpsertWithWhereUniqueWithoutSearchTypesInput = {
  create: PackageSetTypeCreateWithoutSearchTypesInput;
  update: PackageSetTypeUpdateWithoutSearchTypesInput;
  where: PackageSetTypeWhereUniqueInput;
};

export type PackageSetTypeUpsertWithoutPackageSetsInput = {
  create: PackageSetTypeCreateWithoutPackageSetsInput;
  update: PackageSetTypeUpdateWithoutPackageSetsInput;
  where?: InputMaybe<PackageSetTypeWhereInput>;
};

export type PackageSetTypeWhereInput = {
  AND?: InputMaybe<Array<PackageSetTypeWhereInput>>;
  NOT?: InputMaybe<Array<PackageSetTypeWhereInput>>;
  OR?: InputMaybe<Array<PackageSetTypeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  packageSets?: InputMaybe<PackageSetListRelationFilter>;
  searchTypes?: InputMaybe<SearchTypeListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PackageSetTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<PackageSetTypeWhereInput>>;
  NOT?: InputMaybe<Array<PackageSetTypeWhereInput>>;
  OR?: InputMaybe<Array<PackageSetTypeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<StringFilter>;
  packageSets?: InputMaybe<PackageSetListRelationFilter>;
  searchTypes?: InputMaybe<SearchTypeListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PackageSetUpdateInput = {
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  amountPrecision?: InputMaybe<EnumCurrencyMultiplierEnumFieldUpdateOperationsInput>;
  collectSSN?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumCurrencyTypeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutPackageSetsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteReminderPeriod?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  oneAndDone?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutPackageSetsNestedInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  packageSetType?: InputMaybe<PackageSetTypeUpdateOneWithoutPackageSetsNestedInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  searchTypes?: InputMaybe<SearchTypeUpdateManyWithoutPackageSetsNestedInput>;
  sendInviteReminders?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  showDisclosures?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useSampleId?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutPackageSetNestedInput>;
};

export type PackageSetUpdateManyMutationInput = {
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  amountPrecision?: InputMaybe<EnumCurrencyMultiplierEnumFieldUpdateOperationsInput>;
  collectSSN?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumCurrencyTypeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteReminderPeriod?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  oneAndDone?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendInviteReminders?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  showDisclosures?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useSampleId?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type PackageSetUpdateManyWithWhereWithoutDisclosuresInput = {
  data: PackageSetUpdateManyMutationInput;
  where: PackageSetScalarWhereInput;
};

export type PackageSetUpdateManyWithWhereWithoutOrdersInput = {
  data: PackageSetUpdateManyMutationInput;
  where: PackageSetScalarWhereInput;
};

export type PackageSetUpdateManyWithWhereWithoutPackageSetTypeInput = {
  data: PackageSetUpdateManyMutationInput;
  where: PackageSetScalarWhereInput;
};

export type PackageSetUpdateManyWithWhereWithoutSearchTypesInput = {
  data: PackageSetUpdateManyMutationInput;
  where: PackageSetScalarWhereInput;
};

export type PackageSetUpdateManyWithoutDisclosuresNestedInput = {
  connect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSetCreateOrConnectWithoutDisclosuresInput>>;
  create?: InputMaybe<Array<PackageSetCreateWithoutDisclosuresInput>>;
  delete?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackageSetScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  set?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  update?: InputMaybe<Array<PackageSetUpdateWithWhereUniqueWithoutDisclosuresInput>>;
  updateMany?: InputMaybe<Array<PackageSetUpdateManyWithWhereWithoutDisclosuresInput>>;
  upsert?: InputMaybe<Array<PackageSetUpsertWithWhereUniqueWithoutDisclosuresInput>>;
};

export type PackageSetUpdateManyWithoutOrdersNestedInput = {
  connect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSetCreateOrConnectWithoutOrdersInput>>;
  create?: InputMaybe<Array<PackageSetCreateWithoutOrdersInput>>;
  delete?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackageSetScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  set?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  update?: InputMaybe<Array<PackageSetUpdateWithWhereUniqueWithoutOrdersInput>>;
  updateMany?: InputMaybe<Array<PackageSetUpdateManyWithWhereWithoutOrdersInput>>;
  upsert?: InputMaybe<Array<PackageSetUpsertWithWhereUniqueWithoutOrdersInput>>;
};

export type PackageSetUpdateManyWithoutPackageSetTypeNestedInput = {
  connect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSetCreateOrConnectWithoutPackageSetTypeInput>>;
  create?: InputMaybe<Array<PackageSetCreateWithoutPackageSetTypeInput>>;
  createMany?: InputMaybe<PackageSetCreateManyPackageSetTypeInputEnvelope>;
  delete?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackageSetScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  set?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  update?: InputMaybe<Array<PackageSetUpdateWithWhereUniqueWithoutPackageSetTypeInput>>;
  updateMany?: InputMaybe<Array<PackageSetUpdateManyWithWhereWithoutPackageSetTypeInput>>;
  upsert?: InputMaybe<Array<PackageSetUpsertWithWhereUniqueWithoutPackageSetTypeInput>>;
};

export type PackageSetUpdateManyWithoutSearchTypesNestedInput = {
  connect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSetCreateOrConnectWithoutSearchTypesInput>>;
  create?: InputMaybe<Array<PackageSetCreateWithoutSearchTypesInput>>;
  delete?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackageSetScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  set?: InputMaybe<Array<PackageSetWhereUniqueInput>>;
  update?: InputMaybe<Array<PackageSetUpdateWithWhereUniqueWithoutSearchTypesInput>>;
  updateMany?: InputMaybe<Array<PackageSetUpdateManyWithWhereWithoutSearchTypesInput>>;
  upsert?: InputMaybe<Array<PackageSetUpsertWithWhereUniqueWithoutSearchTypesInput>>;
};

export type PackageSetUpdateOneRequiredWithoutVidVouchersNestedInput = {
  connect?: InputMaybe<PackageSetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PackageSetCreateOrConnectWithoutVidVouchersInput>;
  create?: InputMaybe<PackageSetCreateWithoutVidVouchersInput>;
  update?: InputMaybe<PackageSetUpdateToOneWithWhereWithoutVidVouchersInput>;
  upsert?: InputMaybe<PackageSetUpsertWithoutVidVouchersInput>;
};

export type PackageSetUpdateToOneWithWhereWithoutVidVouchersInput = {
  data: PackageSetUpdateWithoutVidVouchersInput;
  where?: InputMaybe<PackageSetWhereInput>;
};

export type PackageSetUpdateWithWhereUniqueWithoutDisclosuresInput = {
  data: PackageSetUpdateWithoutDisclosuresInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetUpdateWithWhereUniqueWithoutOrdersInput = {
  data: PackageSetUpdateWithoutOrdersInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetUpdateWithWhereUniqueWithoutPackageSetTypeInput = {
  data: PackageSetUpdateWithoutPackageSetTypeInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetUpdateWithWhereUniqueWithoutSearchTypesInput = {
  data: PackageSetUpdateWithoutSearchTypesInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetUpdateWithoutDisclosuresInput = {
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  amountPrecision?: InputMaybe<EnumCurrencyMultiplierEnumFieldUpdateOperationsInput>;
  collectSSN?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumCurrencyTypeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteReminderPeriod?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  oneAndDone?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutPackageSetsNestedInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  packageSetType?: InputMaybe<PackageSetTypeUpdateOneWithoutPackageSetsNestedInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  searchTypes?: InputMaybe<SearchTypeUpdateManyWithoutPackageSetsNestedInput>;
  sendInviteReminders?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  showDisclosures?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useSampleId?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutPackageSetNestedInput>;
};

export type PackageSetUpdateWithoutOrdersInput = {
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  amountPrecision?: InputMaybe<EnumCurrencyMultiplierEnumFieldUpdateOperationsInput>;
  collectSSN?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumCurrencyTypeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutPackageSetsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteReminderPeriod?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  oneAndDone?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  packageSetType?: InputMaybe<PackageSetTypeUpdateOneWithoutPackageSetsNestedInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  searchTypes?: InputMaybe<SearchTypeUpdateManyWithoutPackageSetsNestedInput>;
  sendInviteReminders?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  showDisclosures?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useSampleId?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutPackageSetNestedInput>;
};

export type PackageSetUpdateWithoutPackageSetTypeInput = {
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  amountPrecision?: InputMaybe<EnumCurrencyMultiplierEnumFieldUpdateOperationsInput>;
  collectSSN?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumCurrencyTypeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutPackageSetsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteReminderPeriod?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  oneAndDone?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutPackageSetsNestedInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  searchTypes?: InputMaybe<SearchTypeUpdateManyWithoutPackageSetsNestedInput>;
  sendInviteReminders?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  showDisclosures?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useSampleId?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutPackageSetNestedInput>;
};

export type PackageSetUpdateWithoutSearchTypesInput = {
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  amountPrecision?: InputMaybe<EnumCurrencyMultiplierEnumFieldUpdateOperationsInput>;
  collectSSN?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumCurrencyTypeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutPackageSetsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteReminderPeriod?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  oneAndDone?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutPackageSetsNestedInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  packageSetType?: InputMaybe<PackageSetTypeUpdateOneWithoutPackageSetsNestedInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendInviteReminders?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  showDisclosures?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useSampleId?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutPackageSetNestedInput>;
};

export type PackageSetUpdateWithoutVidVouchersInput = {
  amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  amountPrecision?: InputMaybe<EnumCurrencyMultiplierEnumFieldUpdateOperationsInput>;
  collectSSN?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumCurrencyTypeEnumFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  disclosures?: InputMaybe<DisclosureUpdateManyWithoutPackageSetsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteReminderPeriod?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  oneAndDone?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutPackageSetsNestedInput>;
  organizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  packageSetType?: InputMaybe<PackageSetTypeUpdateOneWithoutPackageSetsNestedInput>;
  postbackWebhookUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  searchTypes?: InputMaybe<SearchTypeUpdateManyWithoutPackageSetsNestedInput>;
  sendInviteReminders?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
  showDisclosures?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useSampleId?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type PackageSetUpsertWithWhereUniqueWithoutDisclosuresInput = {
  create: PackageSetCreateWithoutDisclosuresInput;
  update: PackageSetUpdateWithoutDisclosuresInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetUpsertWithWhereUniqueWithoutOrdersInput = {
  create: PackageSetCreateWithoutOrdersInput;
  update: PackageSetUpdateWithoutOrdersInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetUpsertWithWhereUniqueWithoutPackageSetTypeInput = {
  create: PackageSetCreateWithoutPackageSetTypeInput;
  update: PackageSetUpdateWithoutPackageSetTypeInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetUpsertWithWhereUniqueWithoutSearchTypesInput = {
  create: PackageSetCreateWithoutSearchTypesInput;
  update: PackageSetUpdateWithoutSearchTypesInput;
  where: PackageSetWhereUniqueInput;
};

export type PackageSetUpsertWithoutVidVouchersInput = {
  create: PackageSetCreateWithoutVidVouchersInput;
  update: PackageSetUpdateWithoutVidVouchersInput;
  where?: InputMaybe<PackageSetWhereInput>;
};

export type PackageSetWhereInput = {
  AND?: InputMaybe<Array<PackageSetWhereInput>>;
  NOT?: InputMaybe<Array<PackageSetWhereInput>>;
  OR?: InputMaybe<Array<PackageSetWhereInput>>;
  amount?: InputMaybe<IntFilter>;
  amountPrecision?: InputMaybe<EnumCurrencyMultiplierEnumFilter>;
  collectSSN?: InputMaybe<BoolNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumCurrencyTypeEnumFilter>;
  description?: InputMaybe<StringNullableFilter>;
  disclosures?: InputMaybe<DisclosureListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  inviteReminderPeriod?: InputMaybe<IntNullableFilter>;
  isActive?: InputMaybe<BoolNullableFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  oneAndDone?: InputMaybe<BoolNullableFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  packageSetType?: InputMaybe<PackageSetTypeNullableRelationFilter>;
  packageSetTypeId?: InputMaybe<StringNullableFilter>;
  postbackWebhookUrl?: InputMaybe<StringNullableFilter>;
  searchTypes?: InputMaybe<SearchTypeListRelationFilter>;
  sendInviteReminders?: InputMaybe<BoolNullableFilter>;
  shortName?: InputMaybe<StringFilter>;
  showDisclosures?: InputMaybe<BoolNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  useSampleId?: InputMaybe<BoolNullableFilter>;
  vIDVouchers?: InputMaybe<VidVoucherListRelationFilter>;
};

export type PackageSetWhereUniqueInput = {
  AND?: InputMaybe<Array<PackageSetWhereInput>>;
  NOT?: InputMaybe<Array<PackageSetWhereInput>>;
  OR?: InputMaybe<Array<PackageSetWhereInput>>;
  amount?: InputMaybe<IntFilter>;
  amountPrecision?: InputMaybe<EnumCurrencyMultiplierEnumFilter>;
  collectSSN?: InputMaybe<BoolNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumCurrencyTypeEnumFilter>;
  description?: InputMaybe<StringNullableFilter>;
  disclosures?: InputMaybe<DisclosureListRelationFilter>;
  id?: InputMaybe<Scalars['String']>;
  inviteReminderPeriod?: InputMaybe<IntNullableFilter>;
  isActive?: InputMaybe<BoolNullableFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  name_organizationId?: InputMaybe<PackageSetNameOrganizationIdCompoundUniqueInput>;
  oneAndDone?: InputMaybe<BoolNullableFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  packageSetType?: InputMaybe<PackageSetTypeNullableRelationFilter>;
  packageSetTypeId?: InputMaybe<StringNullableFilter>;
  postbackWebhookUrl?: InputMaybe<StringNullableFilter>;
  searchTypes?: InputMaybe<SearchTypeListRelationFilter>;
  sendInviteReminders?: InputMaybe<BoolNullableFilter>;
  shortName?: InputMaybe<StringFilter>;
  shortName_organizationId?: InputMaybe<PackageSetShortNameOrganizationIdCompoundUniqueInput>;
  showDisclosures?: InputMaybe<BoolNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  useSampleId?: InputMaybe<BoolNullableFilter>;
  vIDVouchers?: InputMaybe<VidVoucherListRelationFilter>;
};

export type Pass = {
  __typename?: 'Pass';
  _count?: Maybe<PassCount>;
  createdAt: Scalars['DateTimeISO'];
  credentials: Array<Credential>;
  id: Scalars['String'];
  inputs: Array<BeamInput>;
  passType?: Maybe<PassType>;
  passTypeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTimeISO'];
  user: User;
  userId: Scalars['String'];
};


export type PassCredentialsArgs = {
  cursor?: InputMaybe<CredentialWhereUniqueInput>;
  distinct?: InputMaybe<Array<CredentialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CredentialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CredentialWhereInput>;
};


export type PassInputsArgs = {
  cursor?: InputMaybe<BeamInputWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeamInputScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeamInputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamInputWhereInput>;
};


export type PassPassTypeArgs = {
  where?: InputMaybe<PassTypeWhereInput>;
};

export type PassCount = {
  __typename?: 'PassCount';
  credentials: Scalars['Int'];
  inputs: Scalars['Int'];
};


export type PassCountCredentialsArgs = {
  where?: InputMaybe<CredentialWhereInput>;
};


export type PassCountInputsArgs = {
  where?: InputMaybe<BeamInputWhereInput>;
};

export type PassCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutPassInput>;
  id?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<BeamInputCreateNestedManyWithoutPassInput>;
  passType?: InputMaybe<PassTypeCreateNestedOneWithoutPassesInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user: UserCreateNestedOneWithoutPassesInput;
};

export type PassCreateManyPassTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId: Scalars['String'];
};

export type PassCreateManyPassTypeInputEnvelope = {
  data: Array<PassCreateManyPassTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PassCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  passTypeId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type PassCreateManyUserInputEnvelope = {
  data: Array<PassCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PassCreateNestedManyWithoutPassTypeInput = {
  connect?: InputMaybe<Array<PassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PassCreateOrConnectWithoutPassTypeInput>>;
  create?: InputMaybe<Array<PassCreateWithoutPassTypeInput>>;
  createMany?: InputMaybe<PassCreateManyPassTypeInputEnvelope>;
};

export type PassCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<PassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PassCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PassCreateWithoutUserInput>>;
  createMany?: InputMaybe<PassCreateManyUserInputEnvelope>;
};

export type PassCreateNestedOneWithoutCredentialsInput = {
  connect?: InputMaybe<PassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassCreateOrConnectWithoutCredentialsInput>;
  create?: InputMaybe<PassCreateWithoutCredentialsInput>;
};

export type PassCreateNestedOneWithoutInputsInput = {
  connect?: InputMaybe<PassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassCreateOrConnectWithoutInputsInput>;
  create?: InputMaybe<PassCreateWithoutInputsInput>;
};

export type PassCreateOrConnectWithoutCredentialsInput = {
  create: PassCreateWithoutCredentialsInput;
  where: PassWhereUniqueInput;
};

export type PassCreateOrConnectWithoutInputsInput = {
  create: PassCreateWithoutInputsInput;
  where: PassWhereUniqueInput;
};

export type PassCreateOrConnectWithoutPassTypeInput = {
  create: PassCreateWithoutPassTypeInput;
  where: PassWhereUniqueInput;
};

export type PassCreateOrConnectWithoutUserInput = {
  create: PassCreateWithoutUserInput;
  where: PassWhereUniqueInput;
};

export type PassCreateWithoutCredentialsInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<BeamInputCreateNestedManyWithoutPassInput>;
  passType?: InputMaybe<PassTypeCreateNestedOneWithoutPassesInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user: UserCreateNestedOneWithoutPassesInput;
};

export type PassCreateWithoutInputsInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutPassInput>;
  id?: InputMaybe<Scalars['String']>;
  passType?: InputMaybe<PassTypeCreateNestedOneWithoutPassesInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user: UserCreateNestedOneWithoutPassesInput;
};

export type PassCreateWithoutPassTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutPassInput>;
  id?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<BeamInputCreateNestedManyWithoutPassInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user: UserCreateNestedOneWithoutPassesInput;
};

export type PassCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutPassInput>;
  id?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<BeamInputCreateNestedManyWithoutPassInput>;
  passType?: InputMaybe<PassTypeCreateNestedOneWithoutPassesInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type PassListRelationFilter = {
  every?: InputMaybe<PassWhereInput>;
  none?: InputMaybe<PassWhereInput>;
  some?: InputMaybe<PassWhereInput>;
};

export type PassNullableRelationFilter = {
  is?: InputMaybe<PassWhereInput>;
  isNot?: InputMaybe<PassWhereInput>;
};

export type PassOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PassOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  credentials?: InputMaybe<CredentialOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  inputs?: InputMaybe<BeamInputOrderByRelationAggregateInput>;
  passType?: InputMaybe<PassTypeOrderByWithRelationInput>;
  passTypeId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum PassScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PassTypeId = 'passTypeId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type PassScalarWhereInput = {
  AND?: InputMaybe<Array<PassScalarWhereInput>>;
  NOT?: InputMaybe<Array<PassScalarWhereInput>>;
  OR?: InputMaybe<Array<PassScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  passTypeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type PassType = {
  __typename?: 'PassType';
  _count?: Maybe<PassTypeCount>;
  beams: Array<Beam>;
  brandColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  name: Scalars['String'];
  organization: Organization;
  organizationId: Scalars['String'];
  passes: Array<Pass>;
  slug: Scalars['String'];
  vouchers: Array<VidVoucher>;
};


export type PassTypeBeamsArgs = {
  cursor?: InputMaybe<BeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamWhereInput>;
};


export type PassTypePassesArgs = {
  cursor?: InputMaybe<PassWhereUniqueInput>;
  distinct?: InputMaybe<Array<PassScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PassOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PassWhereInput>;
};


export type PassTypeVouchersArgs = {
  cursor?: InputMaybe<VidVoucherWhereUniqueInput>;
  distinct?: InputMaybe<Array<VidVoucherScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VidVoucherOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VidVoucherWhereInput>;
};

export type PassTypeCount = {
  __typename?: 'PassTypeCount';
  beams: Scalars['Int'];
  passes: Scalars['Int'];
  vouchers: Scalars['Int'];
};


export type PassTypeCountBeamsArgs = {
  where?: InputMaybe<BeamWhereInput>;
};


export type PassTypeCountPassesArgs = {
  where?: InputMaybe<PassWhereInput>;
};


export type PassTypeCountVouchersArgs = {
  where?: InputMaybe<VidVoucherWhereInput>;
};

export type PassTypeCreateInput = {
  beams?: InputMaybe<BeamCreateNestedManyWithoutPassTypesInput>;
  brandColor?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization: OrganizationCreateNestedOneWithoutPassTypesInput;
  passes?: InputMaybe<PassCreateNestedManyWithoutPassTypeInput>;
  slug: Scalars['String'];
  vouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutPassTypeInput>;
};

export type PassTypeCreateManyOrganizationInput = {
  brandColor?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type PassTypeCreateManyOrganizationInputEnvelope = {
  data: Array<PassTypeCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PassTypeCreateNestedManyWithoutBeamsInput = {
  connect?: InputMaybe<Array<PassTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PassTypeCreateOrConnectWithoutBeamsInput>>;
  create?: InputMaybe<Array<PassTypeCreateWithoutBeamsInput>>;
};

export type PassTypeCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<PassTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PassTypeCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<PassTypeCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<PassTypeCreateManyOrganizationInputEnvelope>;
};

export type PassTypeCreateNestedOneWithoutPassesInput = {
  connect?: InputMaybe<PassTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassTypeCreateOrConnectWithoutPassesInput>;
  create?: InputMaybe<PassTypeCreateWithoutPassesInput>;
};

export type PassTypeCreateNestedOneWithoutVouchersInput = {
  connect?: InputMaybe<PassTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassTypeCreateOrConnectWithoutVouchersInput>;
  create?: InputMaybe<PassTypeCreateWithoutVouchersInput>;
};

export type PassTypeCreateOrConnectWithoutBeamsInput = {
  create: PassTypeCreateWithoutBeamsInput;
  where: PassTypeWhereUniqueInput;
};

export type PassTypeCreateOrConnectWithoutOrganizationInput = {
  create: PassTypeCreateWithoutOrganizationInput;
  where: PassTypeWhereUniqueInput;
};

export type PassTypeCreateOrConnectWithoutPassesInput = {
  create: PassTypeCreateWithoutPassesInput;
  where: PassTypeWhereUniqueInput;
};

export type PassTypeCreateOrConnectWithoutVouchersInput = {
  create: PassTypeCreateWithoutVouchersInput;
  where: PassTypeWhereUniqueInput;
};

export type PassTypeCreateWithoutBeamsInput = {
  brandColor?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization: OrganizationCreateNestedOneWithoutPassTypesInput;
  passes?: InputMaybe<PassCreateNestedManyWithoutPassTypeInput>;
  slug: Scalars['String'];
  vouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutPassTypeInput>;
};

export type PassTypeCreateWithoutOrganizationInput = {
  beams?: InputMaybe<BeamCreateNestedManyWithoutPassTypesInput>;
  brandColor?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  passes?: InputMaybe<PassCreateNestedManyWithoutPassTypeInput>;
  slug: Scalars['String'];
  vouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutPassTypeInput>;
};

export type PassTypeCreateWithoutPassesInput = {
  beams?: InputMaybe<BeamCreateNestedManyWithoutPassTypesInput>;
  brandColor?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization: OrganizationCreateNestedOneWithoutPassTypesInput;
  slug: Scalars['String'];
  vouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutPassTypeInput>;
};

export type PassTypeCreateWithoutVouchersInput = {
  beams?: InputMaybe<BeamCreateNestedManyWithoutPassTypesInput>;
  brandColor?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization: OrganizationCreateNestedOneWithoutPassTypesInput;
  passes?: InputMaybe<PassCreateNestedManyWithoutPassTypeInput>;
  slug: Scalars['String'];
};

export type PassTypeListRelationFilter = {
  every?: InputMaybe<PassTypeWhereInput>;
  none?: InputMaybe<PassTypeWhereInput>;
  some?: InputMaybe<PassTypeWhereInput>;
};

export type PassTypeNullableRelationFilter = {
  is?: InputMaybe<PassTypeWhereInput>;
  isNot?: InputMaybe<PassTypeWhereInput>;
};

export type PassTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PassTypeOrderByWithRelationInput = {
  beams?: InputMaybe<BeamOrderByRelationAggregateInput>;
  brandColor?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrder>;
  passes?: InputMaybe<PassOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
  vouchers?: InputMaybe<VidVoucherOrderByRelationAggregateInput>;
};

export enum PassTypeScalarFieldEnum {
  BrandColor = 'brandColor',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organizationId',
  Slug = 'slug'
}

export type PassTypeScalarWhereInput = {
  AND?: InputMaybe<Array<PassTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<PassTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<PassTypeScalarWhereInput>>;
  brandColor?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
};

export type PassTypeUpdateInput = {
  beams?: InputMaybe<BeamUpdateManyWithoutPassTypesNestedInput>;
  brandColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutPassTypesNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutPassTypeNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  vouchers?: InputMaybe<VidVoucherUpdateManyWithoutPassTypeNestedInput>;
};

export type PassTypeUpdateManyMutationInput = {
  brandColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PassTypeUpdateManyWithWhereWithoutBeamsInput = {
  data: PassTypeUpdateManyMutationInput;
  where: PassTypeScalarWhereInput;
};

export type PassTypeUpdateManyWithWhereWithoutOrganizationInput = {
  data: PassTypeUpdateManyMutationInput;
  where: PassTypeScalarWhereInput;
};

export type PassTypeUpdateManyWithoutBeamsNestedInput = {
  connect?: InputMaybe<Array<PassTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PassTypeCreateOrConnectWithoutBeamsInput>>;
  create?: InputMaybe<Array<PassTypeCreateWithoutBeamsInput>>;
  delete?: InputMaybe<Array<PassTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PassTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PassTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<PassTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<PassTypeUpdateWithWhereUniqueWithoutBeamsInput>>;
  updateMany?: InputMaybe<Array<PassTypeUpdateManyWithWhereWithoutBeamsInput>>;
  upsert?: InputMaybe<Array<PassTypeUpsertWithWhereUniqueWithoutBeamsInput>>;
};

export type PassTypeUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<PassTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PassTypeCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<PassTypeCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<PassTypeCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<PassTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PassTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PassTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<PassTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<PassTypeUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<PassTypeUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<PassTypeUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type PassTypeUpdateOneWithoutPassesNestedInput = {
  connect?: InputMaybe<PassTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassTypeCreateOrConnectWithoutPassesInput>;
  create?: InputMaybe<PassTypeCreateWithoutPassesInput>;
  delete?: InputMaybe<PassTypeWhereInput>;
  disconnect?: InputMaybe<PassTypeWhereInput>;
  update?: InputMaybe<PassTypeUpdateToOneWithWhereWithoutPassesInput>;
  upsert?: InputMaybe<PassTypeUpsertWithoutPassesInput>;
};

export type PassTypeUpdateOneWithoutVouchersNestedInput = {
  connect?: InputMaybe<PassTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassTypeCreateOrConnectWithoutVouchersInput>;
  create?: InputMaybe<PassTypeCreateWithoutVouchersInput>;
  delete?: InputMaybe<PassTypeWhereInput>;
  disconnect?: InputMaybe<PassTypeWhereInput>;
  update?: InputMaybe<PassTypeUpdateToOneWithWhereWithoutVouchersInput>;
  upsert?: InputMaybe<PassTypeUpsertWithoutVouchersInput>;
};

export type PassTypeUpdateToOneWithWhereWithoutPassesInput = {
  data: PassTypeUpdateWithoutPassesInput;
  where?: InputMaybe<PassTypeWhereInput>;
};

export type PassTypeUpdateToOneWithWhereWithoutVouchersInput = {
  data: PassTypeUpdateWithoutVouchersInput;
  where?: InputMaybe<PassTypeWhereInput>;
};

export type PassTypeUpdateWithWhereUniqueWithoutBeamsInput = {
  data: PassTypeUpdateWithoutBeamsInput;
  where: PassTypeWhereUniqueInput;
};

export type PassTypeUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: PassTypeUpdateWithoutOrganizationInput;
  where: PassTypeWhereUniqueInput;
};

export type PassTypeUpdateWithoutBeamsInput = {
  brandColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutPassTypesNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutPassTypeNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  vouchers?: InputMaybe<VidVoucherUpdateManyWithoutPassTypeNestedInput>;
};

export type PassTypeUpdateWithoutOrganizationInput = {
  beams?: InputMaybe<BeamUpdateManyWithoutPassTypesNestedInput>;
  brandColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  passes?: InputMaybe<PassUpdateManyWithoutPassTypeNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  vouchers?: InputMaybe<VidVoucherUpdateManyWithoutPassTypeNestedInput>;
};

export type PassTypeUpdateWithoutPassesInput = {
  beams?: InputMaybe<BeamUpdateManyWithoutPassTypesNestedInput>;
  brandColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutPassTypesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  vouchers?: InputMaybe<VidVoucherUpdateManyWithoutPassTypeNestedInput>;
};

export type PassTypeUpdateWithoutVouchersInput = {
  beams?: InputMaybe<BeamUpdateManyWithoutPassTypesNestedInput>;
  brandColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutPassTypesNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutPassTypeNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PassTypeUpsertWithWhereUniqueWithoutBeamsInput = {
  create: PassTypeCreateWithoutBeamsInput;
  update: PassTypeUpdateWithoutBeamsInput;
  where: PassTypeWhereUniqueInput;
};

export type PassTypeUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: PassTypeCreateWithoutOrganizationInput;
  update: PassTypeUpdateWithoutOrganizationInput;
  where: PassTypeWhereUniqueInput;
};

export type PassTypeUpsertWithoutPassesInput = {
  create: PassTypeCreateWithoutPassesInput;
  update: PassTypeUpdateWithoutPassesInput;
  where?: InputMaybe<PassTypeWhereInput>;
};

export type PassTypeUpsertWithoutVouchersInput = {
  create: PassTypeCreateWithoutVouchersInput;
  update: PassTypeUpdateWithoutVouchersInput;
  where?: InputMaybe<PassTypeWhereInput>;
};

export type PassTypeWhereInput = {
  AND?: InputMaybe<Array<PassTypeWhereInput>>;
  NOT?: InputMaybe<Array<PassTypeWhereInput>>;
  OR?: InputMaybe<Array<PassTypeWhereInput>>;
  beams?: InputMaybe<BeamListRelationFilter>;
  brandColor?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  passes?: InputMaybe<PassListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  vouchers?: InputMaybe<VidVoucherListRelationFilter>;
};

export type PassTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<PassTypeWhereInput>>;
  NOT?: InputMaybe<Array<PassTypeWhereInput>>;
  OR?: InputMaybe<Array<PassTypeWhereInput>>;
  beams?: InputMaybe<BeamListRelationFilter>;
  brandColor?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  passes?: InputMaybe<PassListRelationFilter>;
  slug?: InputMaybe<Scalars['String']>;
  vouchers?: InputMaybe<VidVoucherListRelationFilter>;
};

export type PassUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PassUpdateManyWithWhereWithoutPassTypeInput = {
  data: PassUpdateManyMutationInput;
  where: PassScalarWhereInput;
};

export type PassUpdateManyWithWhereWithoutUserInput = {
  data: PassUpdateManyMutationInput;
  where: PassScalarWhereInput;
};

export type PassUpdateManyWithoutPassTypeNestedInput = {
  connect?: InputMaybe<Array<PassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PassCreateOrConnectWithoutPassTypeInput>>;
  create?: InputMaybe<Array<PassCreateWithoutPassTypeInput>>;
  createMany?: InputMaybe<PassCreateManyPassTypeInputEnvelope>;
  delete?: InputMaybe<Array<PassWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PassScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PassWhereUniqueInput>>;
  set?: InputMaybe<Array<PassWhereUniqueInput>>;
  update?: InputMaybe<Array<PassUpdateWithWhereUniqueWithoutPassTypeInput>>;
  updateMany?: InputMaybe<Array<PassUpdateManyWithWhereWithoutPassTypeInput>>;
  upsert?: InputMaybe<Array<PassUpsertWithWhereUniqueWithoutPassTypeInput>>;
};

export type PassUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<PassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PassCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PassCreateWithoutUserInput>>;
  createMany?: InputMaybe<PassCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<PassWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PassScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PassWhereUniqueInput>>;
  set?: InputMaybe<Array<PassWhereUniqueInput>>;
  update?: InputMaybe<Array<PassUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<PassUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<PassUpsertWithWhereUniqueWithoutUserInput>>;
};

export type PassUpdateOneWithoutCredentialsNestedInput = {
  connect?: InputMaybe<PassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassCreateOrConnectWithoutCredentialsInput>;
  create?: InputMaybe<PassCreateWithoutCredentialsInput>;
  delete?: InputMaybe<PassWhereInput>;
  disconnect?: InputMaybe<PassWhereInput>;
  update?: InputMaybe<PassUpdateToOneWithWhereWithoutCredentialsInput>;
  upsert?: InputMaybe<PassUpsertWithoutCredentialsInput>;
};

export type PassUpdateOneWithoutInputsNestedInput = {
  connect?: InputMaybe<PassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassCreateOrConnectWithoutInputsInput>;
  create?: InputMaybe<PassCreateWithoutInputsInput>;
  delete?: InputMaybe<PassWhereInput>;
  disconnect?: InputMaybe<PassWhereInput>;
  update?: InputMaybe<PassUpdateToOneWithWhereWithoutInputsInput>;
  upsert?: InputMaybe<PassUpsertWithoutInputsInput>;
};

export type PassUpdateToOneWithWhereWithoutCredentialsInput = {
  data: PassUpdateWithoutCredentialsInput;
  where?: InputMaybe<PassWhereInput>;
};

export type PassUpdateToOneWithWhereWithoutInputsInput = {
  data: PassUpdateWithoutInputsInput;
  where?: InputMaybe<PassWhereInput>;
};

export type PassUpdateWithWhereUniqueWithoutPassTypeInput = {
  data: PassUpdateWithoutPassTypeInput;
  where: PassWhereUniqueInput;
};

export type PassUpdateWithWhereUniqueWithoutUserInput = {
  data: PassUpdateWithoutUserInput;
  where: PassWhereUniqueInput;
};

export type PassUpdateWithoutCredentialsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inputs?: InputMaybe<BeamInputUpdateManyWithoutPassNestedInput>;
  passType?: InputMaybe<PassTypeUpdateOneWithoutPassesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPassesNestedInput>;
};

export type PassUpdateWithoutInputsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutPassNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  passType?: InputMaybe<PassTypeUpdateOneWithoutPassesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPassesNestedInput>;
};

export type PassUpdateWithoutPassTypeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutPassNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inputs?: InputMaybe<BeamInputUpdateManyWithoutPassNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPassesNestedInput>;
};

export type PassUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutPassNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inputs?: InputMaybe<BeamInputUpdateManyWithoutPassNestedInput>;
  passType?: InputMaybe<PassTypeUpdateOneWithoutPassesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PassUpsertWithWhereUniqueWithoutPassTypeInput = {
  create: PassCreateWithoutPassTypeInput;
  update: PassUpdateWithoutPassTypeInput;
  where: PassWhereUniqueInput;
};

export type PassUpsertWithWhereUniqueWithoutUserInput = {
  create: PassCreateWithoutUserInput;
  update: PassUpdateWithoutUserInput;
  where: PassWhereUniqueInput;
};

export type PassUpsertWithoutCredentialsInput = {
  create: PassCreateWithoutCredentialsInput;
  update: PassUpdateWithoutCredentialsInput;
  where?: InputMaybe<PassWhereInput>;
};

export type PassUpsertWithoutInputsInput = {
  create: PassCreateWithoutInputsInput;
  update: PassUpdateWithoutInputsInput;
  where?: InputMaybe<PassWhereInput>;
};

export type PassWhereInput = {
  AND?: InputMaybe<Array<PassWhereInput>>;
  NOT?: InputMaybe<Array<PassWhereInput>>;
  OR?: InputMaybe<Array<PassWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credentials?: InputMaybe<CredentialListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  inputs?: InputMaybe<BeamInputListRelationFilter>;
  passType?: InputMaybe<PassTypeNullableRelationFilter>;
  passTypeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type PassWhereUniqueInput = {
  AND?: InputMaybe<Array<PassWhereInput>>;
  NOT?: InputMaybe<Array<PassWhereInput>>;
  OR?: InputMaybe<Array<PassWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  credentials?: InputMaybe<CredentialListRelationFilter>;
  id?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<BeamInputListRelationFilter>;
  passType?: InputMaybe<PassTypeNullableRelationFilter>;
  passTypeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type Passport = {
  __typename?: 'Passport';
  _count?: Maybe<PassportCount>;
  address?: Maybe<Address>;
  applicantData?: Maybe<ApplicantData>;
  applicantDataId?: Maybe<Scalars['String']>;
  backImage?: Maybe<File>;
  createdAt: Scalars['DateTimeISO'];
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: Maybe<File>;
  frontImage?: Maybe<File>;
  gender: Scalars['String'];
  id: Scalars['String'];
  idVerificationResult: Array<IdVerificationResult>;
  issueDate: Scalars['String'];
  name?: Maybe<Name>;
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  selfieImage?: Maybe<File>;
  updatedAt: Scalars['DateTimeISO'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type PassportAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};


export type PassportApplicantDataArgs = {
  where?: InputMaybe<ApplicantDataWhereInput>;
};


export type PassportBackImageArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type PassportExtractedPhotoArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type PassportFrontImageArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type PassportIdVerificationResultArgs = {
  cursor?: InputMaybe<IdVerificationResultWhereUniqueInput>;
  distinct?: InputMaybe<Array<IdVerificationResultScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IdVerificationResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IdVerificationResultWhereInput>;
};


export type PassportNameArgs = {
  where?: InputMaybe<NameWhereInput>;
};


export type PassportSelfieImageArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type PassportUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type PassportCount = {
  __typename?: 'PassportCount';
  idVerificationResult: Scalars['Int'];
};


export type PassportCountIdVerificationResultArgs = {
  where?: InputMaybe<IdVerificationResultWhereInput>;
};

export type PassportCreateInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutPassportInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutPassportInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutPassportBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutPassportExtractPhotoInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutPassportFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutPassportInput>;
  issueDate: Scalars['String'];
  name?: InputMaybe<NameCreateNestedOneWithoutPassportInput>;
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutPassportCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPassportsInput>;
};

export type PassportCreateManyUserInput = {
  applicantDataId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  issueDate: Scalars['String'];
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type PassportCreateManyUserInputEnvelope = {
  data: Array<PassportCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PassportCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<PassportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PassportCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PassportCreateWithoutUserInput>>;
  createMany?: InputMaybe<PassportCreateManyUserInputEnvelope>;
};

export type PassportCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<PassportCreateWithoutAddressInput>;
};

export type PassportCreateNestedOneWithoutApplicantDataInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutApplicantDataInput>;
  create?: InputMaybe<PassportCreateWithoutApplicantDataInput>;
};

export type PassportCreateNestedOneWithoutBackImageInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutBackImageInput>;
  create?: InputMaybe<PassportCreateWithoutBackImageInput>;
};

export type PassportCreateNestedOneWithoutExtractedPhotoInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutExtractedPhotoInput>;
  create?: InputMaybe<PassportCreateWithoutExtractedPhotoInput>;
};

export type PassportCreateNestedOneWithoutFrontImageInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutFrontImageInput>;
  create?: InputMaybe<PassportCreateWithoutFrontImageInput>;
};

export type PassportCreateNestedOneWithoutIdVerificationResultInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutIdVerificationResultInput>;
  create?: InputMaybe<PassportCreateWithoutIdVerificationResultInput>;
};

export type PassportCreateNestedOneWithoutNameInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutNameInput>;
  create?: InputMaybe<PassportCreateWithoutNameInput>;
};

export type PassportCreateNestedOneWithoutSelfieImageInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutSelfieImageInput>;
  create?: InputMaybe<PassportCreateWithoutSelfieImageInput>;
};

export type PassportCreateOrConnectWithoutAddressInput = {
  create: PassportCreateWithoutAddressInput;
  where: PassportWhereUniqueInput;
};

export type PassportCreateOrConnectWithoutApplicantDataInput = {
  create: PassportCreateWithoutApplicantDataInput;
  where: PassportWhereUniqueInput;
};

export type PassportCreateOrConnectWithoutBackImageInput = {
  create: PassportCreateWithoutBackImageInput;
  where: PassportWhereUniqueInput;
};

export type PassportCreateOrConnectWithoutExtractedPhotoInput = {
  create: PassportCreateWithoutExtractedPhotoInput;
  where: PassportWhereUniqueInput;
};

export type PassportCreateOrConnectWithoutFrontImageInput = {
  create: PassportCreateWithoutFrontImageInput;
  where: PassportWhereUniqueInput;
};

export type PassportCreateOrConnectWithoutIdVerificationResultInput = {
  create: PassportCreateWithoutIdVerificationResultInput;
  where: PassportWhereUniqueInput;
};

export type PassportCreateOrConnectWithoutNameInput = {
  create: PassportCreateWithoutNameInput;
  where: PassportWhereUniqueInput;
};

export type PassportCreateOrConnectWithoutSelfieImageInput = {
  create: PassportCreateWithoutSelfieImageInput;
  where: PassportWhereUniqueInput;
};

export type PassportCreateOrConnectWithoutUserInput = {
  create: PassportCreateWithoutUserInput;
  where: PassportWhereUniqueInput;
};

export type PassportCreateWithoutAddressInput = {
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutPassportInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutPassportBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutPassportExtractPhotoInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutPassportFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutPassportInput>;
  issueDate: Scalars['String'];
  name?: InputMaybe<NameCreateNestedOneWithoutPassportInput>;
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutPassportCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPassportsInput>;
};

export type PassportCreateWithoutApplicantDataInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutPassportInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutPassportBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutPassportExtractPhotoInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutPassportFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutPassportInput>;
  issueDate: Scalars['String'];
  name?: InputMaybe<NameCreateNestedOneWithoutPassportInput>;
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutPassportCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPassportsInput>;
};

export type PassportCreateWithoutBackImageInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutPassportInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutPassportInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutPassportExtractPhotoInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutPassportFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutPassportInput>;
  issueDate: Scalars['String'];
  name?: InputMaybe<NameCreateNestedOneWithoutPassportInput>;
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutPassportCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPassportsInput>;
};

export type PassportCreateWithoutExtractedPhotoInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutPassportInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutPassportInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutPassportBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  frontImage?: InputMaybe<FileCreateNestedOneWithoutPassportFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutPassportInput>;
  issueDate: Scalars['String'];
  name?: InputMaybe<NameCreateNestedOneWithoutPassportInput>;
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutPassportCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPassportsInput>;
};

export type PassportCreateWithoutFrontImageInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutPassportInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutPassportInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutPassportBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutPassportExtractPhotoInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutPassportInput>;
  issueDate: Scalars['String'];
  name?: InputMaybe<NameCreateNestedOneWithoutPassportInput>;
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutPassportCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPassportsInput>;
};

export type PassportCreateWithoutIdVerificationResultInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutPassportInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutPassportInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutPassportBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutPassportExtractPhotoInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutPassportFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  issueDate: Scalars['String'];
  name?: InputMaybe<NameCreateNestedOneWithoutPassportInput>;
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutPassportCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPassportsInput>;
};

export type PassportCreateWithoutNameInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutPassportInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutPassportInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutPassportBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutPassportExtractPhotoInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutPassportFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutPassportInput>;
  issueDate: Scalars['String'];
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutPassportCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPassportsInput>;
};

export type PassportCreateWithoutSelfieImageInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutPassportInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutPassportInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutPassportBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutPassportExtractPhotoInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutPassportFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutPassportInput>;
  issueDate: Scalars['String'];
  name?: InputMaybe<NameCreateNestedOneWithoutPassportInput>;
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutPassportsInput>;
};

export type PassportCreateWithoutUserInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutPassportInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutPassportInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutPassportBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutPassportExtractPhotoInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutPassportFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutPassportInput>;
  issueDate: Scalars['String'];
  name?: InputMaybe<NameCreateNestedOneWithoutPassportInput>;
  nationality: Scalars['String'];
  passportNumber: Scalars['String'];
  placeOfBirth: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutPassportCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type PassportListRelationFilter = {
  every?: InputMaybe<PassportWhereInput>;
  none?: InputMaybe<PassportWhereInput>;
  some?: InputMaybe<PassportWhereInput>;
};

export type PassportNullableRelationFilter = {
  is?: InputMaybe<PassportWhereInput>;
  isNot?: InputMaybe<PassportWhereInput>;
};

export type PassportOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PassportOrderByWithRelationInput = {
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  applicantData?: InputMaybe<ApplicantDataOrderByWithRelationInput>;
  applicantDataId?: InputMaybe<SortOrderInput>;
  backImage?: InputMaybe<FileOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  expiryDate?: InputMaybe<SortOrder>;
  extractedPhoto?: InputMaybe<FileOrderByWithRelationInput>;
  frontImage?: InputMaybe<FileOrderByWithRelationInput>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  idVerificationResult?: InputMaybe<IdVerificationResultOrderByRelationAggregateInput>;
  issueDate?: InputMaybe<SortOrder>;
  name?: InputMaybe<NameOrderByWithRelationInput>;
  nationality?: InputMaybe<SortOrder>;
  passportNumber?: InputMaybe<SortOrder>;
  placeOfBirth?: InputMaybe<SortOrder>;
  selfieImage?: InputMaybe<FileOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum PassportScalarFieldEnum {
  ApplicantDataId = 'applicantDataId',
  CreatedAt = 'createdAt',
  DateOfBirth = 'dateOfBirth',
  ExpiryDate = 'expiryDate',
  Gender = 'gender',
  Id = 'id',
  IssueDate = 'issueDate',
  Nationality = 'nationality',
  PassportNumber = 'passportNumber',
  PlaceOfBirth = 'placeOfBirth',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type PassportScalarWhereInput = {
  AND?: InputMaybe<Array<PassportScalarWhereInput>>;
  NOT?: InputMaybe<Array<PassportScalarWhereInput>>;
  OR?: InputMaybe<Array<PassportScalarWhereInput>>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringFilter>;
  expiryDate?: InputMaybe<StringFilter>;
  gender?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  issueDate?: InputMaybe<StringFilter>;
  nationality?: InputMaybe<StringFilter>;
  passportNumber?: InputMaybe<StringFilter>;
  placeOfBirth?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type PassportUpdateInput = {
  address?: InputMaybe<AddressUpdateOneWithoutPassportNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutPassportNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutPassportBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutPassportExtractPhotoNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutPassportFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutPassportNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutPassportNestedInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutPassportCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPassportsNestedInput>;
};

export type PassportUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PassportUpdateManyWithWhereWithoutUserInput = {
  data: PassportUpdateManyMutationInput;
  where: PassportScalarWhereInput;
};

export type PassportUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<PassportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PassportCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PassportCreateWithoutUserInput>>;
  createMany?: InputMaybe<PassportCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<PassportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PassportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PassportWhereUniqueInput>>;
  set?: InputMaybe<Array<PassportWhereUniqueInput>>;
  update?: InputMaybe<Array<PassportUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<PassportUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<PassportUpsertWithWhereUniqueWithoutUserInput>>;
};

export type PassportUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<PassportCreateWithoutAddressInput>;
  delete?: InputMaybe<PassportWhereInput>;
  disconnect?: InputMaybe<PassportWhereInput>;
  update?: InputMaybe<PassportUpdateToOneWithWhereWithoutAddressInput>;
  upsert?: InputMaybe<PassportUpsertWithoutAddressInput>;
};

export type PassportUpdateOneWithoutApplicantDataNestedInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutApplicantDataInput>;
  create?: InputMaybe<PassportCreateWithoutApplicantDataInput>;
  delete?: InputMaybe<PassportWhereInput>;
  disconnect?: InputMaybe<PassportWhereInput>;
  update?: InputMaybe<PassportUpdateToOneWithWhereWithoutApplicantDataInput>;
  upsert?: InputMaybe<PassportUpsertWithoutApplicantDataInput>;
};

export type PassportUpdateOneWithoutBackImageNestedInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutBackImageInput>;
  create?: InputMaybe<PassportCreateWithoutBackImageInput>;
  delete?: InputMaybe<PassportWhereInput>;
  disconnect?: InputMaybe<PassportWhereInput>;
  update?: InputMaybe<PassportUpdateToOneWithWhereWithoutBackImageInput>;
  upsert?: InputMaybe<PassportUpsertWithoutBackImageInput>;
};

export type PassportUpdateOneWithoutExtractedPhotoNestedInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutExtractedPhotoInput>;
  create?: InputMaybe<PassportCreateWithoutExtractedPhotoInput>;
  delete?: InputMaybe<PassportWhereInput>;
  disconnect?: InputMaybe<PassportWhereInput>;
  update?: InputMaybe<PassportUpdateToOneWithWhereWithoutExtractedPhotoInput>;
  upsert?: InputMaybe<PassportUpsertWithoutExtractedPhotoInput>;
};

export type PassportUpdateOneWithoutFrontImageNestedInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutFrontImageInput>;
  create?: InputMaybe<PassportCreateWithoutFrontImageInput>;
  delete?: InputMaybe<PassportWhereInput>;
  disconnect?: InputMaybe<PassportWhereInput>;
  update?: InputMaybe<PassportUpdateToOneWithWhereWithoutFrontImageInput>;
  upsert?: InputMaybe<PassportUpsertWithoutFrontImageInput>;
};

export type PassportUpdateOneWithoutIdVerificationResultNestedInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutIdVerificationResultInput>;
  create?: InputMaybe<PassportCreateWithoutIdVerificationResultInput>;
  delete?: InputMaybe<PassportWhereInput>;
  disconnect?: InputMaybe<PassportWhereInput>;
  update?: InputMaybe<PassportUpdateToOneWithWhereWithoutIdVerificationResultInput>;
  upsert?: InputMaybe<PassportUpsertWithoutIdVerificationResultInput>;
};

export type PassportUpdateOneWithoutNameNestedInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutNameInput>;
  create?: InputMaybe<PassportCreateWithoutNameInput>;
  delete?: InputMaybe<PassportWhereInput>;
  disconnect?: InputMaybe<PassportWhereInput>;
  update?: InputMaybe<PassportUpdateToOneWithWhereWithoutNameInput>;
  upsert?: InputMaybe<PassportUpsertWithoutNameInput>;
};

export type PassportUpdateOneWithoutSelfieImageNestedInput = {
  connect?: InputMaybe<PassportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassportCreateOrConnectWithoutSelfieImageInput>;
  create?: InputMaybe<PassportCreateWithoutSelfieImageInput>;
  delete?: InputMaybe<PassportWhereInput>;
  disconnect?: InputMaybe<PassportWhereInput>;
  update?: InputMaybe<PassportUpdateToOneWithWhereWithoutSelfieImageInput>;
  upsert?: InputMaybe<PassportUpsertWithoutSelfieImageInput>;
};

export type PassportUpdateToOneWithWhereWithoutAddressInput = {
  data: PassportUpdateWithoutAddressInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpdateToOneWithWhereWithoutApplicantDataInput = {
  data: PassportUpdateWithoutApplicantDataInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpdateToOneWithWhereWithoutBackImageInput = {
  data: PassportUpdateWithoutBackImageInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpdateToOneWithWhereWithoutExtractedPhotoInput = {
  data: PassportUpdateWithoutExtractedPhotoInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpdateToOneWithWhereWithoutFrontImageInput = {
  data: PassportUpdateWithoutFrontImageInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpdateToOneWithWhereWithoutIdVerificationResultInput = {
  data: PassportUpdateWithoutIdVerificationResultInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpdateToOneWithWhereWithoutNameInput = {
  data: PassportUpdateWithoutNameInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpdateToOneWithWhereWithoutSelfieImageInput = {
  data: PassportUpdateWithoutSelfieImageInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpdateWithWhereUniqueWithoutUserInput = {
  data: PassportUpdateWithoutUserInput;
  where: PassportWhereUniqueInput;
};

export type PassportUpdateWithoutAddressInput = {
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutPassportNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutPassportBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutPassportExtractPhotoNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutPassportFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutPassportNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutPassportNestedInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutPassportCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPassportsNestedInput>;
};

export type PassportUpdateWithoutApplicantDataInput = {
  address?: InputMaybe<AddressUpdateOneWithoutPassportNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutPassportBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutPassportExtractPhotoNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutPassportFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutPassportNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutPassportNestedInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutPassportCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPassportsNestedInput>;
};

export type PassportUpdateWithoutBackImageInput = {
  address?: InputMaybe<AddressUpdateOneWithoutPassportNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutPassportNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutPassportExtractPhotoNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutPassportFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutPassportNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutPassportNestedInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutPassportCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPassportsNestedInput>;
};

export type PassportUpdateWithoutExtractedPhotoInput = {
  address?: InputMaybe<AddressUpdateOneWithoutPassportNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutPassportNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutPassportBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutPassportFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutPassportNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutPassportNestedInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutPassportCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPassportsNestedInput>;
};

export type PassportUpdateWithoutFrontImageInput = {
  address?: InputMaybe<AddressUpdateOneWithoutPassportNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutPassportNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutPassportBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutPassportExtractPhotoNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutPassportNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutPassportNestedInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutPassportCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPassportsNestedInput>;
};

export type PassportUpdateWithoutIdVerificationResultInput = {
  address?: InputMaybe<AddressUpdateOneWithoutPassportNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutPassportNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutPassportBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutPassportExtractPhotoNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutPassportFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutPassportNestedInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutPassportCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPassportsNestedInput>;
};

export type PassportUpdateWithoutNameInput = {
  address?: InputMaybe<AddressUpdateOneWithoutPassportNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutPassportNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutPassportBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutPassportExtractPhotoNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutPassportFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutPassportNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutPassportCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPassportsNestedInput>;
};

export type PassportUpdateWithoutSelfieImageInput = {
  address?: InputMaybe<AddressUpdateOneWithoutPassportNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutPassportNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutPassportBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutPassportExtractPhotoNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutPassportFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutPassportNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutPassportNestedInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutPassportsNestedInput>;
};

export type PassportUpdateWithoutUserInput = {
  address?: InputMaybe<AddressUpdateOneWithoutPassportNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutPassportNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutPassportBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutPassportExtractPhotoNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutPassportFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutPassportNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutPassportNestedInput>;
  nationality?: InputMaybe<StringFieldUpdateOperationsInput>;
  passportNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  placeOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutPassportCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PassportUpsertWithWhereUniqueWithoutUserInput = {
  create: PassportCreateWithoutUserInput;
  update: PassportUpdateWithoutUserInput;
  where: PassportWhereUniqueInput;
};

export type PassportUpsertWithoutAddressInput = {
  create: PassportCreateWithoutAddressInput;
  update: PassportUpdateWithoutAddressInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpsertWithoutApplicantDataInput = {
  create: PassportCreateWithoutApplicantDataInput;
  update: PassportUpdateWithoutApplicantDataInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpsertWithoutBackImageInput = {
  create: PassportCreateWithoutBackImageInput;
  update: PassportUpdateWithoutBackImageInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpsertWithoutExtractedPhotoInput = {
  create: PassportCreateWithoutExtractedPhotoInput;
  update: PassportUpdateWithoutExtractedPhotoInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpsertWithoutFrontImageInput = {
  create: PassportCreateWithoutFrontImageInput;
  update: PassportUpdateWithoutFrontImageInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpsertWithoutIdVerificationResultInput = {
  create: PassportCreateWithoutIdVerificationResultInput;
  update: PassportUpdateWithoutIdVerificationResultInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpsertWithoutNameInput = {
  create: PassportCreateWithoutNameInput;
  update: PassportUpdateWithoutNameInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportUpsertWithoutSelfieImageInput = {
  create: PassportCreateWithoutSelfieImageInput;
  update: PassportUpdateWithoutSelfieImageInput;
  where?: InputMaybe<PassportWhereInput>;
};

export type PassportWhereInput = {
  AND?: InputMaybe<Array<PassportWhereInput>>;
  NOT?: InputMaybe<Array<PassportWhereInput>>;
  OR?: InputMaybe<Array<PassportWhereInput>>;
  address?: InputMaybe<AddressNullableRelationFilter>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  backImage?: InputMaybe<FileNullableRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringFilter>;
  expiryDate?: InputMaybe<StringFilter>;
  extractedPhoto?: InputMaybe<FileNullableRelationFilter>;
  frontImage?: InputMaybe<FileNullableRelationFilter>;
  gender?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  idVerificationResult?: InputMaybe<IdVerificationResultListRelationFilter>;
  issueDate?: InputMaybe<StringFilter>;
  name?: InputMaybe<NameNullableRelationFilter>;
  nationality?: InputMaybe<StringFilter>;
  passportNumber?: InputMaybe<StringFilter>;
  placeOfBirth?: InputMaybe<StringFilter>;
  selfieImage?: InputMaybe<FileNullableRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type PassportWhereUniqueInput = {
  AND?: InputMaybe<Array<PassportWhereInput>>;
  NOT?: InputMaybe<Array<PassportWhereInput>>;
  OR?: InputMaybe<Array<PassportWhereInput>>;
  address?: InputMaybe<AddressNullableRelationFilter>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantDataId?: InputMaybe<Scalars['String']>;
  backImage?: InputMaybe<FileNullableRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringFilter>;
  expiryDate?: InputMaybe<StringFilter>;
  extractedPhoto?: InputMaybe<FileNullableRelationFilter>;
  frontImage?: InputMaybe<FileNullableRelationFilter>;
  gender?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultListRelationFilter>;
  issueDate?: InputMaybe<StringFilter>;
  name?: InputMaybe<NameNullableRelationFilter>;
  nationality?: InputMaybe<StringFilter>;
  passportNumber?: InputMaybe<StringFilter>;
  placeOfBirth?: InputMaybe<StringFilter>;
  selfieImage?: InputMaybe<FileNullableRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export enum PerformerTypes {
  Api = 'API',
  App = 'APP',
  Bot = 'BOT',
  User = 'USER'
}

export enum Platform {
  Cognition = 'COGNITION',
  Cortex = 'CORTEX',
  RapidRequest = 'RAPID_REQUEST',
  Vid = 'VID'
}

export enum ProcessorEnum {
  Auto = 'AUTO',
  Canary = 'CANARY',
  IntegrationTest = 'INTEGRATION_TEST',
  Manual = 'MANUAL',
  Tazworks = 'TAZWORKS'
}

export type ProductCreateNestedOneWithoutRoleAssignmentInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutRoleAssignmentInput>;
  create?: InputMaybe<ProductCreateWithoutRoleAssignmentInput>;
};

export type ProductCreateOrConnectWithoutRoleAssignmentInput = {
  create: ProductCreateWithoutRoleAssignmentInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutRoleAssignmentInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ProductNullableRelationFilter = {
  is?: InputMaybe<ProductWhereInput>;
  isNot?: InputMaybe<ProductWhereInput>;
};

export type ProductOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  roleAssignment?: InputMaybe<RoleAssignmentOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
};

export type ProductUpdateOneWithoutRoleAssignmentNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutRoleAssignmentInput>;
  create?: InputMaybe<ProductCreateWithoutRoleAssignmentInput>;
  delete?: InputMaybe<ProductWhereInput>;
  disconnect?: InputMaybe<ProductWhereInput>;
  update?: InputMaybe<ProductUpdateToOneWithWhereWithoutRoleAssignmentInput>;
  upsert?: InputMaybe<ProductUpsertWithoutRoleAssignmentInput>;
};

export type ProductUpdateToOneWithWhereWithoutRoleAssignmentInput = {
  data: ProductUpdateWithoutRoleAssignmentInput;
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductUpdateWithoutRoleAssignmentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProductUpsertWithoutRoleAssignmentInput = {
  create: ProductCreateWithoutRoleAssignmentInput;
  update: ProductUpdateWithoutRoleAssignmentInput;
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  roleAssignment?: InputMaybe<RoleAssignmentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type ProductWhereUniqueInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roleAssignment?: InputMaybe<RoleAssignmentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<Address>;
  addresses: Array<Address>;
  aggregateBeamInput: AggregateBeamInput;
  aggregateOrder: AggregateOrder;
  aggregatePackageSet: AggregatePackageSet;
  aggregateVIDVoucher: AggregateVidVoucher;
  beam?: Maybe<Beam>;
  beamInput?: Maybe<BeamInput>;
  beamInputType?: Maybe<BeamInputType>;
  beamInputTypes: Array<BeamInputType>;
  beamInputs: Array<BeamInput>;
  beamRun?: Maybe<BeamRun>;
  beams: Array<Beam>;
  checkVoucherValidity?: Maybe<VidVoucher>;
  complianceLetter?: Maybe<ComplianceLetter>;
  credential?: Maybe<Credential>;
  credentialStatus?: Maybe<CredentialStatus>;
  credentialType?: Maybe<CredentialType>;
  credentials: Array<Credential>;
  decodeBranchUrl?: Maybe<Response>;
  disclosureAcceptance?: Maybe<DisclosureAcceptance>;
  disclosureAcceptances: Array<DisclosureAcceptance>;
  dispatchedSearch?: Maybe<DispatchedSearch>;
  file?: Maybe<File>;
  findFirstLegacyCredential?: Maybe<LegacyCredential>;
  findUniqueApplicantData?: Maybe<ApplicantData>;
  generateSignedDisclosurePdf: GenerateSignedDisclosurePdfResponse;
  getInvite?: Maybe<InviteRes>;
  highlySensitiveIdentifier?: Maybe<HighlySensitiveIdentifier>;
  idVerificationResult?: Maybe<IdVerificationResult>;
  idVerificationResults: Array<IdVerificationResult>;
  legacyCredential?: Maybe<LegacyCredential>;
  legacyCredentials: Array<LegacyCredential>;
  me: User;
  name?: Maybe<Name>;
  names: Array<Name>;
  order?: Maybe<Order>;
  orderNote?: Maybe<OrderNote>;
  orderNotes: Array<OrderNote>;
  orders: Array<Order>;
  organization: OrganizationRes;
  organizations: Array<Organization>;
  packageSet?: Maybe<PackageSet>;
  packageSets: Array<PackageSet>;
  pass?: Maybe<Pass>;
  passType?: Maybe<PassType>;
  passTypes: Array<PassType>;
  passes: Array<Pass>;
  passport?: Maybe<Passport>;
  reportableIdVerificationResult?: Maybe<ReportableIdVerificationResult>;
  requestedSearch?: Maybe<RequestedSearch>;
  result?: Maybe<Result>;
  resultRevision?: Maybe<ResultRevision>;
  retrieveDisclosures: Array<Disclosure>;
  searchType?: Maybe<SearchType>;
  shareOrderRecord?: Maybe<ShareOrderRecord>;
  stateIdentificationCard?: Maybe<StateIdentificationCard>;
  user?: Maybe<User>;
  users: Array<User>;
  vIDVoucher?: Maybe<VidVoucher>;
  vIDVouchers: Array<VidVoucher>;
};


export type QueryAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type QueryAddressesArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<AddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryAggregateBeamInputArgs = {
  cursor?: InputMaybe<BeamInputWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BeamInputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamInputWhereInput>;
};


export type QueryAggregateOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryAggregatePackageSetArgs = {
  cursor?: InputMaybe<PackageSetWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PackageSetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackageSetWhereInput>;
};


export type QueryAggregateVidVoucherArgs = {
  cursor?: InputMaybe<VidVoucherWhereUniqueInput>;
  orderBy?: InputMaybe<Array<VidVoucherOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VidVoucherWhereInput>;
};


export type QueryBeamArgs = {
  where: BeamWhereUniqueInput;
};


export type QueryBeamInputArgs = {
  where: BeamInputWhereUniqueInput;
};


export type QueryBeamInputTypeArgs = {
  where: BeamInputTypeWhereUniqueInput;
};


export type QueryBeamInputTypesArgs = {
  cursor?: InputMaybe<BeamInputTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeamInputTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeamInputTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamInputTypeWhereInput>;
};


export type QueryBeamInputsArgs = {
  cursor?: InputMaybe<BeamInputWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeamInputScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeamInputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamInputWhereInput>;
};


export type QueryBeamRunArgs = {
  where: BeamRunWhereUniqueInput;
};


export type QueryBeamsArgs = {
  cursor?: InputMaybe<BeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamWhereInput>;
};


export type QueryCheckVoucherValidityArgs = {
  voucherId: Scalars['String'];
};


export type QueryComplianceLetterArgs = {
  where: ComplianceLetterWhereUniqueInput;
};


export type QueryCredentialArgs = {
  where: CredentialWhereUniqueInput;
};


export type QueryCredentialStatusArgs = {
  where: CredentialStatusWhereUniqueInput;
};


export type QueryCredentialTypeArgs = {
  where: CredentialTypeWhereUniqueInput;
};


export type QueryCredentialsArgs = {
  cursor?: InputMaybe<CredentialWhereUniqueInput>;
  distinct?: InputMaybe<Array<CredentialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CredentialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CredentialWhereInput>;
};


export type QueryDecodeBranchUrlArgs = {
  branchUrl: Scalars['String'];
};


export type QueryDisclosureAcceptanceArgs = {
  where: DisclosureAcceptanceWhereUniqueInput;
};


export type QueryDisclosureAcceptancesArgs = {
  cursor?: InputMaybe<DisclosureAcceptanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<DisclosureAcceptanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DisclosureAcceptanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DisclosureAcceptanceWhereInput>;
};


export type QueryDispatchedSearchArgs = {
  where: DispatchedSearchWhereUniqueInput;
};


export type QueryFileArgs = {
  where: FileWhereUniqueInput;
};


export type QueryFindFirstLegacyCredentialArgs = {
  cursor?: InputMaybe<LegacyCredentialWhereUniqueInput>;
  distinct?: InputMaybe<Array<LegacyCredentialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LegacyCredentialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LegacyCredentialWhereInput>;
};


export type QueryFindUniqueApplicantDataArgs = {
  where: ApplicantDataWhereUniqueInput;
};


export type QueryGenerateSignedDisclosurePdfArgs = {
  disclosureAcceptanceId: Scalars['String'];
};


export type QueryGetInviteArgs = {
  inviteId: Scalars['String'];
};


export type QueryHighlySensitiveIdentifierArgs = {
  where: HighlySensitiveIdentifierWhereUniqueInput;
};


export type QueryIdVerificationResultArgs = {
  where: IdVerificationResultWhereUniqueInput;
};


export type QueryIdVerificationResultsArgs = {
  cursor?: InputMaybe<IdVerificationResultWhereUniqueInput>;
  distinct?: InputMaybe<Array<IdVerificationResultScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IdVerificationResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IdVerificationResultWhereInput>;
};


export type QueryLegacyCredentialArgs = {
  where: LegacyCredentialWhereUniqueInput;
};


export type QueryLegacyCredentialsArgs = {
  cursor?: InputMaybe<LegacyCredentialWhereUniqueInput>;
  distinct?: InputMaybe<Array<LegacyCredentialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LegacyCredentialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LegacyCredentialWhereInput>;
};


export type QueryNameArgs = {
  where: NameWhereUniqueInput;
};


export type QueryNamesArgs = {
  cursor?: InputMaybe<NameWhereUniqueInput>;
  distinct?: InputMaybe<Array<NameScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NameOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NameWhereInput>;
};


export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryOrderNoteArgs = {
  where: OrderNoteWhereUniqueInput;
};


export type QueryOrderNotesArgs = {
  cursor?: InputMaybe<OrderNoteWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderNoteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderNoteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderNoteWhereInput>;
};


export type QueryOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  cursor?: InputMaybe<OrganizationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrganizationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type QueryPackageSetArgs = {
  where: PackageSetWhereUniqueInput;
};


export type QueryPackageSetsArgs = {
  cursor?: InputMaybe<PackageSetWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackageSetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackageSetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackageSetWhereInput>;
};


export type QueryPassArgs = {
  where: PassWhereUniqueInput;
};


export type QueryPassTypeArgs = {
  where: PassTypeWhereUniqueInput;
};


export type QueryPassTypesArgs = {
  cursor?: InputMaybe<PassTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PassTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PassTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PassTypeWhereInput>;
};


export type QueryPassesArgs = {
  cursor?: InputMaybe<PassWhereUniqueInput>;
  distinct?: InputMaybe<Array<PassScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PassOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PassWhereInput>;
};


export type QueryPassportArgs = {
  where: PassportWhereUniqueInput;
};


export type QueryReportableIdVerificationResultArgs = {
  where: ReportableIdVerificationResultWhereUniqueInput;
};


export type QueryRequestedSearchArgs = {
  where: RequestedSearchWhereUniqueInput;
};


export type QueryResultArgs = {
  where: ResultWhereUniqueInput;
};


export type QueryResultRevisionArgs = {
  where: ResultRevisionWhereUniqueInput;
};


export type QueryRetrieveDisclosuresArgs = {
  addresses: Array<DisclosureAddress>;
  packageId: Scalars['String'];
};


export type QuerySearchTypeArgs = {
  where: SearchTypeWhereUniqueInput;
};


export type QueryShareOrderRecordArgs = {
  where: ShareOrderRecordWhereUniqueInput;
};


export type QueryStateIdentificationCardArgs = {
  where: StateIdentificationCardWhereUniqueInput;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVIdVoucherArgs = {
  where: VidVoucherWhereUniqueInput;
};


export type QueryVIdVouchersArgs = {
  cursor?: InputMaybe<VidVoucherWhereUniqueInput>;
  distinct?: InputMaybe<Array<VidVoucherScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VidVoucherOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VidVoucherWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type ReportableIdVerificationResult = {
  __typename?: 'ReportableIdVerificationResult';
  applicantAliasDataId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  idScanBiometricMatch: Scalars['Boolean'];
  idScanData: Scalars['JSON'];
  orderId: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type ReportableIdVerificationResultCreateInput = {
  applicantAliasData?: InputMaybe<ApplicantDataCreateNestedOneWithoutReportableIdVerificationResultInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  idScanBiometricMatch: Scalars['Boolean'];
  idScanData: Scalars['JSON'];
  order: OrderCreateNestedOneWithoutReportableIdVerificationResultInput;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ReportableIdVerificationResultCreateNestedOneWithoutApplicantAliasDataInput = {
  connect?: InputMaybe<ReportableIdVerificationResultWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReportableIdVerificationResultCreateOrConnectWithoutApplicantAliasDataInput>;
  create?: InputMaybe<ReportableIdVerificationResultCreateWithoutApplicantAliasDataInput>;
};

export type ReportableIdVerificationResultCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<ReportableIdVerificationResultWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReportableIdVerificationResultCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<ReportableIdVerificationResultCreateWithoutOrderInput>;
};

export type ReportableIdVerificationResultCreateOrConnectWithoutApplicantAliasDataInput = {
  create: ReportableIdVerificationResultCreateWithoutApplicantAliasDataInput;
  where: ReportableIdVerificationResultWhereUniqueInput;
};

export type ReportableIdVerificationResultCreateOrConnectWithoutOrderInput = {
  create: ReportableIdVerificationResultCreateWithoutOrderInput;
  where: ReportableIdVerificationResultWhereUniqueInput;
};

export type ReportableIdVerificationResultCreateWithoutApplicantAliasDataInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  idScanBiometricMatch: Scalars['Boolean'];
  idScanData: Scalars['JSON'];
  order: OrderCreateNestedOneWithoutReportableIdVerificationResultInput;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ReportableIdVerificationResultCreateWithoutOrderInput = {
  applicantAliasData?: InputMaybe<ApplicantDataCreateNestedOneWithoutReportableIdVerificationResultInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  idScanBiometricMatch: Scalars['Boolean'];
  idScanData: Scalars['JSON'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ReportableIdVerificationResultNullableRelationFilter = {
  is?: InputMaybe<ReportableIdVerificationResultWhereInput>;
  isNot?: InputMaybe<ReportableIdVerificationResultWhereInput>;
};

export type ReportableIdVerificationResultOrderByWithRelationInput = {
  applicantAliasData?: InputMaybe<ApplicantDataOrderByWithRelationInput>;
  applicantAliasDataId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  idScanBiometricMatch?: InputMaybe<SortOrder>;
  idScanData?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ReportableIdVerificationResultUpdateInput = {
  applicantAliasData?: InputMaybe<ApplicantDataUpdateOneWithoutReportableIdVerificationResultNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idScanBiometricMatch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  idScanData?: InputMaybe<Scalars['JSON']>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutReportableIdVerificationResultNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportableIdVerificationResultUpdateOneWithoutApplicantAliasDataNestedInput = {
  connect?: InputMaybe<ReportableIdVerificationResultWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReportableIdVerificationResultCreateOrConnectWithoutApplicantAliasDataInput>;
  create?: InputMaybe<ReportableIdVerificationResultCreateWithoutApplicantAliasDataInput>;
  delete?: InputMaybe<ReportableIdVerificationResultWhereInput>;
  disconnect?: InputMaybe<ReportableIdVerificationResultWhereInput>;
  update?: InputMaybe<ReportableIdVerificationResultUpdateToOneWithWhereWithoutApplicantAliasDataInput>;
  upsert?: InputMaybe<ReportableIdVerificationResultUpsertWithoutApplicantAliasDataInput>;
};

export type ReportableIdVerificationResultUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<ReportableIdVerificationResultWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReportableIdVerificationResultCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<ReportableIdVerificationResultCreateWithoutOrderInput>;
  delete?: InputMaybe<ReportableIdVerificationResultWhereInput>;
  disconnect?: InputMaybe<ReportableIdVerificationResultWhereInput>;
  update?: InputMaybe<ReportableIdVerificationResultUpdateToOneWithWhereWithoutOrderInput>;
  upsert?: InputMaybe<ReportableIdVerificationResultUpsertWithoutOrderInput>;
};

export type ReportableIdVerificationResultUpdateToOneWithWhereWithoutApplicantAliasDataInput = {
  data: ReportableIdVerificationResultUpdateWithoutApplicantAliasDataInput;
  where?: InputMaybe<ReportableIdVerificationResultWhereInput>;
};

export type ReportableIdVerificationResultUpdateToOneWithWhereWithoutOrderInput = {
  data: ReportableIdVerificationResultUpdateWithoutOrderInput;
  where?: InputMaybe<ReportableIdVerificationResultWhereInput>;
};

export type ReportableIdVerificationResultUpdateWithoutApplicantAliasDataInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idScanBiometricMatch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  idScanData?: InputMaybe<Scalars['JSON']>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutReportableIdVerificationResultNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportableIdVerificationResultUpdateWithoutOrderInput = {
  applicantAliasData?: InputMaybe<ApplicantDataUpdateOneWithoutReportableIdVerificationResultNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idScanBiometricMatch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  idScanData?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportableIdVerificationResultUpsertWithoutApplicantAliasDataInput = {
  create: ReportableIdVerificationResultCreateWithoutApplicantAliasDataInput;
  update: ReportableIdVerificationResultUpdateWithoutApplicantAliasDataInput;
  where?: InputMaybe<ReportableIdVerificationResultWhereInput>;
};

export type ReportableIdVerificationResultUpsertWithoutOrderInput = {
  create: ReportableIdVerificationResultCreateWithoutOrderInput;
  update: ReportableIdVerificationResultUpdateWithoutOrderInput;
  where?: InputMaybe<ReportableIdVerificationResultWhereInput>;
};

export type ReportableIdVerificationResultWhereInput = {
  AND?: InputMaybe<Array<ReportableIdVerificationResultWhereInput>>;
  NOT?: InputMaybe<Array<ReportableIdVerificationResultWhereInput>>;
  OR?: InputMaybe<Array<ReportableIdVerificationResultWhereInput>>;
  applicantAliasData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantAliasDataId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  idScanBiometricMatch?: InputMaybe<BoolFilter>;
  idScanData?: InputMaybe<JsonFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReportableIdVerificationResultWhereUniqueInput = {
  AND?: InputMaybe<Array<ReportableIdVerificationResultWhereInput>>;
  NOT?: InputMaybe<Array<ReportableIdVerificationResultWhereInput>>;
  OR?: InputMaybe<Array<ReportableIdVerificationResultWhereInput>>;
  applicantAliasData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantAliasDataId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  idScanBiometricMatch?: InputMaybe<BoolFilter>;
  idScanData?: InputMaybe<JsonFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RequestedSearch = {
  __typename?: 'RequestedSearch';
  _count?: Maybe<RequestedSearchCount>;
  blob: Scalars['JSON'];
  createdAt: Scalars['DateTimeISO'];
  dispatchedSearches: Array<DispatchedSearch>;
  id: Scalars['String'];
  order: Order;
  orderId: Scalars['String'];
  results: Array<Result>;
  searchType: SearchType;
  searchTypeId: Scalars['String'];
  status: SearchStatusTypeEnum;
  updatedAt: Scalars['DateTimeISO'];
};


export type RequestedSearchDispatchedSearchesArgs = {
  cursor?: InputMaybe<DispatchedSearchWhereUniqueInput>;
  distinct?: InputMaybe<Array<DispatchedSearchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DispatchedSearchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DispatchedSearchWhereInput>;
};


export type RequestedSearchResultsArgs = {
  cursor?: InputMaybe<ResultWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResultScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResultWhereInput>;
};

export type RequestedSearchCount = {
  __typename?: 'RequestedSearchCount';
  dispatchedSearches: Scalars['Int'];
  results: Scalars['Int'];
};


export type RequestedSearchCountDispatchedSearchesArgs = {
  where?: InputMaybe<DispatchedSearchWhereInput>;
};


export type RequestedSearchCountResultsArgs = {
  where?: InputMaybe<ResultWhereInput>;
};

export type RequestedSearchCreateInput = {
  blob: Scalars['JSON'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutRequestedSearchInput>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutRequestedSearchesInput;
  results?: InputMaybe<ResultCreateNestedManyWithoutRequestedSearchInput>;
  searchType: SearchTypeCreateNestedOneWithoutRequestedSearchesInput;
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type RequestedSearchCreateManyOrderInput = {
  blob: Scalars['JSON'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  searchTypeId: Scalars['String'];
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type RequestedSearchCreateManyOrderInputEnvelope = {
  data: Array<RequestedSearchCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RequestedSearchCreateManySearchTypeInput = {
  blob: Scalars['JSON'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type RequestedSearchCreateManySearchTypeInputEnvelope = {
  data: Array<RequestedSearchCreateManySearchTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RequestedSearchCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<RequestedSearchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RequestedSearchCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<RequestedSearchCreateWithoutOrderInput>>;
  createMany?: InputMaybe<RequestedSearchCreateManyOrderInputEnvelope>;
};

export type RequestedSearchCreateNestedManyWithoutSearchTypeInput = {
  connect?: InputMaybe<Array<RequestedSearchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RequestedSearchCreateOrConnectWithoutSearchTypeInput>>;
  create?: InputMaybe<Array<RequestedSearchCreateWithoutSearchTypeInput>>;
  createMany?: InputMaybe<RequestedSearchCreateManySearchTypeInputEnvelope>;
};

export type RequestedSearchCreateNestedOneWithoutDispatchedSearchesInput = {
  connect?: InputMaybe<RequestedSearchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RequestedSearchCreateOrConnectWithoutDispatchedSearchesInput>;
  create?: InputMaybe<RequestedSearchCreateWithoutDispatchedSearchesInput>;
};

export type RequestedSearchCreateNestedOneWithoutResultsInput = {
  connect?: InputMaybe<RequestedSearchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RequestedSearchCreateOrConnectWithoutResultsInput>;
  create?: InputMaybe<RequestedSearchCreateWithoutResultsInput>;
};

export type RequestedSearchCreateOrConnectWithoutDispatchedSearchesInput = {
  create: RequestedSearchCreateWithoutDispatchedSearchesInput;
  where: RequestedSearchWhereUniqueInput;
};

export type RequestedSearchCreateOrConnectWithoutOrderInput = {
  create: RequestedSearchCreateWithoutOrderInput;
  where: RequestedSearchWhereUniqueInput;
};

export type RequestedSearchCreateOrConnectWithoutResultsInput = {
  create: RequestedSearchCreateWithoutResultsInput;
  where: RequestedSearchWhereUniqueInput;
};

export type RequestedSearchCreateOrConnectWithoutSearchTypeInput = {
  create: RequestedSearchCreateWithoutSearchTypeInput;
  where: RequestedSearchWhereUniqueInput;
};

export type RequestedSearchCreateWithoutDispatchedSearchesInput = {
  blob: Scalars['JSON'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutRequestedSearchesInput;
  results?: InputMaybe<ResultCreateNestedManyWithoutRequestedSearchInput>;
  searchType: SearchTypeCreateNestedOneWithoutRequestedSearchesInput;
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type RequestedSearchCreateWithoutOrderInput = {
  blob: Scalars['JSON'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutRequestedSearchInput>;
  id?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<ResultCreateNestedManyWithoutRequestedSearchInput>;
  searchType: SearchTypeCreateNestedOneWithoutRequestedSearchesInput;
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type RequestedSearchCreateWithoutResultsInput = {
  blob: Scalars['JSON'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutRequestedSearchInput>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutRequestedSearchesInput;
  searchType: SearchTypeCreateNestedOneWithoutRequestedSearchesInput;
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type RequestedSearchCreateWithoutSearchTypeInput = {
  blob: Scalars['JSON'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutRequestedSearchInput>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutRequestedSearchesInput;
  results?: InputMaybe<ResultCreateNestedManyWithoutRequestedSearchInput>;
  status: SearchStatusTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type RequestedSearchListRelationFilter = {
  every?: InputMaybe<RequestedSearchWhereInput>;
  none?: InputMaybe<RequestedSearchWhereInput>;
  some?: InputMaybe<RequestedSearchWhereInput>;
};

export type RequestedSearchNullableRelationFilter = {
  is?: InputMaybe<RequestedSearchWhereInput>;
  isNot?: InputMaybe<RequestedSearchWhereInput>;
};

export type RequestedSearchOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RequestedSearchOrderByWithRelationInput = {
  blob?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dispatchedSearches?: InputMaybe<DispatchedSearchOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  results?: InputMaybe<ResultOrderByRelationAggregateInput>;
  searchType?: InputMaybe<SearchTypeOrderByWithRelationInput>;
  searchTypeId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RequestedSearchRelationFilter = {
  is?: InputMaybe<RequestedSearchWhereInput>;
  isNot?: InputMaybe<RequestedSearchWhereInput>;
};

export enum RequestedSearchScalarFieldEnum {
  Blob = 'blob',
  CreatedAt = 'createdAt',
  Id = 'id',
  OrderId = 'orderId',
  SearchTypeId = 'searchTypeId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type RequestedSearchScalarWhereInput = {
  AND?: InputMaybe<Array<RequestedSearchScalarWhereInput>>;
  NOT?: InputMaybe<Array<RequestedSearchScalarWhereInput>>;
  OR?: InputMaybe<Array<RequestedSearchScalarWhereInput>>;
  blob?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  searchTypeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RequestedSearchUpdateManyMutationInput = {
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RequestedSearchUpdateManyWithWhereWithoutOrderInput = {
  data: RequestedSearchUpdateManyMutationInput;
  where: RequestedSearchScalarWhereInput;
};

export type RequestedSearchUpdateManyWithWhereWithoutSearchTypeInput = {
  data: RequestedSearchUpdateManyMutationInput;
  where: RequestedSearchScalarWhereInput;
};

export type RequestedSearchUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<RequestedSearchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RequestedSearchCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<RequestedSearchCreateWithoutOrderInput>>;
  createMany?: InputMaybe<RequestedSearchCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<RequestedSearchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RequestedSearchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RequestedSearchWhereUniqueInput>>;
  set?: InputMaybe<Array<RequestedSearchWhereUniqueInput>>;
  update?: InputMaybe<Array<RequestedSearchUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<RequestedSearchUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<RequestedSearchUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type RequestedSearchUpdateManyWithoutSearchTypeNestedInput = {
  connect?: InputMaybe<Array<RequestedSearchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RequestedSearchCreateOrConnectWithoutSearchTypeInput>>;
  create?: InputMaybe<Array<RequestedSearchCreateWithoutSearchTypeInput>>;
  createMany?: InputMaybe<RequestedSearchCreateManySearchTypeInputEnvelope>;
  delete?: InputMaybe<Array<RequestedSearchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RequestedSearchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RequestedSearchWhereUniqueInput>>;
  set?: InputMaybe<Array<RequestedSearchWhereUniqueInput>>;
  update?: InputMaybe<Array<RequestedSearchUpdateWithWhereUniqueWithoutSearchTypeInput>>;
  updateMany?: InputMaybe<Array<RequestedSearchUpdateManyWithWhereWithoutSearchTypeInput>>;
  upsert?: InputMaybe<Array<RequestedSearchUpsertWithWhereUniqueWithoutSearchTypeInput>>;
};

export type RequestedSearchUpdateOneRequiredWithoutDispatchedSearchesNestedInput = {
  connect?: InputMaybe<RequestedSearchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RequestedSearchCreateOrConnectWithoutDispatchedSearchesInput>;
  create?: InputMaybe<RequestedSearchCreateWithoutDispatchedSearchesInput>;
  update?: InputMaybe<RequestedSearchUpdateToOneWithWhereWithoutDispatchedSearchesInput>;
  upsert?: InputMaybe<RequestedSearchUpsertWithoutDispatchedSearchesInput>;
};

export type RequestedSearchUpdateOneWithoutResultsNestedInput = {
  connect?: InputMaybe<RequestedSearchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RequestedSearchCreateOrConnectWithoutResultsInput>;
  create?: InputMaybe<RequestedSearchCreateWithoutResultsInput>;
  delete?: InputMaybe<RequestedSearchWhereInput>;
  disconnect?: InputMaybe<RequestedSearchWhereInput>;
  update?: InputMaybe<RequestedSearchUpdateToOneWithWhereWithoutResultsInput>;
  upsert?: InputMaybe<RequestedSearchUpsertWithoutResultsInput>;
};

export type RequestedSearchUpdateToOneWithWhereWithoutDispatchedSearchesInput = {
  data: RequestedSearchUpdateWithoutDispatchedSearchesInput;
  where?: InputMaybe<RequestedSearchWhereInput>;
};

export type RequestedSearchUpdateToOneWithWhereWithoutResultsInput = {
  data: RequestedSearchUpdateWithoutResultsInput;
  where?: InputMaybe<RequestedSearchWhereInput>;
};

export type RequestedSearchUpdateWithWhereUniqueWithoutOrderInput = {
  data: RequestedSearchUpdateWithoutOrderInput;
  where: RequestedSearchWhereUniqueInput;
};

export type RequestedSearchUpdateWithWhereUniqueWithoutSearchTypeInput = {
  data: RequestedSearchUpdateWithoutSearchTypeInput;
  where: RequestedSearchWhereUniqueInput;
};

export type RequestedSearchUpdateWithoutDispatchedSearchesInput = {
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutRequestedSearchesNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutRequestedSearchNestedInput>;
  searchType?: InputMaybe<SearchTypeUpdateOneRequiredWithoutRequestedSearchesNestedInput>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RequestedSearchUpdateWithoutOrderInput = {
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutRequestedSearchNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  results?: InputMaybe<ResultUpdateManyWithoutRequestedSearchNestedInput>;
  searchType?: InputMaybe<SearchTypeUpdateOneRequiredWithoutRequestedSearchesNestedInput>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RequestedSearchUpdateWithoutResultsInput = {
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutRequestedSearchNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutRequestedSearchesNestedInput>;
  searchType?: InputMaybe<SearchTypeUpdateOneRequiredWithoutRequestedSearchesNestedInput>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RequestedSearchUpdateWithoutSearchTypeInput = {
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutRequestedSearchNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutRequestedSearchesNestedInput>;
  results?: InputMaybe<ResultUpdateManyWithoutRequestedSearchNestedInput>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RequestedSearchUpsertWithWhereUniqueWithoutOrderInput = {
  create: RequestedSearchCreateWithoutOrderInput;
  update: RequestedSearchUpdateWithoutOrderInput;
  where: RequestedSearchWhereUniqueInput;
};

export type RequestedSearchUpsertWithWhereUniqueWithoutSearchTypeInput = {
  create: RequestedSearchCreateWithoutSearchTypeInput;
  update: RequestedSearchUpdateWithoutSearchTypeInput;
  where: RequestedSearchWhereUniqueInput;
};

export type RequestedSearchUpsertWithoutDispatchedSearchesInput = {
  create: RequestedSearchCreateWithoutDispatchedSearchesInput;
  update: RequestedSearchUpdateWithoutDispatchedSearchesInput;
  where?: InputMaybe<RequestedSearchWhereInput>;
};

export type RequestedSearchUpsertWithoutResultsInput = {
  create: RequestedSearchCreateWithoutResultsInput;
  update: RequestedSearchUpdateWithoutResultsInput;
  where?: InputMaybe<RequestedSearchWhereInput>;
};

export type RequestedSearchWhereInput = {
  AND?: InputMaybe<Array<RequestedSearchWhereInput>>;
  NOT?: InputMaybe<Array<RequestedSearchWhereInput>>;
  OR?: InputMaybe<Array<RequestedSearchWhereInput>>;
  blob?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dispatchedSearches?: InputMaybe<DispatchedSearchListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  results?: InputMaybe<ResultListRelationFilter>;
  searchType?: InputMaybe<SearchTypeRelationFilter>;
  searchTypeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RequestedSearchWhereUniqueInput = {
  AND?: InputMaybe<Array<RequestedSearchWhereInput>>;
  NOT?: InputMaybe<Array<RequestedSearchWhereInput>>;
  OR?: InputMaybe<Array<RequestedSearchWhereInput>>;
  blob?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dispatchedSearches?: InputMaybe<DispatchedSearchListRelationFilter>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  results?: InputMaybe<ResultListRelationFilter>;
  searchType?: InputMaybe<SearchTypeRelationFilter>;
  searchTypeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumSearchStatusTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Response = {
  __typename?: 'Response';
  data?: Maybe<Scalars['JSON']>;
};

export type Result = {
  __typename?: 'Result';
  Order?: Maybe<Order>;
  _count?: Maybe<ResultCount>;
  alert?: Maybe<ResultAlertTypeEnum>;
  attachments: Array<File>;
  blob?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTimeISO'];
  detail: Scalars['String'];
  id: Scalars['String'];
  normalized?: Maybe<Scalars['JSON']>;
  orderId?: Maybe<Scalars['String']>;
  requestedSearch?: Maybe<RequestedSearch>;
  requestedSearchId?: Maybe<Scalars['String']>;
  revisions: Array<ResultRevision>;
  searchType?: Maybe<ResultSearchType>;
  sexOffender?: Maybe<Scalars['Boolean']>;
  ssn?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTimeISO'];
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars['String']>;
};


export type ResultOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type ResultAttachmentsArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FileWhereInput>;
};


export type ResultRequestedSearchArgs = {
  where?: InputMaybe<RequestedSearchWhereInput>;
};


export type ResultRevisionsArgs = {
  cursor?: InputMaybe<ResultRevisionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResultRevisionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResultRevisionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResultRevisionWhereInput>;
};


export type ResultVendorArgs = {
  where?: InputMaybe<VendorWhereInput>;
};

export enum ResultAlertTypeEnum {
  AlertsFound = 'ALERTS_FOUND',
  ComplianceReview = 'COMPLIANCE_REVIEW',
  NoAlertsFound = 'NO_ALERTS_FOUND'
}

export type ResultCount = {
  __typename?: 'ResultCount';
  attachments: Scalars['Int'];
  revisions: Scalars['Int'];
};


export type ResultCountAttachmentsArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type ResultCountRevisionsArgs = {
  where?: InputMaybe<ResultRevisionWhereInput>;
};

export type ResultCreateManyOrderInput = {
  alert?: InputMaybe<ResultAlertTypeEnum>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  detail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  normalized?: InputMaybe<Scalars['JSON']>;
  requestedSearchId?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<ResultSearchType>;
  sexOffender?: InputMaybe<Scalars['Boolean']>;
  ssn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type ResultCreateManyOrderInputEnvelope = {
  data: Array<ResultCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ResultCreateManyRequestedSearchInput = {
  alert?: InputMaybe<ResultAlertTypeEnum>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  detail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  normalized?: InputMaybe<Scalars['JSON']>;
  orderId?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<ResultSearchType>;
  sexOffender?: InputMaybe<Scalars['Boolean']>;
  ssn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type ResultCreateManyRequestedSearchInputEnvelope = {
  data: Array<ResultCreateManyRequestedSearchInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ResultCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<ResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResultCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<ResultCreateWithoutOrderInput>>;
  createMany?: InputMaybe<ResultCreateManyOrderInputEnvelope>;
};

export type ResultCreateNestedManyWithoutRequestedSearchInput = {
  connect?: InputMaybe<Array<ResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResultCreateOrConnectWithoutRequestedSearchInput>>;
  create?: InputMaybe<Array<ResultCreateWithoutRequestedSearchInput>>;
  createMany?: InputMaybe<ResultCreateManyRequestedSearchInputEnvelope>;
};

export type ResultCreateNestedOneWithoutAttachmentsInput = {
  connect?: InputMaybe<ResultWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResultCreateOrConnectWithoutAttachmentsInput>;
  create?: InputMaybe<ResultCreateWithoutAttachmentsInput>;
};

export type ResultCreateNestedOneWithoutRevisionsInput = {
  connect?: InputMaybe<ResultWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResultCreateOrConnectWithoutRevisionsInput>;
  create?: InputMaybe<ResultCreateWithoutRevisionsInput>;
};

export type ResultCreateOrConnectWithoutAttachmentsInput = {
  create: ResultCreateWithoutAttachmentsInput;
  where: ResultWhereUniqueInput;
};

export type ResultCreateOrConnectWithoutOrderInput = {
  create: ResultCreateWithoutOrderInput;
  where: ResultWhereUniqueInput;
};

export type ResultCreateOrConnectWithoutRequestedSearchInput = {
  create: ResultCreateWithoutRequestedSearchInput;
  where: ResultWhereUniqueInput;
};

export type ResultCreateOrConnectWithoutRevisionsInput = {
  create: ResultCreateWithoutRevisionsInput;
  where: ResultWhereUniqueInput;
};

export type ResultCreateWithoutAttachmentsInput = {
  Order?: InputMaybe<OrderCreateNestedOneWithoutResultsInput>;
  alert?: InputMaybe<ResultAlertTypeEnum>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  detail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  normalized?: InputMaybe<Scalars['JSON']>;
  requestedSearch?: InputMaybe<RequestedSearchCreateNestedOneWithoutResultsInput>;
  revisions?: InputMaybe<ResultRevisionCreateNestedManyWithoutResultInput>;
  searchType?: InputMaybe<ResultSearchType>;
  sexOffender?: InputMaybe<Scalars['Boolean']>;
  ssn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vendor?: InputMaybe<VendorCreateNestedOneWithoutResultsInput>;
};

export type ResultCreateWithoutOrderInput = {
  alert?: InputMaybe<ResultAlertTypeEnum>;
  attachments?: InputMaybe<FileCreateNestedManyWithoutResultInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  detail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  normalized?: InputMaybe<Scalars['JSON']>;
  requestedSearch?: InputMaybe<RequestedSearchCreateNestedOneWithoutResultsInput>;
  revisions?: InputMaybe<ResultRevisionCreateNestedManyWithoutResultInput>;
  searchType?: InputMaybe<ResultSearchType>;
  sexOffender?: InputMaybe<Scalars['Boolean']>;
  ssn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vendor?: InputMaybe<VendorCreateNestedOneWithoutResultsInput>;
};

export type ResultCreateWithoutRequestedSearchInput = {
  Order?: InputMaybe<OrderCreateNestedOneWithoutResultsInput>;
  alert?: InputMaybe<ResultAlertTypeEnum>;
  attachments?: InputMaybe<FileCreateNestedManyWithoutResultInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  detail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  normalized?: InputMaybe<Scalars['JSON']>;
  revisions?: InputMaybe<ResultRevisionCreateNestedManyWithoutResultInput>;
  searchType?: InputMaybe<ResultSearchType>;
  sexOffender?: InputMaybe<Scalars['Boolean']>;
  ssn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vendor?: InputMaybe<VendorCreateNestedOneWithoutResultsInput>;
};

export type ResultCreateWithoutRevisionsInput = {
  Order?: InputMaybe<OrderCreateNestedOneWithoutResultsInput>;
  alert?: InputMaybe<ResultAlertTypeEnum>;
  attachments?: InputMaybe<FileCreateNestedManyWithoutResultInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  detail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  normalized?: InputMaybe<Scalars['JSON']>;
  requestedSearch?: InputMaybe<RequestedSearchCreateNestedOneWithoutResultsInput>;
  searchType?: InputMaybe<ResultSearchType>;
  sexOffender?: InputMaybe<Scalars['Boolean']>;
  ssn?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vendor?: InputMaybe<VendorCreateNestedOneWithoutResultsInput>;
};

export type ResultListRelationFilter = {
  every?: InputMaybe<ResultWhereInput>;
  none?: InputMaybe<ResultWhereInput>;
  some?: InputMaybe<ResultWhereInput>;
};

export type ResultNullableRelationFilter = {
  is?: InputMaybe<ResultWhereInput>;
  isNot?: InputMaybe<ResultWhereInput>;
};

export type ResultOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ResultOrderByWithRelationInput = {
  Order?: InputMaybe<OrderOrderByWithRelationInput>;
  alert?: InputMaybe<SortOrderInput>;
  attachments?: InputMaybe<FileOrderByRelationAggregateInput>;
  blob?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  detail?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  normalized?: InputMaybe<SortOrderInput>;
  orderId?: InputMaybe<SortOrderInput>;
  requestedSearch?: InputMaybe<RequestedSearchOrderByWithRelationInput>;
  requestedSearchId?: InputMaybe<SortOrderInput>;
  revisions?: InputMaybe<ResultRevisionOrderByRelationAggregateInput>;
  searchType?: InputMaybe<SortOrderInput>;
  sexOffender?: InputMaybe<SortOrderInput>;
  ssn?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  vendor?: InputMaybe<VendorOrderByWithRelationInput>;
  vendorId?: InputMaybe<SortOrderInput>;
};

export type ResultRevision = {
  __typename?: 'ResultRevision';
  Result?: Maybe<Result>;
  blob?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  resultId?: Maybe<Scalars['String']>;
  revisedById: User;
  updatedAt: Scalars['DateTimeISO'];
  userId: Scalars['String'];
};


export type ResultRevisionResultArgs = {
  where?: InputMaybe<ResultWhereInput>;
};

export type ResultRevisionCreateInput = {
  Result?: InputMaybe<ResultCreateNestedOneWithoutRevisionsInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  revisedById: UserCreateNestedOneWithoutResultRevisionInput;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ResultRevisionCreateManyResultInput = {
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId: Scalars['String'];
};

export type ResultRevisionCreateManyResultInputEnvelope = {
  data: Array<ResultRevisionCreateManyResultInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ResultRevisionCreateManyRevisedByIdInput = {
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  resultId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ResultRevisionCreateManyRevisedByIdInputEnvelope = {
  data: Array<ResultRevisionCreateManyRevisedByIdInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ResultRevisionCreateNestedManyWithoutResultInput = {
  connect?: InputMaybe<Array<ResultRevisionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResultRevisionCreateOrConnectWithoutResultInput>>;
  create?: InputMaybe<Array<ResultRevisionCreateWithoutResultInput>>;
  createMany?: InputMaybe<ResultRevisionCreateManyResultInputEnvelope>;
};

export type ResultRevisionCreateNestedManyWithoutRevisedByIdInput = {
  connect?: InputMaybe<Array<ResultRevisionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResultRevisionCreateOrConnectWithoutRevisedByIdInput>>;
  create?: InputMaybe<Array<ResultRevisionCreateWithoutRevisedByIdInput>>;
  createMany?: InputMaybe<ResultRevisionCreateManyRevisedByIdInputEnvelope>;
};

export type ResultRevisionCreateOrConnectWithoutResultInput = {
  create: ResultRevisionCreateWithoutResultInput;
  where: ResultRevisionWhereUniqueInput;
};

export type ResultRevisionCreateOrConnectWithoutRevisedByIdInput = {
  create: ResultRevisionCreateWithoutRevisedByIdInput;
  where: ResultRevisionWhereUniqueInput;
};

export type ResultRevisionCreateWithoutResultInput = {
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  revisedById: UserCreateNestedOneWithoutResultRevisionInput;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ResultRevisionCreateWithoutRevisedByIdInput = {
  Result?: InputMaybe<ResultCreateNestedOneWithoutRevisionsInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ResultRevisionListRelationFilter = {
  every?: InputMaybe<ResultRevisionWhereInput>;
  none?: InputMaybe<ResultRevisionWhereInput>;
  some?: InputMaybe<ResultRevisionWhereInput>;
};

export type ResultRevisionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ResultRevisionOrderByWithRelationInput = {
  Result?: InputMaybe<ResultOrderByWithRelationInput>;
  blob?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  resultId?: InputMaybe<SortOrderInput>;
  revisedById?: InputMaybe<UserOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum ResultRevisionScalarFieldEnum {
  Blob = 'blob',
  CreatedAt = 'createdAt',
  Id = 'id',
  ResultId = 'resultId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ResultRevisionScalarWhereInput = {
  AND?: InputMaybe<Array<ResultRevisionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResultRevisionScalarWhereInput>>;
  OR?: InputMaybe<Array<ResultRevisionScalarWhereInput>>;
  blob?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  resultId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ResultRevisionUpdateManyMutationInput = {
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ResultRevisionUpdateManyWithWhereWithoutResultInput = {
  data: ResultRevisionUpdateManyMutationInput;
  where: ResultRevisionScalarWhereInput;
};

export type ResultRevisionUpdateManyWithWhereWithoutRevisedByIdInput = {
  data: ResultRevisionUpdateManyMutationInput;
  where: ResultRevisionScalarWhereInput;
};

export type ResultRevisionUpdateManyWithoutResultNestedInput = {
  connect?: InputMaybe<Array<ResultRevisionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResultRevisionCreateOrConnectWithoutResultInput>>;
  create?: InputMaybe<Array<ResultRevisionCreateWithoutResultInput>>;
  createMany?: InputMaybe<ResultRevisionCreateManyResultInputEnvelope>;
  delete?: InputMaybe<Array<ResultRevisionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResultRevisionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResultRevisionWhereUniqueInput>>;
  set?: InputMaybe<Array<ResultRevisionWhereUniqueInput>>;
  update?: InputMaybe<Array<ResultRevisionUpdateWithWhereUniqueWithoutResultInput>>;
  updateMany?: InputMaybe<Array<ResultRevisionUpdateManyWithWhereWithoutResultInput>>;
  upsert?: InputMaybe<Array<ResultRevisionUpsertWithWhereUniqueWithoutResultInput>>;
};

export type ResultRevisionUpdateManyWithoutRevisedByIdNestedInput = {
  connect?: InputMaybe<Array<ResultRevisionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResultRevisionCreateOrConnectWithoutRevisedByIdInput>>;
  create?: InputMaybe<Array<ResultRevisionCreateWithoutRevisedByIdInput>>;
  createMany?: InputMaybe<ResultRevisionCreateManyRevisedByIdInputEnvelope>;
  delete?: InputMaybe<Array<ResultRevisionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResultRevisionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResultRevisionWhereUniqueInput>>;
  set?: InputMaybe<Array<ResultRevisionWhereUniqueInput>>;
  update?: InputMaybe<Array<ResultRevisionUpdateWithWhereUniqueWithoutRevisedByIdInput>>;
  updateMany?: InputMaybe<Array<ResultRevisionUpdateManyWithWhereWithoutRevisedByIdInput>>;
  upsert?: InputMaybe<Array<ResultRevisionUpsertWithWhereUniqueWithoutRevisedByIdInput>>;
};

export type ResultRevisionUpdateWithWhereUniqueWithoutResultInput = {
  data: ResultRevisionUpdateWithoutResultInput;
  where: ResultRevisionWhereUniqueInput;
};

export type ResultRevisionUpdateWithWhereUniqueWithoutRevisedByIdInput = {
  data: ResultRevisionUpdateWithoutRevisedByIdInput;
  where: ResultRevisionWhereUniqueInput;
};

export type ResultRevisionUpdateWithoutResultInput = {
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  revisedById?: InputMaybe<UserUpdateOneRequiredWithoutResultRevisionNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ResultRevisionUpdateWithoutRevisedByIdInput = {
  Result?: InputMaybe<ResultUpdateOneWithoutRevisionsNestedInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ResultRevisionUpsertWithWhereUniqueWithoutResultInput = {
  create: ResultRevisionCreateWithoutResultInput;
  update: ResultRevisionUpdateWithoutResultInput;
  where: ResultRevisionWhereUniqueInput;
};

export type ResultRevisionUpsertWithWhereUniqueWithoutRevisedByIdInput = {
  create: ResultRevisionCreateWithoutRevisedByIdInput;
  update: ResultRevisionUpdateWithoutRevisedByIdInput;
  where: ResultRevisionWhereUniqueInput;
};

export type ResultRevisionWhereInput = {
  AND?: InputMaybe<Array<ResultRevisionWhereInput>>;
  NOT?: InputMaybe<Array<ResultRevisionWhereInput>>;
  OR?: InputMaybe<Array<ResultRevisionWhereInput>>;
  Result?: InputMaybe<ResultNullableRelationFilter>;
  blob?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  resultId?: InputMaybe<StringNullableFilter>;
  revisedById?: InputMaybe<UserRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ResultRevisionWhereUniqueInput = {
  AND?: InputMaybe<Array<ResultRevisionWhereInput>>;
  NOT?: InputMaybe<Array<ResultRevisionWhereInput>>;
  OR?: InputMaybe<Array<ResultRevisionWhereInput>>;
  Result?: InputMaybe<ResultNullableRelationFilter>;
  blob?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  resultId?: InputMaybe<StringNullableFilter>;
  revisedById?: InputMaybe<UserRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum ResultScalarFieldEnum {
  Alert = 'alert',
  Blob = 'blob',
  CreatedAt = 'createdAt',
  Detail = 'detail',
  Id = 'id',
  Normalized = 'normalized',
  OrderId = 'orderId',
  RequestedSearchId = 'requestedSearchId',
  SearchType = 'searchType',
  SexOffender = 'sexOffender',
  Ssn = 'ssn',
  UpdatedAt = 'updatedAt',
  VendorId = 'vendorId'
}

export type ResultScalarWhereInput = {
  AND?: InputMaybe<Array<ResultScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResultScalarWhereInput>>;
  OR?: InputMaybe<Array<ResultScalarWhereInput>>;
  alert?: InputMaybe<EnumResultAlertTypeEnumNullableFilter>;
  blob?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  detail?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  normalized?: InputMaybe<JsonNullableFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  requestedSearchId?: InputMaybe<StringNullableFilter>;
  searchType?: InputMaybe<EnumResultSearchTypeNullableFilter>;
  sexOffender?: InputMaybe<BoolNullableFilter>;
  ssn?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vendorId?: InputMaybe<StringNullableFilter>;
};

export enum ResultSearchType {
  County = 'county',
  Natcrim = 'natcrim',
  NatcrimAlias = 'natcrim_alias',
  Sanctions = 'sanctions',
  Sexoffender = 'sexoffender',
  SsnAlert = 'ssn_alert',
  SsnAlias = 'ssn_alias',
  SsnValidation = 'ssn_validation',
  StandardIdVerification = 'standard_id_verification',
  Youth = 'youth'
}

export type ResultTypeCreateNestedManyWithoutSearchTypesInput = {
  connect?: InputMaybe<Array<ResultTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResultTypeCreateOrConnectWithoutSearchTypesInput>>;
  create?: InputMaybe<Array<ResultTypeCreateWithoutSearchTypesInput>>;
};

export type ResultTypeCreateOrConnectWithoutSearchTypesInput = {
  create: ResultTypeCreateWithoutSearchTypesInput;
  where: ResultTypeWhereUniqueInput;
};

export type ResultTypeCreateWithoutSearchTypesInput = {
  cra?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dataStructure: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ResultTypeListRelationFilter = {
  every?: InputMaybe<ResultTypeWhereInput>;
  none?: InputMaybe<ResultTypeWhereInput>;
  some?: InputMaybe<ResultTypeWhereInput>;
};

export type ResultTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ResultTypeScalarWhereInput = {
  AND?: InputMaybe<Array<ResultTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ResultTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<ResultTypeScalarWhereInput>>;
  cra?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataStructure?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ResultTypeUpdateManyMutationInput = {
  cra?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataStructure?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ResultTypeUpdateManyWithWhereWithoutSearchTypesInput = {
  data: ResultTypeUpdateManyMutationInput;
  where: ResultTypeScalarWhereInput;
};

export type ResultTypeUpdateManyWithoutSearchTypesNestedInput = {
  connect?: InputMaybe<Array<ResultTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResultTypeCreateOrConnectWithoutSearchTypesInput>>;
  create?: InputMaybe<Array<ResultTypeCreateWithoutSearchTypesInput>>;
  delete?: InputMaybe<Array<ResultTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResultTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResultTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<ResultTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<ResultTypeUpdateWithWhereUniqueWithoutSearchTypesInput>>;
  updateMany?: InputMaybe<Array<ResultTypeUpdateManyWithWhereWithoutSearchTypesInput>>;
  upsert?: InputMaybe<Array<ResultTypeUpsertWithWhereUniqueWithoutSearchTypesInput>>;
};

export type ResultTypeUpdateWithWhereUniqueWithoutSearchTypesInput = {
  data: ResultTypeUpdateWithoutSearchTypesInput;
  where: ResultTypeWhereUniqueInput;
};

export type ResultTypeUpdateWithoutSearchTypesInput = {
  cra?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataStructure?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ResultTypeUpsertWithWhereUniqueWithoutSearchTypesInput = {
  create: ResultTypeCreateWithoutSearchTypesInput;
  update: ResultTypeUpdateWithoutSearchTypesInput;
  where: ResultTypeWhereUniqueInput;
};

export type ResultTypeWhereInput = {
  AND?: InputMaybe<Array<ResultTypeWhereInput>>;
  NOT?: InputMaybe<Array<ResultTypeWhereInput>>;
  OR?: InputMaybe<Array<ResultTypeWhereInput>>;
  cra?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataStructure?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  searchTypes?: InputMaybe<SearchTypeListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ResultTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<ResultTypeWhereInput>>;
  NOT?: InputMaybe<Array<ResultTypeWhereInput>>;
  OR?: InputMaybe<Array<ResultTypeWhereInput>>;
  cra?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataStructure?: InputMaybe<JsonFilter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  searchTypes?: InputMaybe<SearchTypeListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ResultUpdateInput = {
  Order?: InputMaybe<OrderUpdateOneWithoutResultsNestedInput>;
  alert?: InputMaybe<NullableEnumResultAlertTypeEnumFieldUpdateOperationsInput>;
  attachments?: InputMaybe<FileUpdateManyWithoutResultNestedInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  normalized?: InputMaybe<Scalars['JSON']>;
  requestedSearch?: InputMaybe<RequestedSearchUpdateOneWithoutResultsNestedInput>;
  revisions?: InputMaybe<ResultRevisionUpdateManyWithoutResultNestedInput>;
  searchType?: InputMaybe<NullableEnumResultSearchTypeFieldUpdateOperationsInput>;
  sexOffender?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: InputMaybe<VendorUpdateOneWithoutResultsNestedInput>;
};

export type ResultUpdateManyMutationInput = {
  alert?: InputMaybe<NullableEnumResultAlertTypeEnumFieldUpdateOperationsInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  normalized?: InputMaybe<Scalars['JSON']>;
  searchType?: InputMaybe<NullableEnumResultSearchTypeFieldUpdateOperationsInput>;
  sexOffender?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ResultUpdateManyWithWhereWithoutOrderInput = {
  data: ResultUpdateManyMutationInput;
  where: ResultScalarWhereInput;
};

export type ResultUpdateManyWithWhereWithoutRequestedSearchInput = {
  data: ResultUpdateManyMutationInput;
  where: ResultScalarWhereInput;
};

export type ResultUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<ResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResultCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<ResultCreateWithoutOrderInput>>;
  createMany?: InputMaybe<ResultCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<ResultWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResultScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResultWhereUniqueInput>>;
  set?: InputMaybe<Array<ResultWhereUniqueInput>>;
  update?: InputMaybe<Array<ResultUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<ResultUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<ResultUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type ResultUpdateManyWithoutRequestedSearchNestedInput = {
  connect?: InputMaybe<Array<ResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ResultCreateOrConnectWithoutRequestedSearchInput>>;
  create?: InputMaybe<Array<ResultCreateWithoutRequestedSearchInput>>;
  createMany?: InputMaybe<ResultCreateManyRequestedSearchInputEnvelope>;
  delete?: InputMaybe<Array<ResultWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ResultScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ResultWhereUniqueInput>>;
  set?: InputMaybe<Array<ResultWhereUniqueInput>>;
  update?: InputMaybe<Array<ResultUpdateWithWhereUniqueWithoutRequestedSearchInput>>;
  updateMany?: InputMaybe<Array<ResultUpdateManyWithWhereWithoutRequestedSearchInput>>;
  upsert?: InputMaybe<Array<ResultUpsertWithWhereUniqueWithoutRequestedSearchInput>>;
};

export type ResultUpdateOneWithoutAttachmentsNestedInput = {
  connect?: InputMaybe<ResultWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResultCreateOrConnectWithoutAttachmentsInput>;
  create?: InputMaybe<ResultCreateWithoutAttachmentsInput>;
  delete?: InputMaybe<ResultWhereInput>;
  disconnect?: InputMaybe<ResultWhereInput>;
  update?: InputMaybe<ResultUpdateToOneWithWhereWithoutAttachmentsInput>;
  upsert?: InputMaybe<ResultUpsertWithoutAttachmentsInput>;
};

export type ResultUpdateOneWithoutRevisionsNestedInput = {
  connect?: InputMaybe<ResultWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ResultCreateOrConnectWithoutRevisionsInput>;
  create?: InputMaybe<ResultCreateWithoutRevisionsInput>;
  delete?: InputMaybe<ResultWhereInput>;
  disconnect?: InputMaybe<ResultWhereInput>;
  update?: InputMaybe<ResultUpdateToOneWithWhereWithoutRevisionsInput>;
  upsert?: InputMaybe<ResultUpsertWithoutRevisionsInput>;
};

export type ResultUpdateToOneWithWhereWithoutAttachmentsInput = {
  data: ResultUpdateWithoutAttachmentsInput;
  where?: InputMaybe<ResultWhereInput>;
};

export type ResultUpdateToOneWithWhereWithoutRevisionsInput = {
  data: ResultUpdateWithoutRevisionsInput;
  where?: InputMaybe<ResultWhereInput>;
};

export type ResultUpdateWithWhereUniqueWithoutOrderInput = {
  data: ResultUpdateWithoutOrderInput;
  where: ResultWhereUniqueInput;
};

export type ResultUpdateWithWhereUniqueWithoutRequestedSearchInput = {
  data: ResultUpdateWithoutRequestedSearchInput;
  where: ResultWhereUniqueInput;
};

export type ResultUpdateWithoutAttachmentsInput = {
  Order?: InputMaybe<OrderUpdateOneWithoutResultsNestedInput>;
  alert?: InputMaybe<NullableEnumResultAlertTypeEnumFieldUpdateOperationsInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  normalized?: InputMaybe<Scalars['JSON']>;
  requestedSearch?: InputMaybe<RequestedSearchUpdateOneWithoutResultsNestedInput>;
  revisions?: InputMaybe<ResultRevisionUpdateManyWithoutResultNestedInput>;
  searchType?: InputMaybe<NullableEnumResultSearchTypeFieldUpdateOperationsInput>;
  sexOffender?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: InputMaybe<VendorUpdateOneWithoutResultsNestedInput>;
};

export type ResultUpdateWithoutOrderInput = {
  alert?: InputMaybe<NullableEnumResultAlertTypeEnumFieldUpdateOperationsInput>;
  attachments?: InputMaybe<FileUpdateManyWithoutResultNestedInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  normalized?: InputMaybe<Scalars['JSON']>;
  requestedSearch?: InputMaybe<RequestedSearchUpdateOneWithoutResultsNestedInput>;
  revisions?: InputMaybe<ResultRevisionUpdateManyWithoutResultNestedInput>;
  searchType?: InputMaybe<NullableEnumResultSearchTypeFieldUpdateOperationsInput>;
  sexOffender?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: InputMaybe<VendorUpdateOneWithoutResultsNestedInput>;
};

export type ResultUpdateWithoutRequestedSearchInput = {
  Order?: InputMaybe<OrderUpdateOneWithoutResultsNestedInput>;
  alert?: InputMaybe<NullableEnumResultAlertTypeEnumFieldUpdateOperationsInput>;
  attachments?: InputMaybe<FileUpdateManyWithoutResultNestedInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  normalized?: InputMaybe<Scalars['JSON']>;
  revisions?: InputMaybe<ResultRevisionUpdateManyWithoutResultNestedInput>;
  searchType?: InputMaybe<NullableEnumResultSearchTypeFieldUpdateOperationsInput>;
  sexOffender?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: InputMaybe<VendorUpdateOneWithoutResultsNestedInput>;
};

export type ResultUpdateWithoutRevisionsInput = {
  Order?: InputMaybe<OrderUpdateOneWithoutResultsNestedInput>;
  alert?: InputMaybe<NullableEnumResultAlertTypeEnumFieldUpdateOperationsInput>;
  attachments?: InputMaybe<FileUpdateManyWithoutResultNestedInput>;
  blob?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  normalized?: InputMaybe<Scalars['JSON']>;
  requestedSearch?: InputMaybe<RequestedSearchUpdateOneWithoutResultsNestedInput>;
  searchType?: InputMaybe<NullableEnumResultSearchTypeFieldUpdateOperationsInput>;
  sexOffender?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ssn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vendor?: InputMaybe<VendorUpdateOneWithoutResultsNestedInput>;
};

export type ResultUpsertWithWhereUniqueWithoutOrderInput = {
  create: ResultCreateWithoutOrderInput;
  update: ResultUpdateWithoutOrderInput;
  where: ResultWhereUniqueInput;
};

export type ResultUpsertWithWhereUniqueWithoutRequestedSearchInput = {
  create: ResultCreateWithoutRequestedSearchInput;
  update: ResultUpdateWithoutRequestedSearchInput;
  where: ResultWhereUniqueInput;
};

export type ResultUpsertWithoutAttachmentsInput = {
  create: ResultCreateWithoutAttachmentsInput;
  update: ResultUpdateWithoutAttachmentsInput;
  where?: InputMaybe<ResultWhereInput>;
};

export type ResultUpsertWithoutRevisionsInput = {
  create: ResultCreateWithoutRevisionsInput;
  update: ResultUpdateWithoutRevisionsInput;
  where?: InputMaybe<ResultWhereInput>;
};

export type ResultWhereInput = {
  AND?: InputMaybe<Array<ResultWhereInput>>;
  NOT?: InputMaybe<Array<ResultWhereInput>>;
  OR?: InputMaybe<Array<ResultWhereInput>>;
  Order?: InputMaybe<OrderNullableRelationFilter>;
  alert?: InputMaybe<EnumResultAlertTypeEnumNullableFilter>;
  attachments?: InputMaybe<FileListRelationFilter>;
  blob?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  detail?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  normalized?: InputMaybe<JsonNullableFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  requestedSearch?: InputMaybe<RequestedSearchNullableRelationFilter>;
  requestedSearchId?: InputMaybe<StringNullableFilter>;
  revisions?: InputMaybe<ResultRevisionListRelationFilter>;
  searchType?: InputMaybe<EnumResultSearchTypeNullableFilter>;
  sexOffender?: InputMaybe<BoolNullableFilter>;
  ssn?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vendor?: InputMaybe<VendorNullableRelationFilter>;
  vendorId?: InputMaybe<StringNullableFilter>;
};

export type ResultWhereUniqueInput = {
  AND?: InputMaybe<Array<ResultWhereInput>>;
  NOT?: InputMaybe<Array<ResultWhereInput>>;
  OR?: InputMaybe<Array<ResultWhereInput>>;
  Order?: InputMaybe<OrderNullableRelationFilter>;
  alert?: InputMaybe<EnumResultAlertTypeEnumNullableFilter>;
  attachments?: InputMaybe<FileListRelationFilter>;
  blob?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  detail?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']>;
  normalized?: InputMaybe<JsonNullableFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  requestedSearch?: InputMaybe<RequestedSearchNullableRelationFilter>;
  requestedSearchId?: InputMaybe<StringNullableFilter>;
  revisions?: InputMaybe<ResultRevisionListRelationFilter>;
  searchType?: InputMaybe<EnumResultSearchTypeNullableFilter>;
  sexOffender?: InputMaybe<BoolNullableFilter>;
  ssn?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vendor?: InputMaybe<VendorNullableRelationFilter>;
  vendorId?: InputMaybe<StringNullableFilter>;
};

export type RoleAssignment = {
  __typename?: 'RoleAssignment';
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  roleV2Id: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  userId: Scalars['String'];
};

export type RoleAssignmentCreateManyOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  roleV2Id: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId: Scalars['String'];
};

export type RoleAssignmentCreateManyOrganizationInputEnvelope = {
  data: Array<RoleAssignmentCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RoleAssignmentCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  roleV2Id: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type RoleAssignmentCreateManyUserInputEnvelope = {
  data: Array<RoleAssignmentCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RoleAssignmentCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<RoleAssignmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleAssignmentCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<RoleAssignmentCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<RoleAssignmentCreateManyOrganizationInputEnvelope>;
};

export type RoleAssignmentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<RoleAssignmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleAssignmentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<RoleAssignmentCreateWithoutUserInput>>;
  createMany?: InputMaybe<RoleAssignmentCreateManyUserInputEnvelope>;
};

export type RoleAssignmentCreateOrConnectWithoutOrganizationInput = {
  create: RoleAssignmentCreateWithoutOrganizationInput;
  where: RoleAssignmentWhereUniqueInput;
};

export type RoleAssignmentCreateOrConnectWithoutUserInput = {
  create: RoleAssignmentCreateWithoutUserInput;
  where: RoleAssignmentWhereUniqueInput;
};

export type RoleAssignmentCreateWithoutOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductCreateNestedOneWithoutRoleAssignmentInput>;
  roleV2: RoleV2CreateNestedOneWithoutRoleAssignmentsInput;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user: UserCreateNestedOneWithoutRoleAssignmentInput;
};

export type RoleAssignmentCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutRoleAssignmentInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutRoleAssignmentInput>;
  roleV2: RoleV2CreateNestedOneWithoutRoleAssignmentsInput;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type RoleAssignmentListRelationFilter = {
  every?: InputMaybe<RoleAssignmentWhereInput>;
  none?: InputMaybe<RoleAssignmentWhereInput>;
  some?: InputMaybe<RoleAssignmentWhereInput>;
};

export type RoleAssignmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RoleAssignmentOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrderInput>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrderInput>;
  roleV2?: InputMaybe<RoleV2OrderByWithRelationInput>;
  roleV2Id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum RoleAssignmentScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  OrganizationId = 'organizationId',
  ProductId = 'productId',
  RoleV2Id = 'roleV2Id',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type RoleAssignmentScalarWhereInput = {
  AND?: InputMaybe<Array<RoleAssignmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<RoleAssignmentScalarWhereInput>>;
  OR?: InputMaybe<Array<RoleAssignmentScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  roleV2Id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type RoleAssignmentUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RoleAssignmentUpdateManyWithWhereWithoutOrganizationInput = {
  data: RoleAssignmentUpdateManyMutationInput;
  where: RoleAssignmentScalarWhereInput;
};

export type RoleAssignmentUpdateManyWithWhereWithoutUserInput = {
  data: RoleAssignmentUpdateManyMutationInput;
  where: RoleAssignmentScalarWhereInput;
};

export type RoleAssignmentUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<RoleAssignmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleAssignmentCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<RoleAssignmentCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<RoleAssignmentCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<RoleAssignmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoleAssignmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoleAssignmentWhereUniqueInput>>;
  set?: InputMaybe<Array<RoleAssignmentWhereUniqueInput>>;
  update?: InputMaybe<Array<RoleAssignmentUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<RoleAssignmentUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<RoleAssignmentUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type RoleAssignmentUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<RoleAssignmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleAssignmentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<RoleAssignmentCreateWithoutUserInput>>;
  createMany?: InputMaybe<RoleAssignmentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<RoleAssignmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoleAssignmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoleAssignmentWhereUniqueInput>>;
  set?: InputMaybe<Array<RoleAssignmentWhereUniqueInput>>;
  update?: InputMaybe<Array<RoleAssignmentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<RoleAssignmentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<RoleAssignmentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type RoleAssignmentUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: RoleAssignmentUpdateWithoutOrganizationInput;
  where: RoleAssignmentWhereUniqueInput;
};

export type RoleAssignmentUpdateWithWhereUniqueWithoutUserInput = {
  data: RoleAssignmentUpdateWithoutUserInput;
  where: RoleAssignmentWhereUniqueInput;
};

export type RoleAssignmentUpdateWithoutOrganizationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutRoleAssignmentNestedInput>;
  roleV2?: InputMaybe<RoleV2UpdateOneRequiredWithoutRoleAssignmentsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutRoleAssignmentNestedInput>;
};

export type RoleAssignmentUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutRoleAssignmentNestedInput>;
  product?: InputMaybe<ProductUpdateOneWithoutRoleAssignmentNestedInput>;
  roleV2?: InputMaybe<RoleV2UpdateOneRequiredWithoutRoleAssignmentsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RoleAssignmentUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: RoleAssignmentCreateWithoutOrganizationInput;
  update: RoleAssignmentUpdateWithoutOrganizationInput;
  where: RoleAssignmentWhereUniqueInput;
};

export type RoleAssignmentUpsertWithWhereUniqueWithoutUserInput = {
  create: RoleAssignmentCreateWithoutUserInput;
  update: RoleAssignmentUpdateWithoutUserInput;
  where: RoleAssignmentWhereUniqueInput;
};

export type RoleAssignmentWhereInput = {
  AND?: InputMaybe<Array<RoleAssignmentWhereInput>>;
  NOT?: InputMaybe<Array<RoleAssignmentWhereInput>>;
  OR?: InputMaybe<Array<RoleAssignmentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  product?: InputMaybe<ProductNullableRelationFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  roleV2?: InputMaybe<RoleV2RelationFilter>;
  roleV2Id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type RoleAssignmentWhereUniqueInput = {
  AND?: InputMaybe<Array<RoleAssignmentWhereInput>>;
  NOT?: InputMaybe<Array<RoleAssignmentWhereInput>>;
  OR?: InputMaybe<Array<RoleAssignmentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationNullableRelationFilter>;
  organizationId?: InputMaybe<StringNullableFilter>;
  product?: InputMaybe<ProductNullableRelationFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  roleV2?: InputMaybe<RoleV2RelationFilter>;
  roleV2Id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type RoleV2CreateNestedOneWithoutRoleAssignmentsInput = {
  connect?: InputMaybe<RoleV2WhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoleV2CreateOrConnectWithoutRoleAssignmentsInput>;
  create?: InputMaybe<RoleV2CreateWithoutRoleAssignmentsInput>;
};

export type RoleV2CreateOrConnectWithoutRoleAssignmentsInput = {
  create: RoleV2CreateWithoutRoleAssignmentsInput;
  where: RoleV2WhereUniqueInput;
};

export type RoleV2CreateWithoutRoleAssignmentsInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type RoleV2OrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  roleAssignments?: InputMaybe<RoleAssignmentOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RoleV2RelationFilter = {
  is?: InputMaybe<RoleV2WhereInput>;
  isNot?: InputMaybe<RoleV2WhereInput>;
};

export type RoleV2UpdateOneRequiredWithoutRoleAssignmentsNestedInput = {
  connect?: InputMaybe<RoleV2WhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoleV2CreateOrConnectWithoutRoleAssignmentsInput>;
  create?: InputMaybe<RoleV2CreateWithoutRoleAssignmentsInput>;
  update?: InputMaybe<RoleV2UpdateToOneWithWhereWithoutRoleAssignmentsInput>;
  upsert?: InputMaybe<RoleV2UpsertWithoutRoleAssignmentsInput>;
};

export type RoleV2UpdateToOneWithWhereWithoutRoleAssignmentsInput = {
  data: RoleV2UpdateWithoutRoleAssignmentsInput;
  where?: InputMaybe<RoleV2WhereInput>;
};

export type RoleV2UpdateWithoutRoleAssignmentsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RoleV2UpsertWithoutRoleAssignmentsInput = {
  create: RoleV2CreateWithoutRoleAssignmentsInput;
  update: RoleV2UpdateWithoutRoleAssignmentsInput;
  where?: InputMaybe<RoleV2WhereInput>;
};

export type RoleV2WhereInput = {
  AND?: InputMaybe<Array<RoleV2WhereInput>>;
  NOT?: InputMaybe<Array<RoleV2WhereInput>>;
  OR?: InputMaybe<Array<RoleV2WhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  roleAssignments?: InputMaybe<RoleAssignmentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RoleV2WhereUniqueInput = {
  AND?: InputMaybe<Array<RoleV2WhereInput>>;
  NOT?: InputMaybe<Array<RoleV2WhereInput>>;
  OR?: InputMaybe<Array<RoleV2WhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roleAssignments?: InputMaybe<RoleAssignmentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum SearchStatusTypeEnum {
  Dispatched = 'DISPATCHED',
  Error = 'ERROR',
  Returned = 'RETURNED'
}

export type SearchType = {
  __typename?: 'SearchType';
  _count?: Maybe<SearchTypeCount>;
  cra?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  dataStructure: Scalars['JSON'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  vendorBehaviour?: Maybe<Scalars['String']>;
  vendors?: Maybe<Scalars['String']>;
};

export type SearchTypeCount = {
  __typename?: 'SearchTypeCount';
  dispatchedSearches: Scalars['Int'];
  packageSetTypes: Scalars['Int'];
  packageSets: Scalars['Int'];
  requestedSearches: Scalars['Int'];
  resultTypes: Scalars['Int'];
};


export type SearchTypeCountDispatchedSearchesArgs = {
  where?: InputMaybe<DispatchedSearchWhereInput>;
};


export type SearchTypeCountPackageSetTypesArgs = {
  where?: InputMaybe<PackageSetTypeWhereInput>;
};


export type SearchTypeCountPackageSetsArgs = {
  where?: InputMaybe<PackageSetWhereInput>;
};


export type SearchTypeCountRequestedSearchesArgs = {
  where?: InputMaybe<RequestedSearchWhereInput>;
};


export type SearchTypeCountResultTypesArgs = {
  where?: InputMaybe<ResultTypeWhereInput>;
};

export type SearchTypeCreateNestedManyWithoutPackageSetTypesInput = {
  connect?: InputMaybe<Array<SearchTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SearchTypeCreateOrConnectWithoutPackageSetTypesInput>>;
  create?: InputMaybe<Array<SearchTypeCreateWithoutPackageSetTypesInput>>;
};

export type SearchTypeCreateNestedManyWithoutPackageSetsInput = {
  connect?: InputMaybe<Array<SearchTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SearchTypeCreateOrConnectWithoutPackageSetsInput>>;
  create?: InputMaybe<Array<SearchTypeCreateWithoutPackageSetsInput>>;
};

export type SearchTypeCreateNestedOneWithoutDispatchedSearchesInput = {
  connect?: InputMaybe<SearchTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SearchTypeCreateOrConnectWithoutDispatchedSearchesInput>;
  create?: InputMaybe<SearchTypeCreateWithoutDispatchedSearchesInput>;
};

export type SearchTypeCreateNestedOneWithoutRequestedSearchesInput = {
  connect?: InputMaybe<SearchTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SearchTypeCreateOrConnectWithoutRequestedSearchesInput>;
  create?: InputMaybe<SearchTypeCreateWithoutRequestedSearchesInput>;
};

export type SearchTypeCreateOrConnectWithoutDispatchedSearchesInput = {
  create: SearchTypeCreateWithoutDispatchedSearchesInput;
  where: SearchTypeWhereUniqueInput;
};

export type SearchTypeCreateOrConnectWithoutPackageSetTypesInput = {
  create: SearchTypeCreateWithoutPackageSetTypesInput;
  where: SearchTypeWhereUniqueInput;
};

export type SearchTypeCreateOrConnectWithoutPackageSetsInput = {
  create: SearchTypeCreateWithoutPackageSetsInput;
  where: SearchTypeWhereUniqueInput;
};

export type SearchTypeCreateOrConnectWithoutRequestedSearchesInput = {
  create: SearchTypeCreateWithoutRequestedSearchesInput;
  where: SearchTypeWhereUniqueInput;
};

export type SearchTypeCreateWithoutDispatchedSearchesInput = {
  cra?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dataStructure: Scalars['JSON'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  packageSetTypes?: InputMaybe<PackageSetTypeCreateNestedManyWithoutSearchTypesInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutSearchTypesInput>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutSearchTypeInput>;
  resultTypes?: InputMaybe<ResultTypeCreateNestedManyWithoutSearchTypesInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vendorBehaviour?: InputMaybe<Scalars['String']>;
  vendors?: InputMaybe<Scalars['String']>;
};

export type SearchTypeCreateWithoutPackageSetTypesInput = {
  cra?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dataStructure: Scalars['JSON'];
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutSearchTypeInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutSearchTypesInput>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutSearchTypeInput>;
  resultTypes?: InputMaybe<ResultTypeCreateNestedManyWithoutSearchTypesInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vendorBehaviour?: InputMaybe<Scalars['String']>;
  vendors?: InputMaybe<Scalars['String']>;
};

export type SearchTypeCreateWithoutPackageSetsInput = {
  cra?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dataStructure: Scalars['JSON'];
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutSearchTypeInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  packageSetTypes?: InputMaybe<PackageSetTypeCreateNestedManyWithoutSearchTypesInput>;
  requestedSearches?: InputMaybe<RequestedSearchCreateNestedManyWithoutSearchTypeInput>;
  resultTypes?: InputMaybe<ResultTypeCreateNestedManyWithoutSearchTypesInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vendorBehaviour?: InputMaybe<Scalars['String']>;
  vendors?: InputMaybe<Scalars['String']>;
};

export type SearchTypeCreateWithoutRequestedSearchesInput = {
  cra?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dataStructure: Scalars['JSON'];
  dispatchedSearches?: InputMaybe<DispatchedSearchCreateNestedManyWithoutSearchTypeInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  packageSetTypes?: InputMaybe<PackageSetTypeCreateNestedManyWithoutSearchTypesInput>;
  packageSets?: InputMaybe<PackageSetCreateNestedManyWithoutSearchTypesInput>;
  resultTypes?: InputMaybe<ResultTypeCreateNestedManyWithoutSearchTypesInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vendorBehaviour?: InputMaybe<Scalars['String']>;
  vendors?: InputMaybe<Scalars['String']>;
};

export type SearchTypeListRelationFilter = {
  every?: InputMaybe<SearchTypeWhereInput>;
  none?: InputMaybe<SearchTypeWhereInput>;
  some?: InputMaybe<SearchTypeWhereInput>;
};

export type SearchTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SearchTypeOrderByWithRelationInput = {
  cra?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  dataStructure?: InputMaybe<SortOrder>;
  dispatchedSearches?: InputMaybe<DispatchedSearchOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  packageSetTypes?: InputMaybe<PackageSetTypeOrderByRelationAggregateInput>;
  packageSets?: InputMaybe<PackageSetOrderByRelationAggregateInput>;
  requestedSearches?: InputMaybe<RequestedSearchOrderByRelationAggregateInput>;
  resultTypes?: InputMaybe<ResultTypeOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  vendorBehaviour?: InputMaybe<SortOrderInput>;
  vendors?: InputMaybe<SortOrderInput>;
};

export type SearchTypeRelationFilter = {
  is?: InputMaybe<SearchTypeWhereInput>;
  isNot?: InputMaybe<SearchTypeWhereInput>;
};

export enum SearchTypeScalarFieldEnum {
  Cra = 'cra',
  CreatedAt = 'createdAt',
  DataStructure = 'dataStructure',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  VendorBehaviour = 'vendorBehaviour',
  Vendors = 'vendors'
}

export type SearchTypeScalarWhereInput = {
  AND?: InputMaybe<Array<SearchTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<SearchTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<SearchTypeScalarWhereInput>>;
  cra?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataStructure?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vendorBehaviour?: InputMaybe<StringNullableFilter>;
  vendors?: InputMaybe<StringNullableFilter>;
};

export type SearchTypeUpdateManyMutationInput = {
  cra?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataStructure?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vendorBehaviour?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vendors?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SearchTypeUpdateManyWithWhereWithoutPackageSetTypesInput = {
  data: SearchTypeUpdateManyMutationInput;
  where: SearchTypeScalarWhereInput;
};

export type SearchTypeUpdateManyWithWhereWithoutPackageSetsInput = {
  data: SearchTypeUpdateManyMutationInput;
  where: SearchTypeScalarWhereInput;
};

export type SearchTypeUpdateManyWithoutPackageSetTypesNestedInput = {
  connect?: InputMaybe<Array<SearchTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SearchTypeCreateOrConnectWithoutPackageSetTypesInput>>;
  create?: InputMaybe<Array<SearchTypeCreateWithoutPackageSetTypesInput>>;
  delete?: InputMaybe<Array<SearchTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SearchTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SearchTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<SearchTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<SearchTypeUpdateWithWhereUniqueWithoutPackageSetTypesInput>>;
  updateMany?: InputMaybe<Array<SearchTypeUpdateManyWithWhereWithoutPackageSetTypesInput>>;
  upsert?: InputMaybe<Array<SearchTypeUpsertWithWhereUniqueWithoutPackageSetTypesInput>>;
};

export type SearchTypeUpdateManyWithoutPackageSetsNestedInput = {
  connect?: InputMaybe<Array<SearchTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SearchTypeCreateOrConnectWithoutPackageSetsInput>>;
  create?: InputMaybe<Array<SearchTypeCreateWithoutPackageSetsInput>>;
  delete?: InputMaybe<Array<SearchTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SearchTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SearchTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<SearchTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<SearchTypeUpdateWithWhereUniqueWithoutPackageSetsInput>>;
  updateMany?: InputMaybe<Array<SearchTypeUpdateManyWithWhereWithoutPackageSetsInput>>;
  upsert?: InputMaybe<Array<SearchTypeUpsertWithWhereUniqueWithoutPackageSetsInput>>;
};

export type SearchTypeUpdateOneRequiredWithoutDispatchedSearchesNestedInput = {
  connect?: InputMaybe<SearchTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SearchTypeCreateOrConnectWithoutDispatchedSearchesInput>;
  create?: InputMaybe<SearchTypeCreateWithoutDispatchedSearchesInput>;
  update?: InputMaybe<SearchTypeUpdateToOneWithWhereWithoutDispatchedSearchesInput>;
  upsert?: InputMaybe<SearchTypeUpsertWithoutDispatchedSearchesInput>;
};

export type SearchTypeUpdateOneRequiredWithoutRequestedSearchesNestedInput = {
  connect?: InputMaybe<SearchTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SearchTypeCreateOrConnectWithoutRequestedSearchesInput>;
  create?: InputMaybe<SearchTypeCreateWithoutRequestedSearchesInput>;
  update?: InputMaybe<SearchTypeUpdateToOneWithWhereWithoutRequestedSearchesInput>;
  upsert?: InputMaybe<SearchTypeUpsertWithoutRequestedSearchesInput>;
};

export type SearchTypeUpdateToOneWithWhereWithoutDispatchedSearchesInput = {
  data: SearchTypeUpdateWithoutDispatchedSearchesInput;
  where?: InputMaybe<SearchTypeWhereInput>;
};

export type SearchTypeUpdateToOneWithWhereWithoutRequestedSearchesInput = {
  data: SearchTypeUpdateWithoutRequestedSearchesInput;
  where?: InputMaybe<SearchTypeWhereInput>;
};

export type SearchTypeUpdateWithWhereUniqueWithoutPackageSetTypesInput = {
  data: SearchTypeUpdateWithoutPackageSetTypesInput;
  where: SearchTypeWhereUniqueInput;
};

export type SearchTypeUpdateWithWhereUniqueWithoutPackageSetsInput = {
  data: SearchTypeUpdateWithoutPackageSetsInput;
  where: SearchTypeWhereUniqueInput;
};

export type SearchTypeUpdateWithoutDispatchedSearchesInput = {
  cra?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataStructure?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageSetTypes?: InputMaybe<PackageSetTypeUpdateManyWithoutSearchTypesNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutSearchTypesNestedInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutSearchTypeNestedInput>;
  resultTypes?: InputMaybe<ResultTypeUpdateManyWithoutSearchTypesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vendorBehaviour?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vendors?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SearchTypeUpdateWithoutPackageSetTypesInput = {
  cra?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataStructure?: InputMaybe<Scalars['JSON']>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutSearchTypeNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutSearchTypesNestedInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutSearchTypeNestedInput>;
  resultTypes?: InputMaybe<ResultTypeUpdateManyWithoutSearchTypesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vendorBehaviour?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vendors?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SearchTypeUpdateWithoutPackageSetsInput = {
  cra?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataStructure?: InputMaybe<Scalars['JSON']>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutSearchTypeNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageSetTypes?: InputMaybe<PackageSetTypeUpdateManyWithoutSearchTypesNestedInput>;
  requestedSearches?: InputMaybe<RequestedSearchUpdateManyWithoutSearchTypeNestedInput>;
  resultTypes?: InputMaybe<ResultTypeUpdateManyWithoutSearchTypesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vendorBehaviour?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vendors?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SearchTypeUpdateWithoutRequestedSearchesInput = {
  cra?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataStructure?: InputMaybe<Scalars['JSON']>;
  dispatchedSearches?: InputMaybe<DispatchedSearchUpdateManyWithoutSearchTypeNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageSetTypes?: InputMaybe<PackageSetTypeUpdateManyWithoutSearchTypesNestedInput>;
  packageSets?: InputMaybe<PackageSetUpdateManyWithoutSearchTypesNestedInput>;
  resultTypes?: InputMaybe<ResultTypeUpdateManyWithoutSearchTypesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vendorBehaviour?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vendors?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SearchTypeUpsertWithWhereUniqueWithoutPackageSetTypesInput = {
  create: SearchTypeCreateWithoutPackageSetTypesInput;
  update: SearchTypeUpdateWithoutPackageSetTypesInput;
  where: SearchTypeWhereUniqueInput;
};

export type SearchTypeUpsertWithWhereUniqueWithoutPackageSetsInput = {
  create: SearchTypeCreateWithoutPackageSetsInput;
  update: SearchTypeUpdateWithoutPackageSetsInput;
  where: SearchTypeWhereUniqueInput;
};

export type SearchTypeUpsertWithoutDispatchedSearchesInput = {
  create: SearchTypeCreateWithoutDispatchedSearchesInput;
  update: SearchTypeUpdateWithoutDispatchedSearchesInput;
  where?: InputMaybe<SearchTypeWhereInput>;
};

export type SearchTypeUpsertWithoutRequestedSearchesInput = {
  create: SearchTypeCreateWithoutRequestedSearchesInput;
  update: SearchTypeUpdateWithoutRequestedSearchesInput;
  where?: InputMaybe<SearchTypeWhereInput>;
};

export type SearchTypeWhereInput = {
  AND?: InputMaybe<Array<SearchTypeWhereInput>>;
  NOT?: InputMaybe<Array<SearchTypeWhereInput>>;
  OR?: InputMaybe<Array<SearchTypeWhereInput>>;
  cra?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataStructure?: InputMaybe<JsonFilter>;
  dispatchedSearches?: InputMaybe<DispatchedSearchListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  packageSetTypes?: InputMaybe<PackageSetTypeListRelationFilter>;
  packageSets?: InputMaybe<PackageSetListRelationFilter>;
  requestedSearches?: InputMaybe<RequestedSearchListRelationFilter>;
  resultTypes?: InputMaybe<ResultTypeListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vendorBehaviour?: InputMaybe<StringNullableFilter>;
  vendors?: InputMaybe<StringNullableFilter>;
};

export type SearchTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<SearchTypeWhereInput>>;
  NOT?: InputMaybe<Array<SearchTypeWhereInput>>;
  OR?: InputMaybe<Array<SearchTypeWhereInput>>;
  cra?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataStructure?: InputMaybe<JsonFilter>;
  dispatchedSearches?: InputMaybe<DispatchedSearchListRelationFilter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  packageSetTypes?: InputMaybe<PackageSetTypeListRelationFilter>;
  packageSets?: InputMaybe<PackageSetListRelationFilter>;
  requestedSearches?: InputMaybe<RequestedSearchListRelationFilter>;
  resultTypes?: InputMaybe<ResultTypeListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vendorBehaviour?: InputMaybe<StringNullableFilter>;
  vendors?: InputMaybe<StringNullableFilter>;
};

export type ShareOrderRecord = {
  __typename?: 'ShareOrderRecord';
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['String'];
  order: Order;
  orderId: Scalars['String'];
  organizationId: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  user: User;
  userId: Scalars['String'];
};

export type ShareOrderRecordCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutShareOrderRecordInput;
  organizationId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user: UserCreateNestedOneWithoutShareOrderRecordInput;
};

export type ShareOrderRecordCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  organizationId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ShareOrderRecordCreateManyUserInputEnvelope = {
  data: Array<ShareOrderRecordCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ShareOrderRecordCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ShareOrderRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ShareOrderRecordCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ShareOrderRecordCreateWithoutUserInput>>;
  createMany?: InputMaybe<ShareOrderRecordCreateManyUserInputEnvelope>;
};

export type ShareOrderRecordCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<ShareOrderRecordWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShareOrderRecordCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<ShareOrderRecordCreateWithoutOrderInput>;
};

export type ShareOrderRecordCreateOrConnectWithoutOrderInput = {
  create: ShareOrderRecordCreateWithoutOrderInput;
  where: ShareOrderRecordWhereUniqueInput;
};

export type ShareOrderRecordCreateOrConnectWithoutUserInput = {
  create: ShareOrderRecordCreateWithoutUserInput;
  where: ShareOrderRecordWhereUniqueInput;
};

export type ShareOrderRecordCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user: UserCreateNestedOneWithoutShareOrderRecordInput;
};

export type ShareOrderRecordCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutShareOrderRecordInput;
  organizationId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type ShareOrderRecordListRelationFilter = {
  every?: InputMaybe<ShareOrderRecordWhereInput>;
  none?: InputMaybe<ShareOrderRecordWhereInput>;
  some?: InputMaybe<ShareOrderRecordWhereInput>;
};

export type ShareOrderRecordNullableRelationFilter = {
  is?: InputMaybe<ShareOrderRecordWhereInput>;
  isNot?: InputMaybe<ShareOrderRecordWhereInput>;
};

export type ShareOrderRecordOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ShareOrderRecordOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  organizationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum ShareOrderRecordScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  OrderId = 'orderId',
  OrganizationId = 'organizationId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ShareOrderRecordScalarWhereInput = {
  AND?: InputMaybe<Array<ShareOrderRecordScalarWhereInput>>;
  NOT?: InputMaybe<Array<ShareOrderRecordScalarWhereInput>>;
  OR?: InputMaybe<Array<ShareOrderRecordScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ShareOrderRecordUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutShareOrderRecordNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutShareOrderRecordNestedInput>;
};

export type ShareOrderRecordUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ShareOrderRecordUpdateManyWithWhereWithoutUserInput = {
  data: ShareOrderRecordUpdateManyMutationInput;
  where: ShareOrderRecordScalarWhereInput;
};

export type ShareOrderRecordUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ShareOrderRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ShareOrderRecordCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ShareOrderRecordCreateWithoutUserInput>>;
  createMany?: InputMaybe<ShareOrderRecordCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ShareOrderRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ShareOrderRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ShareOrderRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<ShareOrderRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<ShareOrderRecordUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ShareOrderRecordUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ShareOrderRecordUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ShareOrderRecordUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<ShareOrderRecordWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShareOrderRecordCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<ShareOrderRecordCreateWithoutOrderInput>;
  delete?: InputMaybe<ShareOrderRecordWhereInput>;
  disconnect?: InputMaybe<ShareOrderRecordWhereInput>;
  update?: InputMaybe<ShareOrderRecordUpdateToOneWithWhereWithoutOrderInput>;
  upsert?: InputMaybe<ShareOrderRecordUpsertWithoutOrderInput>;
};

export type ShareOrderRecordUpdateToOneWithWhereWithoutOrderInput = {
  data: ShareOrderRecordUpdateWithoutOrderInput;
  where?: InputMaybe<ShareOrderRecordWhereInput>;
};

export type ShareOrderRecordUpdateWithWhereUniqueWithoutUserInput = {
  data: ShareOrderRecordUpdateWithoutUserInput;
  where: ShareOrderRecordWhereUniqueInput;
};

export type ShareOrderRecordUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutShareOrderRecordNestedInput>;
};

export type ShareOrderRecordUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutShareOrderRecordNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ShareOrderRecordUpsertWithWhereUniqueWithoutUserInput = {
  create: ShareOrderRecordCreateWithoutUserInput;
  update: ShareOrderRecordUpdateWithoutUserInput;
  where: ShareOrderRecordWhereUniqueInput;
};

export type ShareOrderRecordUpsertWithoutOrderInput = {
  create: ShareOrderRecordCreateWithoutOrderInput;
  update: ShareOrderRecordUpdateWithoutOrderInput;
  where?: InputMaybe<ShareOrderRecordWhereInput>;
};

export type ShareOrderRecordWhereInput = {
  AND?: InputMaybe<Array<ShareOrderRecordWhereInput>>;
  NOT?: InputMaybe<Array<ShareOrderRecordWhereInput>>;
  OR?: InputMaybe<Array<ShareOrderRecordWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ShareOrderRecordWhereUniqueInput = {
  AND?: InputMaybe<Array<ShareOrderRecordWhereInput>>;
  NOT?: InputMaybe<Array<ShareOrderRecordWhereInput>>;
  OR?: InputMaybe<Array<ShareOrderRecordWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SignedUrlResponseV2 = {
  __typename?: 'SignedURLResponseV2';
  url: Scalars['String'];
};

export type SimpleResponse = {
  __typename?: 'SimpleResponse';
  status: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export enum SourceTypes {
  App = 'APP',
  Product = 'PRODUCT',
  Url = 'URL'
}

export type StateIdentificationCard = {
  __typename?: 'StateIdentificationCard';
  _count?: Maybe<StateIdentificationCardCount>;
  address?: Maybe<Address>;
  applicantData?: Maybe<ApplicantData>;
  applicantDataId?: Maybe<Scalars['String']>;
  backImage?: Maybe<File>;
  createdAt: Scalars['DateTimeISO'];
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: Maybe<File>;
  frontImage?: Maybe<File>;
  gender: Scalars['String'];
  id: Scalars['String'];
  idVerificationResult: Array<IdVerificationResult>;
  issueDate: Scalars['String'];
  manualIdEntry?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Name>;
  number: Scalars['String'];
  selfieImage?: Maybe<File>;
  updatedAt: Scalars['DateTimeISO'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type StateIdentificationCardAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};


export type StateIdentificationCardApplicantDataArgs = {
  where?: InputMaybe<ApplicantDataWhereInput>;
};


export type StateIdentificationCardBackImageArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type StateIdentificationCardExtractedPhotoArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type StateIdentificationCardFrontImageArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type StateIdentificationCardIdVerificationResultArgs = {
  cursor?: InputMaybe<IdVerificationResultWhereUniqueInput>;
  distinct?: InputMaybe<Array<IdVerificationResultScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IdVerificationResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IdVerificationResultWhereInput>;
};


export type StateIdentificationCardNameArgs = {
  where?: InputMaybe<NameWhereInput>;
};


export type StateIdentificationCardSelfieImageArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type StateIdentificationCardUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type StateIdentificationCardCount = {
  __typename?: 'StateIdentificationCardCount';
  idVerificationResult: Scalars['Int'];
};


export type StateIdentificationCardCountIdVerificationResultArgs = {
  where?: InputMaybe<IdVerificationResultWhereInput>;
};

export type StateIdentificationCardCreateInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutStateIdentificationCardInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutStateIdentificationCardInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardExtractInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutStateIdentificationCardInput>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<NameCreateNestedOneWithoutStateIdentificationCardInput>;
  number: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutStateIdentificationCardsInput>;
};

export type StateIdentificationCardCreateManyUserInput = {
  applicantDataId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  number: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type StateIdentificationCardCreateManyUserInputEnvelope = {
  data: Array<StateIdentificationCardCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StateIdentificationCardCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<StateIdentificationCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StateIdentificationCardCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<StateIdentificationCardCreateWithoutUserInput>>;
  createMany?: InputMaybe<StateIdentificationCardCreateManyUserInputEnvelope>;
};

export type StateIdentificationCardCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutAddressInput>;
};

export type StateIdentificationCardCreateNestedOneWithoutApplicantDataInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutApplicantDataInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutApplicantDataInput>;
};

export type StateIdentificationCardCreateNestedOneWithoutBackImageInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutBackImageInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutBackImageInput>;
};

export type StateIdentificationCardCreateNestedOneWithoutExtractedPhotoInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutExtractedPhotoInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutExtractedPhotoInput>;
};

export type StateIdentificationCardCreateNestedOneWithoutFrontImageInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutFrontImageInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutFrontImageInput>;
};

export type StateIdentificationCardCreateNestedOneWithoutIdVerificationResultInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutIdVerificationResultInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutIdVerificationResultInput>;
};

export type StateIdentificationCardCreateNestedOneWithoutNameInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutNameInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutNameInput>;
};

export type StateIdentificationCardCreateNestedOneWithoutSelfieImageInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutSelfieImageInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutSelfieImageInput>;
};

export type StateIdentificationCardCreateOrConnectWithoutAddressInput = {
  create: StateIdentificationCardCreateWithoutAddressInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardCreateOrConnectWithoutApplicantDataInput = {
  create: StateIdentificationCardCreateWithoutApplicantDataInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardCreateOrConnectWithoutBackImageInput = {
  create: StateIdentificationCardCreateWithoutBackImageInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardCreateOrConnectWithoutExtractedPhotoInput = {
  create: StateIdentificationCardCreateWithoutExtractedPhotoInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardCreateOrConnectWithoutFrontImageInput = {
  create: StateIdentificationCardCreateWithoutFrontImageInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardCreateOrConnectWithoutIdVerificationResultInput = {
  create: StateIdentificationCardCreateWithoutIdVerificationResultInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardCreateOrConnectWithoutNameInput = {
  create: StateIdentificationCardCreateWithoutNameInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardCreateOrConnectWithoutSelfieImageInput = {
  create: StateIdentificationCardCreateWithoutSelfieImageInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardCreateOrConnectWithoutUserInput = {
  create: StateIdentificationCardCreateWithoutUserInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardCreateWithoutAddressInput = {
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutStateIdentificationCardInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardExtractInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutStateIdentificationCardInput>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<NameCreateNestedOneWithoutStateIdentificationCardInput>;
  number: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutStateIdentificationCardsInput>;
};

export type StateIdentificationCardCreateWithoutApplicantDataInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutStateIdentificationCardInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardExtractInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutStateIdentificationCardInput>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<NameCreateNestedOneWithoutStateIdentificationCardInput>;
  number: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutStateIdentificationCardsInput>;
};

export type StateIdentificationCardCreateWithoutBackImageInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutStateIdentificationCardInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutStateIdentificationCardInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardExtractInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutStateIdentificationCardInput>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<NameCreateNestedOneWithoutStateIdentificationCardInput>;
  number: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutStateIdentificationCardsInput>;
};

export type StateIdentificationCardCreateWithoutExtractedPhotoInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutStateIdentificationCardInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutStateIdentificationCardInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  frontImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutStateIdentificationCardInput>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<NameCreateNestedOneWithoutStateIdentificationCardInput>;
  number: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutStateIdentificationCardsInput>;
};

export type StateIdentificationCardCreateWithoutFrontImageInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutStateIdentificationCardInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutStateIdentificationCardInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardExtractInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutStateIdentificationCardInput>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<NameCreateNestedOneWithoutStateIdentificationCardInput>;
  number: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutStateIdentificationCardsInput>;
};

export type StateIdentificationCardCreateWithoutIdVerificationResultInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutStateIdentificationCardInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutStateIdentificationCardInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardExtractInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<NameCreateNestedOneWithoutStateIdentificationCardInput>;
  number: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutStateIdentificationCardsInput>;
};

export type StateIdentificationCardCreateWithoutNameInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutStateIdentificationCardInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutStateIdentificationCardInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardExtractInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutStateIdentificationCardInput>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  number: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutStateIdentificationCardsInput>;
};

export type StateIdentificationCardCreateWithoutSelfieImageInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutStateIdentificationCardInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutStateIdentificationCardInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardExtractInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutStateIdentificationCardInput>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<NameCreateNestedOneWithoutStateIdentificationCardInput>;
  number: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutStateIdentificationCardsInput>;
};

export type StateIdentificationCardCreateWithoutUserInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutStateIdentificationCardInput>;
  applicantData?: InputMaybe<ApplicantDataCreateNestedOneWithoutStateIdentificationCardInput>;
  backImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardBackInput>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth: Scalars['String'];
  expiryDate: Scalars['String'];
  extractedPhoto?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardExtractInput>;
  frontImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardFrontInput>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultCreateNestedManyWithoutStateIdentificationCardInput>;
  issueDate: Scalars['String'];
  manualIdEntry?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<NameCreateNestedOneWithoutStateIdentificationCardInput>;
  number: Scalars['String'];
  selfieImage?: InputMaybe<FileCreateNestedOneWithoutStateIdentificationCardSelfieInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type StateIdentificationCardListRelationFilter = {
  every?: InputMaybe<StateIdentificationCardWhereInput>;
  none?: InputMaybe<StateIdentificationCardWhereInput>;
  some?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardNullableRelationFilter = {
  is?: InputMaybe<StateIdentificationCardWhereInput>;
  isNot?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StateIdentificationCardOrderByWithRelationInput = {
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  applicantData?: InputMaybe<ApplicantDataOrderByWithRelationInput>;
  applicantDataId?: InputMaybe<SortOrderInput>;
  backImage?: InputMaybe<FileOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  expiryDate?: InputMaybe<SortOrder>;
  extractedPhoto?: InputMaybe<FileOrderByWithRelationInput>;
  frontImage?: InputMaybe<FileOrderByWithRelationInput>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  idVerificationResult?: InputMaybe<IdVerificationResultOrderByRelationAggregateInput>;
  issueDate?: InputMaybe<SortOrder>;
  manualIdEntry?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<NameOrderByWithRelationInput>;
  number?: InputMaybe<SortOrder>;
  selfieImage?: InputMaybe<FileOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum StateIdentificationCardScalarFieldEnum {
  ApplicantDataId = 'applicantDataId',
  CreatedAt = 'createdAt',
  DateOfBirth = 'dateOfBirth',
  ExpiryDate = 'expiryDate',
  Gender = 'gender',
  Id = 'id',
  IssueDate = 'issueDate',
  ManualIdEntry = 'manualIdEntry',
  Number = 'number',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type StateIdentificationCardScalarWhereInput = {
  AND?: InputMaybe<Array<StateIdentificationCardScalarWhereInput>>;
  NOT?: InputMaybe<Array<StateIdentificationCardScalarWhereInput>>;
  OR?: InputMaybe<Array<StateIdentificationCardScalarWhereInput>>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringFilter>;
  expiryDate?: InputMaybe<StringFilter>;
  gender?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  issueDate?: InputMaybe<StringFilter>;
  manualIdEntry?: InputMaybe<BoolNullableFilter>;
  number?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type StateIdentificationCardUpdateInput = {
  address?: InputMaybe<AddressUpdateOneWithoutStateIdentificationCardNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutStateIdentificationCardNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardExtractNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutStateIdentificationCardNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutStateIdentificationCardNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutStateIdentificationCardsNestedInput>;
};

export type StateIdentificationCardUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StateIdentificationCardUpdateManyWithWhereWithoutUserInput = {
  data: StateIdentificationCardUpdateManyMutationInput;
  where: StateIdentificationCardScalarWhereInput;
};

export type StateIdentificationCardUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<StateIdentificationCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StateIdentificationCardCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<StateIdentificationCardCreateWithoutUserInput>>;
  createMany?: InputMaybe<StateIdentificationCardCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<StateIdentificationCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StateIdentificationCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StateIdentificationCardWhereUniqueInput>>;
  set?: InputMaybe<Array<StateIdentificationCardWhereUniqueInput>>;
  update?: InputMaybe<Array<StateIdentificationCardUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<StateIdentificationCardUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<StateIdentificationCardUpsertWithWhereUniqueWithoutUserInput>>;
};

export type StateIdentificationCardUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutAddressInput>;
  delete?: InputMaybe<StateIdentificationCardWhereInput>;
  disconnect?: InputMaybe<StateIdentificationCardWhereInput>;
  update?: InputMaybe<StateIdentificationCardUpdateToOneWithWhereWithoutAddressInput>;
  upsert?: InputMaybe<StateIdentificationCardUpsertWithoutAddressInput>;
};

export type StateIdentificationCardUpdateOneWithoutApplicantDataNestedInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutApplicantDataInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutApplicantDataInput>;
  delete?: InputMaybe<StateIdentificationCardWhereInput>;
  disconnect?: InputMaybe<StateIdentificationCardWhereInput>;
  update?: InputMaybe<StateIdentificationCardUpdateToOneWithWhereWithoutApplicantDataInput>;
  upsert?: InputMaybe<StateIdentificationCardUpsertWithoutApplicantDataInput>;
};

export type StateIdentificationCardUpdateOneWithoutBackImageNestedInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutBackImageInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutBackImageInput>;
  delete?: InputMaybe<StateIdentificationCardWhereInput>;
  disconnect?: InputMaybe<StateIdentificationCardWhereInput>;
  update?: InputMaybe<StateIdentificationCardUpdateToOneWithWhereWithoutBackImageInput>;
  upsert?: InputMaybe<StateIdentificationCardUpsertWithoutBackImageInput>;
};

export type StateIdentificationCardUpdateOneWithoutExtractedPhotoNestedInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutExtractedPhotoInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutExtractedPhotoInput>;
  delete?: InputMaybe<StateIdentificationCardWhereInput>;
  disconnect?: InputMaybe<StateIdentificationCardWhereInput>;
  update?: InputMaybe<StateIdentificationCardUpdateToOneWithWhereWithoutExtractedPhotoInput>;
  upsert?: InputMaybe<StateIdentificationCardUpsertWithoutExtractedPhotoInput>;
};

export type StateIdentificationCardUpdateOneWithoutFrontImageNestedInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutFrontImageInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutFrontImageInput>;
  delete?: InputMaybe<StateIdentificationCardWhereInput>;
  disconnect?: InputMaybe<StateIdentificationCardWhereInput>;
  update?: InputMaybe<StateIdentificationCardUpdateToOneWithWhereWithoutFrontImageInput>;
  upsert?: InputMaybe<StateIdentificationCardUpsertWithoutFrontImageInput>;
};

export type StateIdentificationCardUpdateOneWithoutIdVerificationResultNestedInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutIdVerificationResultInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutIdVerificationResultInput>;
  delete?: InputMaybe<StateIdentificationCardWhereInput>;
  disconnect?: InputMaybe<StateIdentificationCardWhereInput>;
  update?: InputMaybe<StateIdentificationCardUpdateToOneWithWhereWithoutIdVerificationResultInput>;
  upsert?: InputMaybe<StateIdentificationCardUpsertWithoutIdVerificationResultInput>;
};

export type StateIdentificationCardUpdateOneWithoutNameNestedInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutNameInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutNameInput>;
  delete?: InputMaybe<StateIdentificationCardWhereInput>;
  disconnect?: InputMaybe<StateIdentificationCardWhereInput>;
  update?: InputMaybe<StateIdentificationCardUpdateToOneWithWhereWithoutNameInput>;
  upsert?: InputMaybe<StateIdentificationCardUpsertWithoutNameInput>;
};

export type StateIdentificationCardUpdateOneWithoutSelfieImageNestedInput = {
  connect?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StateIdentificationCardCreateOrConnectWithoutSelfieImageInput>;
  create?: InputMaybe<StateIdentificationCardCreateWithoutSelfieImageInput>;
  delete?: InputMaybe<StateIdentificationCardWhereInput>;
  disconnect?: InputMaybe<StateIdentificationCardWhereInput>;
  update?: InputMaybe<StateIdentificationCardUpdateToOneWithWhereWithoutSelfieImageInput>;
  upsert?: InputMaybe<StateIdentificationCardUpsertWithoutSelfieImageInput>;
};

export type StateIdentificationCardUpdateToOneWithWhereWithoutAddressInput = {
  data: StateIdentificationCardUpdateWithoutAddressInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpdateToOneWithWhereWithoutApplicantDataInput = {
  data: StateIdentificationCardUpdateWithoutApplicantDataInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpdateToOneWithWhereWithoutBackImageInput = {
  data: StateIdentificationCardUpdateWithoutBackImageInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpdateToOneWithWhereWithoutExtractedPhotoInput = {
  data: StateIdentificationCardUpdateWithoutExtractedPhotoInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpdateToOneWithWhereWithoutFrontImageInput = {
  data: StateIdentificationCardUpdateWithoutFrontImageInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpdateToOneWithWhereWithoutIdVerificationResultInput = {
  data: StateIdentificationCardUpdateWithoutIdVerificationResultInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpdateToOneWithWhereWithoutNameInput = {
  data: StateIdentificationCardUpdateWithoutNameInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpdateToOneWithWhereWithoutSelfieImageInput = {
  data: StateIdentificationCardUpdateWithoutSelfieImageInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpdateWithWhereUniqueWithoutUserInput = {
  data: StateIdentificationCardUpdateWithoutUserInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardUpdateWithoutAddressInput = {
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutStateIdentificationCardNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardExtractNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutStateIdentificationCardNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutStateIdentificationCardNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutStateIdentificationCardsNestedInput>;
};

export type StateIdentificationCardUpdateWithoutApplicantDataInput = {
  address?: InputMaybe<AddressUpdateOneWithoutStateIdentificationCardNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardExtractNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutStateIdentificationCardNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutStateIdentificationCardNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutStateIdentificationCardsNestedInput>;
};

export type StateIdentificationCardUpdateWithoutBackImageInput = {
  address?: InputMaybe<AddressUpdateOneWithoutStateIdentificationCardNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutStateIdentificationCardNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardExtractNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutStateIdentificationCardNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutStateIdentificationCardNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutStateIdentificationCardsNestedInput>;
};

export type StateIdentificationCardUpdateWithoutExtractedPhotoInput = {
  address?: InputMaybe<AddressUpdateOneWithoutStateIdentificationCardNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutStateIdentificationCardNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutStateIdentificationCardNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutStateIdentificationCardNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutStateIdentificationCardsNestedInput>;
};

export type StateIdentificationCardUpdateWithoutFrontImageInput = {
  address?: InputMaybe<AddressUpdateOneWithoutStateIdentificationCardNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutStateIdentificationCardNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardExtractNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutStateIdentificationCardNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutStateIdentificationCardNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutStateIdentificationCardsNestedInput>;
};

export type StateIdentificationCardUpdateWithoutIdVerificationResultInput = {
  address?: InputMaybe<AddressUpdateOneWithoutStateIdentificationCardNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutStateIdentificationCardNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardExtractNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutStateIdentificationCardNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutStateIdentificationCardsNestedInput>;
};

export type StateIdentificationCardUpdateWithoutNameInput = {
  address?: InputMaybe<AddressUpdateOneWithoutStateIdentificationCardNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutStateIdentificationCardNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardExtractNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutStateIdentificationCardNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutStateIdentificationCardsNestedInput>;
};

export type StateIdentificationCardUpdateWithoutSelfieImageInput = {
  address?: InputMaybe<AddressUpdateOneWithoutStateIdentificationCardNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutStateIdentificationCardNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardExtractNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutStateIdentificationCardNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutStateIdentificationCardNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutStateIdentificationCardsNestedInput>;
};

export type StateIdentificationCardUpdateWithoutUserInput = {
  address?: InputMaybe<AddressUpdateOneWithoutStateIdentificationCardNestedInput>;
  applicantData?: InputMaybe<ApplicantDataUpdateOneWithoutStateIdentificationCardNestedInput>;
  backImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardBackNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<StringFieldUpdateOperationsInput>;
  expiryDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  extractedPhoto?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardExtractNestedInput>;
  frontImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardFrontNestedInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idVerificationResult?: InputMaybe<IdVerificationResultUpdateManyWithoutStateIdentificationCardNestedInput>;
  issueDate?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualIdEntry?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NameUpdateOneWithoutStateIdentificationCardNestedInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfieImage?: InputMaybe<FileUpdateOneWithoutStateIdentificationCardSelfieNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StateIdentificationCardUpsertWithWhereUniqueWithoutUserInput = {
  create: StateIdentificationCardCreateWithoutUserInput;
  update: StateIdentificationCardUpdateWithoutUserInput;
  where: StateIdentificationCardWhereUniqueInput;
};

export type StateIdentificationCardUpsertWithoutAddressInput = {
  create: StateIdentificationCardCreateWithoutAddressInput;
  update: StateIdentificationCardUpdateWithoutAddressInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpsertWithoutApplicantDataInput = {
  create: StateIdentificationCardCreateWithoutApplicantDataInput;
  update: StateIdentificationCardUpdateWithoutApplicantDataInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpsertWithoutBackImageInput = {
  create: StateIdentificationCardCreateWithoutBackImageInput;
  update: StateIdentificationCardUpdateWithoutBackImageInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpsertWithoutExtractedPhotoInput = {
  create: StateIdentificationCardCreateWithoutExtractedPhotoInput;
  update: StateIdentificationCardUpdateWithoutExtractedPhotoInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpsertWithoutFrontImageInput = {
  create: StateIdentificationCardCreateWithoutFrontImageInput;
  update: StateIdentificationCardUpdateWithoutFrontImageInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpsertWithoutIdVerificationResultInput = {
  create: StateIdentificationCardCreateWithoutIdVerificationResultInput;
  update: StateIdentificationCardUpdateWithoutIdVerificationResultInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpsertWithoutNameInput = {
  create: StateIdentificationCardCreateWithoutNameInput;
  update: StateIdentificationCardUpdateWithoutNameInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardUpsertWithoutSelfieImageInput = {
  create: StateIdentificationCardCreateWithoutSelfieImageInput;
  update: StateIdentificationCardUpdateWithoutSelfieImageInput;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};

export type StateIdentificationCardWhereInput = {
  AND?: InputMaybe<Array<StateIdentificationCardWhereInput>>;
  NOT?: InputMaybe<Array<StateIdentificationCardWhereInput>>;
  OR?: InputMaybe<Array<StateIdentificationCardWhereInput>>;
  address?: InputMaybe<AddressNullableRelationFilter>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantDataId?: InputMaybe<StringNullableFilter>;
  backImage?: InputMaybe<FileNullableRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringFilter>;
  expiryDate?: InputMaybe<StringFilter>;
  extractedPhoto?: InputMaybe<FileNullableRelationFilter>;
  frontImage?: InputMaybe<FileNullableRelationFilter>;
  gender?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  idVerificationResult?: InputMaybe<IdVerificationResultListRelationFilter>;
  issueDate?: InputMaybe<StringFilter>;
  manualIdEntry?: InputMaybe<BoolNullableFilter>;
  name?: InputMaybe<NameNullableRelationFilter>;
  number?: InputMaybe<StringFilter>;
  selfieImage?: InputMaybe<FileNullableRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type StateIdentificationCardWhereUniqueInput = {
  AND?: InputMaybe<Array<StateIdentificationCardWhereInput>>;
  NOT?: InputMaybe<Array<StateIdentificationCardWhereInput>>;
  OR?: InputMaybe<Array<StateIdentificationCardWhereInput>>;
  address?: InputMaybe<AddressNullableRelationFilter>;
  applicantData?: InputMaybe<ApplicantDataNullableRelationFilter>;
  applicantDataId?: InputMaybe<Scalars['String']>;
  backImage?: InputMaybe<FileNullableRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringFilter>;
  expiryDate?: InputMaybe<StringFilter>;
  extractedPhoto?: InputMaybe<FileNullableRelationFilter>;
  frontImage?: InputMaybe<FileNullableRelationFilter>;
  gender?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']>;
  idVerificationResult?: InputMaybe<IdVerificationResultListRelationFilter>;
  issueDate?: InputMaybe<StringFilter>;
  manualIdEntry?: InputMaybe<BoolNullableFilter>;
  name?: InputMaybe<NameNullableRelationFilter>;
  number?: InputMaybe<StringFilter>;
  selfieImage?: InputMaybe<FileNullableRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type StatusResponse = {
  __typename?: 'StatusResponse';
  count?: Maybe<Scalars['Int']>;
  status: Scalars['Boolean'];
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  deletedOrder: Order;
  newOrder: Order;
  updatedOrder: Order;
};

export type TempFileSignedUrlResponse = {
  __typename?: 'TempFileSignedUrlResponse';
  readUrl: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type UpdateVoucherEmailRes = {
  __typename?: 'UpdateVoucherEmailRes';
  id: Scalars['String'];
  qrCode: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  ResultRevision: Array<ResultRevision>;
  _count?: Maybe<UserCount>;
  addresses: Array<Address>;
  apiKeys: Array<ApiKey>;
  backId?: Maybe<File>;
  beamInput: Array<BeamInput>;
  consumerReportingAgencyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  createdOrders: Array<Order>;
  createdOrganizations: Array<Organization>;
  credentials: Array<Credential>;
  disclosureAcceptances: Array<DisclosureAcceptance>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  frontId?: Maybe<File>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  identifiers: Array<Identifier>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  legacyCredentials: Array<LegacyCredential>;
  legacySSN?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  myOrders: Array<Order>;
  names: Array<Name>;
  noMiddleName: Scalars['Boolean'];
  onboardingStep?: Maybe<Scalars['String']>;
  orderNotes: Array<OrderNote>;
  organizationIds: Array<Scalars['String']>;
  organizations: Array<Organization>;
  passes: Array<Pass>;
  passports: Array<Passport>;
  phone?: Maybe<Scalars['String']>;
  pushTokens: Array<Scalars['String']>;
  redeemedInvites: Array<Invite>;
  roleAssignment: Array<RoleAssignment>;
  selectedOrganizationId?: Maybe<Scalars['String']>;
  selfie?: Maybe<File>;
  sentInvites: Array<Invite>;
  shareOrderRecord: Array<ShareOrderRecord>;
  ssn?: Maybe<HighlySensitiveIdentifier>;
  ssnLastFour: Scalars['String'];
  stateIdentificationCards: Array<StateIdentificationCard>;
  status: UserStatusEnum;
  updatedAt: Scalars['DateTimeISO'];
  vIDVouchers: Array<VidVoucher>;
};


export type UserResultRevisionArgs = {
  cursor?: InputMaybe<ResultRevisionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ResultRevisionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ResultRevisionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResultRevisionWhereInput>;
};


export type UserAddressesArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<AddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type UserApiKeysArgs = {
  cursor?: InputMaybe<ApiKeyWhereUniqueInput>;
  distinct?: InputMaybe<Array<ApiKeyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ApiKeyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ApiKeyWhereInput>;
};


export type UserBackIdArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type UserBeamInputArgs = {
  cursor?: InputMaybe<BeamInputWhereUniqueInput>;
  distinct?: InputMaybe<Array<BeamInputScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BeamInputOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BeamInputWhereInput>;
};


export type UserCreatedOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type UserCreatedOrganizationsArgs = {
  cursor?: InputMaybe<OrganizationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrganizationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type UserCredentialsArgs = {
  cursor?: InputMaybe<CredentialWhereUniqueInput>;
  distinct?: InputMaybe<Array<CredentialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CredentialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CredentialWhereInput>;
};


export type UserDisclosureAcceptancesArgs = {
  cursor?: InputMaybe<DisclosureAcceptanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<DisclosureAcceptanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DisclosureAcceptanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DisclosureAcceptanceWhereInput>;
};


export type UserFrontIdArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type UserIdentifiersArgs = {
  cursor?: InputMaybe<IdentifierWhereUniqueInput>;
  distinct?: InputMaybe<Array<IdentifierScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IdentifierOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IdentifierWhereInput>;
};


export type UserLegacyCredentialsArgs = {
  cursor?: InputMaybe<LegacyCredentialWhereUniqueInput>;
  distinct?: InputMaybe<Array<LegacyCredentialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LegacyCredentialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LegacyCredentialWhereInput>;
};


export type UserMyOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type UserNamesArgs = {
  cursor?: InputMaybe<NameWhereUniqueInput>;
  distinct?: InputMaybe<Array<NameScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NameOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NameWhereInput>;
};


export type UserOrderNotesArgs = {
  cursor?: InputMaybe<OrderNoteWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderNoteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderNoteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderNoteWhereInput>;
};


export type UserOrganizationsArgs = {
  cursor?: InputMaybe<OrganizationWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrganizationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrganizationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganizationWhereInput>;
};


export type UserPassesArgs = {
  cursor?: InputMaybe<PassWhereUniqueInput>;
  distinct?: InputMaybe<Array<PassScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PassOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PassWhereInput>;
};


export type UserPassportsArgs = {
  cursor?: InputMaybe<PassportWhereUniqueInput>;
  distinct?: InputMaybe<Array<PassportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PassportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PassportWhereInput>;
};


export type UserRedeemedInvitesArgs = {
  cursor?: InputMaybe<InviteWhereUniqueInput>;
  distinct?: InputMaybe<Array<InviteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InviteWhereInput>;
};


export type UserRoleAssignmentArgs = {
  cursor?: InputMaybe<RoleAssignmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleAssignmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleAssignmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RoleAssignmentWhereInput>;
};


export type UserSelfieArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type UserSentInvitesArgs = {
  cursor?: InputMaybe<InviteWhereUniqueInput>;
  distinct?: InputMaybe<Array<InviteScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InviteOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InviteWhereInput>;
};


export type UserShareOrderRecordArgs = {
  cursor?: InputMaybe<ShareOrderRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<ShareOrderRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ShareOrderRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ShareOrderRecordWhereInput>;
};


export type UserSsnArgs = {
  where?: InputMaybe<HighlySensitiveIdentifierWhereInput>;
};


export type UserStateIdentificationCardsArgs = {
  cursor?: InputMaybe<StateIdentificationCardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StateIdentificationCardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StateIdentificationCardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};


export type UserVIdVouchersArgs = {
  cursor?: InputMaybe<VidVoucherWhereUniqueInput>;
  distinct?: InputMaybe<Array<VidVoucherScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VidVoucherOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VidVoucherWhereInput>;
};

export type UserCount = {
  __typename?: 'UserCount';
  ResultRevision: Scalars['Int'];
  addresses: Scalars['Int'];
  apiKeys: Scalars['Int'];
  beamInput: Scalars['Int'];
  createdOrders: Scalars['Int'];
  createdOrganizations: Scalars['Int'];
  credentials: Scalars['Int'];
  disclosureAcceptances: Scalars['Int'];
  identifiers: Scalars['Int'];
  legacyCredentials: Scalars['Int'];
  myOrders: Scalars['Int'];
  names: Scalars['Int'];
  orderNotes: Scalars['Int'];
  organizations: Scalars['Int'];
  passes: Scalars['Int'];
  passports: Scalars['Int'];
  redeemedInvites: Scalars['Int'];
  roleAssignment: Scalars['Int'];
  sentInvites: Scalars['Int'];
  shareOrderRecord: Scalars['Int'];
  stateIdentificationCards: Scalars['Int'];
  vIDVouchers: Scalars['Int'];
};


export type UserCountResultRevisionArgs = {
  where?: InputMaybe<ResultRevisionWhereInput>;
};


export type UserCountAddressesArgs = {
  where?: InputMaybe<AddressWhereInput>;
};


export type UserCountApiKeysArgs = {
  where?: InputMaybe<ApiKeyWhereInput>;
};


export type UserCountBeamInputArgs = {
  where?: InputMaybe<BeamInputWhereInput>;
};


export type UserCountCreatedOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type UserCountCreatedOrganizationsArgs = {
  where?: InputMaybe<OrganizationWhereInput>;
};


export type UserCountCredentialsArgs = {
  where?: InputMaybe<CredentialWhereInput>;
};


export type UserCountDisclosureAcceptancesArgs = {
  where?: InputMaybe<DisclosureAcceptanceWhereInput>;
};


export type UserCountIdentifiersArgs = {
  where?: InputMaybe<IdentifierWhereInput>;
};


export type UserCountLegacyCredentialsArgs = {
  where?: InputMaybe<LegacyCredentialWhereInput>;
};


export type UserCountMyOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type UserCountNamesArgs = {
  where?: InputMaybe<NameWhereInput>;
};


export type UserCountOrderNotesArgs = {
  where?: InputMaybe<OrderNoteWhereInput>;
};


export type UserCountOrganizationsArgs = {
  where?: InputMaybe<OrganizationWhereInput>;
};


export type UserCountPassesArgs = {
  where?: InputMaybe<PassWhereInput>;
};


export type UserCountPassportsArgs = {
  where?: InputMaybe<PassportWhereInput>;
};


export type UserCountRedeemedInvitesArgs = {
  where?: InputMaybe<InviteWhereInput>;
};


export type UserCountRoleAssignmentArgs = {
  where?: InputMaybe<RoleAssignmentWhereInput>;
};


export type UserCountSentInvitesArgs = {
  where?: InputMaybe<InviteWhereInput>;
};


export type UserCountShareOrderRecordArgs = {
  where?: InputMaybe<ShareOrderRecordWhereInput>;
};


export type UserCountStateIdentificationCardsArgs = {
  where?: InputMaybe<StateIdentificationCardWhereInput>;
};


export type UserCountVIdVouchersArgs = {
  where?: InputMaybe<VidVoucherWhereInput>;
};

export type UserCreateNestedManyWithoutOrganizationsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOrganizationsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOrganizationsInput>>;
};

export type UserCreateNestedOneWithoutAddressesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<UserCreateWithoutAddressesInput>;
};

export type UserCreateNestedOneWithoutBackIdInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBackIdInput>;
  create?: InputMaybe<UserCreateWithoutBackIdInput>;
};

export type UserCreateNestedOneWithoutBeamInputInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBeamInputInput>;
  create?: InputMaybe<UserCreateWithoutBeamInputInput>;
};

export type UserCreateNestedOneWithoutCreatedOrdersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedOrdersInput>;
  create?: InputMaybe<UserCreateWithoutCreatedOrdersInput>;
};

export type UserCreateNestedOneWithoutCreatedOrganizationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedOrganizationsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedOrganizationsInput>;
};

export type UserCreateNestedOneWithoutCredentialsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCredentialsInput>;
  create?: InputMaybe<UserCreateWithoutCredentialsInput>;
};

export type UserCreateNestedOneWithoutDisclosureAcceptancesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDisclosureAcceptancesInput>;
  create?: InputMaybe<UserCreateWithoutDisclosureAcceptancesInput>;
};

export type UserCreateNestedOneWithoutFrontIdInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFrontIdInput>;
  create?: InputMaybe<UserCreateWithoutFrontIdInput>;
};

export type UserCreateNestedOneWithoutMyOrdersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMyOrdersInput>;
  create?: InputMaybe<UserCreateWithoutMyOrdersInput>;
};

export type UserCreateNestedOneWithoutNamesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNamesInput>;
  create?: InputMaybe<UserCreateWithoutNamesInput>;
};

export type UserCreateNestedOneWithoutOrderNotesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrderNotesInput>;
  create?: InputMaybe<UserCreateWithoutOrderNotesInput>;
};

export type UserCreateNestedOneWithoutPassesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPassesInput>;
  create?: InputMaybe<UserCreateWithoutPassesInput>;
};

export type UserCreateNestedOneWithoutPassportsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPassportsInput>;
  create?: InputMaybe<UserCreateWithoutPassportsInput>;
};

export type UserCreateNestedOneWithoutRedeemedInvitesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRedeemedInvitesInput>;
  create?: InputMaybe<UserCreateWithoutRedeemedInvitesInput>;
};

export type UserCreateNestedOneWithoutResultRevisionInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutResultRevisionInput>;
  create?: InputMaybe<UserCreateWithoutResultRevisionInput>;
};

export type UserCreateNestedOneWithoutRoleAssignmentInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRoleAssignmentInput>;
  create?: InputMaybe<UserCreateWithoutRoleAssignmentInput>;
};

export type UserCreateNestedOneWithoutSelfieInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSelfieInput>;
  create?: InputMaybe<UserCreateWithoutSelfieInput>;
};

export type UserCreateNestedOneWithoutSentInvitesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSentInvitesInput>;
  create?: InputMaybe<UserCreateWithoutSentInvitesInput>;
};

export type UserCreateNestedOneWithoutShareOrderRecordInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutShareOrderRecordInput>;
  create?: InputMaybe<UserCreateWithoutShareOrderRecordInput>;
};

export type UserCreateNestedOneWithoutSsnInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSsnInput>;
  create?: InputMaybe<UserCreateWithoutSsnInput>;
};

export type UserCreateNestedOneWithoutStateIdentificationCardsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutStateIdentificationCardsInput>;
  create?: InputMaybe<UserCreateWithoutStateIdentificationCardsInput>;
};

export type UserCreateNestedOneWithoutVidVouchersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutVidVouchersInput>;
  create?: InputMaybe<UserCreateWithoutVidVouchersInput>;
};

export type UserCreateOrConnectWithoutAddressesInput = {
  create: UserCreateWithoutAddressesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBackIdInput = {
  create: UserCreateWithoutBackIdInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBeamInputInput = {
  create: UserCreateWithoutBeamInputInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedOrdersInput = {
  create: UserCreateWithoutCreatedOrdersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedOrganizationsInput = {
  create: UserCreateWithoutCreatedOrganizationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCredentialsInput = {
  create: UserCreateWithoutCredentialsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDisclosureAcceptancesInput = {
  create: UserCreateWithoutDisclosureAcceptancesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFrontIdInput = {
  create: UserCreateWithoutFrontIdInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutMyOrdersInput = {
  create: UserCreateWithoutMyOrdersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNamesInput = {
  create: UserCreateWithoutNamesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOrderNotesInput = {
  create: UserCreateWithoutOrderNotesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOrganizationsInput = {
  create: UserCreateWithoutOrganizationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPassesInput = {
  create: UserCreateWithoutPassesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPassportsInput = {
  create: UserCreateWithoutPassportsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRedeemedInvitesInput = {
  create: UserCreateWithoutRedeemedInvitesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutResultRevisionInput = {
  create: UserCreateWithoutResultRevisionInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRoleAssignmentInput = {
  create: UserCreateWithoutRoleAssignmentInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSelfieInput = {
  create: UserCreateWithoutSelfieInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSentInvitesInput = {
  create: UserCreateWithoutSentInvitesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutShareOrderRecordInput = {
  create: UserCreateWithoutShareOrderRecordInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSsnInput = {
  create: UserCreateWithoutSsnInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutStateIdentificationCardsInput = {
  create: UserCreateWithoutStateIdentificationCardsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutVidVouchersInput = {
  create: UserCreateWithoutVidVouchersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAddressesInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutBackIdInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutBeamInputInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutCreatedOrdersInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutCreatedOrganizationsInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutCredentialsInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutDisclosureAcceptancesInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutFrontIdInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutMyOrdersInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutNamesInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutOrderNotesInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutOrganizationsInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutPassesInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutPassportsInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutRedeemedInvitesInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutResultRevisionInput = {
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutRoleAssignmentInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutSelfieInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutSentInvitesInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutShareOrderRecordInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutSsnInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutStateIdentificationCardsInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  vIDVouchers?: InputMaybe<VidVoucherCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutVidVouchersInput = {
  ResultRevision?: InputMaybe<ResultRevisionCreateNestedManyWithoutRevisedByIdInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  apiKeys?: InputMaybe<ApiKeyCreateNestedManyWithoutUserInput>;
  backId?: InputMaybe<FileCreateNestedOneWithoutBackIdUserInput>;
  beamInput?: InputMaybe<BeamInputCreateNestedManyWithoutUserInput>;
  consumerReportingAgencyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  createdOrders?: InputMaybe<OrderCreateNestedManyWithoutCreatedByInput>;
  createdOrganizations?: InputMaybe<OrganizationCreateNestedManyWithoutCreatedByInput>;
  credentials?: InputMaybe<CredentialCreateNestedManyWithoutUserInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceCreateNestedManyWithoutUserInput>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileCreateNestedOneWithoutFrontIdUserInput>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierCreateNestedManyWithoutUserInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  legacyCredentials?: InputMaybe<LegacyCredentialCreateNestedManyWithoutUserInput>;
  legacySSN?: InputMaybe<Scalars['String']>;
  myOrders?: InputMaybe<OrderCreateNestedManyWithoutUserInput>;
  names?: InputMaybe<NameCreateNestedManyWithoutUserInput>;
  onboardingStep?: InputMaybe<Scalars['String']>;
  orderNotes?: InputMaybe<OrderNoteCreateNestedManyWithoutCreatedByInput>;
  organizationIds?: InputMaybe<UserCreateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutMembersInput>;
  passes?: InputMaybe<PassCreateNestedManyWithoutUserInput>;
  passports?: InputMaybe<PassportCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars['String']>;
  pushTokens?: InputMaybe<UserCreatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteCreateNestedManyWithoutRedeemedByInput>;
  roleAssignment?: InputMaybe<RoleAssignmentCreateNestedManyWithoutUserInput>;
  selectedOrganizationId?: InputMaybe<Scalars['String']>;
  selfie?: InputMaybe<FileCreateNestedOneWithoutSelfieUserInput>;
  sentInvites?: InputMaybe<InviteCreateNestedManyWithoutSenderInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordCreateNestedManyWithoutUserInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierCreateNestedOneWithoutUserInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardCreateNestedManyWithoutUserInput>;
  status?: InputMaybe<UserStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type UserCreateorganizationIdsInput = {
  set: Array<Scalars['String']>;
};

export type UserCreatepushTokensInput = {
  set: Array<Scalars['String']>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserNullableRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  ResultRevision?: InputMaybe<ResultRevisionOrderByRelationAggregateInput>;
  addresses?: InputMaybe<AddressOrderByRelationAggregateInput>;
  apiKeys?: InputMaybe<ApiKeyOrderByRelationAggregateInput>;
  backId?: InputMaybe<FileOrderByWithRelationInput>;
  beamInput?: InputMaybe<BeamInputOrderByRelationAggregateInput>;
  consumerReportingAgencyId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdOrders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  createdOrganizations?: InputMaybe<OrganizationOrderByRelationAggregateInput>;
  credentials?: InputMaybe<CredentialOrderByRelationAggregateInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceOrderByRelationAggregateInput>;
  dob?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrderInput>;
  frontId?: InputMaybe<FileOrderByWithRelationInput>;
  gender?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  identifiers?: InputMaybe<IdentifierOrderByRelationAggregateInput>;
  jobTitle?: InputMaybe<SortOrderInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialOrderByRelationAggregateInput>;
  legacySSN?: InputMaybe<SortOrderInput>;
  myOrders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  names?: InputMaybe<NameOrderByRelationAggregateInput>;
  onboardingStep?: InputMaybe<SortOrderInput>;
  orderNotes?: InputMaybe<OrderNoteOrderByRelationAggregateInput>;
  organizationIds?: InputMaybe<SortOrder>;
  organizations?: InputMaybe<OrganizationOrderByRelationAggregateInput>;
  passes?: InputMaybe<PassOrderByRelationAggregateInput>;
  passports?: InputMaybe<PassportOrderByRelationAggregateInput>;
  phone?: InputMaybe<SortOrderInput>;
  pushTokens?: InputMaybe<SortOrder>;
  redeemedInvites?: InputMaybe<InviteOrderByRelationAggregateInput>;
  roleAssignment?: InputMaybe<RoleAssignmentOrderByRelationAggregateInput>;
  selectedOrganizationId?: InputMaybe<SortOrderInput>;
  selfie?: InputMaybe<FileOrderByWithRelationInput>;
  sentInvites?: InputMaybe<InviteOrderByRelationAggregateInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordOrderByRelationAggregateInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierOrderByWithRelationInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  vIDVouchers?: InputMaybe<VidVoucherOrderByRelationAggregateInput>;
};

export enum UserPrimaryContactEnum {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  ConsumerReportingAgencyId = 'consumerReportingAgencyId',
  CreatedAt = 'createdAt',
  Dob = 'dob',
  Email = 'email',
  Gender = 'gender',
  Id = 'id',
  JobTitle = 'jobTitle',
  LegacySsn = 'legacySSN',
  OnboardingStep = 'onboardingStep',
  OrganizationIds = 'organizationIds',
  Phone = 'phone',
  PushTokens = 'pushTokens',
  SelectedOrganizationId = 'selectedOrganizationId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  consumerReportingAgencyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dob?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  gender?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  jobTitle?: InputMaybe<StringNullableFilter>;
  legacySSN?: InputMaybe<StringNullableFilter>;
  onboardingStep?: InputMaybe<StringNullableFilter>;
  organizationIds?: InputMaybe<StringNullableListFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  pushTokens?: InputMaybe<StringNullableListFilter>;
  selectedOrganizationId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumUserStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum UserStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

export type UserUpdateInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateManyMutationInput = {
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutOrganizationsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutOrganizationsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOrganizationsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOrganizationsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutOrganizationsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutOrganizationsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutOrganizationsInput>>;
};

export type UserUpdateOneRequiredWithoutBeamInputNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBeamInputInput>;
  create?: InputMaybe<UserCreateWithoutBeamInputInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutBeamInputInput>;
  upsert?: InputMaybe<UserUpsertWithoutBeamInputInput>;
};

export type UserUpdateOneRequiredWithoutCredentialsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCredentialsInput>;
  create?: InputMaybe<UserCreateWithoutCredentialsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCredentialsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCredentialsInput>;
};

export type UserUpdateOneRequiredWithoutDisclosureAcceptancesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDisclosureAcceptancesInput>;
  create?: InputMaybe<UserCreateWithoutDisclosureAcceptancesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutDisclosureAcceptancesInput>;
  upsert?: InputMaybe<UserUpsertWithoutDisclosureAcceptancesInput>;
};

export type UserUpdateOneRequiredWithoutPassesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPassesInput>;
  create?: InputMaybe<UserCreateWithoutPassesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutPassesInput>;
  upsert?: InputMaybe<UserUpsertWithoutPassesInput>;
};

export type UserUpdateOneRequiredWithoutResultRevisionNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutResultRevisionInput>;
  create?: InputMaybe<UserCreateWithoutResultRevisionInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutResultRevisionInput>;
  upsert?: InputMaybe<UserUpsertWithoutResultRevisionInput>;
};

export type UserUpdateOneRequiredWithoutRoleAssignmentNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRoleAssignmentInput>;
  create?: InputMaybe<UserCreateWithoutRoleAssignmentInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutRoleAssignmentInput>;
  upsert?: InputMaybe<UserUpsertWithoutRoleAssignmentInput>;
};

export type UserUpdateOneRequiredWithoutSentInvitesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSentInvitesInput>;
  create?: InputMaybe<UserCreateWithoutSentInvitesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutSentInvitesInput>;
  upsert?: InputMaybe<UserUpsertWithoutSentInvitesInput>;
};

export type UserUpdateOneRequiredWithoutShareOrderRecordNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutShareOrderRecordInput>;
  create?: InputMaybe<UserCreateWithoutShareOrderRecordInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutShareOrderRecordInput>;
  upsert?: InputMaybe<UserUpsertWithoutShareOrderRecordInput>;
};

export type UserUpdateOneWithoutAddressesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<UserCreateWithoutAddressesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutAddressesInput>;
  upsert?: InputMaybe<UserUpsertWithoutAddressesInput>;
};

export type UserUpdateOneWithoutBackIdNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBackIdInput>;
  create?: InputMaybe<UserCreateWithoutBackIdInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutBackIdInput>;
  upsert?: InputMaybe<UserUpsertWithoutBackIdInput>;
};

export type UserUpdateOneWithoutCreatedOrdersNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedOrdersInput>;
  create?: InputMaybe<UserCreateWithoutCreatedOrdersInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCreatedOrdersInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreatedOrdersInput>;
};

export type UserUpdateOneWithoutCreatedOrganizationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedOrganizationsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedOrganizationsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCreatedOrganizationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreatedOrganizationsInput>;
};

export type UserUpdateOneWithoutFrontIdNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFrontIdInput>;
  create?: InputMaybe<UserCreateWithoutFrontIdInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutFrontIdInput>;
  upsert?: InputMaybe<UserUpsertWithoutFrontIdInput>;
};

export type UserUpdateOneWithoutMyOrdersNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMyOrdersInput>;
  create?: InputMaybe<UserCreateWithoutMyOrdersInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutMyOrdersInput>;
  upsert?: InputMaybe<UserUpsertWithoutMyOrdersInput>;
};

export type UserUpdateOneWithoutNamesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNamesInput>;
  create?: InputMaybe<UserCreateWithoutNamesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutNamesInput>;
  upsert?: InputMaybe<UserUpsertWithoutNamesInput>;
};

export type UserUpdateOneWithoutOrderNotesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrderNotesInput>;
  create?: InputMaybe<UserCreateWithoutOrderNotesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutOrderNotesInput>;
  upsert?: InputMaybe<UserUpsertWithoutOrderNotesInput>;
};

export type UserUpdateOneWithoutPassportsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPassportsInput>;
  create?: InputMaybe<UserCreateWithoutPassportsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutPassportsInput>;
  upsert?: InputMaybe<UserUpsertWithoutPassportsInput>;
};

export type UserUpdateOneWithoutRedeemedInvitesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRedeemedInvitesInput>;
  create?: InputMaybe<UserCreateWithoutRedeemedInvitesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutRedeemedInvitesInput>;
  upsert?: InputMaybe<UserUpsertWithoutRedeemedInvitesInput>;
};

export type UserUpdateOneWithoutSelfieNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSelfieInput>;
  create?: InputMaybe<UserCreateWithoutSelfieInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutSelfieInput>;
  upsert?: InputMaybe<UserUpsertWithoutSelfieInput>;
};

export type UserUpdateOneWithoutSsnNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSsnInput>;
  create?: InputMaybe<UserCreateWithoutSsnInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutSsnInput>;
  upsert?: InputMaybe<UserUpsertWithoutSsnInput>;
};

export type UserUpdateOneWithoutStateIdentificationCardsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutStateIdentificationCardsInput>;
  create?: InputMaybe<UserCreateWithoutStateIdentificationCardsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutStateIdentificationCardsInput>;
  upsert?: InputMaybe<UserUpsertWithoutStateIdentificationCardsInput>;
};

export type UserUpdateOneWithoutVidVouchersNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutVidVouchersInput>;
  create?: InputMaybe<UserCreateWithoutVidVouchersInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutVidVouchersInput>;
  upsert?: InputMaybe<UserUpsertWithoutVidVouchersInput>;
};

export type UserUpdateToOneWithWhereWithoutAddressesInput = {
  data: UserUpdateWithoutAddressesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutBackIdInput = {
  data: UserUpdateWithoutBackIdInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutBeamInputInput = {
  data: UserUpdateWithoutBeamInputInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutCreatedOrdersInput = {
  data: UserUpdateWithoutCreatedOrdersInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutCreatedOrganizationsInput = {
  data: UserUpdateWithoutCreatedOrganizationsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutCredentialsInput = {
  data: UserUpdateWithoutCredentialsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutDisclosureAcceptancesInput = {
  data: UserUpdateWithoutDisclosureAcceptancesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutFrontIdInput = {
  data: UserUpdateWithoutFrontIdInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutMyOrdersInput = {
  data: UserUpdateWithoutMyOrdersInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutNamesInput = {
  data: UserUpdateWithoutNamesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutOrderNotesInput = {
  data: UserUpdateWithoutOrderNotesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutPassesInput = {
  data: UserUpdateWithoutPassesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutPassportsInput = {
  data: UserUpdateWithoutPassportsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutRedeemedInvitesInput = {
  data: UserUpdateWithoutRedeemedInvitesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutResultRevisionInput = {
  data: UserUpdateWithoutResultRevisionInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutRoleAssignmentInput = {
  data: UserUpdateWithoutRoleAssignmentInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutSelfieInput = {
  data: UserUpdateWithoutSelfieInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutSentInvitesInput = {
  data: UserUpdateWithoutSentInvitesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutShareOrderRecordInput = {
  data: UserUpdateWithoutShareOrderRecordInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutSsnInput = {
  data: UserUpdateWithoutSsnInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutStateIdentificationCardsInput = {
  data: UserUpdateWithoutStateIdentificationCardsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutVidVouchersInput = {
  data: UserUpdateWithoutVidVouchersInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateWithWhereUniqueWithoutOrganizationsInput = {
  data: UserUpdateWithoutOrganizationsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutAddressesInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutBackIdInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutBeamInputInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutCreatedOrdersInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutCreatedOrganizationsInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutCredentialsInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutDisclosureAcceptancesInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutFrontIdInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutMyOrdersInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutNamesInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutOrderNotesInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutOrganizationsInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutPassesInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutPassportsInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutRedeemedInvitesInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutResultRevisionInput = {
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutRoleAssignmentInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutSelfieInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutSentInvitesInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutShareOrderRecordInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutSsnInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutStateIdentificationCardsInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vIDVouchers?: InputMaybe<VidVoucherUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutVidVouchersInput = {
  ResultRevision?: InputMaybe<ResultRevisionUpdateManyWithoutRevisedByIdNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  apiKeys?: InputMaybe<ApiKeyUpdateManyWithoutUserNestedInput>;
  backId?: InputMaybe<FileUpdateOneWithoutBackIdUserNestedInput>;
  beamInput?: InputMaybe<BeamInputUpdateManyWithoutUserNestedInput>;
  consumerReportingAgencyId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdOrders?: InputMaybe<OrderUpdateManyWithoutCreatedByNestedInput>;
  createdOrganizations?: InputMaybe<OrganizationUpdateManyWithoutCreatedByNestedInput>;
  credentials?: InputMaybe<CredentialUpdateManyWithoutUserNestedInput>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceUpdateManyWithoutUserNestedInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  frontId?: InputMaybe<FileUpdateOneWithoutFrontIdUserNestedInput>;
  gender?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identifiers?: InputMaybe<IdentifierUpdateManyWithoutUserNestedInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  legacyCredentials?: InputMaybe<LegacyCredentialUpdateManyWithoutUserNestedInput>;
  legacySSN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  myOrders?: InputMaybe<OrderUpdateManyWithoutUserNestedInput>;
  names?: InputMaybe<NameUpdateManyWithoutUserNestedInput>;
  onboardingStep?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderNotes?: InputMaybe<OrderNoteUpdateManyWithoutCreatedByNestedInput>;
  organizationIds?: InputMaybe<UserUpdateorganizationIdsInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutMembersNestedInput>;
  passes?: InputMaybe<PassUpdateManyWithoutUserNestedInput>;
  passports?: InputMaybe<PassportUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTokens?: InputMaybe<UserUpdatepushTokensInput>;
  redeemedInvites?: InputMaybe<InviteUpdateManyWithoutRedeemedByNestedInput>;
  roleAssignment?: InputMaybe<RoleAssignmentUpdateManyWithoutUserNestedInput>;
  selectedOrganizationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selfie?: InputMaybe<FileUpdateOneWithoutSelfieUserNestedInput>;
  sentInvites?: InputMaybe<InviteUpdateManyWithoutSenderNestedInput>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordUpdateManyWithoutUserNestedInput>;
  ssn?: InputMaybe<HighlySensitiveIdentifierUpdateOneWithoutUserNestedInput>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardUpdateManyWithoutUserNestedInput>;
  status?: InputMaybe<EnumUserStatusEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateorganizationIdsInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UserUpdatepushTokensInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UserUpsertWithWhereUniqueWithoutOrganizationsInput = {
  create: UserCreateWithoutOrganizationsInput;
  update: UserUpdateWithoutOrganizationsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutAddressesInput = {
  create: UserCreateWithoutAddressesInput;
  update: UserUpdateWithoutAddressesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutBackIdInput = {
  create: UserCreateWithoutBackIdInput;
  update: UserUpdateWithoutBackIdInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutBeamInputInput = {
  create: UserCreateWithoutBeamInputInput;
  update: UserUpdateWithoutBeamInputInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutCreatedOrdersInput = {
  create: UserCreateWithoutCreatedOrdersInput;
  update: UserUpdateWithoutCreatedOrdersInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutCreatedOrganizationsInput = {
  create: UserCreateWithoutCreatedOrganizationsInput;
  update: UserUpdateWithoutCreatedOrganizationsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutCredentialsInput = {
  create: UserCreateWithoutCredentialsInput;
  update: UserUpdateWithoutCredentialsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutDisclosureAcceptancesInput = {
  create: UserCreateWithoutDisclosureAcceptancesInput;
  update: UserUpdateWithoutDisclosureAcceptancesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutFrontIdInput = {
  create: UserCreateWithoutFrontIdInput;
  update: UserUpdateWithoutFrontIdInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutMyOrdersInput = {
  create: UserCreateWithoutMyOrdersInput;
  update: UserUpdateWithoutMyOrdersInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutNamesInput = {
  create: UserCreateWithoutNamesInput;
  update: UserUpdateWithoutNamesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutOrderNotesInput = {
  create: UserCreateWithoutOrderNotesInput;
  update: UserUpdateWithoutOrderNotesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutPassesInput = {
  create: UserCreateWithoutPassesInput;
  update: UserUpdateWithoutPassesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutPassportsInput = {
  create: UserCreateWithoutPassportsInput;
  update: UserUpdateWithoutPassportsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutRedeemedInvitesInput = {
  create: UserCreateWithoutRedeemedInvitesInput;
  update: UserUpdateWithoutRedeemedInvitesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutResultRevisionInput = {
  create: UserCreateWithoutResultRevisionInput;
  update: UserUpdateWithoutResultRevisionInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutRoleAssignmentInput = {
  create: UserCreateWithoutRoleAssignmentInput;
  update: UserUpdateWithoutRoleAssignmentInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutSelfieInput = {
  create: UserCreateWithoutSelfieInput;
  update: UserUpdateWithoutSelfieInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutSentInvitesInput = {
  create: UserCreateWithoutSentInvitesInput;
  update: UserUpdateWithoutSentInvitesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutShareOrderRecordInput = {
  create: UserCreateWithoutShareOrderRecordInput;
  update: UserUpdateWithoutShareOrderRecordInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutSsnInput = {
  create: UserCreateWithoutSsnInput;
  update: UserUpdateWithoutSsnInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutStateIdentificationCardsInput = {
  create: UserCreateWithoutStateIdentificationCardsInput;
  update: UserUpdateWithoutStateIdentificationCardsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutVidVouchersInput = {
  create: UserCreateWithoutVidVouchersInput;
  update: UserUpdateWithoutVidVouchersInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  ResultRevision?: InputMaybe<ResultRevisionListRelationFilter>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  apiKeys?: InputMaybe<ApiKeyListRelationFilter>;
  backId?: InputMaybe<FileNullableRelationFilter>;
  beamInput?: InputMaybe<BeamInputListRelationFilter>;
  consumerReportingAgencyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdOrders?: InputMaybe<OrderListRelationFilter>;
  createdOrganizations?: InputMaybe<OrganizationListRelationFilter>;
  credentials?: InputMaybe<CredentialListRelationFilter>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceListRelationFilter>;
  dob?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  frontId?: InputMaybe<FileNullableRelationFilter>;
  gender?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  identifiers?: InputMaybe<IdentifierListRelationFilter>;
  jobTitle?: InputMaybe<StringNullableFilter>;
  legacyCredentials?: InputMaybe<LegacyCredentialListRelationFilter>;
  legacySSN?: InputMaybe<StringNullableFilter>;
  myOrders?: InputMaybe<OrderListRelationFilter>;
  names?: InputMaybe<NameListRelationFilter>;
  onboardingStep?: InputMaybe<StringNullableFilter>;
  orderNotes?: InputMaybe<OrderNoteListRelationFilter>;
  organizationIds?: InputMaybe<StringNullableListFilter>;
  organizations?: InputMaybe<OrganizationListRelationFilter>;
  passes?: InputMaybe<PassListRelationFilter>;
  passports?: InputMaybe<PassportListRelationFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  pushTokens?: InputMaybe<StringNullableListFilter>;
  redeemedInvites?: InputMaybe<InviteListRelationFilter>;
  roleAssignment?: InputMaybe<RoleAssignmentListRelationFilter>;
  selectedOrganizationId?: InputMaybe<StringNullableFilter>;
  selfie?: InputMaybe<FileNullableRelationFilter>;
  sentInvites?: InputMaybe<InviteListRelationFilter>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordListRelationFilter>;
  ssn?: InputMaybe<HighlySensitiveIdentifierNullableRelationFilter>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardListRelationFilter>;
  status?: InputMaybe<EnumUserStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vIDVouchers?: InputMaybe<VidVoucherListRelationFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  ResultRevision?: InputMaybe<ResultRevisionListRelationFilter>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  apiKeys?: InputMaybe<ApiKeyListRelationFilter>;
  backId?: InputMaybe<FileNullableRelationFilter>;
  beamInput?: InputMaybe<BeamInputListRelationFilter>;
  consumerReportingAgencyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdOrders?: InputMaybe<OrderListRelationFilter>;
  createdOrganizations?: InputMaybe<OrganizationListRelationFilter>;
  credentials?: InputMaybe<CredentialListRelationFilter>;
  disclosureAcceptances?: InputMaybe<DisclosureAcceptanceListRelationFilter>;
  dob?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<FileNullableRelationFilter>;
  gender?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  identifiers?: InputMaybe<IdentifierListRelationFilter>;
  jobTitle?: InputMaybe<StringNullableFilter>;
  legacyCredentials?: InputMaybe<LegacyCredentialListRelationFilter>;
  legacySSN?: InputMaybe<StringNullableFilter>;
  myOrders?: InputMaybe<OrderListRelationFilter>;
  names?: InputMaybe<NameListRelationFilter>;
  onboardingStep?: InputMaybe<StringNullableFilter>;
  orderNotes?: InputMaybe<OrderNoteListRelationFilter>;
  organizationIds?: InputMaybe<StringNullableListFilter>;
  organizations?: InputMaybe<OrganizationListRelationFilter>;
  passes?: InputMaybe<PassListRelationFilter>;
  passports?: InputMaybe<PassportListRelationFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  pushTokens?: InputMaybe<StringNullableListFilter>;
  redeemedInvites?: InputMaybe<InviteListRelationFilter>;
  roleAssignment?: InputMaybe<RoleAssignmentListRelationFilter>;
  selectedOrganizationId?: InputMaybe<StringNullableFilter>;
  selfie?: InputMaybe<FileNullableRelationFilter>;
  sentInvites?: InputMaybe<InviteListRelationFilter>;
  shareOrderRecord?: InputMaybe<ShareOrderRecordListRelationFilter>;
  ssn?: InputMaybe<HighlySensitiveIdentifierNullableRelationFilter>;
  stateIdentificationCards?: InputMaybe<StateIdentificationCardListRelationFilter>;
  status?: InputMaybe<EnumUserStatusEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vIDVouchers?: InputMaybe<VidVoucherListRelationFilter>;
};

export type VidVoucher = {
  __typename?: 'VIDVoucher';
  branchIOShortCode?: Maybe<Scalars['String']>;
  clientMetadata?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTimeISO'];
  dateOfBirth?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fileNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inviteId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  packageSet: PackageSet;
  packageSetId: Scalars['String'];
  passType?: Maybe<PassType>;
  passTypeId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postbackUrl?: Maybe<Scalars['String']>;
  primaryContactMethod?: Maybe<UserPrimaryContactEnum>;
  status: VoucherStatusEnum;
  suffix?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTimeISO'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type VidVoucherOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type VidVoucherPassTypeArgs = {
  where?: InputMaybe<PassTypeWhereInput>;
};


export type VidVoucherUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type VidVoucherCountAggregate = {
  __typename?: 'VIDVoucherCountAggregate';
  _all: Scalars['Int'];
  branchIOShortCode: Scalars['Int'];
  clientMetadata: Scalars['Int'];
  createdAt: Scalars['Int'];
  dateOfBirth: Scalars['Int'];
  email: Scalars['Int'];
  firstName: Scalars['Int'];
  id: Scalars['Int'];
  inviteId: Scalars['Int'];
  lastName: Scalars['Int'];
  middleName: Scalars['Int'];
  orderId: Scalars['Int'];
  organizationId: Scalars['Int'];
  packageSetId: Scalars['Int'];
  passTypeId: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  postbackUrl: Scalars['Int'];
  primaryContactMethod: Scalars['Int'];
  status: Scalars['Int'];
  suffix: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type VidVoucherCreateManyPackageSetInput = {
  branchIOShortCode?: InputMaybe<Scalars['String']>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inviteId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  passTypeId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postbackUrl?: InputMaybe<Scalars['String']>;
  primaryContactMethod?: InputMaybe<UserPrimaryContactEnum>;
  status?: InputMaybe<VoucherStatusEnum>;
  suffix?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type VidVoucherCreateManyPackageSetInputEnvelope = {
  data: Array<VidVoucherCreateManyPackageSetInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type VidVoucherCreateManyPassTypeInput = {
  branchIOShortCode?: InputMaybe<Scalars['String']>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inviteId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  packageSetId: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postbackUrl?: InputMaybe<Scalars['String']>;
  primaryContactMethod?: InputMaybe<UserPrimaryContactEnum>;
  status?: InputMaybe<VoucherStatusEnum>;
  suffix?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type VidVoucherCreateManyPassTypeInputEnvelope = {
  data: Array<VidVoucherCreateManyPassTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type VidVoucherCreateManyUserInput = {
  branchIOShortCode?: InputMaybe<Scalars['String']>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inviteId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  packageSetId: Scalars['String'];
  passTypeId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postbackUrl?: InputMaybe<Scalars['String']>;
  primaryContactMethod?: InputMaybe<UserPrimaryContactEnum>;
  status?: InputMaybe<VoucherStatusEnum>;
  suffix?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type VidVoucherCreateManyUserInputEnvelope = {
  data: Array<VidVoucherCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type VidVoucherCreateNestedManyWithoutPackageSetInput = {
  connect?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VidVoucherCreateOrConnectWithoutPackageSetInput>>;
  create?: InputMaybe<Array<VidVoucherCreateWithoutPackageSetInput>>;
  createMany?: InputMaybe<VidVoucherCreateManyPackageSetInputEnvelope>;
};

export type VidVoucherCreateNestedManyWithoutPassTypeInput = {
  connect?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VidVoucherCreateOrConnectWithoutPassTypeInput>>;
  create?: InputMaybe<Array<VidVoucherCreateWithoutPassTypeInput>>;
  createMany?: InputMaybe<VidVoucherCreateManyPassTypeInputEnvelope>;
};

export type VidVoucherCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VidVoucherCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<VidVoucherCreateWithoutUserInput>>;
  createMany?: InputMaybe<VidVoucherCreateManyUserInputEnvelope>;
};

export type VidVoucherCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<VidVoucherWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VidVoucherCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<VidVoucherCreateWithoutOrderInput>;
};

export type VidVoucherCreateOrConnectWithoutOrderInput = {
  create: VidVoucherCreateWithoutOrderInput;
  where: VidVoucherWhereUniqueInput;
};

export type VidVoucherCreateOrConnectWithoutPackageSetInput = {
  create: VidVoucherCreateWithoutPackageSetInput;
  where: VidVoucherWhereUniqueInput;
};

export type VidVoucherCreateOrConnectWithoutPassTypeInput = {
  create: VidVoucherCreateWithoutPassTypeInput;
  where: VidVoucherWhereUniqueInput;
};

export type VidVoucherCreateOrConnectWithoutUserInput = {
  create: VidVoucherCreateWithoutUserInput;
  where: VidVoucherWhereUniqueInput;
};

export type VidVoucherCreateWithoutOrderInput = {
  branchIOShortCode?: InputMaybe<Scalars['String']>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inviteId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  packageSet: PackageSetCreateNestedOneWithoutVidVouchersInput;
  passType?: InputMaybe<PassTypeCreateNestedOneWithoutVouchersInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postbackUrl?: InputMaybe<Scalars['String']>;
  primaryContactMethod?: InputMaybe<UserPrimaryContactEnum>;
  status?: InputMaybe<VoucherStatusEnum>;
  suffix?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutVidVouchersInput>;
};

export type VidVoucherCreateWithoutPackageSetInput = {
  branchIOShortCode?: InputMaybe<Scalars['String']>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inviteId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutVidVoucherInput>;
  organizationId: Scalars['String'];
  passType?: InputMaybe<PassTypeCreateNestedOneWithoutVouchersInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postbackUrl?: InputMaybe<Scalars['String']>;
  primaryContactMethod?: InputMaybe<UserPrimaryContactEnum>;
  status?: InputMaybe<VoucherStatusEnum>;
  suffix?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutVidVouchersInput>;
};

export type VidVoucherCreateWithoutPassTypeInput = {
  branchIOShortCode?: InputMaybe<Scalars['String']>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inviteId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutVidVoucherInput>;
  organizationId: Scalars['String'];
  packageSet: PackageSetCreateNestedOneWithoutVidVouchersInput;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postbackUrl?: InputMaybe<Scalars['String']>;
  primaryContactMethod?: InputMaybe<UserPrimaryContactEnum>;
  status?: InputMaybe<VoucherStatusEnum>;
  suffix?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
  user?: InputMaybe<UserCreateNestedOneWithoutVidVouchersInput>;
};

export type VidVoucherCreateWithoutUserInput = {
  branchIOShortCode?: InputMaybe<Scalars['String']>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inviteId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutVidVoucherInput>;
  organizationId: Scalars['String'];
  packageSet: PackageSetCreateNestedOneWithoutVidVouchersInput;
  passType?: InputMaybe<PassTypeCreateNestedOneWithoutVouchersInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postbackUrl?: InputMaybe<Scalars['String']>;
  primaryContactMethod?: InputMaybe<UserPrimaryContactEnum>;
  status?: InputMaybe<VoucherStatusEnum>;
  suffix?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type VidVoucherListRelationFilter = {
  every?: InputMaybe<VidVoucherWhereInput>;
  none?: InputMaybe<VidVoucherWhereInput>;
  some?: InputMaybe<VidVoucherWhereInput>;
};

export type VidVoucherMaxAggregate = {
  __typename?: 'VIDVoucherMaxAggregate';
  branchIOShortCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inviteId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  packageSetId?: Maybe<Scalars['String']>;
  passTypeId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postbackUrl?: Maybe<Scalars['String']>;
  primaryContactMethod?: Maybe<UserPrimaryContactEnum>;
  status?: Maybe<VoucherStatusEnum>;
  suffix?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  userId?: Maybe<Scalars['String']>;
};

export type VidVoucherMinAggregate = {
  __typename?: 'VIDVoucherMinAggregate';
  branchIOShortCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTimeISO']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inviteId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  packageSetId?: Maybe<Scalars['String']>;
  passTypeId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postbackUrl?: Maybe<Scalars['String']>;
  primaryContactMethod?: Maybe<UserPrimaryContactEnum>;
  status?: Maybe<VoucherStatusEnum>;
  suffix?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
  userId?: Maybe<Scalars['String']>;
};

export type VidVoucherNullableRelationFilter = {
  is?: InputMaybe<VidVoucherWhereInput>;
  isNot?: InputMaybe<VidVoucherWhereInput>;
};

export type VidVoucherOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type VidVoucherOrderByWithRelationInput = {
  branchIOShortCode?: InputMaybe<SortOrderInput>;
  clientMetadata?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inviteId?: InputMaybe<SortOrderInput>;
  lastName?: InputMaybe<SortOrderInput>;
  middleName?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrderInput>;
  organizationId?: InputMaybe<SortOrder>;
  packageSet?: InputMaybe<PackageSetOrderByWithRelationInput>;
  packageSetId?: InputMaybe<SortOrder>;
  passType?: InputMaybe<PassTypeOrderByWithRelationInput>;
  passTypeId?: InputMaybe<SortOrderInput>;
  phoneNumber?: InputMaybe<SortOrderInput>;
  postbackUrl?: InputMaybe<SortOrderInput>;
  primaryContactMethod?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  suffix?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum VidVoucherScalarFieldEnum {
  BranchIoShortCode = 'branchIOShortCode',
  ClientMetadata = 'clientMetadata',
  CreatedAt = 'createdAt',
  DateOfBirth = 'dateOfBirth',
  Email = 'email',
  FirstName = 'firstName',
  Id = 'id',
  InviteId = 'inviteId',
  LastName = 'lastName',
  MiddleName = 'middleName',
  OrderId = 'orderId',
  OrganizationId = 'organizationId',
  PackageSetId = 'packageSetId',
  PassTypeId = 'passTypeId',
  PhoneNumber = 'phoneNumber',
  PostbackUrl = 'postbackUrl',
  PrimaryContactMethod = 'primaryContactMethod',
  Status = 'status',
  Suffix = 'suffix',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type VidVoucherScalarWhereInput = {
  AND?: InputMaybe<Array<VidVoucherScalarWhereInput>>;
  NOT?: InputMaybe<Array<VidVoucherScalarWhereInput>>;
  OR?: InputMaybe<Array<VidVoucherScalarWhereInput>>;
  branchIOShortCode?: InputMaybe<StringNullableFilter>;
  clientMetadata?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inviteId?: InputMaybe<StringNullableFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  organizationId?: InputMaybe<StringFilter>;
  packageSetId?: InputMaybe<StringFilter>;
  passTypeId?: InputMaybe<StringNullableFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  postbackUrl?: InputMaybe<StringNullableFilter>;
  primaryContactMethod?: InputMaybe<EnumUserPrimaryContactEnumNullableFilter>;
  status?: InputMaybe<EnumVoucherStatusEnumFilter>;
  suffix?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type VidVoucherUpdateInput = {
  branchIOShortCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutVidVoucherNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageSet?: InputMaybe<PackageSetUpdateOneRequiredWithoutVidVouchersNestedInput>;
  passType?: InputMaybe<PassTypeUpdateOneWithoutVouchersNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryContactMethod?: InputMaybe<NullableEnumUserPrimaryContactEnumFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVoucherStatusEnumFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutVidVouchersNestedInput>;
};

export type VidVoucherUpdateManyMutationInput = {
  branchIOShortCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryContactMethod?: InputMaybe<NullableEnumUserPrimaryContactEnumFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVoucherStatusEnumFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VidVoucherUpdateManyWithWhereWithoutPackageSetInput = {
  data: VidVoucherUpdateManyMutationInput;
  where: VidVoucherScalarWhereInput;
};

export type VidVoucherUpdateManyWithWhereWithoutPassTypeInput = {
  data: VidVoucherUpdateManyMutationInput;
  where: VidVoucherScalarWhereInput;
};

export type VidVoucherUpdateManyWithWhereWithoutUserInput = {
  data: VidVoucherUpdateManyMutationInput;
  where: VidVoucherScalarWhereInput;
};

export type VidVoucherUpdateManyWithoutPackageSetNestedInput = {
  connect?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VidVoucherCreateOrConnectWithoutPackageSetInput>>;
  create?: InputMaybe<Array<VidVoucherCreateWithoutPackageSetInput>>;
  createMany?: InputMaybe<VidVoucherCreateManyPackageSetInputEnvelope>;
  delete?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VidVoucherScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  set?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  update?: InputMaybe<Array<VidVoucherUpdateWithWhereUniqueWithoutPackageSetInput>>;
  updateMany?: InputMaybe<Array<VidVoucherUpdateManyWithWhereWithoutPackageSetInput>>;
  upsert?: InputMaybe<Array<VidVoucherUpsertWithWhereUniqueWithoutPackageSetInput>>;
};

export type VidVoucherUpdateManyWithoutPassTypeNestedInput = {
  connect?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VidVoucherCreateOrConnectWithoutPassTypeInput>>;
  create?: InputMaybe<Array<VidVoucherCreateWithoutPassTypeInput>>;
  createMany?: InputMaybe<VidVoucherCreateManyPassTypeInputEnvelope>;
  delete?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VidVoucherScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  set?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  update?: InputMaybe<Array<VidVoucherUpdateWithWhereUniqueWithoutPassTypeInput>>;
  updateMany?: InputMaybe<Array<VidVoucherUpdateManyWithWhereWithoutPassTypeInput>>;
  upsert?: InputMaybe<Array<VidVoucherUpsertWithWhereUniqueWithoutPassTypeInput>>;
};

export type VidVoucherUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<VidVoucherCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<VidVoucherCreateWithoutUserInput>>;
  createMany?: InputMaybe<VidVoucherCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VidVoucherScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  set?: InputMaybe<Array<VidVoucherWhereUniqueInput>>;
  update?: InputMaybe<Array<VidVoucherUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<VidVoucherUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<VidVoucherUpsertWithWhereUniqueWithoutUserInput>>;
};

export type VidVoucherUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<VidVoucherWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VidVoucherCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<VidVoucherCreateWithoutOrderInput>;
  delete?: InputMaybe<VidVoucherWhereInput>;
  disconnect?: InputMaybe<VidVoucherWhereInput>;
  update?: InputMaybe<VidVoucherUpdateToOneWithWhereWithoutOrderInput>;
  upsert?: InputMaybe<VidVoucherUpsertWithoutOrderInput>;
};

export type VidVoucherUpdateToOneWithWhereWithoutOrderInput = {
  data: VidVoucherUpdateWithoutOrderInput;
  where?: InputMaybe<VidVoucherWhereInput>;
};

export type VidVoucherUpdateWithWhereUniqueWithoutPackageSetInput = {
  data: VidVoucherUpdateWithoutPackageSetInput;
  where: VidVoucherWhereUniqueInput;
};

export type VidVoucherUpdateWithWhereUniqueWithoutPassTypeInput = {
  data: VidVoucherUpdateWithoutPassTypeInput;
  where: VidVoucherWhereUniqueInput;
};

export type VidVoucherUpdateWithWhereUniqueWithoutUserInput = {
  data: VidVoucherUpdateWithoutUserInput;
  where: VidVoucherWhereUniqueInput;
};

export type VidVoucherUpdateWithoutOrderInput = {
  branchIOShortCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageSet?: InputMaybe<PackageSetUpdateOneRequiredWithoutVidVouchersNestedInput>;
  passType?: InputMaybe<PassTypeUpdateOneWithoutVouchersNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryContactMethod?: InputMaybe<NullableEnumUserPrimaryContactEnumFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVoucherStatusEnumFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutVidVouchersNestedInput>;
};

export type VidVoucherUpdateWithoutPackageSetInput = {
  branchIOShortCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutVidVoucherNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  passType?: InputMaybe<PassTypeUpdateOneWithoutVouchersNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryContactMethod?: InputMaybe<NullableEnumUserPrimaryContactEnumFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVoucherStatusEnumFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutVidVouchersNestedInput>;
};

export type VidVoucherUpdateWithoutPassTypeInput = {
  branchIOShortCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutVidVoucherNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageSet?: InputMaybe<PackageSetUpdateOneRequiredWithoutVidVouchersNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryContactMethod?: InputMaybe<NullableEnumUserPrimaryContactEnumFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVoucherStatusEnumFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutVidVouchersNestedInput>;
};

export type VidVoucherUpdateWithoutUserInput = {
  branchIOShortCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientMetadata?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateOfBirth?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inviteId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  middleName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutVidVoucherNestedInput>;
  organizationId?: InputMaybe<StringFieldUpdateOperationsInput>;
  packageSet?: InputMaybe<PackageSetUpdateOneRequiredWithoutVidVouchersNestedInput>;
  passType?: InputMaybe<PassTypeUpdateOneWithoutVouchersNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postbackUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primaryContactMethod?: InputMaybe<NullableEnumUserPrimaryContactEnumFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumVoucherStatusEnumFieldUpdateOperationsInput>;
  suffix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VidVoucherUpsertWithWhereUniqueWithoutPackageSetInput = {
  create: VidVoucherCreateWithoutPackageSetInput;
  update: VidVoucherUpdateWithoutPackageSetInput;
  where: VidVoucherWhereUniqueInput;
};

export type VidVoucherUpsertWithWhereUniqueWithoutPassTypeInput = {
  create: VidVoucherCreateWithoutPassTypeInput;
  update: VidVoucherUpdateWithoutPassTypeInput;
  where: VidVoucherWhereUniqueInput;
};

export type VidVoucherUpsertWithWhereUniqueWithoutUserInput = {
  create: VidVoucherCreateWithoutUserInput;
  update: VidVoucherUpdateWithoutUserInput;
  where: VidVoucherWhereUniqueInput;
};

export type VidVoucherUpsertWithoutOrderInput = {
  create: VidVoucherCreateWithoutOrderInput;
  update: VidVoucherUpdateWithoutOrderInput;
  where?: InputMaybe<VidVoucherWhereInput>;
};

export type VidVoucherWhereInput = {
  AND?: InputMaybe<Array<VidVoucherWhereInput>>;
  NOT?: InputMaybe<Array<VidVoucherWhereInput>>;
  OR?: InputMaybe<Array<VidVoucherWhereInput>>;
  branchIOShortCode?: InputMaybe<StringNullableFilter>;
  clientMetadata?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inviteId?: InputMaybe<StringNullableFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  organizationId?: InputMaybe<StringFilter>;
  packageSet?: InputMaybe<PackageSetRelationFilter>;
  packageSetId?: InputMaybe<StringFilter>;
  passType?: InputMaybe<PassTypeNullableRelationFilter>;
  passTypeId?: InputMaybe<StringNullableFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  postbackUrl?: InputMaybe<StringNullableFilter>;
  primaryContactMethod?: InputMaybe<EnumUserPrimaryContactEnumNullableFilter>;
  status?: InputMaybe<EnumVoucherStatusEnumFilter>;
  suffix?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type VidVoucherWhereUniqueInput = {
  AND?: InputMaybe<Array<VidVoucherWhereInput>>;
  NOT?: InputMaybe<Array<VidVoucherWhereInput>>;
  OR?: InputMaybe<Array<VidVoucherWhereInput>>;
  branchIOShortCode?: InputMaybe<StringNullableFilter>;
  clientMetadata?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  inviteId?: InputMaybe<StringNullableFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<StringFilter>;
  packageSet?: InputMaybe<PackageSetRelationFilter>;
  packageSetId?: InputMaybe<StringFilter>;
  passType?: InputMaybe<PassTypeNullableRelationFilter>;
  passTypeId?: InputMaybe<StringNullableFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  postbackUrl?: InputMaybe<StringNullableFilter>;
  primaryContactMethod?: InputMaybe<EnumUserPrimaryContactEnumNullableFilter>;
  status?: InputMaybe<EnumVoucherStatusEnumFilter>;
  suffix?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type Vendor = {
  __typename?: 'Vendor';
  _count?: Maybe<VendorCount>;
  createdAt: Scalars['DateTimeISO'];
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type VendorCount = {
  __typename?: 'VendorCount';
  Results: Scalars['Int'];
};


export type VendorCountResultsArgs = {
  where?: InputMaybe<ResultWhereInput>;
};

export type VendorCreateNestedOneWithoutResultsInput = {
  connect?: InputMaybe<VendorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VendorCreateOrConnectWithoutResultsInput>;
  create?: InputMaybe<VendorCreateWithoutResultsInput>;
};

export type VendorCreateOrConnectWithoutResultsInput = {
  create: VendorCreateWithoutResultsInput;
  where: VendorWhereUniqueInput;
};

export type VendorCreateWithoutResultsInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']>;
};

export type VendorNullableRelationFilter = {
  is?: InputMaybe<VendorWhereInput>;
  isNot?: InputMaybe<VendorWhereInput>;
};

export type VendorOrderByWithRelationInput = {
  Results?: InputMaybe<ResultOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VendorUpdateOneWithoutResultsNestedInput = {
  connect?: InputMaybe<VendorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VendorCreateOrConnectWithoutResultsInput>;
  create?: InputMaybe<VendorCreateWithoutResultsInput>;
  delete?: InputMaybe<VendorWhereInput>;
  disconnect?: InputMaybe<VendorWhereInput>;
  update?: InputMaybe<VendorUpdateToOneWithWhereWithoutResultsInput>;
  upsert?: InputMaybe<VendorUpsertWithoutResultsInput>;
};

export type VendorUpdateToOneWithWhereWithoutResultsInput = {
  data: VendorUpdateWithoutResultsInput;
  where?: InputMaybe<VendorWhereInput>;
};

export type VendorUpdateWithoutResultsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VendorUpsertWithoutResultsInput = {
  create: VendorCreateWithoutResultsInput;
  update: VendorUpdateWithoutResultsInput;
  where?: InputMaybe<VendorWhereInput>;
};

export type VendorWhereInput = {
  AND?: InputMaybe<Array<VendorWhereInput>>;
  NOT?: InputMaybe<Array<VendorWhereInput>>;
  OR?: InputMaybe<Array<VendorWhereInput>>;
  Results?: InputMaybe<ResultListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type VendorWhereUniqueInput = {
  AND?: InputMaybe<Array<VendorWhereInput>>;
  NOT?: InputMaybe<Array<VendorWhereInput>>;
  OR?: InputMaybe<Array<VendorWhereInput>>;
  Results?: InputMaybe<ResultListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum VoucherStatusEnum {
  Complete = 'COMPLETE',
  OptOut = 'OPT_OUT',
  Pending = 'PENDING'
}

export enum VIdInviteDeliveryMethodEnum {
  Email = 'EMAIL',
  None = 'NONE'
}

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, dob?: string | null, organizations: Array<{ __typename?: 'Organization', id: string }>, selfie?: { __typename?: 'File', bucket?: string | null, url: string } | null, names: Array<{ __typename?: 'Name', middleName?: string | null, lastName?: string | null, firstName?: string | null, type?: NameTypeEnum | null }>, ssn?: { __typename?: 'HighlySensitiveIdentifier', id: string } | null } };

export type BeamQueryVariables = Exact<{
  where: BeamWhereUniqueInput;
}>;


export type BeamQuery = { __typename?: 'Query', beam?: { __typename?: 'Beam', id: string, name: string, description: string, organizationId: string, updatedAt: any, createdAt: any, processor: ProcessorEnum, config?: any | null, processorConfig?: any | null, inputTypes: Array<{ __typename?: 'BeamInputType', id: string, slug: string, beamId: string, schema: any, uiSchema?: any | null, version: string, createdAt: any, organizationId: string }> } | null };

export type BeamListQueryVariables = Exact<{
  where?: InputMaybe<BeamWhereInput>;
  take?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BeamInputTypeOrderByWithRelationInput> | BeamInputTypeOrderByWithRelationInput>;
}>;


export type BeamListQuery = { __typename?: 'Query', beams: Array<{ __typename?: 'Beam', id: string, name: string, description: string, organizationId: string, updatedAt: any, createdAt: any, processor: ProcessorEnum, config?: any | null, processorConfig?: any | null, inputTypes: Array<{ __typename?: 'BeamInputType', id: string, slug: string, beamId: string, schema: any, uiSchema?: any | null, version: string, createdAt: any, organizationId: string }> }> };

export type ExtractBeamDataFromImageMutationVariables = Exact<{
  imageUrl: Scalars['String'];
  beamInputTypeId: Scalars['String'];
}>;


export type ExtractBeamDataFromImageMutation = { __typename?: 'Mutation', extractBeamDataFromImage?: any | null };

export type BeamInputTypeQueryVariables = Exact<{
  where: BeamInputTypeWhereUniqueInput;
}>;


export type BeamInputTypeQuery = { __typename?: 'Query', beamInputType?: { __typename?: 'BeamInputType', id: string, slug: string, beamId: string, schema: any, uiSchema?: any | null, version: string, createdAt: any, organizationId: string } | null };

export type BeamInputTypesListQueryVariables = Exact<{
  where?: InputMaybe<BeamInputTypeWhereInput>;
  orderBy?: InputMaybe<Array<BeamInputTypeOrderByWithRelationInput> | BeamInputTypeOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<BeamInputTypeScalarFieldEnum> | BeamInputTypeScalarFieldEnum>;
}>;


export type BeamInputTypesListQuery = { __typename?: 'Query', beamInputTypes: Array<{ __typename?: 'BeamInputType', id: string, slug: string, beamId: string, schema: any, uiSchema?: any | null, version: string, createdAt: any, organizationId: string }> };

export type CredentialsQueryVariables = Exact<{
  where?: InputMaybe<CredentialWhereInput>;
}>;


export type CredentialsQuery = { __typename?: 'Query', credentials: Array<{ __typename?: 'Credential', id: string, createdAt: any, status?: { __typename?: 'CredentialStatus', id: string, value: CredentialStatusEnum } | null, input: { __typename?: 'BeamInput', type: { __typename?: 'BeamInputType', beam: { __typename?: 'Beam', name: string } } }, type: { __typename?: 'CredentialType', id: string, name: string }, pass?: { __typename?: 'Pass', passType?: { __typename?: 'PassType', name: string } | null } | null }> };

export type RetrieveDisclosuresQueryVariables = Exact<{
  packageId: Scalars['String'];
  addresses: Array<DisclosureAddress> | DisclosureAddress;
}>;


export type RetrieveDisclosuresQuery = { __typename?: 'Query', retrieveDisclosures: Array<{ __typename?: 'Disclosure', id: string, location?: string | null, locationType?: LocationTypeEnum | null, name: string, text: string, type: DisclosureTypeEnum }> };

export type CreateDisclosureAcceptanceMutationVariables = Exact<{
  data: DisclosureAcceptanceArgs;
}>;


export type CreateDisclosureAcceptanceMutation = { __typename?: 'Mutation', createDisclosureAcceptance: { __typename?: 'DisclosureAcceptance', userId: string, updatedAt: any, signatureSvg: any, orderId?: string | null, id: string, createdAt: any } };

export type CreateUploadSignedUrlMutationVariables = Exact<{
  fileId: Scalars['String'];
}>;


export type CreateUploadSignedUrlMutation = { __typename?: 'Mutation', createUploadSignedURL: { __typename?: 'SignedURLResponseV2', url: string } };

export type CreateMultipleUploadSignedUrlMutationVariables = Exact<{
  files: Array<MultipleSignedUrlFileInputV2> | MultipleSignedUrlFileInputV2;
}>;


export type CreateMultipleUploadSignedUrlMutation = { __typename?: 'Mutation', createMultipleUploadSignedURL: Array<{ __typename?: 'SignedURLResponseV2', url: string }> };

export type CreateOneFileMutationVariables = Exact<{
  data: FileCreateInput;
}>;


export type CreateOneFileMutation = { __typename?: 'Mutation', createOneFile: { __typename?: 'File', id: string } };

export type ApplicantDataFragment = { __typename?: 'ApplicantData', id: string, dateOfBirth: string, email?: string | null, phoneNumber?: string | null, createdAt: any, updatedAt: any, orderId: string, ssn?: { __typename?: 'HighlySensitiveIdentifier', value: string } | null, names: Array<{ __typename?: 'Name', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, type?: NameTypeEnum | null, userId?: string | null }>, addresses: Array<{ __typename?: 'Address', id: string, userId?: string | null, addressLineOne: string, addressLineTwo?: string | null, city: string, state: string, postalCode: string, country: string, type?: AddressTypeEnum | null, createdAt: any, updatedAt: any, organizationId?: string | null }> };

export type DashboardOrderListFragment = { __typename?: 'Order', id: string, fileNumber: string, status?: OrderStatusEnum | null, score?: OrderScoreEnum | null, alert?: OrderAlertEnum | null, deliveryMethod?: OrderDeliveryMethod | null, createdAt: any, applicantData?: { __typename?: 'ApplicantData', id: string, names: Array<{ __typename?: 'Name', firstName?: string | null, middleName?: string | null, lastName?: string | null }> } | null, user?: { __typename?: 'User', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null } | null, packageSets: Array<{ __typename?: 'PackageSet', name: string }> };

export type OrderFragment = { __typename?: 'Order', id: string, fileNumber: string, deliveryMethod?: OrderDeliveryMethod | null, status?: OrderStatusEnum | null, score?: OrderScoreEnum | null, createdAt: any, reportableResults?: any | null, reportCompletedAt?: any | null, files: Array<{ __typename?: 'File', id: string, createdAt: any, name: string, url: string }>, organization?: { __typename?: 'Organization', id: string, name: string } | null, applicantData?: { __typename?: 'ApplicantData', id: string, dateOfBirth: string, email?: string | null, phoneNumber?: string | null, createdAt: any, updatedAt: any, orderId: string, ssn?: { __typename?: 'HighlySensitiveIdentifier', value: string } | null, names: Array<{ __typename?: 'Name', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, type?: NameTypeEnum | null, userId?: string | null }>, addresses: Array<{ __typename?: 'Address', id: string, userId?: string | null, addressLineOne: string, addressLineTwo?: string | null, city: string, state: string, postalCode: string, country: string, type?: AddressTypeEnum | null, createdAt: any, updatedAt: any, organizationId?: string | null }> } | null };

export type SearchTypeFragment = { __typename?: 'SearchType', id: string, name: string };

export type PackageSetFragment = { __typename?: 'PackageSet', id: string, name: string, organization?: { __typename?: 'Organization', id: string, name: string } | null, searchTypes: Array<{ __typename?: 'SearchType', id: string, name: string }> };

export type OrganizationResFragment = { __typename?: 'OrganizationRes', id?: string | null, name?: string | null, logoUrl?: string | null };

export type BeamFragment = { __typename?: 'Beam', id: string, name: string, description: string, organizationId: string, updatedAt: any, createdAt: any, processor: ProcessorEnum, config?: any | null, processorConfig?: any | null, inputTypes: Array<{ __typename?: 'BeamInputType', id: string, slug: string, beamId: string, schema: any, uiSchema?: any | null, version: string, createdAt: any, organizationId: string }> };

export type BeamInputTypeFragment = { __typename?: 'BeamInputType', id: string, slug: string, beamId: string, schema: any, uiSchema?: any | null, version: string, createdAt: any, organizationId: string };

export type ShareOrderRecordFragment = { __typename?: 'ShareOrderRecord', id: string, userId: string, orderId: string, organizationId: string, createdAt: any, updatedAt: any };

export type CreateOneHighlySensitiveIdentifierMutationVariables = Exact<{
  data: HighlySensitiveIdentifierCreateInput;
}>;


export type CreateOneHighlySensitiveIdentifierMutation = { __typename?: 'Mutation', createOneHighlySensitiveIdentifier: { __typename?: 'HighlySensitiveIdentifier', id: string } };

export type CreateOneNameMutationVariables = Exact<{
  data: NameCreateInput;
}>;


export type CreateOneNameMutation = { __typename?: 'Mutation', createOneName: { __typename?: 'Name', id: string } };

export type OrderListQueryVariables = Exact<{
  where?: InputMaybe<OrderWhereInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput> | OrderOrderByWithRelationInput>;
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum> | OrderScalarFieldEnum>;
}>;


export type OrderListQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: string, fileNumber: string, status?: OrderStatusEnum | null, score?: OrderScoreEnum | null, alert?: OrderAlertEnum | null, deliveryMethod?: OrderDeliveryMethod | null, createdAt: any, applicantData?: { __typename?: 'ApplicantData', id: string, names: Array<{ __typename?: 'Name', firstName?: string | null, middleName?: string | null, lastName?: string | null }> } | null, user?: { __typename?: 'User', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null } | null, packageSets: Array<{ __typename?: 'PackageSet', name: string }> }>, aggregateOrder: { __typename?: 'AggregateOrder', _count?: { __typename?: 'OrderCountAggregate', _all: number } | null } };

export type UserOrderQueryVariables = Exact<{
  where: OrderWhereUniqueInput;
}>;


export type UserOrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', beamInputs: Array<{ __typename?: 'BeamInput', id: string, typeId: string }> } | null };

export type UserOrdersQueryVariables = Exact<{
  where?: InputMaybe<OrderWhereInput>;
}>;


export type UserOrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', organization?: { __typename?: 'Organization', name: string } | null, beamInputs: Array<{ __typename?: 'BeamInput', id: string, typeId: string, files: Array<{ __typename?: 'File', id: string, url: string, name: string, createdAt: any }> }>, results: Array<{ __typename?: 'Result', searchType?: ResultSearchType | null, attachments: Array<{ __typename?: 'File', id: string, url: string, type?: string | null, createdAt: any }> }>, disclosureAcceptances: Array<{ __typename?: 'DisclosureAcceptance', disclosures: Array<{ __typename?: 'Disclosure', id: string, name: string, createdAt: any }>, pdf?: { __typename?: 'File', url: string } | null }> }> };

export type OrdersQueryVariables = Exact<{
  where?: InputMaybe<OrderWhereInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput> | OrderOrderByWithRelationInput>;
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum> | OrderScalarFieldEnum>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: string, fileNumber: string, deliveryMethod?: OrderDeliveryMethod | null, status?: OrderStatusEnum | null, score?: OrderScoreEnum | null, createdAt: any, reportableResults?: any | null, reportCompletedAt?: any | null, files: Array<{ __typename?: 'File', id: string, createdAt: any, name: string, url: string }>, organization?: { __typename?: 'Organization', id: string, name: string } | null, applicantData?: { __typename?: 'ApplicantData', id: string, dateOfBirth: string, email?: string | null, phoneNumber?: string | null, createdAt: any, updatedAt: any, orderId: string, ssn?: { __typename?: 'HighlySensitiveIdentifier', value: string } | null, names: Array<{ __typename?: 'Name', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, type?: NameTypeEnum | null, userId?: string | null }>, addresses: Array<{ __typename?: 'Address', id: string, userId?: string | null, addressLineOne: string, addressLineTwo?: string | null, city: string, state: string, postalCode: string, country: string, type?: AddressTypeEnum | null, createdAt: any, updatedAt: any, organizationId?: string | null }> } | null }> };

export type CreateManyOrderMutationVariables = Exact<{
  applicants: Array<Scalars['JSON']> | Scalars['JSON'];
  deliveryMethod: OrderDeliveryMethod;
  packageId: Scalars['String'];
  organizationId: Scalars['String'];
  orderFrom?: InputMaybe<Platform>;
}>;


export type CreateManyOrderMutation = { __typename?: 'Mutation', createManyOrder: { __typename?: 'StatusResponse', status: boolean } };

export type UpdateOrderMutationVariables = Exact<{
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder: { __typename?: 'Order', id: string, fileNumber: string, deliveryMethod?: OrderDeliveryMethod | null, status?: OrderStatusEnum | null, score?: OrderScoreEnum | null, createdAt: any, reportableResults?: any | null, reportCompletedAt?: any | null, files: Array<{ __typename?: 'File', id: string, createdAt: any, name: string, url: string }>, organization?: { __typename?: 'Organization', id: string, name: string } | null, applicantData?: { __typename?: 'ApplicantData', id: string, dateOfBirth: string, email?: string | null, phoneNumber?: string | null, createdAt: any, updatedAt: any, orderId: string, ssn?: { __typename?: 'HighlySensitiveIdentifier', value: string } | null, names: Array<{ __typename?: 'Name', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, type?: NameTypeEnum | null, userId?: string | null }>, addresses: Array<{ __typename?: 'Address', id: string, userId?: string | null, addressLineOne: string, addressLineTwo?: string | null, city: string, state: string, postalCode: string, country: string, type?: AddressTypeEnum | null, createdAt: any, updatedAt: any, organizationId?: string | null }> } | null } };

export type CreateOrderMutationVariables = Exact<{
  data: ExtendedOrderCreateInput;
  orderFrom?: InputMaybe<Platform>;
  stateIdentificationCardId?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  beamData?: InputMaybe<Array<OrderBeamData> | OrderBeamData>;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'Order', id: string, fileNumber: string } };

export type OrganizationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OrganizationQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationRes', id?: string | null, name?: string | null, logoUrl?: string | null } };

export type AffiliateOrgToQueryVariables = Exact<{
  where?: InputMaybe<OrganizationWhereInput>;
}>;


export type AffiliateOrgToQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', id: string, name: string }> };

export type PackagesForFiltersQueryVariables = Exact<{
  where?: InputMaybe<PackageSetWhereInput>;
}>;


export type PackagesForFiltersQuery = { __typename?: 'Query', packageSets: Array<{ __typename?: 'PackageSet', id: string, name: string, description?: string | null }> };

export type GetPackageNameQueryVariables = Exact<{
  where: PackageSetWhereUniqueInput;
}>;


export type GetPackageNameQuery = { __typename?: 'Query', packageSet?: { __typename?: 'PackageSet', id: string, name: string } | null };

export type PackageSetQueryVariables = Exact<{
  where: PackageSetWhereUniqueInput;
}>;


export type PackageSetQuery = { __typename?: 'Query', packageSet?: { __typename?: 'PackageSet', id: string, name: string, metadata?: any | null } | null };

export type PackageSetsQueryVariables = Exact<{
  where?: InputMaybe<PackageSetWhereInput>;
  orderBy?: InputMaybe<Array<PackageSetOrderByWithRelationInput> | PackageSetOrderByWithRelationInput>;
  cursor?: InputMaybe<PackageSetWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  distinct?: InputMaybe<Array<PackageSetScalarFieldEnum> | PackageSetScalarFieldEnum>;
}>;


export type PackageSetsQuery = { __typename?: 'Query', packageSets: Array<{ __typename?: 'PackageSet', id: string, name: string, organization?: { __typename?: 'Organization', id: string, name: string } | null, searchTypes: Array<{ __typename?: 'SearchType', id: string, name: string }> }> };

export type PassTypeQueryVariables = Exact<{
  where: PassTypeWhereUniqueInput;
}>;


export type PassTypeQuery = { __typename?: 'Query', passType?: { __typename?: 'PassType', id: string, slug: string, brandColor?: string | null, organizationId: string, createdAt: any } | null };

export type PassDetailQueryVariables = Exact<{
  where: PassWhereUniqueInput;
}>;


export type PassDetailQuery = { __typename?: 'Query', pass?: { __typename?: 'Pass', id: string, passType?: { __typename?: 'PassType', id: string, name: string, organization: { __typename?: 'Organization', id: string, name: string, legalName: string } } | null, inputs: Array<{ __typename?: 'BeamInput', id: string, createdAt: any, type: { __typename?: 'BeamInputType', beam: { __typename?: 'Beam', id: string, name: string } }, credential?: { __typename?: 'Credential', id: string, status?: { __typename?: 'CredentialStatus', value: CredentialStatusEnum } | null } | null }>, user: { __typename?: 'User', id: string, email?: string | null, names: Array<{ __typename?: 'Name', firstName?: string | null, lastName?: string | null, middleName?: string | null }>, stateIdentificationCards: Array<{ __typename?: 'StateIdentificationCard', selfieImage?: { __typename?: 'File', url: string } | null }>, passports: Array<{ __typename?: 'Passport', selfieImage?: { __typename?: 'File', url: string } | null }> } } | null };

export type PassesQueryVariables = Exact<{
  where?: InputMaybe<PassWhereInput>;
}>;


export type PassesQuery = { __typename?: 'Query', passes: Array<{ __typename?: 'Pass', createdAt: any, id: string, passType?: { __typename?: 'PassType', name: string, organization: { __typename?: 'Organization', name: string } } | null, inputs: Array<{ __typename?: 'BeamInput', credential?: { __typename?: 'Credential', id: string, status?: { __typename?: 'CredentialStatus', value: CredentialStatusEnum } | null } | null }> }> };

export type PassTypesQueryVariables = Exact<{
  where?: InputMaybe<PassTypeWhereInput>;
}>;


export type PassTypesQuery = { __typename?: 'Query', passTypes: Array<{ __typename?: 'PassType', name: string, id: string }> };

export type PassQueryVariables = Exact<{
  where: PassWhereUniqueInput;
}>;


export type PassQuery = { __typename?: 'Query', pass?: { __typename?: 'Pass', updatedAt: any, id: string, passType?: { __typename?: 'PassType', brandColor?: string | null, id: string, name: string, organization: { __typename?: 'Organization', id: string, logoUrl?: string | null, name: string } } | null, inputs: Array<{ __typename?: 'BeamInput', id: string, data: any, type: { __typename?: 'BeamInputType', beam: { __typename?: 'Beam', name: string } }, credential?: { __typename?: 'Credential', id: string, data: any, status?: { __typename?: 'CredentialStatus', createdAt: any, updatedAt: any, value: CredentialStatusEnum } | null, type: { __typename?: 'CredentialType', createdAt: any, name: string, beam: { __typename?: 'Beam', name: string } } } | null, order?: { __typename?: 'Order', createdAt: any } | null }> } | null };

export type CreateOneShareOrderRecordMutationVariables = Exact<{
  data: ShareOrderRecordCreateInput;
}>;


export type CreateOneShareOrderRecordMutation = { __typename?: 'Mutation', createOneShareOrderRecord: { __typename?: 'ShareOrderRecord', id: string, userId: string, orderId: string, organizationId: string, createdAt: any, updatedAt: any } };

export type UpdateUserMutationVariables = Exact<{
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string } };

export type UpdateOneUserMutationVariables = Exact<{
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
}>;


export type UpdateOneUserMutation = { __typename?: 'Mutation', updateOneUser?: { __typename?: 'User', id: string } | null };

export type UserCardQueryVariables = Exact<{
  where: UserWhereUniqueInput;
  orderWhere: OrderWhereInput;
}>;


export type UserCardQuery = { __typename?: 'Query', user?: { __typename?: 'User', dob?: string | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, myOrders: Array<{ __typename?: 'Order', score?: OrderScoreEnum | null }>, stateIdentificationCards: Array<{ __typename?: 'StateIdentificationCard', id: string, address?: { __typename?: 'Address', addressLineOne: string, addressLineTwo?: string | null, city: string, country: string, state: string, postalCode: string } | null, selfieImage?: { __typename?: 'File', id: string } | null }> } | null };

export type SelfieImageQueryVariables = Exact<{
  where: FileWhereUniqueInput;
}>;


export type SelfieImageQuery = { __typename?: 'Query', selfieImage?: { __typename?: 'File', id: string, url: string } | null };

export type CreateOneStateIdentificationCardMutationVariables = Exact<{
  data: StateIdentificationCardCreateInput;
}>;


export type CreateOneStateIdentificationCardMutation = { __typename?: 'Mutation', createOneStateIdentificationCard: { __typename?: 'StateIdentificationCard', id: string } };

export type UpdateOnePassportMutationVariables = Exact<{
  data: PassportUpdateInput;
  where: PassportWhereUniqueInput;
}>;


export type UpdateOnePassportMutation = { __typename?: 'Mutation', updateOnePassport?: { __typename?: 'Passport', id: string } | null };

export type CreateOnePassportMutationVariables = Exact<{
  data: PassportCreateInput;
}>;


export type CreateOnePassportMutation = { __typename?: 'Mutation', createOnePassport: { __typename?: 'Passport', id: string } };

export type UpdateOneStateIdentificationCardMutationVariables = Exact<{
  where: StateIdentificationCardWhereUniqueInput;
  data: StateIdentificationCardUpdateInput;
}>;


export type UpdateOneStateIdentificationCardMutation = { __typename?: 'Mutation', updateOneStateIdentificationCard?: { __typename?: 'StateIdentificationCard', id: string } | null };

export type AcceptInviteMutationVariables = Exact<{
  inviteId: Scalars['String'];
}>;


export type AcceptInviteMutation = { __typename?: 'Mutation', acceptInvite?: { __typename?: 'InviteRes', email: string, id: string, organizationId: string, redeemedAt?: string | null, redeemedById?: string | null, senderId: string, vidVoucherId?: string | null } | null };

export type VidVoucherQueryVariables = Exact<{
  where: VidVoucherWhereUniqueInput;
}>;


export type VidVoucherQuery = { __typename?: 'Query', vIDVoucher?: { __typename?: 'VIDVoucher', clientMetadata?: any | null, createdAt: any, email: string, id: string, inviteId?: string | null, orderId?: string | null, organizationId: string, packageSetId: string, postbackUrl?: string | null, status: VoucherStatusEnum, updatedAt: any, passTypeId?: string | null, userId?: string | null } | null };

export type VidVouchersQueryVariables = Exact<{
  where: VidVoucherWhereInput;
  orderBy?: InputMaybe<Array<VidVoucherOrderByWithRelationInput> | VidVoucherOrderByWithRelationInput>;
  cursor?: InputMaybe<VidVoucherWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  packageSetsWhere: PackageSetWhereInput;
}>;


export type VidVouchersQuery = { __typename?: 'Query', vIDVouchers: Array<{ __typename?: 'VIDVoucher', branchIOShortCode?: string | null, clientMetadata?: any | null, createdAt: any, dateOfBirth?: string | null, email: string, fileNumber?: string | null, firstName?: string | null, id: string, inviteId?: string | null, lastName?: string | null, middleName?: string | null, orderId?: string | null, organizationId: string, packageSetId: string, phoneNumber?: string | null, postbackUrl?: string | null, primaryContactMethod?: UserPrimaryContactEnum | null, status: VoucherStatusEnum, suffix?: string | null, updatedAt: any, userId?: string | null }>, packageSets: Array<{ __typename?: 'PackageSet', description?: string | null, id: string, name: string }>, aggregateVIDVoucher: { __typename?: 'AggregateVIDVoucher', _count?: { __typename?: 'VIDVoucherCountAggregate', _all: number } | null } };

export type GetInviteCountQueryVariables = Exact<{
  where?: InputMaybe<VidVoucherWhereInput>;
}>;


export type GetInviteCountQuery = { __typename?: 'Query', aggregateVIDVoucher: { __typename?: 'AggregateVIDVoucher', _count?: { __typename?: 'VIDVoucherCountAggregate', _all: number } | null } };

export type CheckVoucherValidityQueryVariables = Exact<{
  voucherId: Scalars['String'];
}>;


export type CheckVoucherValidityQuery = { __typename?: 'Query', checkVoucherValidity?: { __typename?: 'VIDVoucher', clientMetadata?: any | null, createdAt: any, email: string, id: string, inviteId?: string | null, orderId?: string | null, organizationId: string, packageSetId: string, postbackUrl?: string | null, status: VoucherStatusEnum, updatedAt: any, passTypeId?: string | null, userId?: string | null, packageSet: { __typename?: 'PackageSet', id: string, collectSSN?: boolean | null, showDisclosures?: boolean | null, useSampleId?: boolean | null } } | null };

export type GetInviteQueryVariables = Exact<{
  inviteId: Scalars['String'];
}>;


export type GetInviteQuery = { __typename?: 'Query', getInvite?: { __typename?: 'InviteRes', redeemedById?: string | null, redeemedAt?: string | null } | null };

export type DeleteOneVidVoucherMutationVariables = Exact<{
  where: VidVoucherWhereUniqueInput;
}>;


export type DeleteOneVidVoucherMutation = { __typename?: 'Mutation', deleteOneVIDVoucher?: { __typename?: 'VIDVoucher', id: string, userId?: string | null, updatedAt: any, status: VoucherStatusEnum, postbackUrl?: string | null, packageSetId: string, organizationId: string, orderId?: string | null, inviteId?: string | null, email: string, createdAt: any } | null };

export type CreateOneVidVoucherMutationVariables = Exact<{
  data: CreateOneVidVoucherInput;
}>;


export type CreateOneVidVoucherMutation = { __typename?: 'Mutation', createOneVIDVoucher?: { __typename?: 'CreateOneVidVoucherRes', inviteExpiry: string, inviteId: string, packageId: string, packageName: string, vIDInviteUrl: string, vIDQRCode: string, vIDShortCode: string, inviteData: { __typename?: 'InviteDataOutputRes', contactInformation: { __typename?: 'ContactInformationOutput', email: string, phoneNumber: string } } } | null };

export type CreateManyVidVoucherMutationVariables = Exact<{
  data: Array<CreateOneVidVoucherInput> | CreateOneVidVoucherInput;
}>;


export type CreateManyVidVoucherMutation = { __typename?: 'Mutation', createManyVIDVoucher?: { __typename?: 'CreateManyVidVoucherRes', data: Array<{ __typename?: 'CreateOneVidVoucherRes', inviteId: string, packageId: string, packageName: string, vIDInviteUrl: string, vIDQRCode: string, vIDShortCode: string }>, errors?: Array<{ __typename?: 'InviteErrorResponse', error: string, email: string }> | null } | null };

export type CreateOneVidWebVoucherMutationVariables = Exact<{
  data: CreateOneVidVoucherInput;
}>;


export type CreateOneVidWebVoucherMutation = { __typename?: 'Mutation', createOneVIDWebVoucher?: { __typename?: 'CreateOneVidWebVoucherRes', inviteId: string, packageId: string, packageName: string, vIDInviteUrl: string, vIDQRCode: string, vIDShortCode: string } | null };

export const DashboardOrderListFragmentDoc = `
    fragment DashboardOrderList on Order {
  id
  fileNumber
  status
  score
  alert
  deliveryMethod
  createdAt
  applicantData {
    id
    names {
      firstName
      middleName
      lastName
    }
  }
  user {
    id
    firstName
    middleName
    lastName
  }
  packageSets {
    name
  }
}
    `;
export const ApplicantDataFragmentDoc = `
    fragment ApplicantData on ApplicantData {
  id
  dateOfBirth
  ssn {
    value
  }
  email
  phoneNumber
  createdAt
  updatedAt
  orderId
  names {
    id
    firstName
    middleName
    lastName
    type
    userId
  }
  addresses {
    id
    userId
    addressLineOne
    addressLineTwo
    city
    state
    postalCode
    country
    type
    createdAt
    updatedAt
    organizationId
  }
}
    `;
export const OrderFragmentDoc = `
    fragment Order on Order {
  id
  fileNumber
  deliveryMethod
  status
  score
  createdAt
  files {
    id
    createdAt
    name
    url
  }
  organization {
    id
    name
  }
  applicantData {
    ...ApplicantData
  }
  reportableResults
  reportCompletedAt
}
    ${ApplicantDataFragmentDoc}`;
export const SearchTypeFragmentDoc = `
    fragment SearchType on SearchType {
  id
  name
}
    `;
export const PackageSetFragmentDoc = `
    fragment PackageSet on PackageSet {
  id
  name
  organization {
    id
    name
  }
  searchTypes {
    ...SearchType
  }
}
    ${SearchTypeFragmentDoc}`;
export const OrganizationResFragmentDoc = `
    fragment OrganizationRes on OrganizationRes {
  id
  name
  logoUrl
}
    `;
export const BeamInputTypeFragmentDoc = `
    fragment BeamInputType on BeamInputType {
  id
  slug
  beamId
  schema
  uiSchema
  version
  createdAt
  organizationId
}
    `;
export const BeamFragmentDoc = `
    fragment Beam on Beam {
  id
  name
  description
  organizationId
  updatedAt
  createdAt
  processor
  config
  processorConfig
  inputTypes {
    ...BeamInputType
  }
}
    ${BeamInputTypeFragmentDoc}`;
export const ShareOrderRecordFragmentDoc = `
    fragment ShareOrderRecord on ShareOrderRecord {
  id
  userId
  orderId
  organizationId
  createdAt
  updatedAt
}
    `;
export const MeDocument = `
    query me {
  me {
    id
    email
    firstName
    lastName
    dob
    organizations {
      id
    }
    selfie {
      bucket
      url
    }
    names {
      middleName
      lastName
      firstName
      type
    }
    ssn {
      id
    }
  }
}
    `;
export const useMeQuery = <
      TData = MeQuery,
      TError = unknown
    >(
      variables?: MeQueryVariables,
      options?: UseQueryOptions<MeQuery, TError, TData>
    ) =>
    useQuery<MeQuery, TError, TData>(
      variables === undefined ? ['me'] : ['me', variables],
      useFetchData<MeQuery, MeQueryVariables>(MeDocument).bind(null, variables),
      options
    );
export const BeamDocument = `
    query Beam($where: BeamWhereUniqueInput!) {
  beam(where: $where) {
    ...Beam
  }
}
    ${BeamFragmentDoc}`;
export const useBeamQuery = <
      TData = BeamQuery,
      TError = unknown
    >(
      variables: BeamQueryVariables,
      options?: UseQueryOptions<BeamQuery, TError, TData>
    ) =>
    useQuery<BeamQuery, TError, TData>(
      ['Beam', variables],
      useFetchData<BeamQuery, BeamQueryVariables>(BeamDocument).bind(null, variables),
      options
    );
export const BeamListDocument = `
    query BeamList($where: BeamWhereInput, $take: Int, $orderBy: [BeamInputTypeOrderByWithRelationInput!]) {
  beams(where: $where) {
    id
    name
    description
    organizationId
    updatedAt
    createdAt
    processor
    config
    processorConfig
    inputTypes(take: $take, orderBy: $orderBy) {
      id
      slug
      beamId
      schema
      uiSchema
      version
      createdAt
      organizationId
    }
  }
}
    `;
export const useBeamListQuery = <
      TData = BeamListQuery,
      TError = unknown
    >(
      variables?: BeamListQueryVariables,
      options?: UseQueryOptions<BeamListQuery, TError, TData>
    ) =>
    useQuery<BeamListQuery, TError, TData>(
      variables === undefined ? ['BeamList'] : ['BeamList', variables],
      useFetchData<BeamListQuery, BeamListQueryVariables>(BeamListDocument).bind(null, variables),
      options
    );
export const ExtractBeamDataFromImageDocument = `
    mutation extractBeamDataFromImage($imageUrl: String!, $beamInputTypeId: String!) {
  extractBeamDataFromImage(imageUrl: $imageUrl, beamInputTypeId: $beamInputTypeId)
}
    `;
export const useExtractBeamDataFromImageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ExtractBeamDataFromImageMutation, TError, ExtractBeamDataFromImageMutationVariables, TContext>) =>
    useMutation<ExtractBeamDataFromImageMutation, TError, ExtractBeamDataFromImageMutationVariables, TContext>(
      ['extractBeamDataFromImage'],
      useFetchData<ExtractBeamDataFromImageMutation, ExtractBeamDataFromImageMutationVariables>(ExtractBeamDataFromImageDocument),
      options
    );
export const BeamInputTypeDocument = `
    query BeamInputType($where: BeamInputTypeWhereUniqueInput!) {
  beamInputType(where: $where) {
    ...BeamInputType
  }
}
    ${BeamInputTypeFragmentDoc}`;
export const useBeamInputTypeQuery = <
      TData = BeamInputTypeQuery,
      TError = unknown
    >(
      variables: BeamInputTypeQueryVariables,
      options?: UseQueryOptions<BeamInputTypeQuery, TError, TData>
    ) =>
    useQuery<BeamInputTypeQuery, TError, TData>(
      ['BeamInputType', variables],
      useFetchData<BeamInputTypeQuery, BeamInputTypeQueryVariables>(BeamInputTypeDocument).bind(null, variables),
      options
    );
export const BeamInputTypesListDocument = `
    query BeamInputTypesList($where: BeamInputTypeWhereInput, $orderBy: [BeamInputTypeOrderByWithRelationInput!], $take: Int, $skip: Int, $distinct: [BeamInputTypeScalarFieldEnum!]) {
  beamInputTypes(
    where: $where
    orderBy: $orderBy
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...BeamInputType
  }
}
    ${BeamInputTypeFragmentDoc}`;
export const useBeamInputTypesListQuery = <
      TData = BeamInputTypesListQuery,
      TError = unknown
    >(
      variables?: BeamInputTypesListQueryVariables,
      options?: UseQueryOptions<BeamInputTypesListQuery, TError, TData>
    ) =>
    useQuery<BeamInputTypesListQuery, TError, TData>(
      variables === undefined ? ['BeamInputTypesList'] : ['BeamInputTypesList', variables],
      useFetchData<BeamInputTypesListQuery, BeamInputTypesListQueryVariables>(BeamInputTypesListDocument).bind(null, variables),
      options
    );
export const CredentialsDocument = `
    query credentials($where: CredentialWhereInput) {
  credentials(where: $where) {
    id
    createdAt
    status {
      id
      value
    }
    input {
      type {
        beam {
          name
        }
      }
    }
    type {
      id
      name
    }
    pass {
      passType {
        name
      }
    }
  }
}
    `;
export const useCredentialsQuery = <
      TData = CredentialsQuery,
      TError = unknown
    >(
      variables?: CredentialsQueryVariables,
      options?: UseQueryOptions<CredentialsQuery, TError, TData>
    ) =>
    useQuery<CredentialsQuery, TError, TData>(
      variables === undefined ? ['credentials'] : ['credentials', variables],
      useFetchData<CredentialsQuery, CredentialsQueryVariables>(CredentialsDocument).bind(null, variables),
      options
    );
export const RetrieveDisclosuresDocument = `
    query RetrieveDisclosures($packageId: String!, $addresses: [DisclosureAddress!]!) {
  retrieveDisclosures(packageId: $packageId, addresses: $addresses) {
    id
    location
    locationType
    name
    text
    type
  }
}
    `;
export const useRetrieveDisclosuresQuery = <
      TData = RetrieveDisclosuresQuery,
      TError = unknown
    >(
      variables: RetrieveDisclosuresQueryVariables,
      options?: UseQueryOptions<RetrieveDisclosuresQuery, TError, TData>
    ) =>
    useQuery<RetrieveDisclosuresQuery, TError, TData>(
      ['RetrieveDisclosures', variables],
      useFetchData<RetrieveDisclosuresQuery, RetrieveDisclosuresQueryVariables>(RetrieveDisclosuresDocument).bind(null, variables),
      options
    );
export const CreateDisclosureAcceptanceDocument = `
    mutation createDisclosureAcceptance($data: DisclosureAcceptanceArgs!) {
  createDisclosureAcceptance(data: $data) {
    userId
    updatedAt
    signatureSvg
    orderId
    id
    createdAt
  }
}
    `;
export const useCreateDisclosureAcceptanceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDisclosureAcceptanceMutation, TError, CreateDisclosureAcceptanceMutationVariables, TContext>) =>
    useMutation<CreateDisclosureAcceptanceMutation, TError, CreateDisclosureAcceptanceMutationVariables, TContext>(
      ['createDisclosureAcceptance'],
      useFetchData<CreateDisclosureAcceptanceMutation, CreateDisclosureAcceptanceMutationVariables>(CreateDisclosureAcceptanceDocument),
      options
    );
export const CreateUploadSignedUrlDocument = `
    mutation CreateUploadSignedURL($fileId: String!) {
  createUploadSignedURL(fileId: $fileId) {
    url
  }
}
    `;
export const useCreateUploadSignedUrlMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUploadSignedUrlMutation, TError, CreateUploadSignedUrlMutationVariables, TContext>) =>
    useMutation<CreateUploadSignedUrlMutation, TError, CreateUploadSignedUrlMutationVariables, TContext>(
      ['CreateUploadSignedURL'],
      useFetchData<CreateUploadSignedUrlMutation, CreateUploadSignedUrlMutationVariables>(CreateUploadSignedUrlDocument),
      options
    );
export const CreateMultipleUploadSignedUrlDocument = `
    mutation CreateMultipleUploadSignedURL($files: [MultipleSignedURLFileInputV2!]!) {
  createMultipleUploadSignedURL(files: $files) {
    url
  }
}
    `;
export const useCreateMultipleUploadSignedUrlMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateMultipleUploadSignedUrlMutation, TError, CreateMultipleUploadSignedUrlMutationVariables, TContext>) =>
    useMutation<CreateMultipleUploadSignedUrlMutation, TError, CreateMultipleUploadSignedUrlMutationVariables, TContext>(
      ['CreateMultipleUploadSignedURL'],
      useFetchData<CreateMultipleUploadSignedUrlMutation, CreateMultipleUploadSignedUrlMutationVariables>(CreateMultipleUploadSignedUrlDocument),
      options
    );
export const CreateOneFileDocument = `
    mutation CreateOneFile($data: FileCreateInput!) {
  createOneFile(data: $data) {
    id
  }
}
    `;
export const useCreateOneFileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOneFileMutation, TError, CreateOneFileMutationVariables, TContext>) =>
    useMutation<CreateOneFileMutation, TError, CreateOneFileMutationVariables, TContext>(
      ['CreateOneFile'],
      useFetchData<CreateOneFileMutation, CreateOneFileMutationVariables>(CreateOneFileDocument),
      options
    );
export const CreateOneHighlySensitiveIdentifierDocument = `
    mutation CreateOneHighlySensitiveIdentifier($data: HighlySensitiveIdentifierCreateInput!) {
  createOneHighlySensitiveIdentifier(data: $data) {
    id
  }
}
    `;
export const useCreateOneHighlySensitiveIdentifierMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOneHighlySensitiveIdentifierMutation, TError, CreateOneHighlySensitiveIdentifierMutationVariables, TContext>) =>
    useMutation<CreateOneHighlySensitiveIdentifierMutation, TError, CreateOneHighlySensitiveIdentifierMutationVariables, TContext>(
      ['CreateOneHighlySensitiveIdentifier'],
      useFetchData<CreateOneHighlySensitiveIdentifierMutation, CreateOneHighlySensitiveIdentifierMutationVariables>(CreateOneHighlySensitiveIdentifierDocument),
      options
    );
export const CreateOneNameDocument = `
    mutation CreateOneName($data: NameCreateInput!) {
  createOneName(data: $data) {
    id
  }
}
    `;
export const useCreateOneNameMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOneNameMutation, TError, CreateOneNameMutationVariables, TContext>) =>
    useMutation<CreateOneNameMutation, TError, CreateOneNameMutationVariables, TContext>(
      ['CreateOneName'],
      useFetchData<CreateOneNameMutation, CreateOneNameMutationVariables>(CreateOneNameDocument),
      options
    );
export const OrderListDocument = `
    query orderList($where: OrderWhereInput, $orderBy: [OrderOrderByWithRelationInput!], $cursor: OrderWhereUniqueInput, $take: Int, $skip: Int, $distinct: [OrderScalarFieldEnum!]) {
  orders(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...DashboardOrderList
  }
  aggregateOrder(where: $where) {
    _count {
      _all
    }
  }
}
    ${DashboardOrderListFragmentDoc}`;
export const useOrderListQuery = <
      TData = OrderListQuery,
      TError = unknown
    >(
      variables?: OrderListQueryVariables,
      options?: UseQueryOptions<OrderListQuery, TError, TData>
    ) =>
    useQuery<OrderListQuery, TError, TData>(
      variables === undefined ? ['orderList'] : ['orderList', variables],
      useFetchData<OrderListQuery, OrderListQueryVariables>(OrderListDocument).bind(null, variables),
      options
    );
export const UserOrderDocument = `
    query UserOrder($where: OrderWhereUniqueInput!) {
  order(where: $where) {
    beamInputs {
      id
      typeId
    }
  }
}
    `;
export const useUserOrderQuery = <
      TData = UserOrderQuery,
      TError = unknown
    >(
      variables: UserOrderQueryVariables,
      options?: UseQueryOptions<UserOrderQuery, TError, TData>
    ) =>
    useQuery<UserOrderQuery, TError, TData>(
      ['UserOrder', variables],
      useFetchData<UserOrderQuery, UserOrderQueryVariables>(UserOrderDocument).bind(null, variables),
      options
    );
export const UserOrdersDocument = `
    query UserOrders($where: OrderWhereInput) {
  orders(where: $where) {
    organization {
      name
    }
    beamInputs {
      id
      typeId
      files {
        id
        url
        name
        createdAt
      }
    }
    results {
      searchType
      attachments {
        id
        url
        type
        createdAt
      }
    }
    disclosureAcceptances {
      disclosures {
        id
        name
        createdAt
      }
      pdf {
        url
      }
    }
  }
}
    `;
export const useUserOrdersQuery = <
      TData = UserOrdersQuery,
      TError = unknown
    >(
      variables?: UserOrdersQueryVariables,
      options?: UseQueryOptions<UserOrdersQuery, TError, TData>
    ) =>
    useQuery<UserOrdersQuery, TError, TData>(
      variables === undefined ? ['UserOrders'] : ['UserOrders', variables],
      useFetchData<UserOrdersQuery, UserOrdersQueryVariables>(UserOrdersDocument).bind(null, variables),
      options
    );
export const OrdersDocument = `
    query orders($where: OrderWhereInput, $orderBy: [OrderOrderByWithRelationInput!], $cursor: OrderWhereUniqueInput, $take: Int, $skip: Int, $distinct: [OrderScalarFieldEnum!]) {
  orders(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const useOrdersQuery = <
      TData = OrdersQuery,
      TError = unknown
    >(
      variables?: OrdersQueryVariables,
      options?: UseQueryOptions<OrdersQuery, TError, TData>
    ) =>
    useQuery<OrdersQuery, TError, TData>(
      variables === undefined ? ['orders'] : ['orders', variables],
      useFetchData<OrdersQuery, OrdersQueryVariables>(OrdersDocument).bind(null, variables),
      options
    );
export const CreateManyOrderDocument = `
    mutation createManyOrder($applicants: [JSON!]!, $deliveryMethod: OrderDeliveryMethod!, $packageId: String!, $organizationId: String!, $orderFrom: Platform) {
  createManyOrder(
    applicants: $applicants
    deliveryMethod: $deliveryMethod
    packageId: $packageId
    organizationId: $organizationId
    orderFrom: $orderFrom
  ) {
    status
  }
}
    `;
export const useCreateManyOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateManyOrderMutation, TError, CreateManyOrderMutationVariables, TContext>) =>
    useMutation<CreateManyOrderMutation, TError, CreateManyOrderMutationVariables, TContext>(
      ['createManyOrder'],
      useFetchData<CreateManyOrderMutation, CreateManyOrderMutationVariables>(CreateManyOrderDocument),
      options
    );
export const UpdateOrderDocument = `
    mutation updateOrder($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!) {
  updateOrder(data: $data, where: $where) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const useUpdateOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrderMutation, TError, UpdateOrderMutationVariables, TContext>) =>
    useMutation<UpdateOrderMutation, TError, UpdateOrderMutationVariables, TContext>(
      ['updateOrder'],
      useFetchData<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument),
      options
    );
export const CreateOrderDocument = `
    mutation CreateOrder($data: ExtendedOrderCreateInput!, $orderFrom: Platform, $stateIdentificationCardId: String, $documentId: String, $documentType: String, $beamData: [OrderBeamData!]) {
  createOrder(
    data: $data
    orderFrom: $orderFrom
    stateIdentificationCardId: $stateIdentificationCardId
    documentId: $documentId
    documentType: $documentType
    beamData: $beamData
  ) {
    id
    fileNumber
  }
}
    `;
export const useCreateOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOrderMutation, TError, CreateOrderMutationVariables, TContext>) =>
    useMutation<CreateOrderMutation, TError, CreateOrderMutationVariables, TContext>(
      ['CreateOrder'],
      useFetchData<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument),
      options
    );
export const OrganizationDocument = `
    query organization($id: String!) {
  organization(id: $id) {
    ...OrganizationRes
  }
}
    ${OrganizationResFragmentDoc}`;
export const useOrganizationQuery = <
      TData = OrganizationQuery,
      TError = unknown
    >(
      variables: OrganizationQueryVariables,
      options?: UseQueryOptions<OrganizationQuery, TError, TData>
    ) =>
    useQuery<OrganizationQuery, TError, TData>(
      ['organization', variables],
      useFetchData<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument).bind(null, variables),
      options
    );
export const AffiliateOrgToDocument = `
    query affiliateOrgTo($where: OrganizationWhereInput) {
  organizations(where: $where) {
    id
    name
  }
}
    `;
export const useAffiliateOrgToQuery = <
      TData = AffiliateOrgToQuery,
      TError = unknown
    >(
      variables?: AffiliateOrgToQueryVariables,
      options?: UseQueryOptions<AffiliateOrgToQuery, TError, TData>
    ) =>
    useQuery<AffiliateOrgToQuery, TError, TData>(
      variables === undefined ? ['affiliateOrgTo'] : ['affiliateOrgTo', variables],
      useFetchData<AffiliateOrgToQuery, AffiliateOrgToQueryVariables>(AffiliateOrgToDocument).bind(null, variables),
      options
    );
export const PackagesForFiltersDocument = `
    query packagesForFilters($where: PackageSetWhereInput) {
  packageSets(where: $where) {
    id
    name
    description
  }
}
    `;
export const usePackagesForFiltersQuery = <
      TData = PackagesForFiltersQuery,
      TError = unknown
    >(
      variables?: PackagesForFiltersQueryVariables,
      options?: UseQueryOptions<PackagesForFiltersQuery, TError, TData>
    ) =>
    useQuery<PackagesForFiltersQuery, TError, TData>(
      variables === undefined ? ['packagesForFilters'] : ['packagesForFilters', variables],
      useFetchData<PackagesForFiltersQuery, PackagesForFiltersQueryVariables>(PackagesForFiltersDocument).bind(null, variables),
      options
    );
export const GetPackageNameDocument = `
    query GetPackageName($where: PackageSetWhereUniqueInput!) {
  packageSet(where: $where) {
    id
    name
  }
}
    `;
export const useGetPackageNameQuery = <
      TData = GetPackageNameQuery,
      TError = unknown
    >(
      variables: GetPackageNameQueryVariables,
      options?: UseQueryOptions<GetPackageNameQuery, TError, TData>
    ) =>
    useQuery<GetPackageNameQuery, TError, TData>(
      ['GetPackageName', variables],
      useFetchData<GetPackageNameQuery, GetPackageNameQueryVariables>(GetPackageNameDocument).bind(null, variables),
      options
    );
export const PackageSetDocument = `
    query packageSet($where: PackageSetWhereUniqueInput!) {
  packageSet(where: $where) {
    id
    name
    metadata
  }
}
    `;
export const usePackageSetQuery = <
      TData = PackageSetQuery,
      TError = unknown
    >(
      variables: PackageSetQueryVariables,
      options?: UseQueryOptions<PackageSetQuery, TError, TData>
    ) =>
    useQuery<PackageSetQuery, TError, TData>(
      ['packageSet', variables],
      useFetchData<PackageSetQuery, PackageSetQueryVariables>(PackageSetDocument).bind(null, variables),
      options
    );
export const PackageSetsDocument = `
    query packageSets($where: PackageSetWhereInput, $orderBy: [PackageSetOrderByWithRelationInput!], $cursor: PackageSetWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PackageSetScalarFieldEnum!]) {
  packageSets(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...PackageSet
  }
}
    ${PackageSetFragmentDoc}`;
export const usePackageSetsQuery = <
      TData = PackageSetsQuery,
      TError = unknown
    >(
      variables?: PackageSetsQueryVariables,
      options?: UseQueryOptions<PackageSetsQuery, TError, TData>
    ) =>
    useQuery<PackageSetsQuery, TError, TData>(
      variables === undefined ? ['packageSets'] : ['packageSets', variables],
      useFetchData<PackageSetsQuery, PackageSetsQueryVariables>(PackageSetsDocument).bind(null, variables),
      options
    );
export const PassTypeDocument = `
    query PassType($where: PassTypeWhereUniqueInput!) {
  passType(where: $where) {
    id
    slug
    brandColor
    organizationId
    createdAt
  }
}
    `;
export const usePassTypeQuery = <
      TData = PassTypeQuery,
      TError = unknown
    >(
      variables: PassTypeQueryVariables,
      options?: UseQueryOptions<PassTypeQuery, TError, TData>
    ) =>
    useQuery<PassTypeQuery, TError, TData>(
      ['PassType', variables],
      useFetchData<PassTypeQuery, PassTypeQueryVariables>(PassTypeDocument).bind(null, variables),
      options
    );
export const PassDetailDocument = `
    query passDetail($where: PassWhereUniqueInput!) {
  pass(where: $where) {
    id
    passType {
      id
      name
      organization {
        id
        name
        legalName
      }
    }
    inputs {
      id
      createdAt
      type {
        beam {
          id
          name
        }
      }
      credential {
        id
        status {
          value
        }
      }
    }
    user {
      id
      email
      names(where: {type: {equals: PRIMARY}}, take: 1) {
        firstName
        lastName
        middleName
      }
      stateIdentificationCards(take: 1, orderBy: {createdAt: desc}) {
        selfieImage {
          url
        }
      }
      passports(take: 1, orderBy: {createdAt: desc}) {
        selfieImage {
          url
        }
      }
    }
  }
}
    `;
export const usePassDetailQuery = <
      TData = PassDetailQuery,
      TError = unknown
    >(
      variables: PassDetailQueryVariables,
      options?: UseQueryOptions<PassDetailQuery, TError, TData>
    ) =>
    useQuery<PassDetailQuery, TError, TData>(
      ['passDetail', variables],
      useFetchData<PassDetailQuery, PassDetailQueryVariables>(PassDetailDocument).bind(null, variables),
      options
    );
export const PassesDocument = `
    query passes($where: PassWhereInput) {
  passes(where: $where) {
    createdAt
    id
    passType {
      name
      organization {
        name
      }
    }
    inputs {
      credential {
        id
        status {
          value
        }
      }
    }
  }
}
    `;
export const usePassesQuery = <
      TData = PassesQuery,
      TError = unknown
    >(
      variables?: PassesQueryVariables,
      options?: UseQueryOptions<PassesQuery, TError, TData>
    ) =>
    useQuery<PassesQuery, TError, TData>(
      variables === undefined ? ['passes'] : ['passes', variables],
      useFetchData<PassesQuery, PassesQueryVariables>(PassesDocument).bind(null, variables),
      options
    );
export const PassTypesDocument = `
    query PassTypes($where: PassTypeWhereInput) {
  passTypes(where: $where) {
    name
    id
  }
}
    `;
export const usePassTypesQuery = <
      TData = PassTypesQuery,
      TError = unknown
    >(
      variables?: PassTypesQueryVariables,
      options?: UseQueryOptions<PassTypesQuery, TError, TData>
    ) =>
    useQuery<PassTypesQuery, TError, TData>(
      variables === undefined ? ['PassTypes'] : ['PassTypes', variables],
      useFetchData<PassTypesQuery, PassTypesQueryVariables>(PassTypesDocument).bind(null, variables),
      options
    );
export const PassDocument = `
    query Pass($where: PassWhereUniqueInput!) {
  pass(where: $where) {
    passType {
      brandColor
      id
      name
      organization {
        id
        logoUrl
        name
      }
    }
    inputs {
      id
      data
      type {
        beam {
          name
        }
      }
      credential {
        id
        data
        status {
          createdAt
          updatedAt
          value
        }
        type {
          createdAt
          name
          beam {
            name
          }
        }
      }
      order {
        createdAt
      }
    }
    updatedAt
    id
  }
}
    `;
export const usePassQuery = <
      TData = PassQuery,
      TError = unknown
    >(
      variables: PassQueryVariables,
      options?: UseQueryOptions<PassQuery, TError, TData>
    ) =>
    useQuery<PassQuery, TError, TData>(
      ['Pass', variables],
      useFetchData<PassQuery, PassQueryVariables>(PassDocument).bind(null, variables),
      options
    );
export const CreateOneShareOrderRecordDocument = `
    mutation createOneShareOrderRecord($data: ShareOrderRecordCreateInput!) {
  createOneShareOrderRecord(data: $data) {
    ...ShareOrderRecord
  }
}
    ${ShareOrderRecordFragmentDoc}`;
export const useCreateOneShareOrderRecordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOneShareOrderRecordMutation, TError, CreateOneShareOrderRecordMutationVariables, TContext>) =>
    useMutation<CreateOneShareOrderRecordMutation, TError, CreateOneShareOrderRecordMutationVariables, TContext>(
      ['createOneShareOrderRecord'],
      useFetchData<CreateOneShareOrderRecordMutation, CreateOneShareOrderRecordMutationVariables>(CreateOneShareOrderRecordDocument),
      options
    );
export const UpdateUserDocument = `
    mutation UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateUser(data: $data, where: $where) {
    id
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['UpdateUser'],
      useFetchData<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument),
      options
    );
export const UpdateOneUserDocument = `
    mutation UpdateOneUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateOneUser(data: $data, where: $where) {
    id
  }
}
    `;
export const useUpdateOneUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOneUserMutation, TError, UpdateOneUserMutationVariables, TContext>) =>
    useMutation<UpdateOneUserMutation, TError, UpdateOneUserMutationVariables, TContext>(
      ['UpdateOneUser'],
      useFetchData<UpdateOneUserMutation, UpdateOneUserMutationVariables>(UpdateOneUserDocument),
      options
    );
export const UserCardDocument = `
    query UserCard($where: UserWhereUniqueInput!, $orderWhere: OrderWhereInput!) {
  user(where: $where) {
    dob
    firstName
    lastName
    middleName
    myOrders(where: $orderWhere) {
      score
    }
    stateIdentificationCards {
      address {
        addressLineOne
        addressLineTwo
        city
        country
        state
        postalCode
      }
      id
      selfieImage {
        id
      }
    }
  }
}
    `;
export const useUserCardQuery = <
      TData = UserCardQuery,
      TError = unknown
    >(
      variables: UserCardQueryVariables,
      options?: UseQueryOptions<UserCardQuery, TError, TData>
    ) =>
    useQuery<UserCardQuery, TError, TData>(
      ['UserCard', variables],
      useFetchData<UserCardQuery, UserCardQueryVariables>(UserCardDocument).bind(null, variables),
      options
    );
export const SelfieImageDocument = `
    query selfieImage($where: FileWhereUniqueInput!) {
  selfieImage: file(where: $where) {
    id
    url
  }
}
    `;
export const useSelfieImageQuery = <
      TData = SelfieImageQuery,
      TError = unknown
    >(
      variables: SelfieImageQueryVariables,
      options?: UseQueryOptions<SelfieImageQuery, TError, TData>
    ) =>
    useQuery<SelfieImageQuery, TError, TData>(
      ['selfieImage', variables],
      useFetchData<SelfieImageQuery, SelfieImageQueryVariables>(SelfieImageDocument).bind(null, variables),
      options
    );
export const CreateOneStateIdentificationCardDocument = `
    mutation CreateOneStateIdentificationCard($data: StateIdentificationCardCreateInput!) {
  createOneStateIdentificationCard(data: $data) {
    id
  }
}
    `;
export const useCreateOneStateIdentificationCardMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOneStateIdentificationCardMutation, TError, CreateOneStateIdentificationCardMutationVariables, TContext>) =>
    useMutation<CreateOneStateIdentificationCardMutation, TError, CreateOneStateIdentificationCardMutationVariables, TContext>(
      ['CreateOneStateIdentificationCard'],
      useFetchData<CreateOneStateIdentificationCardMutation, CreateOneStateIdentificationCardMutationVariables>(CreateOneStateIdentificationCardDocument),
      options
    );
export const UpdateOnePassportDocument = `
    mutation UpdateOnePassport($data: PassportUpdateInput!, $where: PassportWhereUniqueInput!) {
  updateOnePassport(data: $data, where: $where) {
    id
  }
}
    `;
export const useUpdateOnePassportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOnePassportMutation, TError, UpdateOnePassportMutationVariables, TContext>) =>
    useMutation<UpdateOnePassportMutation, TError, UpdateOnePassportMutationVariables, TContext>(
      ['UpdateOnePassport'],
      useFetchData<UpdateOnePassportMutation, UpdateOnePassportMutationVariables>(UpdateOnePassportDocument),
      options
    );
export const CreateOnePassportDocument = `
    mutation CreateOnePassport($data: PassportCreateInput!) {
  createOnePassport(data: $data) {
    id
  }
}
    `;
export const useCreateOnePassportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOnePassportMutation, TError, CreateOnePassportMutationVariables, TContext>) =>
    useMutation<CreateOnePassportMutation, TError, CreateOnePassportMutationVariables, TContext>(
      ['CreateOnePassport'],
      useFetchData<CreateOnePassportMutation, CreateOnePassportMutationVariables>(CreateOnePassportDocument),
      options
    );
export const UpdateOneStateIdentificationCardDocument = `
    mutation UpdateOneStateIdentificationCard($where: StateIdentificationCardWhereUniqueInput!, $data: StateIdentificationCardUpdateInput!) {
  updateOneStateIdentificationCard(where: $where, data: $data) {
    id
  }
}
    `;
export const useUpdateOneStateIdentificationCardMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOneStateIdentificationCardMutation, TError, UpdateOneStateIdentificationCardMutationVariables, TContext>) =>
    useMutation<UpdateOneStateIdentificationCardMutation, TError, UpdateOneStateIdentificationCardMutationVariables, TContext>(
      ['UpdateOneStateIdentificationCard'],
      useFetchData<UpdateOneStateIdentificationCardMutation, UpdateOneStateIdentificationCardMutationVariables>(UpdateOneStateIdentificationCardDocument),
      options
    );
export const AcceptInviteDocument = `
    mutation AcceptInvite($inviteId: String!) {
  acceptInvite(inviteId: $inviteId) {
    email
    id
    organizationId
    redeemedAt
    redeemedById
    senderId
    vidVoucherId
  }
}
    `;
export const useAcceptInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AcceptInviteMutation, TError, AcceptInviteMutationVariables, TContext>) =>
    useMutation<AcceptInviteMutation, TError, AcceptInviteMutationVariables, TContext>(
      ['AcceptInvite'],
      useFetchData<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument),
      options
    );
export const VidVoucherDocument = `
    query VIDVoucher($where: VIDVoucherWhereUniqueInput!) {
  vIDVoucher(where: $where) {
    clientMetadata
    createdAt
    email
    id
    inviteId
    orderId
    organizationId
    packageSetId
    postbackUrl
    status
    updatedAt
    passTypeId
    userId
  }
}
    `;
export const useVidVoucherQuery = <
      TData = VidVoucherQuery,
      TError = unknown
    >(
      variables: VidVoucherQueryVariables,
      options?: UseQueryOptions<VidVoucherQuery, TError, TData>
    ) =>
    useQuery<VidVoucherQuery, TError, TData>(
      ['VIDVoucher', variables],
      useFetchData<VidVoucherQuery, VidVoucherQueryVariables>(VidVoucherDocument).bind(null, variables),
      options
    );
export const VidVouchersDocument = `
    query VIDVouchers($where: VIDVoucherWhereInput!, $orderBy: [VIDVoucherOrderByWithRelationInput!], $cursor: VIDVoucherWhereUniqueInput, $take: Int, $skip: Int, $packageSetsWhere: PackageSetWhereInput!) {
  vIDVouchers(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    branchIOShortCode
    clientMetadata
    createdAt
    dateOfBirth
    email
    fileNumber
    firstName
    id
    inviteId
    lastName
    middleName
    orderId
    organizationId
    packageSetId
    phoneNumber
    postbackUrl
    primaryContactMethod
    status
    suffix
    updatedAt
    userId
  }
  packageSets(where: $packageSetsWhere) {
    description
    id
    name
  }
  aggregateVIDVoucher(where: $where, orderBy: $orderBy, cursor: $cursor) {
    _count {
      _all
    }
  }
}
    `;
export const useVidVouchersQuery = <
      TData = VidVouchersQuery,
      TError = unknown
    >(
      variables: VidVouchersQueryVariables,
      options?: UseQueryOptions<VidVouchersQuery, TError, TData>
    ) =>
    useQuery<VidVouchersQuery, TError, TData>(
      ['VIDVouchers', variables],
      useFetchData<VidVouchersQuery, VidVouchersQueryVariables>(VidVouchersDocument).bind(null, variables),
      options
    );
export const GetInviteCountDocument = `
    query GetInviteCount($where: VIDVoucherWhereInput) {
  aggregateVIDVoucher(where: $where) {
    _count {
      _all
    }
  }
}
    `;
export const useGetInviteCountQuery = <
      TData = GetInviteCountQuery,
      TError = unknown
    >(
      variables?: GetInviteCountQueryVariables,
      options?: UseQueryOptions<GetInviteCountQuery, TError, TData>
    ) =>
    useQuery<GetInviteCountQuery, TError, TData>(
      variables === undefined ? ['GetInviteCount'] : ['GetInviteCount', variables],
      useFetchData<GetInviteCountQuery, GetInviteCountQueryVariables>(GetInviteCountDocument).bind(null, variables),
      options
    );
export const CheckVoucherValidityDocument = `
    query CheckVoucherValidity($voucherId: String!) {
  checkVoucherValidity(voucherId: $voucherId) {
    clientMetadata
    createdAt
    email
    id
    inviteId
    orderId
    organizationId
    packageSet {
      id
      collectSSN
      showDisclosures
      useSampleId
    }
    packageSetId
    postbackUrl
    status
    updatedAt
    passTypeId
    userId
  }
}
    `;
export const useCheckVoucherValidityQuery = <
      TData = CheckVoucherValidityQuery,
      TError = unknown
    >(
      variables: CheckVoucherValidityQueryVariables,
      options?: UseQueryOptions<CheckVoucherValidityQuery, TError, TData>
    ) =>
    useQuery<CheckVoucherValidityQuery, TError, TData>(
      ['CheckVoucherValidity', variables],
      useFetchData<CheckVoucherValidityQuery, CheckVoucherValidityQueryVariables>(CheckVoucherValidityDocument).bind(null, variables),
      options
    );
export const GetInviteDocument = `
    query GetInvite($inviteId: String!) {
  getInvite(inviteId: $inviteId) {
    redeemedById
    redeemedAt
  }
}
    `;
export const useGetInviteQuery = <
      TData = GetInviteQuery,
      TError = unknown
    >(
      variables: GetInviteQueryVariables,
      options?: UseQueryOptions<GetInviteQuery, TError, TData>
    ) =>
    useQuery<GetInviteQuery, TError, TData>(
      ['GetInvite', variables],
      useFetchData<GetInviteQuery, GetInviteQueryVariables>(GetInviteDocument).bind(null, variables),
      options
    );
export const DeleteOneVidVoucherDocument = `
    mutation DeleteOneVidVoucher($where: VIDVoucherWhereUniqueInput!) {
  deleteOneVIDVoucher(where: $where) {
    id
    userId
    updatedAt
    status
    postbackUrl
    packageSetId
    organizationId
    orderId
    inviteId
    email
    createdAt
  }
}
    `;
export const useDeleteOneVidVoucherMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteOneVidVoucherMutation, TError, DeleteOneVidVoucherMutationVariables, TContext>) =>
    useMutation<DeleteOneVidVoucherMutation, TError, DeleteOneVidVoucherMutationVariables, TContext>(
      ['DeleteOneVidVoucher'],
      useFetchData<DeleteOneVidVoucherMutation, DeleteOneVidVoucherMutationVariables>(DeleteOneVidVoucherDocument),
      options
    );
export const CreateOneVidVoucherDocument = `
    mutation CreateOneVidVoucher($data: CreateOneVidVoucherInput!) {
  createOneVIDVoucher(data: $data) {
    inviteData {
      contactInformation {
        email
        phoneNumber
      }
    }
    inviteExpiry
    inviteId
    packageId
    packageName
    vIDInviteUrl
    vIDQRCode
    vIDShortCode
  }
}
    `;
export const useCreateOneVidVoucherMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOneVidVoucherMutation, TError, CreateOneVidVoucherMutationVariables, TContext>) =>
    useMutation<CreateOneVidVoucherMutation, TError, CreateOneVidVoucherMutationVariables, TContext>(
      ['CreateOneVidVoucher'],
      useFetchData<CreateOneVidVoucherMutation, CreateOneVidVoucherMutationVariables>(CreateOneVidVoucherDocument),
      options
    );
export const CreateManyVidVoucherDocument = `
    mutation CreateManyVidVoucher($data: [CreateOneVidVoucherInput!]!) {
  createManyVIDVoucher(data: $data) {
    data {
      inviteId
      packageId
      packageName
      vIDInviteUrl
      vIDQRCode
      vIDShortCode
    }
    errors {
      error
      email
    }
  }
}
    `;
export const useCreateManyVidVoucherMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateManyVidVoucherMutation, TError, CreateManyVidVoucherMutationVariables, TContext>) =>
    useMutation<CreateManyVidVoucherMutation, TError, CreateManyVidVoucherMutationVariables, TContext>(
      ['CreateManyVidVoucher'],
      useFetchData<CreateManyVidVoucherMutation, CreateManyVidVoucherMutationVariables>(CreateManyVidVoucherDocument),
      options
    );
export const CreateOneVidWebVoucherDocument = `
    mutation createOneVIDWebVoucher($data: CreateOneVidVoucherInput!) {
  createOneVIDWebVoucher(data: $data) {
    inviteId
    packageId
    packageName
    vIDInviteUrl
    vIDQRCode
    vIDShortCode
  }
}
    `;
export const useCreateOneVidWebVoucherMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOneVidWebVoucherMutation, TError, CreateOneVidWebVoucherMutationVariables, TContext>) =>
    useMutation<CreateOneVidWebVoucherMutation, TError, CreateOneVidWebVoucherMutationVariables, TContext>(
      ['createOneVIDWebVoucher'],
      useFetchData<CreateOneVidWebVoucherMutation, CreateOneVidWebVoucherMutationVariables>(CreateOneVidWebVoucherDocument),
      options
    );