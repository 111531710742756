import { CheckboxGroup } from "@cerebruminc/cerebellum";
import { useAtomValue } from "jotai";
import { availableFiltersAtom, filterModalFiltersAtom } from "src/components/FiltersModal/atom";
import { UserTableFilterIds } from "../types";
import { useUpdateFilterModalFilters } from "src/components/FiltersModal/hooks/useUpdateFilterModalFilters";
import { TabFormContainer } from "src/components/FiltersModal/TabFormContainer";
import React from "react";
const TAB_ID = UserTableFilterIds.Organization;
export function OrganizationsForm({
  allFilters
}: {
  allFilters: any;
}) {
  const availableFilters = useAtomValue(availableFiltersAtom);
  const draftFilters = useAtomValue(filterModalFiltersAtom);
  const updateFilterModalFilters = useUpdateFilterModalFilters();
  const typeCheckedFilters = Array.isArray(draftFilters[TAB_ID]) ? draftFilters[TAB_ID] : [];
  const noActiveFilters = typeCheckedFilters?.length === 0;
  return <TabFormContainer allFilters={allFilters} tabId={TAB_ID} title="Organizations" noActiveFilters={noActiveFilters}>
      <CheckboxGroup wrapText={true} activeValues={typeCheckedFilters} checkboxes={availableFilters?.organizations?.map(item => ({
      label: item.label,
      value: item.value
    }))} label=" " name={TAB_ID} onClick={event => {
      const newValue = event.target.value;
      updateFilterModalFilters({
        filterIdArray: typeCheckedFilters,
        newValue,
        tabId: TAB_ID
      });
    }} />
    </TabFormContainer>;
}